import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogContent, TextField, DialogActions, Button, FormHelperText } from '@mui/material';
import React, { FunctionComponent, useState, useEffect } from 'react';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { store } from 'src/app/store';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { updateSegmentAsync, setCurSeg, selectCurSeg } from 'src/features/contact/contactList/contactListSlice';
const SegmentEdit: FunctionComponent<{
  editOpen: any;
  onReload: () => void;
  onClose: () => void;
}> = ({
  editOpen,
  onReload,
  onClose
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const curSeg = useAppSelector(selectCurSeg);
  const [value, setValue] = useState<string>('');
  const handleClose = () => {
    setValue('');
    onClose();
  };
  const handleSaveEdit = async () => {
    const res: any = await dispatch(updateSegmentAsync({
      ...editOpen.item,
      name: value
    })).unwrap();
    if (res.code === CODE.OK) {
      handleClose();
      store.dispatch(setCurSeg({
        ...curSeg,
        name: value
      }));
      onReload();
    }
  };
  useEffect(() => {
    setValue(editOpen.item.name);
  }, [editOpen]);
  return <Dialog open={editOpen.open}>
			<CustomDialogTitle title={formatMessage({
      defaultMessage: 'Edit segment name'
    })} onClose={handleClose} />
			<DialogContent sx={{
      width: '412px'
    }}>
				<TextField autoFocus={true} variant="outlined" autoComplete="off" value={value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      }} sx={{
        width: '396px'
      }} />
				{value && value.length > 60 && <FormHelperText error>
						<ErrorHelperText>
							{formatMessage({
            defaultMessage: 'Max Length 60'
          })}
						</ErrorHelperText>
					</FormHelperText>}
			</DialogContent>
			<DialogActions sx={{
      p: 2
    }}>
				<Button variant="outlined" onClick={handleClose}>
					<FormattedMessage defaultMessage={'Cancel'} />
				</Button>
				<Button variant="contained" sx={{
        ml: 2
      }} disabled={value === '' || (value && value.length > 60 ? true : false)} onClick={handleSaveEdit}>
					<FormattedMessage defaultMessage={'Save'} />
				</Button>
			</DialogActions>
		</Dialog>;
};
export default SegmentEdit;