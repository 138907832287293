import { Box } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { FunctionComponent } from 'react';
const ErrorHelperText: FunctionComponent<{
  children: any;
  textStyle?: any;
  sx?: object;
  hasIcon?: boolean;
}> = ({
  children,
  textStyle = {},
  sx = {},
  hasIcon = true
}) => {
  return <Box component="span" sx={{
    display: 'flex',
    alignItems: 'flex-start',
    mx: 0,
    my: '4px',
    ...sx
  }}>
			{children && <>
					{hasIcon && <ErrorRoundedIcon sx={{
        fontSize: '16px',
        color: 'error.main',
        mr: '4px',
        mt: '1px'
      }} />}
					<span style={{
        color: '#F5222D',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 500,
        ...textStyle
      }}>
						{children}
					</span>
				</>}
		</Box>;
};
export default ErrorHelperText;