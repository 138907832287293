export const timeNumArr = ['MORE_THAN', 'LESS_THAN'];
export const timeArr = ['BEFORE', 'AFTER', 'ON'];

export const timeOptions = [
	{ label: 'more than', value: 'MORE_THAN' },
	{ label: 'less than', value: 'LESS_THAN' },
	{ label: 'between', value: 'BETWEEN' },
	{ label: 'before', value: 'BEFORE' },
	{ label: 'after', value: 'AFTER' },
	{ label: 'on', value: 'ON' },
];

export const stringOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'is not', value: 'IS_NOT' },
	{ label: 'contains', value: 'CONTAINS' },
];

export const booleanOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'is not', value: 'IS_NOT' },
	{ label: 'is unknown', value: 'IS_UNKNOWN' },
];

export const numberOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'greater than', value: 'GREATER_THAN' },
	{ label: 'less than', value: 'LESS_THAN' },
	{ label: 'between', value: 'BETWEEN' },
];

export interface ContactListParams {
	query: any;
	lastSeenDesc?: boolean;
	creatTimeDesc?: boolean;
	blockTimeDesc?: boolean;
	pageNo: number;
	pageSize: number;
	segmentId?: string;
	blocked?: boolean;
}

export interface CustomAttrDefParams {
	custom: boolean;
	block?: boolean;
}

export interface ContactItemParams {
	nickName: string;
	phoneNumber: string;
	email: string;
	tags: Array<string>;
	customAttrs: any;
	contactId?: string;
}

export interface BatchAddTagParams {
	contactIds?: Array<string>;
	searchConditions?: any;
	tags: Array<any>;
}

export interface DelTagParams {
	contactId: string;
	tag: string;
}

export interface SegmentParams {
	id?: string;
	name?: string;
	query?: any;
}
