import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useEffect, useRef, useState } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useCountdown = (initialTime: number) => {
	const [time, setTime] = useState(initialTime);
	const [isRunning, setIsRunning] = useState(false);
	const timerId = useRef<any>();

	useEffect(() => {
		if (isRunning && time > 0) {
			timerId.current = setTimeout(() => {
				setTime(time - 1);
			}, 1000);
		} else {
			setIsRunning(false);
		}

		return () => {
			if (timerId.current) {
				clearTimeout(timerId.current);
			}
		};
	}, [isRunning, time]);

	// 定义一个开始倒计时的函数
	const start = () => {
		setIsRunning(true);
	};

	// 定义一个暂停倒计时的函数
	const pause = () => {
		setIsRunning(false);
	};

	// 定义一个重置倒计时的函数
	const reset = () => {
		setTime(initialTime);
		setIsRunning(true);
	};

	// 返回时间和控制函数
	return { time, isRunning, start, pause, reset };
};
