import { useIntl } from 'react-intl';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
const OperateFilter: FunctionComponent<{
  value: string;
  actionArea?: ReactNode;
  onChange: (e: string) => void;
  onClose?: () => void;
}> = ({
  value,
  onChange
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const e = (event.target as HTMLInputElement).value;
    onChange(e);
  };
  return <RadioGroup value={value} onChange={handleChange} sx={{
    '& .MuiFormControlLabel-root': {
      margin: 0,
      '& .MuiButtonBase-root': {
        p: '6px',
        mr: '4px'
      }
    }
  }}>
			<FormControlLabel value="AND" control={<Radio size="small" />} label={formatMessage({
      defaultMessage: 'and'
    })} />
			<FormControlLabel value="OR" control={<Radio size="small" />} label={formatMessage({
      defaultMessage: 'or'
    })} />
		</RadioGroup>;
};
export default OperateFilter;