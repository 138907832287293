import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPlatformInfo, getPlatformInstallPath, getPlatformSyncStatus, startSync } from './integrationAPI';
import { RootState } from 'src/app/store';
import { CODE } from 'src/app/const/code';

const initialState: any = {
	isExpand: true,
	shopify: '',
};

export const getPlatformSyncStatusAsync = createAsyncThunk('integration/getPlatformSyncStatus', async () => {
	return getPlatformSyncStatus('shopify');
});

export const getPlatformInfoAsync = createAsyncThunk('integration/getPlatformInfo', async () => {
	return getPlatformInfo('shopify');
});

export const getPlatformInstallPathAsync = createAsyncThunk('integration/getPlatformInstallPath', async () => {
	return getPlatformInstallPath('shopify');
});

export const startSyncAsync = createAsyncThunk('integration/startSync', async () => {
	return startSync('shopify');
});

export const getIsExpand = (state: RootState) => state.integration.isExpand;
export const getShopify = (state: RootState) => state.integration.shopify;

export const integrationSlice = createSlice({
	name: 'integration',
	initialState,
	reducers: {
		changeExpand: (state, action) => {
			state.isExpand = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getPlatformInfoAsync.fulfilled, (state, action) => {
			const { code, data } = action.payload;
			if (code === CODE.OK) {
				state.shopify = data.website;
			}
		});
	},
});

export const { changeExpand } = integrationSlice.actions;
export default integrationSlice.reducer;
