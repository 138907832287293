import { OutlinedInput, FormHelperText } from '@mui/material';
import { FunctionComponent, ReactNode, forwardRef } from 'react';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
const computeNumber = (value: any, precision?: number) => {
  if (value === '') {
    return undefined;
  }
  if (precision === undefined) {
    return parseFloat(value);
  }
  return parseFloat(parseFloat(value).toFixed(precision));
};
const NumberInput: FunctionComponent<{
  size?: any;
  value?: number;
  onChange: any;
  placeholder?: string;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  min?: number;
  max?: number;
  error?: boolean;
  disabled?: boolean;
  helperText?: any;
  required?: boolean;
  autoFocus?: boolean;
  hasEvent?: boolean;
  isReturnNum?: boolean;
  sx?: any;
  precision?: number;
}> = forwardRef(({
  error = false,
  helperText = '',
  size,
  value,
  onChange,
  placeholder,
  min,
  max,
  disabled = false,
  endAdornment,
  startAdornment,
  required = false,
  autoFocus = false,
  precision,
  hasEvent = false,
  isReturnNum = true,
  sx
}, ref) => {
  const onValueChange = (e: any) => {
    let currentValue = e.target.value;
    if (!currentValue && required) {
      currentValue = min || 0;
    }
    if (hasEvent) {
      onChange(isReturnNum ? computeNumber(currentValue, precision) : currentValue, e);
    } else {
      onChange(isReturnNum ? computeNumber(currentValue, precision) : currentValue);
    }
  };
  const onValueBlur = (e: any) => {
    let currentValue = e.target.value;
    if (typeof min === 'number' && currentValue < min) {
      currentValue = min;
    } else if (typeof max === 'number' && currentValue > max) {
      currentValue = max;
    }
    if (hasEvent) {
      onChange(isReturnNum ? computeNumber(currentValue, precision) : currentValue, e);
    } else {
      onChange(isReturnNum ? computeNumber(currentValue, precision) : currentValue);
    }
  };
  return <>
			<OutlinedInput startAdornment={startAdornment} autoFocus={autoFocus} value={value} ref={ref} error={error} disabled={disabled} onChange={onValueChange} onBlur={onValueBlur} placeholder={placeholder} type="number" size={size} endAdornment={endAdornment} sx={{
      ...(sx && sx)
    }} />
			{error && helperText && <FormHelperText error={error}>
					<ErrorHelperText>{helperText}</ErrorHelperText>
				</FormHelperText>}
		</>;
});
export default NumberInput;