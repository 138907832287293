import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendSmsTest } from './smsAPI';
import { SendSmsTestParams } from './type';

export const sendSmsTestAsync = createAsyncThunk('sms/sendText', async (params: SendSmsTestParams) => {
	const res = await sendSmsTest(params);
	return res;
});

export const smsSlice = createSlice({
	name: 'sms',
	initialState: {},
	reducers: {},
});

export default smsSlice.reducer;
