import { Popper, Fade, Paper } from '@mui/material';
import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import OperateFilter from './components/OperateFilter';
import AddFilter from './components/AddFilter';
import EditFilter from './components/EditFilter';
const FilterPopper: FunctionComponent<{
  open: boolean;
  anchorEl: any;
  value: any;
  type: string;
  preType: string;
  onChange: (e: any) => void;
  onClose: () => void;
}> = ({
  open,
  anchorEl,
  value,
  type,
  preType,
  onChange,
  onClose
}) => {
  const [offset, setOffset] = useState<number>(0);
  useLayoutEffect(() => {
    if (anchorEl) {
      const top = anchorEl.getBoundingClientRect().top + 60;
      setOffset(top);
    }
  }, [anchorEl]);
  return <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition sx={{
    zIndex: 100
  }}>
			{({
      TransitionProps
    }) => <Fade {...TransitionProps} timeout={350}>
					<Paper sx={{
        boxShadow: '0px 2px 15px rgba(26, 30, 35, 0.14), 0px 4px 5px -3px rgba(26, 30, 35, 0.15)',
        borderRadius: '4px 4px 8px 8px',
        p: 1,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - ' + offset + 'px)',
        ...(type === 'operate' && {
          pr: '14px'
        }),
        ...(type === 'add' && {
          p: 0
        }),
        ...(type === 'edit' && {
          pr: 2
        })
      }}>
						{type === 'operate' ? <OperateFilter value={value} onChange={onChange} /> : type === 'add' ? <AddFilter onAdd={onChange} /> : type === 'edit' ? <EditFilter preType={preType} item={value} onChange={onChange} onClose={onClose} /> : <></>}
					</Paper>
				</Fade>}
		</Popper>;
};
export default FilterPopper;