import loadComponent from '../loadComponent';
const userProfileRouter = [{
  path: 'myProfile',
  element: loadComponent('userSettings/myProfile')
}, {
  path: 'security',
  element: loadComponent('userSettings/security')
}, {
  path: 'notification',
  element: loadComponent('userSettings/notification')
}, {
  path: 'cannedResponse',
  element: loadComponent('userSettings/cannedResponse')
}];
export default userProfileRouter;