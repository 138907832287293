import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import { FunctionComponent, ReactNode, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions<string>();
const OptionEditor: FunctionComponent<{
  value: any;
  error?: boolean;
  options?: Array<string>;
  helperText?: ReactNode;
  sx?: object;
  freeSolo?: boolean;
  multiple?: boolean;
  onChange: (value: any, e: any) => void;
}> = ({
  value,
  onChange,
  helperText,
  error = false,
  multiple = true,
  options,
  sx = {},
  freeSolo = true
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const [initValue] = useState(value);
  return <Autocomplete componentsProps={{
    popper: {
      sx: {
        zIndex: '3000!important'
      }
    }
  }} value={value} multiple={multiple} sx={sx} onChange={(event, newValue) => {
    onChange(newValue, event);
  }} filterOptions={(myOptions, params) => {
    const filtered = filter(myOptions, params);
    const {
      inputValue
    } = params;
    if (freeSolo) {
      // Suggest the creation of a new value
      const isExisting = myOptions.some(option => inputValue === option);
      if (inputValue !== '' && !isExisting) {
        filtered.push(inputValue);
      }
    }
    return filtered;
  }} selectOnFocus clearOnBlur handleHomeEndKeys options={options || initValue} getOptionLabel={option => {
    return option;
  }} renderOption={(props, option) => <li {...props}>{option}</li>} freeSolo={freeSolo} renderInput={params => <TextField placeholder={freeSolo ? formatMessage({
    defaultMessage: 'Type to search or create a new option'
  }) : formatMessage({
    defaultMessage: 'Type to search'
  })} {...params} error={error} helperText={helperText} />} />;
};
export default OptionEditor;