import { createContext, FC, ReactElement, useMemo, memo } from 'react';
import useFetchData from 'src/hooks/useFetchData';
import { orderBy } from 'lodash-es';
export interface IContext {
  tagList: any[];
  filterList: any[];
  teamList: any[];
  agentList: any[];
  tagMapping: any;
  filterMapping: any;
  teamMapping: any;
  agentMapping: any;
  [key: string]: any;
}
export const ChatBotFlowContext = createContext({} as IContext);
export const createDataMappingFromData = (data: any[], key: string) => {
  return data.reduce((acc, cur) => {
    return {
      ...acc,
      [cur[key]]: cur
    };
  }, {});
};
const ChatBotFlowProvider: FC<{
  children: ReactElement;
}> = ({
  children
}) => {
  /* 获取contact attributes */
  const {
    data: filterList,
    loading: filterListLoading
  } = useFetchData({
    type: 'get',
    url: '/api/contacts/customAttributeDefinition/all',
    params: {},
    formatDataFn: (data: any) => {
      const systemAttributes = orderBy(data.filter((item: any) => item.isCustomAttr === false), ['displayName']);
      const customAttributes = orderBy(data.filter((item: any) => item.isCustomAttr === true), ['displayName']);
      return [...systemAttributes, ...customAttributes].map((item: any) => ({
        column: item.key,
        type: item.displayType,
        displayName: item.displayName,
        values: item.values || []
      }));
    }
  });

  /* 获取tags */
  const {
    data: tagList,
    loading: tagListLoading,
    refresh: refreshTagList
  } = useFetchData({
    type: 'get',
    url: '/api/contacts/tags/all',
    params: {},
    formatDataFn: (data: any[]) => orderBy(data, ['tag'])
  });

  /* 获取teams */
  const {
    data: teamList,
    loading: teamListLoading
  } = useFetchData({
    type: 'get',
    url: '/api/inbox/team/all',
    params: {},
    formatDataFn: (data: any[]) => orderBy(data.map((item: any) => ({
      id: item.id,
      name: item.name
    })), ['name'])
  });

  /* 获取agents */
  const {
    data: agentList,
    loading: agentListLoading
  } = useFetchData({
    type: 'get',
    url: '/api/inbox/agent/all',
    params: {},
    formatDataFn: (data: any[]) => orderBy(data.map((item: any) => ({
      id: item.id,
      onlineStatus: item.onlineStatus,
      email: item.email,
      avatar: item.avatar,
      fullName: item.firstName + ' ' + item.lastName
    })), ['fullName'])
  });
  const isLoading = useMemo(() => {
    return filterListLoading || tagListLoading || teamListLoading || agentListLoading;
  }, [filterListLoading, tagListLoading, teamListLoading, agentListLoading]);
  return <ChatBotFlowContext.Provider value={{
    tagList,
    tagMapping: createDataMappingFromData(tagList, 'id'),
    filterList,
    filterMapping: createDataMappingFromData(filterList, 'column'),
    teamList,
    teamMapping: createDataMappingFromData(teamList, 'id'),
    agentList,
    agentMapping: createDataMappingFromData(agentList, 'id'),
    refreshTagList
  }}>
			{!isLoading && children}
		</ChatBotFlowContext.Provider>;
};
export default memo(ChatBotFlowProvider);