import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import NumberInput from 'src/app/components/numberInput';
import { isEmpty } from 'lodash-es';
const NumberRange: FunctionComponent<{
  value: any;
  onChange: (e: any) => void;
  preType: string;
}> = ({
  value,
  onChange,
  preType
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const [number1, setNumber1] = React.useState<any>(null);
  const [number2, setNumber2] = React.useState<any>(null);
  useEffect(() => {
    if (!isEmpty(value)) {
      const [num1, num2] = value?.split(',');
      if (!isEmpty(num1) && !isNaN(parseInt(num1))) {
        setNumber1(Number(num1));
      }
      if (!isEmpty(num2) && !isNaN(parseInt(num2))) {
        setNumber2(Number(num2));
      }
    }
  }, [value]);
  const onValueChange = (v: number, type: string) => {
    if (type === 'number1') {
      setNumber1(v);
      onChange(`${v || ''},${number2 || ''}`);
      return;
    }
    if (type === 'number2') {
      setNumber2(v);
      onChange(`${number1 || ''},${v || ''}`);
    }
  };
  return <>
			<Box sx={{
      display: 'flex',
      columnGap: '12px',
      alignItems: 'center'
    }}>
				<Box sx={{
        flex: 1
      }}>
					<NumberInput autoFocus={true} size="small" value={number1} onChange={(v: number) => onValueChange(v, 'number1')} hasEvent={true} isReturnNum={true} sx={{
          width: '100%',
          ...(!number1 && preType !== 'add' && {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #FF4842 !important'
            }
          })
        }} />
				</Box>
				<Typography variant="caption" color="primary.dark" component="div">
					{formatMessage({
          defaultMessage: '—'
        })}
				</Typography>
				<Box sx={{
        flex: 1
      }}>
					<NumberInput autoFocus={false} size="small" value={number2} onChange={(v: number) => onValueChange(v, 'number2')} hasEvent={true} isReturnNum={true} sx={{
          width: '100%',
          ...(!number2 && preType !== 'add' && {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #FF4842 !important'
            }
          })
        }} />
				</Box>
			</Box>
		</>;
};
export default NumberRange;