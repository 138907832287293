import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography, IconButton, Button } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import React, { FunctionComponent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import ConfirmTitle from 'src/app/components/confirm/ConfirmTitle';
import LongTooltipText from 'src/app/components/form/LongTooltipText';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { selectAllSegments, selectAllTags, selectCurSeg, selectCurTag, selectAllTotal, selectBlocklistTotal, getAllSegmentAsync, delSegmentAsync } from 'src/features/contact/contactList/contactListSlice';
import SegmentEdit from './SegmentEdit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export const colorMapping: any = {
  '176,35,84': 'rgba(253, 76, 125, 1)',
  '173,104,0': 'rgba(255, 214, 102, 1)',
  '0,110,80': 'rgba(17, 187, 130, 1)',
  '16,35,158': 'rgba(68, 87, 255, 1)',
  '58,27,142': 'rgba(96, 45, 237, 1)',
  '94,89,106': 'rgba(137, 131, 149, 1)'
};
const Segment: FunctionComponent<{
  isExpand: boolean;
  editable: boolean;
  onSegClick: (e: any) => void;
  onTagClick: (tag: any, query: any) => void;
}> = ({
  isExpand,
  editable,
  onSegClick,
  onTagClick
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const listItemBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: 1,
    cursor: 'pointer'
  };
  const listItemStyle = {
    display: 'flex',
    alignItems: 'center'
  };
  const listItemNameStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '24px'
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const curSegId = searchParams.get('curSegId');
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const allSegments = useAppSelector(selectAllSegments);
  const allTags = useAppSelector(selectAllTags);
  const curSeg = useAppSelector(selectCurSeg);
  const curTag = useAppSelector(selectCurTag);
  const allTotal = useAppSelector(selectAllTotal);
  const blocklistTotal = useAppSelector(selectBlocklistTotal);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSegExpand, setIsSegExpand] = useState<boolean>(true);
  const [isTagExpand, setIsTagExpand] = useState<boolean>(true);
  const [editOpen, setEditOpen] = useState<any>({
    open: false,
    item: {}
  });
  const handleSegClick = (e: any) => {
    if (curSegId) {
      navigate('/app/contact/contactList', {
        replace: true
      });
    }
    onSegClick(e);
  };
  const formatTagFilter = (tag: any) => {
    const query = {
      connector: 'AND',
      filters: [{
        conditions: [{
          column: 'Tag',
          operator: 'IS',
          value: tag.id,
          values: []
        }],
        connector: 'AND'
      }]
    };
    return query;
  };
  const handleTagClick = (e: any) => {
    onTagClick(e.tag, formatTagFilter(e));
  };
  const handleEdit = (open: boolean, item: any) => {
    setEditOpen({
      open,
      item
    });
  };
  const getSegment = async () => {
    await dispatch(getAllSegmentAsync());
  };
  const handleDel = (event: any, e: any) => {
    event.stopPropagation();
    confirm({
      title: <ConfirmTitle color="error" title={formatMessage({
        defaultMessage: 'Are you sure to delete this Segment?'
      })} />,
      content: <FormattedMessage defaultMessage={'The segment of {name} will be removed'} values={{
        name: <Typography variant="subtitle2" component="span" sx={{
          wordBreak: 'break-all'
        }}>
								{e.name}
							</Typography>
      }} />
    }).then(async () => {
      const res: any = await dispatch(delSegmentAsync({
        id: e.id
      })).unwrap();
      if (res.code === CODE.OK) {
        getSegment();
        if (e.id === curSeg.id) {
          handleSegClick('all');
        }
      }
    }).catch(() => {});
  };
  return <Box sx={{
    width: isExpand ? '218px' : 0,
    height: '100%',
    boxSizing: 'border-box',
    p: isExpand ? 3 : 0,
    overflow: 'scroll',
    backgroundColor: 'fill.hover'
  }}>
			<Typography variant="h3" sx={{
      mb: 2
    }}>
				<FormattedMessage defaultMessage={'Contact list'} />
			</Typography>
			<Box onClick={() => handleSegClick('all')} sx={{
      ...listItemBoxStyle,
      '&:hover': {
        color: 'secondary.main'
      },
      color: curSeg === 'all' ? 'secondary.main' : 'primary.dark'
    }}>
				<Typography variant="subtitle2">
					<FormattedMessage defaultMessage={'All'} />
				</Typography>
				<Typography variant="subtitle2" sx={{
        lineHeight: '24px'
      }}>
					{allTotal}
				</Typography>
			</Box>
			{allSegments.length > 0 && <>
					<Box sx={{
        ...listItemBoxStyle,
        '&:hover': {
          color: 'secondary.main'
        }
      }} onClick={() => {
        if (isEdit) {
          setIsEdit(false);
        }
        setIsSegExpand(!isSegExpand);
      }}>
						<Typography variant="subtitle2">
							<FormattedMessage defaultMessage={'Segment'} />
						</Typography>
						<Box sx={{
          mr: -1
        }}>
							{editable && <Button size="small" onClick={e => {
            if (isEdit || isSegExpand) {
              e.stopPropagation();
            }
            setIsEdit(!isEdit);
          }} sx={{
            ml: 'auto',
            minWidth: 'auto',
            height: '22px',
            fontSize: '12px'
          }}>
									{isEdit ? formatMessage({
              defaultMessage: 'Exit edit'
            }) : formatMessage({
              defaultMessage: 'Edit'
            })}
								</Button>}
							<Button size="small" sx={{
            ml: 'auto',
            minWidth: 'auto',
            height: '22px',
            fontSize: '12px'
          }}>
								<ExpandMoreIcon sx={{
              width: '20px',
              height: '20px',
              fontSize: '20px',
              color: '#1A1E22',
              cursor: 'pointer',
              transform: !isSegExpand ? 'rotate(180deg)' : 'rotate(0deg)'
            }} />
							</Button>
						</Box>
					</Box>
					{isSegExpand && allSegments.map((e: any) => {
        return <Box key={e.id} onClick={() => handleSegClick(e)} sx={{
          ...listItemBoxStyle,
          color: curSeg.id && curSeg.id === e.id ? 'secondary.main' : 'primary.dark',
          pl: 2,
          '&:hover': {
            color: 'secondary.main',
            '.delIcon': {
              color: 'rgba(65, 69, 73, 0.65)'
            }
          }
        }}>
									<Box sx={{
            ...listItemStyle,
            width: '100px'
          }}>
										<LongTooltipText variant="body1" maxLine={1} maxWidth={100} sx={{
              lineHeight: '24px'
            }}>
											{e.name}
										</LongTooltipText>
										{isEdit && <IconButton sx={{
              p: '4px'
            }} onClick={() => handleEdit(true, e)}>
												<EditRoundedIcon sx={{
                fontSize: '16px',
                color: '#5E596A'
              }} />
											</IconButton>}
									</Box>
									<Box sx={{
            ...listItemStyle,
            width: '50px',
            justifyContent: 'flex-end'
          }}>
										<Typography variant="caption" component="p" sx={listItemNameStyle}>
											{e.count}
										</Typography>
										{isEdit && <CancelRoundedIcon className="delIcon" sx={{
              fontSize: '16px',
              color: 'rgb(170, 173, 179)'
            }} onClick={(event: any) => handleDel(event, e)} />}
									</Box>
								</Box>;
      })}
				</>}
			{allTags.length > 0 && <>
					<Box sx={{
        ...listItemBoxStyle,
        '&:hover': {
          color: 'secondary.main'
        }
      }} onClick={() => setIsTagExpand(!isTagExpand)}>
						<Typography variant="subtitle2">
							<FormattedMessage defaultMessage={'Tag'} />
						</Typography>
						<Box sx={{
          mr: -1
        }}>
							<Button size="small" sx={{
            ml: 'auto',
            minWidth: 'auto',
            height: '22px',
            fontSize: '12px'
          }}>
								<ExpandMoreIcon sx={{
              width: '20px',
              height: '20px',
              fontSize: '20px',
              color: '#1A1E22',
              cursor: 'pointer',
              transform: !isTagExpand ? 'rotate(180deg)' : 'rotate(0deg)'
            }} />
							</Button>
						</Box>
					</Box>
					{isTagExpand && allTags.map((tag: any) => {
        const color = tag.color;
        const colorKey = `${color.r},${color.g},${color.b}`;
        const labelBg = colorMapping[colorKey] || `rgb(${colorKey})`;
        return <Box key={tag.id} onClick={() => handleTagClick(tag)} sx={{
          ...listItemBoxStyle,
          color: curTag && curTag === tag.tag ? 'secondary.main' : 'primary.dark',
          pl: 2,
          '&:hover': {
            color: 'secondary.main',
            '.delIcon': {
              color: 'rgba(65, 69, 73, 0.65)'
            }
          }
        }}>
									<Box sx={{
            ...listItemStyle,
            width: '130px'
          }}>
										<Box sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              flexShrink: 0,
              mr: '10px',
              background: labelBg
            }} />
										<LongTooltipText variant="body1" maxLine={1} maxWidth={120} sx={{
              lineHeight: '24px',
              wordBreak: 'break-all',
              height: '24px'
            }}>
											{tag.tag}
										</LongTooltipText>
									</Box>
								</Box>;
      })}
				</>}
			<Box onClick={() => handleSegClick('blocklist')} sx={{
      ...listItemBoxStyle,
      '&:hover': {
        color: 'secondary.main'
      },
      color: curSeg === 'blocklist' ? 'secondary.main' : 'primary.dark'
    }}>
				<Typography variant="subtitle2">
					<FormattedMessage defaultMessage={'Blocklist'} />
				</Typography>
				<Typography variant="subtitle2" sx={{
        lineHeight: '24px'
      }}>
					{blocklistTotal}
				</Typography>
			</Box>
			<SegmentEdit editOpen={editOpen} onReload={getSegment} onClose={() => handleEdit(false, {})} />
		</Box>;
};
export default Segment;