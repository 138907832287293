import { useEffect, useRef } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import { getIntegrationStatusAsync } from 'src/features/user/userSlice';
const useBroadcastChannelEvent = () => {
  const dispatch = useAppDispatch();
  const bcRef: any = useRef();
  useEffect(() => {
    if (window.BroadcastChannel) {
      bcRef.current = new BroadcastChannel('broadcastChannelEvent');
      bcRef.current.onmessage = async (event: any) => {
        const eventType = event.data?.type;
        if (eventType === 'userChange') {
          sessionStorage.clear();
          localStorage.clear();
          window.location.reload();
        } else if (eventType === 'changeIntegrationStatus') {
          dispatch(getIntegrationStatusAsync());
        }
      };
    }
    return () => {
      if (bcRef.current) {
        bcRef.current.close();
      }
    };
  }, []);
  return null;
};
export default useBroadcastChannelEvent;