import { Accordion, IconButton, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
export enum Size {
  LARGE,
  MEDIUM,
}
const CustomAccordion: FunctionComponent<{
  disabled?: boolean;
  isFirst?: boolean;
  expanded: boolean;
  onChange: any;
  title: string | ReactNode;
  titleIcon?: ReactNode;
  children: ReactNode;
  detailStyle?: any;
  onDelete?: () => void;
  size?: Size;
  sx?: object;
}> = ({
  disabled,
  size = Size.LARGE,
  isFirst = false,
  onDelete,
  expanded,
  onChange,
  title,
  titleIcon,
  children,
  detailStyle = {},
  sx = {}
}) => {
  const styleMap = {
    titlePadding: {
      [Size.LARGE]: '28px 32px',
      [Size.MEDIUM]: '16px 24px'
    }
  };
  return <Accordion disabled={disabled} expanded={expanded} onChange={onChange} sx={{
    ...(!isFirst && {
      boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.10)'
    }),
    margin: '0 !important',
    ...sx
  }}>
			<AccordionSummary sx={{
      p: styleMap.titlePadding[size]
    }}>
				<Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}>
					<Typography variant={size === Size.LARGE ? 'h2' : 'subtitle2'} sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
						{titleIcon && titleIcon}
						{title}
					</Typography>
					{onDelete && <IconButton sx={{
          width: '24px',
          height: '24px',
          mr: '10px'
        }} onClick={(e: any) => {
          e.stopPropagation();
          onDelete();
        }}>
							<DeleteOutlineRoundedIcon sx={{
            width: '16px',
            height: '16px',
            color: '#000'
          }} />
						</IconButton>}
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{
      ...(size === Size.MEDIUM ? {
        px: 0
      } : {}),
      ...detailStyle
    }}>
				{children}
			</AccordionDetails>
		</Accordion>;
};
export default CustomAccordion;