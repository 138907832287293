import { Node } from 'reactflow';
import { NodeErrorType, NodeType, WhatsAppFlowInBotData } from './type';
import { WhatsAppFlowsStatusEnum } from '../whatsappFlows/type';

export const getFlowIdFromNode = (node: Node) => {
	if (node.type === NodeType.ASK_QUESTION) {
		return node?.data?.config?.message?.interactive?.action?.parameters?.flow_id || '';
	}
	return '';
};

// 根据bot data中的flow数据，计算出是否有不适用的flow
export const computeWhatsAppFlowError = (node: Node, errorArray: Array<any>, whatsAppFlows: Array<WhatsAppFlowInBotData>) => {
	const flowIdFromNode = getFlowIdFromNode(node);
	if (!flowIdFromNode) {
		return errorArray;
	}
	let hasWhatsAppFlowError = false;
	const whatsAppFlowsIds = whatsAppFlows.map(flow => flow.id);
	// if (!whatsAppFlowsIds.includes(flowIdFromNode)) {
	// 	hasWhatsAppFlowError = true; // Flow已经被删除了
	// }
	for (let i = 0; i < whatsAppFlowsIds.length; i++) {
		if (whatsAppFlowsIds[i] === flowIdFromNode) {
			const status = whatsAppFlows[i].status;
			if (status === WhatsAppFlowsStatusEnum.DEPRECATED || status === WhatsAppFlowsStatusEnum.BLOCKED) {
				hasWhatsAppFlowError = true;
			}
		}
	}
	const nodeErrors = errorArray || [];
	// 如果hasWhatsAppFlowError为true，且nodeErrors中没有包含NodeErrorType.WHATSAPP_FLOW_STATUS_ERROR，则添加错误
	// 如果hasWhatsAppFlowError为false，且nodeErrors中有包含NodeErrorType.WHATSAPP_FLOW_STATUS_ERROR，则删除错误
	if (hasWhatsAppFlowError) {
		const hasWhatsAppFlowErrorNodeError = nodeErrors.find((error: any) => error.type === NodeErrorType.WHATSAPP_FLOW_STATUS_ERROR);
		if (!hasWhatsAppFlowErrorNodeError) {
			return [...nodeErrors, { nodeId: node.id, type: NodeErrorType.WHATSAPP_FLOW_STATUS_ERROR }];
		}
	} else {
		return nodeErrors.filter((error: any) => error.type !== NodeErrorType.WHATSAPP_FLOW_STATUS_ERROR);
	}
	return errorArray;
};
