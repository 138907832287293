import { Box, Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import ImageContainer from 'src/app/components/imageContainer';
import TableEmptyData from 'src/app/images/emptyData.png';
import TableEmptyDataNeutral from 'src/app/images/emptyData_neutral.png';
import { useAppSelector } from 'src/app/hooks';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
import { useIntl } from 'react-intl';
const EmptyData: FunctionComponent<{
  sx?: any;
  description?: ReactNode;
  children?: any;
  imageSize?: number;
}> = ({
  sx,
  description,
  children,
  imageSize = 220
}) => {
  const {
    formatMessage
  } = useIntl();
  if (description === undefined) {
    description = formatMessage({
      defaultMessage: 'No data'
    });
  }
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  return <Box sx={{
    textAlign: 'center',
    ...(sx && sx)
  }}>
			<ImageContainer backgroundImage={isOtherBrand ? TableEmptyDataNeutral : TableEmptyData} sx={{
      width: `${imageSize}px`,
      height: `${imageSize}px`,
      margin: 'auto'
    }} />
			<Typography variant="body1" color="rgba(0, 0, 0, 0.85)" sx={{
      my: 2,
      textAlign: 'center'
    }}>
				{description}
			</Typography>
			{children}
		</Box>;
};
export default EmptyData;