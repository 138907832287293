import { FunctionComponent, ReactNode } from 'react';
import { Box } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import useTheme from '@mui/material/styles/useTheme';
const ConfirmIcon: FunctionComponent<{
  color?: 'warning' | 'info' | 'error';
  icon?: ReactNode;
  iconSx?: any;
}> = ({
  color = 'info',
  iconSx,
  icon = <ErrorOutlineOutlinedIcon sx={iconSx} />
}) => {
  const theme = useTheme();
  return <Box sx={{
    color: theme.palette[color].main,
    display: 'inline-block',
    mr: 2,
    verticalAlign: 'middle'
  }}>
			{icon}
		</Box>;
};
export default ConfirmIcon;