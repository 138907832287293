import { POST } from 'src/utils/fetch';
import { LoginParams, CheckAccountReturnType, UpdateDefaultPasswordParams, SendVcodeParams, LoginAccountObject, ResetPasswordParams, CheckConfirmCodeParams, SendResetPassword } from './type';

export async function login(params: LoginParams) {
	return POST('/api/user/login', params, true, true);
}

export async function checkAccount(params: LoginAccountObject) {
	return (await POST('/api/user/checkLoginAccount', params, true)) as CheckAccountReturnType;
}

export async function checkRegisterForbidden() {
	return (await POST('/api/user/precheck', undefined, true, true)) as CheckAccountReturnType;
}

export async function sendVcode(params: SendVcodeParams) {
	return POST('/api/user/mobileSetting/vcode/send', params, true);
}

export async function resend(params: LoginAccountObject) {
	return POST('/api/user/register/resend', params, true, true);
}

export async function getCurrencyList() {
	return POST('/api/user/register/currency/search', undefined, true);
}

export async function resetPassword(params: ResetPasswordParams) {
	return POST('/api/user/password/forget/reset', params);
}

export async function verifyMobile(params: any) {
	return POST('/api/user/mobileSetting/vcode/verify', params);
}

export async function sendResetPassword(params: SendResetPassword) {
	return POST('/api/user/password/forget', params, true);
}

export async function register(params: any) {
	return POST('/api/user/register', params, true);
}

export async function logout() {
	return POST('/api/user/logout', undefined, true);
}

export async function checkConfirmCode(params: CheckConfirmCodeParams) {
	return POST('/api/user/password/forget/confirmCode/check', params, true, true);
}

export async function registerConfirm(params: CheckConfirmCodeParams) {
	return POST('/api/user/register/confirm', params, true, true);
}

export async function updateDefaultPassword(params: UpdateDefaultPasswordParams) {
	return POST('/api/user/password/updateDefaultPassword', params);
}

export async function needUpdatePassword() {
	return POST('/api/user/needUpdatePassword', {}, true, true);
}
export async function emailCheck(params: { loginAccount: string }) {
	return POST('/api/user/emailCheck', params, true, true);
}
