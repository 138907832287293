import { FunctionComponent } from 'react';
import ConfirmIcon from './ConfirmIcon';
import { Box } from '@mui/material';
const ConfirmTitle: FunctionComponent<{
  color?: 'warning' | 'info' | 'error';
  title: any;
}> = ({
  color = 'info',
  title
}) => {
  return <Box sx={{
    display: 'flex',
    alignContent: 'center'
  }}>
			<ConfirmIcon color={color} />
			{title}
		</Box>;
};
export default ConfirmTitle;