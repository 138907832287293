import { Popper, Fade, Paper } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import VariablesSelect from './VariablesSelect';
const VariablesPopper: FunctionComponent<{
  open: boolean;
  anchorEl: any;
  onChange: (value: string) => void;
  onClose: () => void;
}> = props => {
  const {
    open,
    anchorEl,
    onChange,
    onClose
  } = props;
  const [placement, setPlacement] = useState<string>('bottom-start');
  const [maxHeight, setMaxHeight] = useState<number>(400);
  const updatePlacement = (v: number) => {
    const rect = anchorEl.getBoundingClientRect();
    const distanceToBottom = window.innerHeight - rect.bottom;
    const distanceToTop = rect.top;
    const boxHeight = v > 400 ? 400 : v;
    if (distanceToBottom >= boxHeight) {
      setPlacement('bottom-start');
    } else if (distanceToTop >= boxHeight) {
      setPlacement('top-start');
    } else {
      if (distanceToBottom >= distanceToTop) {
        setMaxHeight(distanceToBottom - 2);
        setPlacement('bottom-start');
      } else {
        setMaxHeight(distanceToTop - 2);
        setPlacement('top-start');
      }
    }
  };
  return <Popper open={open} anchorEl={anchorEl} placement={placement as any} transition sx={{
    zIndex: 2000
  }}>
			{({
      TransitionProps
    }) => <Fade {...TransitionProps} timeout={350}>
					<Paper sx={{
        boxShadow: '0px 2px 15px rgba(26, 30, 35, 0.14), 0px 4px 5px -3px rgba(26, 30, 35, 0.15)',
        borderRadius: '4px 4px 8px 8px',
        overflowY: 'auto',
        maxHeight: maxHeight + 'px',
        width: '272px',
        boxSizing: 'border-box'
      }}>
						<VariablesSelect onClick={onChange} onClose={onClose} updatePlacement={updatePlacement} />
					</Paper>
				</Fade>}
		</Popper>;
};
export default VariablesPopper;