import { hasValue } from 'src/utils/tools';
import { isEmpty, lowerCase } from 'lodash-es';
import { format } from 'date-fns';

export enum GroupTypes {
	CONTACT_ATTRIBUTES = 'CONTACT',
	CUSTOM_EVENTS = 'CUSTOM_DATA_EVENT',
	SHOPIFY_EVENTS = 'SHOPIFY',
}

export const stringOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'is not', value: 'IS_NOT' },
	{ label: 'contains', value: 'CONTAINS' },
	{ label: 'is unknown', value: 'IS_UNKNOWN' },
	{ label: 'have any value', value: 'HAS_ANY_VALUE' },
];

export const numberOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'greater than', value: 'GREATER_THAN' },
	{ label: 'less than', value: 'LESS_THAN' },
	{ label: 'between', value: 'BETWEEN' },
	{ label: 'is unknown', value: 'IS_UNKNOWN' },
	{ label: 'have any value', value: 'HAS_ANY_VALUE' },
];

export const booleanOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'is not', value: 'IS_NOT' },
	{ label: 'is unknown', value: 'IS_UNKNOWN' },
	{ label: 'have any value', value: 'HAS_ANY_VALUE' },
];

export const dateOptions = [
	{ label: 'more than', value: 'MORE_THAN' },
	{ label: 'less than', value: 'LESS_THAN' },
	{ label: 'between', value: 'BETWEEN' },
	{ label: 'before', value: 'BEFORE' },
	{ label: 'after', value: 'AFTER' },
	{ label: 'on', value: 'ON' },
	{ label: 'is unknown', value: 'IS_UNKNOWN' },
	{ label: 'have any value', value: 'HAS_ANY_VALUE' },
];

export const arrayOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'is not', value: 'IS_NOT' },
	{ label: 'is unknown', value: 'IS_UNKNOWN' },
	{ label: 'have any value', value: 'HAS_ANY_VALUE' },
];

export enum Types {
	TEXT = 'Text',
	LONG_TEXT = 'Long Text',
	NUMBER = 'Number',
	BOOLEAN = 'True/False',
	ARRAY = 'Array',
	DATE = 'Time',
}

export enum EventAttributesType {
	STRING = Types.TEXT,
	NUMBER = Types.NUMBER,
	TIMESTAMP = Types.DATE,
	URL = Types.TEXT,
}

export enum OperatorTypes {
	IS = 'IS',
	IS_NOT = 'IS_NOT',
	CONTAINS = 'CONTAINS',
	GREATER_THAN = 'GREATER_THAN',
	MORE_THAN = 'MORE_THAN',
	LESS_THAN = 'LESS_THAN',
	BETWEEN = 'BETWEEN',
	IS_UNKNOWN = 'IS_UNKNOWN',
	BEFORE = 'BEFORE',
	AFTER = 'AFTER',
	ON = 'ON',
	HAS_ANY_VALUE = 'HAS_ANY_VALUE',
}

export const operatorMapping = {
	[Types.TEXT]: stringOptions,
	[Types.LONG_TEXT]: stringOptions,
	[Types.NUMBER]: numberOptions,
	[Types.BOOLEAN]: booleanOptions,
	[Types.ARRAY]: arrayOptions,
	[Types.DATE]: dateOptions,
};

const formatValue = (data: any, tagsMapping: any) => {
	if (!hasValue(data.value)) {
		return '';
	}
	if (hasValue(data.value) && data.column === 'tags') {
		return tagsMapping[data.value as keyof typeof tagsMapping]?.tag || 'removed';
	}
	if (data.type === Types.NUMBER && data.operator === OperatorTypes.BETWEEN) {
		return data.value.replace(',', '~');
	}
	if (data.type === Types.DATE) {
		if ([OperatorTypes.MORE_THAN, OperatorTypes.LESS_THAN].includes(data.operator)) {
			return +data.value > 1 ? `${data.value} days ago` : `${data.value} day ago`;
		}
		if ([OperatorTypes.BEFORE, OperatorTypes.AFTER, OperatorTypes.ON].includes(data.operator)) {
			return format(+data.value, 'yyyy-MM-dd');
		}
		if ([OperatorTypes.BETWEEN].includes(data.operator)) {
			const [start, end] = data.value.split(',');
			return `${format(+start, 'yyyy-MM-dd')} ~ ${format(+end, 'yyyy-MM-dd')}`;
		}
	}

	return data.value;
};

export const computedValue = (data: any, tagsMapping: any) => {
	if (isEmpty(data)) {
		return '';
	}
	if (!data.operator) {
		return data.columnDisplayName;
	}
	if (data.timeStamp > 0 && !hasValue(data.value)) {
		return `${data.columnDisplayName} is missing value`;
	}
	if ([OperatorTypes.IS_UNKNOWN, OperatorTypes.HAS_ANY_VALUE].includes(data.operator)) {
		return `${data.columnDisplayName} ${lowerCase(data.operator)}`;
	}

	return `${data.columnDisplayName} ${lowerCase(data.operator)} ${formatValue(data, tagsMapping)}`;
};
