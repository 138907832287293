import { createTheme } from '@mui/material/styles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import shadows from '@mui/material/styles/shadows';
import { Box } from '@mui/material';
import { rgbaToHexWithOpacity, darkenColor } from './color';
shadows[1] = 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)';
shadows[2] = '0px 3px 15px rgba(26, 30, 34, 0.05)';
shadows[3] = '0px 6px 15px rgba(26, 30, 35, 0.14), 0px 4px 5px -3px rgba(26, 30, 35, 0.15)';
shadows[4] = '0px 9px 8px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(45, 60, 80, 0.2)';
export const computeBrandTheme = ({
  isOtherBrand,
  secondary,
  primary
}: {
  isOtherBrand: boolean;
  secondary: {
    r: number;
    g: number;
    b: number;
  };
  primary: {
    r: number;
    g: number;
    b: number;
  };
}) => {
  const secondaryColor = rgbaToHexWithOpacity(secondary);
  const primaryColor = rgbaToHexWithOpacity(primary);
  const primaryDarkColor = darkenColor(primary, 10);
  const secondaryColor10 = isOtherBrand ? rgbaToHexWithOpacity(secondary, 0.1) : '#EFEAFD'; // #EFEAFD的透明色版本
  const secondaryColor20 = isOtherBrand ? rgbaToHexWithOpacity(secondary, 0.2) : '#DFD5FB'; // #DFD5FB的透明色版本

  return createTheme({
    palette: {
      primary: {
        main: '#1A1E22',
        dark: '#000'
      },
      // 贴牌primary，针对button、avatar这些比较明显的，贴牌厂商可以自定义品牌色
      brandPrimary: {
        main: primaryColor,
        dark: primaryDarkColor,
        contrastText: '#fff',
        light: ''
      },
      secondary: {
        main: secondaryColor,
        activeBg: secondaryColor20,
        bg: secondaryColor10,
        contrastText: '#fff'
      },
      error: {
        main: '#FF4842',
        dark: '#A8071A',
        bg: '#FEE9EA'
      },
      warning: {
        main: '#FFC107',
        dark: '#AD6800',
        bg: '#FFF6DC'
      },
      success: {
        main: '#11BB82',
        bg: '#EAF9F4',
        dark: '#006E50'
      },
      info: {
        main: '#4457FF',
        bg: '#E1E4FF'
      },
      text: {
        primary: '#1A1E22',
        secondary: '#898395',
        disabled: '#A29DAE',
        white: '#fff'
      },
      line: {
        main: '#A29DAE',
        line2: '#D7D5DF',
        line3: '#E7E6EF'
      },
      fill: {
        disabledDark: '#D7D5DF',
        new: '#E7E6EF',
        disabled: isOtherBrand ? '#EEEEEE' : '#EFEDF5',
        hover: isOtherBrand ? '#F5F5F5' : '#F5F4F9',
        bg: isOtherBrand ? '#FAFAFA' : '#FBFAFD',
        w: '#FFF'
      },
      action: {
        hover: isOtherBrand ? '#F5F5F5' : '#F5F4F9'
      }
    },
    components: {
      MuiAlert: {
        defaultProps: {
          iconMapping: {
            success: <CheckCircleRoundedIcon />,
            error: <InfoRoundedIcon />,
            info: <InfoRoundedIcon />,
            warning: <ReportProblemRoundedIcon />
          }
        },
        styleOverrides: {
          root: {
            '&.MuiAlert-root': {
              fontSize: '14px',
              borderRadius: '8px',
              padding: '4px 12px 4px 16px',
              '& .MuiAlert-icon': {
                alignSelf: 'center'
              },
              '& .MuiAlert-action': {
                marginRight: '1px',
                alignSelf: 'center'
              }
            },
            '&.MuiAlert-standardSuccess': {
              alignItems: 'center',
              backgroundColor: '#E0F6EE',
              '& .MuiAlert-icon': {
                padding: 0,
                color: '#11BB82',
                '& .MuiSvgIcon-root': {
                  fontSize: '22px'
                }
              },
              '& .MuiAlert-message': {
                padding: 0
              },
              '& .MuiAlert-action': {
                padding: 0,
                color: '#086647'
              }
            },
            '&.MuiAlert-standardWarning': {
              color: '#7A4F01',
              backgroundColor: '#FFF6DC',
              '& .MuiAlert-icon': {
                color: '#FFC107'
              },
              '& .MuiAlert-action': {
                color: '#7A4F01'
              }
            },
            '&.MuiAlert-standardError': {
              color: '#611A15',
              backgroundColor: '#FEE9EA',
              '& .MuiAlert-icon': {
                color: '#FF4842'
              },
              '& .MuiAlert-action': {
                color: '#611A15'
              }
            }
          }
        }
      },
      MuiPagination: {
        defaultProps: {
          color: 'secondary'
        }
      },
      MuiStep: {
        styleOverrides: {
          root: {
            '& .MuiStepLabel-label': {
              fontSize: '14px',
              marginTop: '12px !important',
              '&.Mui-active': {
                fontWeight: '600'
              }
            },
            '& .MuiStepConnector-horizontal': {
              left: 'calc(-50% + 38.5px)',
              right: 'calc(50% + 38.5px)'
            },
            '& .MuiStepConnector-line': {
              borderColor: '#A29DAE'
            }
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            fontSize: '28px',
            '&.Mui-completed': {
              color: secondaryColor
            },
            '&.Mui-active': {
              color: secondaryColor
            },
            '& .MuiStepIcon-text': {
              fontSize: '16px',
              fontWeight: '600'
            }
          }
        }
      },
      MuiSwitch: {
        defaultProps: {
          color: 'secondary'
        }
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary'
        }
      },
      MuiTabs: {
        defaultProps: {
          indicatorColor: 'secondary',
          textColor: 'secondary'
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'initial'
          }
        }
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: <CancelRoundedIcon />
        }
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
          checkedIcon: <CheckBoxRoundedIcon />
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            color: '#1A1E22',
            boxShadow: '0px 3px 15px rgba(26, 30, 34, 0.05)',
            marginBottom: '16px',
            '&:before': {
              display: 'none'
            },
            '&.Mui-disabled': {
              backgroundColor: 'fill.w'
            },
            '& .Mui-disabled': {
              color: '#A29DAE'
            }
          }
        }
      },
      MuiAccordionSummary: {
        defaultProps: {
          expandIcon: <ExpandMoreRoundedIcon />
        },
        styleOverrides: {
          root: {
            padding: '20px 32px',
            '&.Mui-expanded': {
              borderRadius: '8px 8px 0px 0px'
            },
            '& .MuiAccordionSummary-content': {
              margin: '0 !important'
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              cursor: 'pointer',
              color: '#1A1E22'
            },
            '&.Mui-disabled': {
              '& .MuiAccordionSummary-expandIconWrapper': {
                color: '#A29DAE'
              }
            }
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0 32px 32px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: ({
            ownerState
          }) => ({
            ...(ownerState.variant === 'contained' && ownerState.color === 'primary' ? {
              background: primaryColor,
              '&:hover': {
                background: primaryDarkColor
              }
            } : {}),
            ...{
              textTransform: 'initial',
              borderColor: 'brandPrimary.main',
              '&.Mui-disabled': {
                color: '#A29DAE'
              },
              '&.MuiButton-text': {
                '&:hover': {
                  backgroundColor: 'rgba(26, 30, 34, 0.06)'
                }
              }
            },
            ...(ownerState.variant === 'outlined' && ownerState.color === 'primary' ? {
              border: '1px solid #A29DAE'
            } : {})
          }),
          contained: {
            boxShadow: 'none'
          }
        },
        variants: [{
          style: {
            height: '46px',
            fontWeight: '600',
            lineHeight: '22px'
          },
          props: {
            size: 'large'
          }
        }, {
          style: {
            height: '40px',
            fontWeight: '600',
            lineHeight: '22px'
          },
          props: {
            size: 'medium'
          }
        }, {
          style: {
            height: '30px'
          },
          props: {
            size: 'small'
          }
        }]
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
          renderOption: (props, option, state, ownerState) => <Box sx={{
            minHeight: '46px !important'
          }} component="li" {...props}>
							{ownerState.getOptionLabel(option)}
						</Box>
        }
      },
      MuiPopper: {
        defaultProps: {
          sx: {
            '&.MuiAutocomplete-popper': {
              boxShadow: '0px 2px 15px rgba(26, 30, 35, 0.14), 0px 4px 5px -3px rgba(26, 30, 35, 0.15)'
            }
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-root': {
              color: '#000',
              fontSize: '14px',
              lineHeight: '22px',
              fontWeight: '600',
              borderBottom: '1px solid rgb(214, 212, 222)'
            }
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-root': {
              fontSize: '14px',
              borderBottom: '1px solid rgb(234, 233, 239)'
            }
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            '& .MuiTablePagination-selectLabel': {
              fontSize: '12px',
              lineHeight: '20px'
            },
            '& .MuiTablePagination-displayedRows ': {
              fontSize: '12px',
              lineHeight: '20px'
            },
            '& .MuiTablePagination-actions': {
              marginLeft: '19px'
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '& .MuiTypography-root': {
              color: '#000',
              fontSize: '16px',
              lineHeight: '24px'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginTop: 0
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: secondaryColor,
            textDecoration: 'none',
            cursor: 'pointer'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: '1px solid #D7D5DF',
            borderRadius: '8px'
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          size: 'small'
        },
        variants: [{
          style: {
            input: {
              height: '40px',
              boxSizing: 'border-box'
            },
            '& .MuiSelect-select': {
              lineHeight: '23px'
            }
          },
          props: {
            size: 'small'
          }
        }]
      },
      MuiOutlinedInput: {
        variants: [{
          style: {
            input: {
              height: '40px',
              boxSizing: 'border-box'
            },
            '&.MuiInputBase-sizeSmall': {
              paddingTop: '0 !important',
              paddingBottom: '0 !important'
            },
            '&.Mui-disabled': {
              color: '#A29DAE',
              backgroundColor: '#EFEDF5',
              '& input': {
                color: '#1A1E22',
                textFillColor: '#1A1E22'
              },
              '& .MuiSelect-select.Mui-disabled': {
                color: '#1A1E22',
                textFillColor: '#1A1E22'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#A29DAE'
            }
          },
          props: {
            size: 'small'
          }
        }],
        styleOverrides: {
          root: {
            '& input': {
              paddingTop: '16px',
              paddingBottom: '16px',
              height: '24px'
            },
            '&.Mui-error': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #FF4842 !important'
              }
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            lineHeight: '24px',
            color: '#A29DAE',
            '&.Mui-focused': {
              color: '#1A1E22'
            },
            '&.MuiInputLabel-shrink': {
              color: '#1A1E22'
            }
          }
        }
      },
      MuiMenuItem: {
        defaultProps: {
          sx: {
            py: '9px'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: 'text.white',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
            borderRadius: '4px',
            backgroundColor: 'rgba(26, 30, 34, 0.9)'
          },
          arrow: {
            color: 'rgba(26, 30, 34, 0.9)'
          }
        }
      }
    },
    shadows,
    typography: {
      button: {
        textTransform: 'none'
      },
      h1: {
        fontSize: 34,
        fontWeight: 700,
        lineHeight: '52px',
        letterSpacing: '0.6px'
      },
      h2: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '36px'
      },
      h3: {
        fontSize: 20,
        lineHeight: '30px',
        fontWeight: 500
      },
      h5: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '24px'
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '24px'
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '22px'
      },
      body1: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '22px'
      },
      body2: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px'
      },
      caption: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '18px'
      }
    }
  });
};