import { Box, Typography } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import useTheme from '@mui/material/styles/useTheme';
import { FunctionComponent, Suspense, useMemo } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Loading from 'src/app/components/loading';
import { useAppSelector } from 'src/app/hooks';
import { selectCustomerType, selectUser } from 'src/features/user/userSlice';
import { screenMenusByRole } from '../../menuData';
import { cloneDeep } from 'lodash-es';
import { defineMessages, useIntl } from 'react-intl';
// 匹配到的路由，不显示左侧菜单
import { CustomerType } from 'src/features/user/type';
export const titleMap = defineMessages({
  billing: {
    defaultMessage: 'Billing'
  },
  settings: {
    defaultMessage: 'Settings'
  },
  userSettings: {
    defaultMessage: 'Your preference'
  }
});
const PageContainerBack: FunctionComponent<{
  titleKey: string;
}> = ({
  titleKey
}) => {
  const {
    role
  } = useAppSelector(selectUser);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const lastPath = sessionStorage.getItem('subMenuBackPath');
  const isHideMenu = false;
  const customerType = useAppSelector(selectCustomerType);
  const {
    formatMessage
  } = useIntl();
  const pathArray = location.pathname.split('/').filter(item => !!item);
  const handleBack = () => {
    if (lastPath) {
      navigate(lastPath);
    } else {
      navigate(-1);
    }
  };
  const menuItemClick = (item: any) => {
    if (item.onClick) {
      item.onClick(item);
      return;
    }
    if (item.isLink) {
      window.open(item.path);
      return;
    }
    navigate(item.redirect || item.path);
  };
  const billingMenuData = [{
    name: formatMessage({
      defaultMessage: 'Billing overview'
    }),
    path: '/app/billing/overview',
    activeKeys: ['overview'],
    roles: ['ADMIN']
  }, {
    name: formatMessage({
      defaultMessage: 'Buy credits'
    }),
    path: '/app/billing/buyCredits',
    activeKeys: ['buyCredits'],
    unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER]
  }, {
    name: formatMessage({
      defaultMessage: 'Invoice logs'
    }),
    path: '/app/billing/logs',
    activeKeys: ['logs'],
    roles: ['ADMIN'],
    unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER]
  }, {
    name: formatMessage({
      defaultMessage: 'Notifications'
    }),
    path: '/app/billing/notification',
    activeKeys: ['notification'],
    roles: ['ADMIN'],
    unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER]
  }];
  const userSettingsMenuData = [{
    name: formatMessage({
      defaultMessage: 'Profile'
    }),
    path: '/app/userSettings/myProfile',
    activeKeys: ['myProfile']
  }, {
    name: formatMessage({
      defaultMessage: 'Security'
    }),
    path: '/app/userSettings/security',
    activeKeys: ['security']
  }, {
    name: formatMessage({
      defaultMessage: 'Notifications'
    }),
    path: '/app/userSettings/notification',
    activeKeys: ['notification']
  }, {
    name: formatMessage({
      defaultMessage: 'Canned Response'
    }),
    path: '/app/userSettings/cannedResponse',
    activeKeys: ['cannedResponse']
  }];
  const settingsMenuData = [{
    name: formatMessage({
      defaultMessage: 'Company info'
    }),
    path: '/app/settings/companyInfo',
    activeKeys: ['companyInfo']
  }, {
    name: formatMessage({
      defaultMessage: 'Users and teams'
    }),
    path: '/app/settings/usersAndTeams',
    activeKeys: ['usersAndTeams'],
    roles: ['ADMIN']
  }];
  const menuMap = {
    billing: billingMenuData,
    settings: settingsMenuData,
    userSettings: userSettingsMenuData
  };
  const menu = menuMap[titleKey as keyof typeof menuMap];
  const whitelist = useMemo(() => {
    return screenMenusByRole(cloneDeep(menu), role, [], customerType);
  }, [role, menu]);
  return <Box sx={{
    backgroundColor: isHideMenu ? 'fill.bg' : 'fill.hover',
    flexGrow: 1,
    height: '100%',
    display: 'flex'
  }}>
			{!isHideMenu && <Box sx={{
      width: '240px',
      padding: '24px 36px',
      flexShrink: 0,
      boxSizing: 'border-box'
    }}>
					<Box onClick={handleBack} sx={{
        display: 'flex',
        cursor: 'pointer',
        height: '52px',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 500,
        letterSpacing: '0.15px',
        position: 'relative',
        left: '-15px'
      }}>
						<ArrowBackRoundedIcon />
						<Typography sx={{
          marginLeft: '10px'
        }} variant="subtitle1">
							{formatMessage(titleMap[titleKey as keyof typeof titleMap])}
						</Typography>
					</Box>

					{whitelist.map((item: any) => {
        const itemActive = (item?.activeKeys || []).includes(pathArray[2]);
        return <Box onClick={() => menuItemClick(item)} key={item.path} sx={{
          cursor: 'pointer',
          borderLeft: `1px solid ${theme.palette.line.line2}`,
          color: itemActive ? theme.palette.secondary.main : theme.palette.text.secondary,
          '&:hover': {
            background: theme.palette.fill.hover
          }
        }}>
								<Typography variant={itemActive ? 'subtitle2' : 'body1'} sx={{
            padding: '8px 20px',
            borderLeft: `solid 2px ${itemActive ? theme.palette.secondary.main : 'transparent'}`,
            position: 'relative',
            left: '-1px'
          }}>
									{item.name}
								</Typography>
							</Box>;
      })}
				</Box>}
			<Box id="pageContentBack" sx={{
      height: '100%',
      overflowY: 'auto',
      flexGrow: 1,
      position: 'relative'
    }}>
				<Suspense fallback={<Loading open={true} />}>
					<Outlet />
				</Suspense>
			</Box>
		</Box>;
};
export default PageContainerBack;