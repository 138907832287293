import { Popover } from '@mui/material';
import MoodRoundedIcon from '@mui/icons-material/MoodRounded';
import { FunctionComponent, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useIntl } from 'react-intl';
const EmojiPicker: FunctionComponent<{
  disabled?: boolean;
  iconStyle?: any;
  onSelect: (e: any) => void;
}> = ({
  disabled = false,
  iconStyle,
  onSelect
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (e: any) => {
    onSelect(e);
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? 'emoji-popover' : undefined;
  const {
    locale
  } = useIntl();
  return <>
			<MoodRoundedIcon sx={{
      ...(iconStyle && iconStyle)
    }} onClick={e => !disabled && handleClick(e)} />
			<Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}>
				<Picker locale={locale || 'en'} data={data} onEmojiSelect={handleSelect} />
			</Popover>
		</>;
};
export default EmojiPicker;