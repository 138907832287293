import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogActions, Button, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { store } from 'src/app/store';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { addSegmentAsync, updateSegmentAsync, selectCurSeg, selectCurTag, addEditSeg, selectTableTotal } from 'src/features/contact/contactList/contactListSlice';
import { showMessage } from 'src/utils/message';
const SegmentCreate: FunctionComponent<{
  open: boolean;
  filterData: any;
  onReload: (e: any) => void;
  onClose: () => void;
}> = ({
  filterData,
  open,
  onReload,
  onClose
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const tableTotal = useAppSelector(selectTableTotal);
  const curSeg = useAppSelector(selectCurSeg);
  const [localCurSeg, setLocalCurSeg] = useState(curSeg); // 避免dispatch更新curSeg导致关闭前dialogContent被二次渲染
  const curTag = useAppSelector(selectCurTag);
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({
    mode: 'all',
    defaultValues: {
      type: '',
      name: ''
    }
  });
  const type = watch('type');
  const name = watch('name');
  const handleClose = () => {
    reset();
    onClose();
  };
  const handleSave = async () => {
    let res: any;
    if (type === 'old') {
      res = await dispatch(updateSegmentAsync({
        ...curSeg,
        query: filterData
      })).unwrap();
    } else {
      res = await dispatch(addSegmentAsync({
        name: name,
        query: filterData
      })).unwrap();
    }
    if (res.code === CODE.OK) {
      if (type === 'new') {
        showMessage({
          type: 'success',
          message: formatMessage({
            defaultMessage: 'Segment created successfully!'
          })
        });
      }
      handleClose();
      store.dispatch(addEditSeg({
        type: type === 'old' ? 'edit' : 'add',
        data: {
          ...res.data,
          count: tableTotal
        }
      }));
      onReload(res.data);
    }
  };
  useEffect(() => {
    if (open === true) {
      setLocalCurSeg(curSeg);
    }
  }, [open]);
  useEffect(() => {
    if (open) {
      if (curSeg === 'all' || curTag) {
        setValue('type', 'new');
      } else {
        setValue('type', '');
      }
    }
  }, [open, curSeg, curTag, setValue]);
  return <Dialog open={open}>
			<CustomDialogTitle title={formatMessage({
      defaultMessage: 'Save segment'
    })} onClose={handleClose} />
			<DialogContent sx={{
      width: '600px',
      pl: 5,
      boxSizing: 'border-box'
    }}>
				<FormControl fullWidth>
					<Controller name="type" control={control} render={({
          field
        }) => <RadioGroup {...field}>
								{localCurSeg.name && <FormControlLabel value="old" control={<Radio />} label={formatMessage({
            defaultMessage: 'Save changes to the segment ‘{a}’'
          }, {
            a: curSeg.name
          })} sx={{
            mb: 1
          }} />}
								<FormControlLabel value="new" control={<Radio />} label={formatMessage({
            defaultMessage: 'Create new segment'
          })} />
							</RadioGroup>} />
				</FormControl>
				<FormControl fullWidth>
					<Controller name="name" control={control} rules={{
          maxLength: {
            value: 60,
            message: formatMessage({
              defaultMessage: 'Max Length 60'
            })
          }
        }} render={({
          field
        }) => <TextField {...field} autoComplete="off" variant="outlined" placeholder={formatMessage({
          defaultMessage: 'Please enter new segment name'
        })} error={Boolean(errors.name?.type)} helperText={<ErrorHelperText>{errors.name?.message}</ErrorHelperText>} sx={{
          width: '448px',
          ml: 4
        }} />} />
				</FormControl>
			</DialogContent>
			<DialogActions sx={{
      p: 2
    }}>
				<Button variant="outlined" onClick={handleClose}>
					<FormattedMessage defaultMessage={'Cancel'} />
				</Button>
				<Button variant="contained" sx={{
        ml: 2
      }} disabled={!type || type === 'new' && name === ''} onClick={handleSubmit(handleSave)}>
					<FormattedMessage defaultMessage={'Save'} />
				</Button>
			</DialogActions>
		</Dialog>;
};
export default SegmentCreate;