import { Box, IconButton, Typography, MenuItem } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import React, { FunctionComponent, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MoreIcon } from 'src/app/icons';
import CustomMenu from 'src/app/components/customMenu';
import { useAppSelector } from 'src/app/hooks';
import { selectTagColorMapping } from 'src/features/contact/contactList/contactListSlice';
import ColorChip, { colorMapping } from 'src/app/components/colorChip';
const TagColumn: FunctionComponent<{
  contactId: string;
  data: Array<any>;
  onDel?: (id: string, tag: string) => void;
}> = ({
  contactId,
  data,
  onDel
}) => {
  const tagColorMapping = useAppSelector(selectTagColorMapping);
  const [overflowYStyle, setOverflowYStyle] = useState<string>('overlay');
  const [sliceIndex, setSliceIndex] = useState<number>(0);
  const [iconLeft, setIconLeft] = useState<number>(0);
  const ref = useRef();
  const handleDel = (event: any, e: string) => {
    if (!e) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (onDel) {
      onDel(contactId, e);
    }
  };
  const formatData = useMemo(() => {
    return data.map(item => tagColorMapping?.[item]).filter(Boolean);
  }, [data]);
  useLayoutEffect(() => {
    const el: any = ref.current;
    const overflowY = el?.scrollHeight > el.clientHeight ? 'hidden' : 'auto';
    setOverflowYStyle(overflowY);
    if (overflowY === 'hidden') {
      const parentWidth = el.getBoundingClientRect().width;
      const allChildNodes: Array<any> = el.childNodes;
      let allChildWidth = 24;
      let index = 0;
      for (let i = 0; i < allChildNodes.length; i++) {
        const item = allChildNodes[i];
        allChildWidth += item.clientWidth + 4;
        if (allChildWidth > parentWidth) {
          index = i;
          setSliceIndex(index === 0 ? 1 : index);
          const left = allChildWidth - item.clientWidth - 28;
          setIconLeft(left === 0 ? 204 : left);
          break;
        }
      }
    }
  }, [formatData]);
  return <Box ref={ref} sx={{
    height: '24px',
    overflowY: overflowYStyle,
    position: 'relative',
    pr: 3
  }} className="notranslate">
			{formatData.map((item: any) => {
      const {
        id,
        tag,
        color
      } = item;
      let key = `${color?.r},${color?.g},${color?.b}`;
      if (!Object.keys(colorMapping).includes(key)) {
        key = '94,89,106';
      }
      const current = colorMapping?.[key as keyof typeof colorMapping];
      return <ColorChip key={id} size="small" label={tag} onDelete={onDel && ((event: any) => handleDel(event, item))} value={color || {
        r: 94,
        g: 89,
        b: 106
      }} sx={{
        mr: '4px',
        my: 0,
        px: '16px',
        maxWidth: '194px',
        fontSize: '13px',
        lineHeight: '18px',
        color: `rgba(${key}, 1)`,
        background: current.background,
        '&:hover': {
          color: `rgba(${key}, 1)`,
          background: current.background
        },
        '& .MuiChip-deleteIcon': {
          display: 'none'
        },
        ...(onDel && {
          '&:hover': {
            pl: '8px',
            pr: 0,
            '& .MuiChip-deleteIcon': {
              display: 'inline-block',
              mx: '4px',
              color: `rgba(${key}, 0.3)`,
              '&:hover': {
                color: `rgba(${key}, 0.5)`
              }
            }
          }
        })
      }} />;
    })}
			{overflowYStyle === 'hidden' && <CustomMenu actionArea={<IconButton sx={{
      p: 0,
      borderRadius: '2px'
    }}>
							<MoreIcon />
						</IconButton>} boxStyle={{
      position: 'absolute',
      top: 0,
      left: iconLeft + 'px'
    }}>
					{formatData.slice(sliceIndex).map((item: any) => <MenuItem key={item?.id} sx={{
        maxWidth: '400px',
        '&:hover': {
          '& .MuiSvgIcon-root': {
            display: 'inline-block'
          }
        }
      }}>
							<Typography component="span" sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}>
								{item?.tag}
							</Typography>
							<CancelRoundedIcon onClick={(event: any) => handleDel(event, item)} sx={{
          display: 'none',
          fontSize: '16px',
          ml: '4px',
          color: 'rgb(170, 173, 179)'
        }} />
						</MenuItem>)}
				</CustomMenu>}
		</Box>;
};
export default TagColumn;