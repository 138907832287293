import { Chip, ChipProps } from '@mui/material';
import { FunctionComponent } from 'react';
import useTheme from '@mui/material/styles/useTheme';
export type colorType = 'default' | 'secondary' | 'success' | 'error' | 'warning';

// medium的Chip高度为24, small的Chip高度为20（默认值）
const CustomChip: FunctionComponent<ChipProps & {
  [index: string]: any;
}> = props => {
  const {
    size = 'small',
    color,
    ...otherProps
  } = props;
  const theme = useTheme();
  const styleMap = {
    secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.bg
    },
    success: {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.bg
    },
    error: {
      color: theme.palette.error.dark,
      backgroundColor: theme.palette.error.bg
    },
    default: {
      color: '#5E596A',
      backgroundColor: 'fill.disabled'
    },
    warning: {
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.bg
    }
  };
  const style = styleMap[(color || 'default') as keyof typeof styleMap];
  if (size === 'medium') {
    return <Chip color={color} {...otherProps} size="small" sx={{
      fontWeight: 500,
      ...style,
      ...otherProps.sx
    }} />;
  } else {
    return <Chip color={color} {...otherProps} sx={{
      ...style,
      height: '20px',
      cursor: 'pointer',
      '& .MuiChip-label': {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
        px: '8px'
      },
      ...(otherProps.icon && {
        '& .MuiChip-icon': {
          fontSize: '12px !important',
          ml: 1
        }
      }),
      ...otherProps.sx
    }} />;
  }
};
export default CustomChip;