import { POST, GET } from 'src/utils/fetch';
import { memberListParams, updateMemberParams, activeConfirmParams, newUserRegisterParams, tenantSwitchParams, addTenantParams, editAgentCapacityParams } from './type';
import { FactorsSwitchParams } from 'src/features/user/type';

export async function getMemberList(params: memberListParams) {
	return POST('/api/member/search', params, true, false);
}

export async function addMember(params: updateMemberParams) {
	return POST('/api/member/add', params, false, false);
}

export async function editMember(params: updateMemberParams) {
	return POST('/api/member/update', params, false, false);
}

export async function editAgentCapacity(params: editAgentCapacityParams) {
	return POST('/api/member/changeReceptionLimit', params, false, false);
}

export async function delMember(params: updateMemberParams) {
	return POST('/api/member/delete', params, false, false);
}

export async function resendMember(params: updateMemberParams) {
	return POST('/api/member/resendInvitationEmail', params, true, false);
}

export async function activeConfirm(params: activeConfirmParams) {
	return GET('/api/member/confirm', params, true, true);
}

export async function newUserRegister(params: newUserRegisterParams) {
	return POST('/api/user/register/basic', params, true, false);
}

export async function getTenantList() {
	return GET('/api/tenant/list', undefined, true, false);
}

export async function addTenant(params: addTenantParams) {
	return POST('/api/tenant/create', params, true, false);
}

export async function tenantSwitch(params: tenantSwitchParams) {
	return POST('/api/tenant/switch', params, true, true);
}

export async function setTenantFactorsSwitch(params: FactorsSwitchParams) {
	return POST('/api/tenant/updateMultiFactorsSetting', params, true, true);
}

export async function makeOwner(params: updateMemberParams) {
	return POST('/api/member/makeOwner', params, false, false);
}
