import { Data } from 'src/utils/fetch';

export enum DistributionType {
	Partner = 'partner',
}

export interface BrandConfig {
	distribution?: DistributionType;
	loginUrl: string; // 客户提供的登录地址
	name: string; // 站点名称
	favicon: string; // 站点缩略图
	logo: string; //logo
	squareLogo: string; //logo
	themeColor0: string; // 主题色 0 RGB，用于设置主题里面的secondary颜色
	themeColor1: string; // 主题色 1 RGB，用于设置主题里面的brandPrimary颜色
	whatsappEmbeddedSignupConfig: {
		customized: boolean; // 为 true 时取以下 appId configId solutionId 配置嵌入式注册 SDK
		appId: string; // Facebook App ID
		configId: string; // Facebook WhatsApp Config ID
		solutionId: string; // Facebook WhatsApp Solution ID
	};
}

export interface GetBrandConfigRes extends Data {
	data?: BrandConfig;
}

export enum LogoType {
	Square, // 正方形logo 宽高比 = 1
	Small_Width, // 宽logo 宽高比 > 1, 但是小于等于2.2
	Width, // 宽logo 宽高比 > 2.2
	Height, // 长logo 宽高比 < 1
}
