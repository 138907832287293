import { GET, POST } from 'src/utils/fetch';

export async function getPlatformSyncStatus(platform: string) {
	return GET(`/api/integration/${platform}/synchronize/getStatus`, undefined, true, false);
}

// 没有安装会返回 code： -1
export async function getPlatformInfo(platform: string) {
	return GET(`/api/integration/${platform}/info`, undefined, true, true);
}

export async function getPlatformInstallPath(platform: string) {
	return GET(`/api/integration/${platform}/install-path`, undefined, true, false);
}

export async function startSync(platform: string) {
	return GET(`/api/integration/${platform}/synchronize/start`, undefined, false, false);
}

export async function changeIntegrationStatus(params: { integration: string; type: string }) {
	return POST('/api/integration/enable', params, false, false);
}
