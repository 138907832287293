import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Popover, Typography, Box, Stack } from '@mui/material';
import { FunctionComponent, useEffect, useState, MouseEvent, ReactNode } from 'react';
import { useFocused, useSelected } from 'slate-react';
import { useAppSelector } from 'src/app/hooks';
import { selectAllShortUrlInfoList } from 'src/features/shortLink/shortLinkSlice';
import { formatDateTime } from 'src/utils/transform';
import { ShortUrlInfo } from 'src/features/shortLink/type';
import useTheme from '@mui/material/styles/useTheme';
const PopoverTitle: FunctionComponent<{
  children: ReactNode;
}> = ({
  children
}) => {
  return <Typography variant="subtitle1" sx={{
    fontWeight: 400,
    color: 'text.secondary',
    mb: 0.5
  }}>
			{children}
		</Typography>;
};
const ShortLinkElement: FunctionComponent<{
  attributes?: any;
  element?: any;
  children?: any;
}> = ({
  attributes = {},
  element = {},
  children
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const selected = useSelected();
  const focused = useFocused();
  const theme = useTheme();
  const allShortUrlInfoList = useAppSelector(selectAllShortUrlInfoList);
  const [shortUrlInfo, setShortUrlInfo] = useState<undefined | ShortUrlInfo>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (!shortUrlInfo) {
      for (let i = 0; i < allShortUrlInfoList.length; i++) {
        if (allShortUrlInfoList[i].url === element.character) {
          setShortUrlInfo(allShortUrlInfoList[i]);
          break;
        }
      }
    }
  }, [element.character, allShortUrlInfoList, shortUrlInfo]);
  return <span {...attributes} contentEditable={false} style={{
    display: 'inline-block'
  }}>
			<Link href={element.character} target="__blank" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} sx={{
      backgroundColor: selected && focused ? theme.palette.secondary.activeBg : 'none',
      boxShadow: selected && focused ? '0 0 0 2px rgb(239, 234, 253)' : 'none'
    }}>
				{children}
				{element.character}
			</Link>
			<Popover id="mouse-over-popover" sx={{
      pointerEvents: 'none',
      marginTop: 1
    }} open={open} anchorEl={anchorEl} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }} onClose={handlePopoverClose} disableRestoreFocus>
				<Stack spacing={2} sx={{
        padding: '28px 24px',
        width: '300px',
        wordBreak: 'break-all'
      }}>
					<Box>
						<PopoverTitle>
							<FormattedMessage defaultMessage={'Destination URL'} />
						</PopoverTitle>
						<Typography variant="subtitle1">
							{shortUrlInfo?.targetUrl || formatMessage({
              defaultMessage: 'Loading...'
            })}
						</Typography>
					</Box>
					<Box>
						<PopoverTitle>
							<FormattedMessage defaultMessage={'Short URL'} />
						</PopoverTitle>
						<Typography variant="subtitle1">{shortUrlInfo?.url || element.character}</Typography>
					</Box>
					<Box>
						<PopoverTitle>
							<FormattedMessage defaultMessage={'Expiration Date'} />
						</PopoverTitle>
						<Typography variant="subtitle1">
							{shortUrlInfo?.expireTime ? formatDateTime(shortUrlInfo?.expireTime) : formatMessage({
              defaultMessage: 'Loading...'
            })}
						</Typography>
					</Box>
				</Stack>
			</Popover>
		</span>;
};
export default ShortLinkElement;