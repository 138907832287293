import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { ContactSettingParams, CustomAttrParams } from './type';
import { getContactSettingList, addCustomAttr, updateCustomAttr, delCustomAttr, getAttrType, getAllLanguages, getAllEventList } from './contactSettingAPI';

export interface ContactSettingState {
	allAttr: Array<any>;
}

const initialState: ContactSettingState = {
	allAttr: [],
};

export const getContactSettingAsync = createAsyncThunk('contactSetting/getList', async (params: ContactSettingParams) => {
	const res = await getContactSettingList(params);
	return res;
});

export const getAllEventListAsync = createAsyncThunk('contactSetting/getList', async (params: any) => {
	const res = await getAllEventList(params);
	return res;
});

export const addCustomAttrAsync = createAsyncThunk('contactSetting/addCustomAttr', async (params: CustomAttrParams) => {
	const res = await addCustomAttr(params);
	return res;
});

export const updateCustomAttrAsync = createAsyncThunk('contactSetting/updateCustomAttr', async (params: CustomAttrParams) => {
	const res = await updateCustomAttr(params);
	return res;
});

export const delCustomAttrAsync = createAsyncThunk('contactSetting/delCustomAttr', async (params: CustomAttrParams, { rejectWithValue }) => {
	try {
		const res = await delCustomAttr(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getAllLanguagesAsync = createAsyncThunk('contactSetting/getAllLanguages', async () => {
	const res = await getAllLanguages();
	return res;
});

export const getAttrTypeAsync = createAsyncThunk('contactSetting/getAttrType', async () => {
	const res = await getAttrType();
	return res.data;
});

export const selectAllAttr = (state: RootState) => state.contactSetting.allAttr;

export const contactSettingSlice = createSlice({
	name: 'contactSetting',
	initialState: initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAttrTypeAsync.fulfilled, (state, action) => {
			state.allAttr = action.payload;
		});
	},
});

export default contactSettingSlice.reducer;
