import axios from 'axios';
import { showMessage } from 'src/utils/message';
import { Message } from 'src/features/toast/type';
import { CODE } from 'src/app/const/code';
import { hostnameAndPrev } from 'src/app/const';
import { isMock } from 'src/app/const';
import { DownloadParams } from 'src/features/common/type';

export const joinUrlAndSearchParams = (url: string, params: any) => {
	return `${url}?${new URLSearchParams(params).toString()}`;
};

enum MethodEnum {
	Get = 'get',
	Post = 'post',
	Upload = 'upload',
	Patch = 'patch',
	Delete = 'delete',
	Put = 'put',
}

const headerDefaultContentType = {
	[MethodEnum.Get]: 'application/x-www-form-urlencoded; charset=utf-8',
	[MethodEnum.Post]: 'application/json; charset=utf-8',
	[MethodEnum.Patch]: 'application/json; charset=utf-8',
	[MethodEnum.Upload]: 'multipart/form-data',
	[MethodEnum.Put]: 'application/json; charset=utf-8',
	[MethodEnum.Delete]: 'application/json; charset=utf-8',
};

enum CodeMap {
	Success = 0,
	Error = -1,
	NotLogin = 10001,
	Failed = -100,
}

export interface Data {
	code: CodeMap;
	msg: string;
	timestamp: number;
	data?: any;
}

export type Pagin = {
	pageNo: number;
	pageSize: number;
	totalCount: number;
	totalPage: number;
};

export interface Res {
	data?: Data;
	msg?: string;
}

let preCode: any = undefined;

const fetch = (url: string, method: MethodEnum, params?: object, hideSuccessMessage = false, hideFailedMessage = false, isQuery?: boolean, isAllRes?: boolean, mock?: boolean) => {
	return new Promise<Data>(async (resolve, reject) => {
		let message: Message = {
			message: '',
			type: 'default',
		};
		const getData = () => {
			if (method === MethodEnum.Post || method === MethodEnum.Patch || method === MethodEnum.Put || method === MethodEnum.Delete) {
				return JSON.stringify(params);
			}
			if (method === MethodEnum.Upload) {
				return params;
			}
		};
		try {
			const res: Res = await axios({
				url: isMock || mock ? 'https://yapi.qipeng.com/mock/78' + url : url,
				method: method === MethodEnum.Upload ? MethodEnum.Post : method,
				data: !isQuery && getData(),
				params: (isQuery || method === MethodEnum.Get) && params,
				headers: {
					'Content-Type': headerDefaultContentType[method],
				},
			});
			switch (res.data?.code) {
				case CODE.NEED_LOGIN:
					if (!((url === '/api/user/get' || url === '/api/user/needUpdatePassword') && hideFailedMessage)) {
						if (preCode !== CODE.NEED_LOGIN) {
							message = {
								message: 'Looks like your session expired. Please login again.',
								type: 'error',
							};
							showMessage(message);
						}
						if (!window.location.href.includes('/entry/login')) {
							const lastPath = encodeURIComponent(window.location.href);
							const brandLoginUrl = localStorage.getItem('brandLoginUrl');
							if (brandLoginUrl) {
								window.location.href = `${brandLoginUrl}?redirectUri=${lastPath}`;
							} else {
								window.location.href = `${hostnameAndPrev}/entry/login?lastPath=${lastPath}`;
							}
						}
					}
					break;
				// case CODE.NO_POWER:
				// 	window.location.replace(`${hostnameAndPrev}/app/dashboard/getStarted`);
				// break;
				case CodeMap.Success:
					message = {
						message: res.data.msg,
						type: 'success',
					};
					resolve(res.data);
					break;
				default:
					if (isAllRes) {
						if (res.data) {
							resolve(res.data);
						}
					} else {
						message = {
							message: res.data ? res.data.msg : '',
							type: 'error',
						};
						reject(
							res.data || {
								msg: res.msg,
							},
						);
					}
			}
			preCode = res.data?.code;
		} catch (error) {
			message = {
				message: error ? `The request url: '${error?.config?.url}' responded with an error: ${error?.message}` : 'Error',
				type: 'error',
			};
			reject({
				message: message.message,
				code: CodeMap.Failed,
				timestamp: 0,
				data: {},
			});
		}
		if (!hideSuccessMessage && message.type === 'success') {
			showMessage(message);
		}
		if (!hideFailedMessage && message.type !== 'success') {
			showMessage(message);
		}
		if (hideSuccessMessage && hideFailedMessage && isAllRes && message.type !== 'success') {
			showMessage(message);
		}
	});
};

export const GET = (url: string, params?: object, hideSuccessMessage?: boolean, hideFailedMessage?: boolean, mock?: boolean) => {
	return fetch(url, MethodEnum.Get, params, hideSuccessMessage, hideFailedMessage, false, false, mock);
};

export const DOWNLOAD = async (url: string, params: DownloadParams, config?: any) => {
	const response: any = await axios.get(url, {
		params,
		responseType: 'blob',
		...config,
	});
	// create file link in browser's memory
	const href = URL.createObjectURL(response.data);

	// create "a" HTML element with href to file & click
	const link = document.createElement('a');
	link.href = href;
	link.setAttribute('download', params.filename); //or any other extension
	document.body.appendChild(link);
	link.click();

	// clean up "a" element & remove ObjectURL
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
};

export const POST = (url: string, params?: object, hideSuccessMessage?: boolean, hideFailedMessage?: boolean, isQuery?: boolean, isAllRes?: boolean, mock?: boolean) => {
	return fetch(url, MethodEnum.Post, params, hideSuccessMessage, hideFailedMessage, isQuery, isAllRes, mock);
};

export const PATCH = (url: string, params?: object, hideSuccessMessage?: boolean, hideFailedMessage?: boolean) => {
	return fetch(url, MethodEnum.Patch, params, hideSuccessMessage, hideFailedMessage);
};

export const UPLOAD = (url: string, params?: object, hideSuccessMessage?: boolean, hideFailedMessage?: boolean) => {
	return fetch(url, MethodEnum.Upload, params, hideSuccessMessage, hideFailedMessage);
};

export default GET;
