import { Box } from '@mui/material';
import { FunctionComponent, ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from 'src/app/components/loading';
const PageContainer: FunctionComponent<{
  children?: ReactNode;
  fullScreen?: boolean;
  sx?: object;
}> = ({
  children,
  fullScreen = false,
  sx
}) => {
  return <Box id="pageContent" sx={{
    flexGrow: 1,
    padding: fullScreen ? '0px' : '32px 24px',
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    position: 'relative',
    flex: 1,
    ...sx
  }}>
			{children || <Suspense fallback={<Loading open={true} />}>
					<Outlet />
				</Suspense>}
		</Box>;
};
export default PageContainer;