import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { FunctionComponent, ReactNode } from 'react';
import { CustomStyleTooltip } from 'src/app/components/form/customTooltip';
export const CustomLabel: FunctionComponent<{
  variant?: 'subtitle2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit' | undefined;
  children: ReactNode;
  tooltip?: any;
  tipPlacement?: 'right' | 'top' | 'bottom' | 'left' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | undefined;
  tooltipStyle?: any;
  color?: string;
  sx?: any;
  iconStyle?: any;
  subText?: string;
  optional?: boolean;
  subTextVariant?: 'subtitle2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit' | undefined;
}> = ({
  variant = 'subtitle2',
  children,
  tooltip,
  tipPlacement = 'right',
  tooltipStyle,
  color = 'primary.main',
  sx,
  iconStyle,
  subText = '',
  optional = false,
  subTextVariant = 'body1'
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  return <Typography variant={variant} sx={{
    mb: 1,
    ...(sx && sx)
  }} color={color}>
			{children}
			{optional && formatMessage({
      defaultMessage: ' (optional)'
    })}
			{tooltip && <CustomStyleTooltip title={tooltip} placement={tipPlacement} tooltipStyle={tooltipStyle}>
					<HelpRoundedIcon sx={{
        color: 'rgba(26, 30, 34, 0.3)',
        fontSize: '14px',
        ml: 1,
        verticalAlign: 'text-top',
        cursor: 'pointer',
        ...(iconStyle && iconStyle)
      }}></HelpRoundedIcon>
				</CustomStyleTooltip>}
			{subText && <Typography variant={subTextVariant}>{subText}</Typography>}
		</Typography>;
};