import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import PageContainer from 'src/app/components/pageContainer';
import ImageContainer from 'src/app/components/imageContainer';
import Image404 from 'src/app/images/404.png';
import Image404Neutral from 'src/app/images/404_neutral.png';
import NoPower from 'src/app/images/noPower.png';
import NoPowerNeutral from 'src/app/images/noPower_neutral.png';
import { useAppSelector } from 'src/app/hooks';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
const EmptyPage: FunctionComponent<{
  type: string;
}> = ({
  type = '404'
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const image404 = isOtherBrand ? Image404Neutral : Image404;
  const noPower = isOtherBrand ? NoPowerNeutral : NoPower;
  return <PageContainer>
			<Box sx={{
      textAlign: 'center',
      pt: 11
    }}>
				<ImageContainer backgroundImage={type === '404' ? image404 : noPower} sx={{
        width: '220px',
        height: '220px',
        margin: 'auto'
      }} />
				<Typography variant="subtitle1" sx={{
        my: 2
      }}>
					{type === '404' ? formatMessage({
          defaultMessage: 'Not Found'
        }) : formatMessage({
          defaultMessage: 'No authority to access, please contact the administrator'
        })}
				</Typography>
			</Box>
		</PageContainer>;
};
export default EmptyPage;