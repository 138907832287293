import { POST, DOWNLOAD, UPLOAD } from 'src/utils/fetch';
import { JumpToSubSystemParams, JumpToSubSystemData, DownloadParams, UploadFileData } from './type';

export async function jumpToSubSystem(params: JumpToSubSystemParams) {
	return (await POST('/api/superLogin/generateSuperloginUrl', params, true, true)) as JumpToSubSystemData;
}

export async function uploadFile(params: any) {
	return (await UPLOAD('/api/common/upload', params, true)) as UploadFileData;
}

export async function downloadImage(params: DownloadParams) {
	return DOWNLOAD('/api/common/downloadByUrl', params);
}
