import { Node } from 'slate';
import { isFirefox } from 'src/utils/browser';
import { shortLinkPattern, smsVariablePattern, whatsAppVariablePattern, botVariablePattern } from './regexp';
import { VariableType, ComputeValueConfig } from './type';
import { getUuid } from 'src/utils/tools';

export const withInlines = (editor: any) => {
	const { isInline, isVoid } = editor;

	editor.isInline = (element: any) => {
		return ['shortLink', 'smsVariable', 'whatsAppVariable', 'botVariable'].includes(element.type) ? true : isInline(element);
	};

	editor.isVoid = (element: any) => {
		return ['shortLink', 'smsVariable', 'whatsAppVariable', 'botVariable'].includes(element.type) ? true : isVoid(element);
	};

	return editor;
};

const formatCharacter = (character: string, type: string) => {
	if (type !== 'whatsAppVariable') {
		return character;
	}
	const { groups = {} } = (new RegExp(whatsAppVariablePattern, 'g').exec(character) as any) || {};

	const { variable, params } = groups || {};

	if (!params) {
		return `{{${variable}:${JSON.stringify({ id: getUuid(), value: '' })}}}`;
	}
	return character;
};

export const convertTextToNodes: any = (text: string, type: VariableType) => {
	const ranges = [];
	const shortLinkMatchs = [...text.matchAll(new RegExp(shortLinkPattern, 'g'))];
	for (let i = 0; i < shortLinkMatchs.length; i++) {
		ranges.push({
			range: [shortLinkMatchs[i].index, shortLinkMatchs[i].index + shortLinkMatchs[i][0].length],
			type: 'shortLink',
		});
	}

	if (type === 'whatsapp') {
		const whatsAppVariableMatchs = [...text.matchAll(new RegExp(whatsAppVariablePattern, 'g'))];

		for (let i = 0; i < whatsAppVariableMatchs.length; i++) {
			ranges.push({
				range: [whatsAppVariableMatchs[i].index, whatsAppVariableMatchs[i].index + whatsAppVariableMatchs[i][0].length],
				type: 'whatsAppVariable',
			});
		}
	}

	if (type === 'bot') {
		const botVariableMatchs = [...text.matchAll(new RegExp(botVariablePattern, 'g'))];
		// const botVariableMatchs = [...text.matchAll(new RegExp(botVariablePattern, 'g'))].filter((item: any) => {
		//   const currentMatchRes = item?.groups || {};
		//   const matchText = `{{${currentMatchRes?.group}:${currentMatchRes?.keyname}}}`;
		//   return botVariablesText.includes(matchText)
		// });

		for (let i = 0; i < botVariableMatchs.length; i++) {
			const current: any = botVariableMatchs[i];
			ranges.push({
				range: [current.index, current.index + current[0].length],
				type: 'botVariable',
			});
		}
	}

	if (type === 'sms') {
		const smsVariableMatchs = [...text.matchAll(new RegExp(smsVariablePattern, 'g'))];

		for (let i = 0; i < smsVariableMatchs.length; i++) {
			ranges.push({
				range: [smsVariableMatchs[i].index, smsVariableMatchs[i].index + smsVariableMatchs[i][0].length],
				type: 'smsVariable',
			});
		}
	}

	const results = [];

	let lastIndex = 0;

	ranges.sort((a, b) => a.range[0] - b.range[0]);

	for (let i = 0; i < ranges.length; i++) {
		if (ranges[i].range[0] >= lastIndex) {
			results.push({
				text: text.slice(lastIndex, ranges[i].range[0]),
			});

			results.push({
				index: i,
				character: formatCharacter(text.slice(ranges[i].range[0], ranges[i].range[1]), ranges[i].type),
				type: ranges[i].type,
				children: [{ text: '' }],
			});
			lastIndex = ranges[i].range[1];
		}
		if (i === ranges.length - 1) {
			if (ranges[i].range[1] < text.length) {
				results.push({
					text: text.slice(ranges[i].range[1], text.length),
				});
			}
			if (ranges[i].range[1] === text.length) {
				results.push({
					text: '',
				});
			}
		}
	}
	return results;
};

// 将多个node节点转换成纯文本
export const serialize = (nodes: any, joinChar = '\n') => {
	return nodes
		.map((n: any) => {
			if (['whatsAppVariable', 'smsVariable', 'shortLink', 'botVariable'].includes(n.type)) {
				return n.character;
			} else {
				if (n.children) {
					return serialize(n.children, '');
				}
				return Node.string(n);
			}
		})
		.join(joinChar);
};

/* 重点修改 */
export const computeValue = (config: ComputeValueConfig) => {
	const { value, type, color = '#602DED', canEnter, background = '#E3DBFA', dontPreviewLink = false } = config;
	const varStyle = `"white-space: nowrap; color: ${color}; margin: 0 3px; border-radius: 16px; font-size: 13px; padding: 3px 8px; background: ${background}"`;
	const couponVarStyle = `"white-space: nowrap; color: #FD4C7D; margin: 0 3px; border-radius: 16px; font-size: 13px; padding: 3px 8px; background: #FFF0F1"`;

	let newValue = value || '';

	if (type === 'bot') {
		const matchArrList = [...newValue.matchAll(new RegExp(botVariablePattern, 'g'))];

		const varIndexList: any[] = matchArrList.map((item: any) => {
			const botVariable = item[0];
			const { groups = {}, index } = item;

			const { namespace, variable: keyName, params } = groups;

			if (namespace === 'coupon') {
				return {
					start: index,
					end: index + botVariable.length,
					text: `<span contentEditable="${Boolean(isFirefox)}" style=${couponVarStyle}>{{coupon}}</span>`,
				};
			} else {
				let variable = keyName;

				try {
					const { displayName } = JSON.parse(params);
					if (displayName) {
						variable = displayName;
					}
				} catch (e) {
					console.log(e);
				}
				return {
					start: index,
					end: index + botVariable.length,
					text: `<span contentEditable="${Boolean(isFirefox)}" style=${varStyle}>{{${namespace ? `${namespace}:` : ''}${variable.replace(/_/g, '&#95;')}}}</span>`,
				};
			}
		});

		newValue = varIndexList.reduceRight((r, { start, end, text }) => `${r.substring(0, start)}${text}${r.substring(end)}`, newValue);
	} else if (type === 'whatsapp') {
		newValue = newValue.replace(new RegExp(whatsAppVariablePattern, 'g'), (match: string) => {
			const { groups = {} } = (new RegExp(whatsAppVariablePattern, 'g').exec(match) as any) || {};
			const { variable } = groups || {};
			return `<span contentEditable="${Boolean(isFirefox)}" style=${varStyle}>{{${variable.replace(/_/g, '&#95;')}}}</span>`;
		});
		if (dontPreviewLink) {
			// 将'<a'替换成&lt;a
			newValue = newValue.replaceAll('<a>', '&lt;a&gt;');
			// 将'a>'替换成a&gt;
			newValue = newValue.replaceAll('</a>', '&lt;/a&gt;');
		}

		newValue = newValue.replaceAll(new RegExp(shortLinkPattern, 'g'), '<a target="__blank" href="$1">$1</a>');
	} else {
		newValue = newValue.replaceAll(new RegExp(smsVariablePattern, 'g'), `<span contentEditable="${Boolean(isFirefox)}" style=${varStyle}>$1</span>`);
		if (dontPreviewLink) {
			// 将'<a'替换成&lt;a
			newValue = newValue.replaceAll('<a>', '&lt;a&gt;');
			// 将'a>'替换成a&gt;
			newValue = newValue.replaceAll('</a>', '&lt;/a&gt;');
		}
		newValue = newValue.replaceAll(new RegExp(shortLinkPattern, 'g'), '<a target="__blank" href="$1">$1</a>');
	}
	if (canEnter) {
		newValue = newValue.replaceAll(/\\n/g, '</br>');
	}

	return newValue
		.replaceAll(/\*(.+?)\*/g, '<strong>$1</strong>')
		.replaceAll(/_(.+?)_/g, '<em>$1</em>')
		.replaceAll(/```(.+?)```/g, '<code>$1</code>')
		.replaceAll(/~(.+?)~/g, '<del>$1</del>');
};

export const computeTextType = (text: string, type: VariableType) => {
	if (text.match(new RegExp(shortLinkPattern, 'g'))) {
		return 'shortLink';
	}
	if (text.match(new RegExp(smsVariablePattern, 'g')) && type === 'sms') {
		return 'smsVariable';
	}
	if (text.match(new RegExp(whatsAppVariablePattern, 'g')) && type === 'whatsapp') {
		return 'whatsAppVariable';
	}
	if (text.match(new RegExp(botVariablePattern, 'g')) && type === 'bot') {
		return 'botVariable';
	}
	return '';
};

export const getSlugFromShortUrl = (shortUrl: string) => {
	const res = shortLinkPattern.exec(shortUrl);
	return res && res[5] ? res[5] : '';
};

export const formatTextToJson = (text: string) => {
	try {
		return JSON.parse(text);
	} catch (e) {
		return {};
	}
};

export const getNoParamsText = (text = '', type = 'whatsapp') => {
	let variablePattern = whatsAppVariablePattern;
	if (type === 'bot') {
		variablePattern = botVariablePattern;
	}
	return text.replace(new RegExp(variablePattern, 'g'), (match: string) => {
		const { groups = {} } = (new RegExp(variablePattern, 'g').exec(match) as any) || {};
		const { variable } = groups || {};
		return `{{${variable}}}`;
	});
};
