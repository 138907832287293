import { Box, Typography } from '@mui/material';
import { ReactNode, forwardRef } from 'react';
import Loading from 'src/app/components/loading';
interface IProps {
  title?: ReactNode;
  rightComponent?: ReactNode; // title右边的组件，可以放额外的按钮
  children?: ReactNode;
  sx?: any;
  label?: ReactNode;
  isLoading?: boolean;
  loadingStyle?: any;
  ref?: any;
}
export type Ref = any;
const PageBlock = forwardRef<Ref, IProps>(({
  title,
  children,
  sx = {},
  isLoading = false,
  loadingStyle,
  rightComponent,
  label
}, ref) => {
  return <Box ref={ref} sx={{
    p: 3,
    borderRadius: 1,
    boxShadow: 2,
    backgroundColor: 'fill.w',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: '520px',
    '& > .MuiBox-root > .MuiBox-root > .MuiTableContainer-root': {
      minHeight: '420px'
    },
    '& > .MuiBox-root > .MuiTableContainer-root': {
      minHeight: '420px'
    },
    ...sx
  }}>
			{(title || rightComponent) && <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
					<Box sx={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        mb: 2
      }}>
						<Typography variant="subtitle1">{title}</Typography>
						{label}
					</Box>
					<Box>{rightComponent}</Box>
				</Box>}
			{children}
			{isLoading && <Loading open={isLoading} backdropStyle={loadingStyle} />}
		</Box>;
});
export default PageBlock;