import { useIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DndProvider } from 'react-dnd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BoxWithHandle } from './BoxWithHandle';
import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { Button, Box, Stack } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
// eslint-disable-next-line import/no-extraneous-dependencies
import update from 'immutability-helper';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
const Drag: FunctionComponent<{
  [index: string]: any;
}> = ({
  ...props
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const [list, setList] = useState([]);
  const handleMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setList((prevCards: any) => {
      const newList = update(prevCards, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, prevCards[dragIndex]]]
      });
      props.onChange(newList);
      return newList;
    });
  }, []);
  useEffect(() => {
    if (props.value) {
      setList(props.value);
    } else {
      const defaultList: any = [{
        type: 'reply',
        reply: {
          title: ''
        },
        handleId: '-1'
      }];
      setList(defaultList);
      props.onChange(defaultList);
    }
  }, []);
  const error = props.error;
  const helperText = props.helperText;
  const helperTextList = helperText ? helperText.split('-') : [];
  const handleValueChange = (valueIndex: number, newValue: string) => {
    setList((prevCards: any) => {
      const newList = update(prevCards, {
        $splice: [[valueIndex, 1], [valueIndex, 0, {
          ...prevCards[valueIndex],
          reply: {
            title: newValue
          }
        }]]
      });
      props.onChange(newList);
      return newList;
    });
  };
  const handleAddButton = () => {
    setList((prevCards: any) => {
      const newList = update(prevCards, {
        $push: [{
          type: 'reply',
          reply: {
            title: ''
          },
          handleId: `${props.maxNodeHandleNumber + 1}`
        }]
      });
      props.onChange(newList);
      props.onAddButton();
      return newList;
    });
  };
  const handleDelete = (deleteIndex: number) => {
    setList((prevCards: any) => {
      const newList = update(prevCards, {
        $splice: [[deleteIndex, 1]]
      });
      props.onChange(newList);
      return newList;
    });
  };
  const renderItem = useCallback((item: any, index: number) => {
    return <BoxWithHandle onDelete={handleDelete} key={item.handleId} onBlur={props.onBlur} error={helperTextList.includes(`${index}`) ? error : ''} index={index} onValueChange={handleValueChange} moveCard={handleMove} text={item.reply.title} id={item.handleId} />;
  }, [helperTextList]);
  return <>
			<DndProvider backend={HTML5Backend}>
				<Stack spacing={1}>
					{list.map((item: any, index: number) => {
          return renderItem(item, index);
        })}
					<Box>
						<Button startIcon={<AddRoundedIcon />} variant="text" disabled={list.length >= 3} onClick={handleAddButton} color="secondary">
							{formatMessage({
              defaultMessage: 'Add button'
            })}
						</Button>
					</Box>
				</Stack>
				{error === 'required' && !helperText && <ErrorHelperText>
						{formatMessage({
          defaultMessage: 'Required'
        })}
					</ErrorHelperText>}
			</DndProvider>
		</>;
};
export default Drag;