import { useCallback, FunctionComponent } from 'react';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import { Box } from '@mui/material';
const DragButton: FunctionComponent<{
  sx?: object;
  onChange: (value: number) => void;
}> = ({
  onChange,
  sx
}) => {
  const handleMouseDown = useCallback((event: any) => {
    const startY = event.clientY;
    let distance = 0;
    let move = 0;
    const handleMouseMove = (moveEvent: any) => {
      distance = moveEvent.clientY - startY;
      onChange(distance - move);
      move = distance;
    };
    document.addEventListener('mousemove', handleMouseMove);
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    document.addEventListener('mouseup', handleMouseUp);
  }, []);
  return <Box onMouseDown={handleMouseDown} sx={{
    width: '40px',
    height: '40px',
    position: 'absolute',
    right: -20,
    bottom: -20,
    cursor: 'ns-resize',
    zIndex: 100,
    ...sx,
    '&:hover': {
      '& svg': {
        color: '#000'
      }
    }
  }}>
			<NavigateBeforeRoundedIcon sx={{
      color: '#a19dad',
      transform: 'rotate(225deg)'
    }} />
		</Box>;
};
export default DragButton;