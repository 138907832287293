import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import {
	WaBaByTokenParams,
	WaBaPhoneParams,
	UnBindPhoneParams,
	getPhoneInfoParams,
	updatePhoneInfoParams,
	TemplateListParams,
	AddTemplateParams,
	DelTemplateParams,
	DelTemplateByIdParams,
	WhatsappLogsParams,
	WhatsappOverViewParams,
	CheckUsedLangParams,
	CheckWhatsappCopyParams,
	GetTemplateInsightsParams,
} from './type';
import {
	getWaBaByToken,
	getWaBaPhone,
	checkWhatsappCopy,
	getBindedPhone,
	checkUsedLang,
	setBindPhone,
	setUnBindPhone,
	createExport,
	getWabaList,
	getLanguages,
	getUsedCategorys,
	getUsedLanguages,
	getPhoneInfo,
	uploadPhonePhoto,
	updatePhoneInfo,
	getTemplateList,
	getFlattenTemplateList,
	uploadMedia,
	addTemplate,
	delTemplate,
	delTemplateById,
	getLogs,
	checkExportProgress,
	getAnalyticsParams,
	getConversationAnalytics,
	getMessageAnalytics,
	getCreator,
	addPhoneNumber,
	sendCode,
	verifyCode,
	registerPhoneNumber,
	getInboxDetailByInboxId,
	getAddableAgents,
	getChatBotConfigByPhoneId,
	removeChatBot,
	updatePhoneNumberChatBotConfig,
	exportCampaign,
	getCampaignExportProgress,
	cancelExportCampaign,
	getTemplateInsightsData,
	getBatchInsightsData,
	checkReference,
	updatePhoneNumberAlias,
} from './whatsappAPI';

export interface WhatsappState {
	bindedPhoneList: Array<any>;
	wabaList: Array<any>;
	accountList: Array<any>;
	listLanguages: Array<any>;
	usedLanguages: Array<any>;
	templateList: Array<any>;
	curWabaId: string;
	currentEditPhoneId: string;
	curWabaStatus: string;
	variableListDirty: any;
	usedCategories: Array<any>;
	addVariable: any;
	automationDirty: any;
}

const initialState: WhatsappState = {
	bindedPhoneList: [],
	wabaList: [],
	usedCategories: [],
	accountList: [],
	listLanguages: [],
	usedLanguages: [],
	templateList: [],
	curWabaId: '',
	curWabaStatus: '',
	variableListDirty: {},
	currentEditPhoneId: '',
	addVariable: {},
	automationDirty: {},
};

export const getWaBaByTokenAsync = createAsyncThunk('whatsapp/getWaBaByToken', async (params: WaBaByTokenParams) => {
	const res = await getWaBaByToken(params);
	return res;
});

export const getTemplateInsightsDataAsync = createAsyncThunk('whatsapp/getTemplateInsightsData', async (params: GetTemplateInsightsParams) => {
	const res = await getTemplateInsightsData(params);
	return res;
});

// 获取所有wabaId下的所有手机号
export const getAllPhoneAsync = createAsyncThunk('whatsapp/getAllPhone', (params: Array<any>) => {
	const promiseData: any = [];
	params.forEach((i: any) => {
		if (i.id.length > 5) {
			promiseData.push(getWaBaPhone({ wabaId: i.id }));
		}
	});
	return promiseData;
});

export const whatsappInit = createAsyncThunk('whatsapp/init', async (page: string) => {
	const wabaList = await getWabaList();
	if (page === 'bot') {
		return { wabaList };
	} else if (page === 'account') {
		const phoneList = await getBindedPhone();
		return { wabaList, phoneList };
	} else {
		const languages = await getLanguages();
		return { wabaList, languages };
	}
});

export const getWabaListAsync = createAsyncThunk('whatsapp/getWabaList', async () => {
	const res = await getWabaList();
	return res;
});

export const addPhoneNumberAsync = createAsyncThunk('whatsapp/addPhoneNumber', async (params: any) => {
	const res = await addPhoneNumber(params);
	return res;
});

export const sendCodeAsync = createAsyncThunk('whatsapp/sendCode', async (params: any) => {
	const res = await sendCode(params);
	return res;
});

export const verifyCodeAsync = createAsyncThunk('whatsapp/verifyCode', async (params: any) => {
	const res = await verifyCode(params);
	return res;
});

export const getLanguagesAsync = createAsyncThunk('whatsapp/getLanguages', async () => {
	const res = await getLanguages();
	return res;
});

export const getAddableAgentsAsync = createAsyncThunk('whatsapp/getAddableAgents', async (params: any) => {
	const res = await getAddableAgents(params);
	return res;
});

export const getCreatorAsync = createAsyncThunk('whatsapp/getCreator', async (params: { wabaId: string }) => {
	const res = await getCreator(params);
	return res;
});

export const getUsedLanguagesAsync = createAsyncThunk('whatsapp/getUsedLanguages', async (params: WaBaPhoneParams) => {
	const res = await getUsedLanguages(params);
	return res;
});

export const getUsedCategorysAsync = createAsyncThunk('whatsapp/getUsedCategorys', async (params: WaBaPhoneParams) => {
	const res = await getUsedCategorys(params);
	return res;
});

export const getBindedPhoneAsync = createAsyncThunk('whatsapp/getBindedPhone', async () => {
	const res = await getBindedPhone();
	return res;
});

export const setBindPhoneAsync = createAsyncThunk('whatsapp/setBindPhone', async (params: any) => {
	const res = await setBindPhone(params);
	return res;
});

export const createExportAsync = createAsyncThunk('whatsapp/createExport', async (params: any) => {
	const res = await createExport(params);
	return res;
});

export const checkExportProgressAsync = createAsyncThunk('whatsapp/checkExportProgress', async (params: any) => {
	const res = await checkExportProgress(params);
	return res;
});

export const setUnBindPhoneAsync = createAsyncThunk('whatsapp/setUnBindPhone', async (params: UnBindPhoneParams) => {
	const res = await setUnBindPhone(params);
	return res;
});

export const getPhoneInfoAsync = createAsyncThunk('whatsapp/getPhoneInfo', async (params: getPhoneInfoParams) => {
	const res = await getPhoneInfo(params);
	return res;
});

export const uploadPhonePhotoAsync = createAsyncThunk('whatsapp/uploadPhonePhoto', async (params: any) => {
	const res = await uploadPhonePhoto(params);
	return res;
});

export const updatePhoneInfoAsync = createAsyncThunk('whatsapp/updatePhoneInfo', async (params: updatePhoneInfoParams) => {
	const res = await updatePhoneInfo(params);
	return res;
});

export const getTemplateListAsync = createAsyncThunk('whatsapp/getTemplateList', async (params: TemplateListParams) => {
	const res = await getTemplateList(params);
	return res;
});

// 与getTemplateListAsync做区分，whatsapp template列表以模版语言作细分
export const getFlattenTemplateListAsync = createAsyncThunk('whatsapp/getFlattenTemplateList', async (params: TemplateListParams) => {
	const res = await getFlattenTemplateList(params);
	return res;
});

export const uploadMediaAsync = createAsyncThunk('whatsapp/uploadMedia', async (params: any) => {
	const res = await uploadMedia(params);
	return res;
});

export const checkWhatsappCopyAsync = createAsyncThunk('whatsapp/checkWhatsappCopy', async (params: CheckWhatsappCopyParams, { rejectWithValue }) => {
	try {
		const res = await checkWhatsappCopy(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addTemplateAsync = createAsyncThunk('whatsapp/addTemplate', async (params: AddTemplateParams, { rejectWithValue }) => {
	try {
		const res = await addTemplate(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const delTemplateAsync = createAsyncThunk('whatsapp/delTemplate', async (params: DelTemplateParams) => {
	const res = await delTemplate(params);
	return res;
});

export const checkUsedLangAsync = createAsyncThunk('whatsapp/checkUsedLang', async (params: CheckUsedLangParams) => {
	const res = await checkUsedLang(params);
	return res;
});

export const delTemplateByIdAsync = createAsyncThunk('whatsapp/delTemplateById', async (params: DelTemplateByIdParams) => {
	const res = await delTemplateById(params);
	return res;
});

export const getLogsAsync = createAsyncThunk('whatsapp/logs', async (params: WhatsappLogsParams) => {
	const res = await getLogs(params);
	return res;
});

export const exportCampaignAsync = createAsyncThunk('whatsapp/exportCampaign', async (params: any) => {
	const res = await exportCampaign(params);
	return res;
});

export const getCampaignExportProgressAsync = createAsyncThunk('whatsapp/checkCampaignExportProgress', async (params: any) => {
	const res = await getCampaignExportProgress(params);
	return res;
});

export const cancelExportCampaignAsync = createAsyncThunk('whatsapp/cancelExportCampaign', async (params: any) => {
	const res = await cancelExportCampaign(params);
	return res;
});

export const getAnalyticsParamsAsync = createAsyncThunk('whatsapp/getAnalyticsParams', async (params: WhatsappOverViewParams) => {
	const res = await getAnalyticsParams(params);
	return res;
});

export const getConversationAnalyticsAsync = createAsyncThunk('whatsapp/conversation', async (params: WhatsappOverViewParams) => {
	const res = await getConversationAnalytics(params);
	return res;
});

export const getMessageAnalyticsAsync = createAsyncThunk('whatsapp/message', async (params: WhatsappOverViewParams) => {
	const res = await getMessageAnalytics(params);
	return res;
});

export const registerPhoneNumberAsync = createAsyncThunk('whatsapp/registerPhoneNumber', async (params: any) => {
	const res = await registerPhoneNumber(params);
	return res;
});

export const getChatBotConfigByPhoneIdAsync = createAsyncThunk('whatsapp/getChatBotConfigByPhoneId', async (params: any) => {
	const res = await getChatBotConfigByPhoneId(params);
	return res;
});

export const removeChatBotAsync = createAsyncThunk('whatsapp/removeChatBot', async (params: any) => {
	const res = await removeChatBot(params);
	return res;
});

export const updatePhoneNumberChatBotConfigAsync = createAsyncThunk('whatsapp/updatePhoneNumberChatBotConfig', async (params: any) => {
	const res = await updatePhoneNumberChatBotConfig(params);
	return res;
});

export const getBatchInsightsDataAsync = createAsyncThunk('whatsapp/getBatchInsightsData', async (params: any) => {
	const res = await getBatchInsightsData(params);
	return res;
});

// 主要是inbox里面的agents、teams
export const getInboxDetailByInboxIdAsync = createAsyncThunk('whatsapp/getInboxDetailByInboxId', async (params: any) => {
	const res = await getInboxDetailByInboxId(params);
	return res;
});

// 检查whatsapp template在flows或者自动化营销中的引用
export const checkReferenceAsync = createAsyncThunk('whatsapp/checkReference', async (params: any) => {
	const res = await checkReference(params);
	return res;
});

export const updatePhoneNumberAliasAsync = createAsyncThunk('whatsapp/updatePhoneNumberAlias', async (params: any) => {
	const res = await updatePhoneNumberAlias(params);
	return res;
});

export const selectBindedPhoneList = (state: RootState) => state.whatsapp.bindedPhoneList;

export const selectWabaList = (state: RootState) => state.whatsapp.wabaList;
export const selectAccountList = (state: RootState) => state.whatsapp.accountList;
export const selectListLanguages = (state: RootState) => state.whatsapp.listLanguages;
export const selectUsedLanguages = (state: RootState) => state.whatsapp.usedLanguages;
export const selectUsedCategories = (state: RootState) => state.whatsapp.usedCategories;
export const selectTemplateList = (state: RootState) => state.whatsapp.templateList;
export const selectCurWabaId = (state: RootState) => state.whatsapp.curWabaId;
export const selectCurWabaStatus = (state: RootState) => state.whatsapp.curWabaStatus;
export const selectAddVariable = (state: RootState) => state.whatsapp.addVariable;
export const selectVariableListDirty = (state: RootState) => state.whatsapp.variableListDirty;

export const whatsappSlice = createSlice({
	name: 'whatsapp',
	initialState,
	reducers: {
		setCurWabaId: (state, action) => {
			localStorage.setItem('curWabaIdInLocalStorage', action.payload);
			state.curWabaId = action.payload;
		},
		setCurWaBaStatus: (state, action) => {
			state.curWabaStatus = action.payload;
		},
		setCurrentEditPhoneId: (state, action) => {
			state.currentEditPhoneId = action.payload;
		},
		updateAddVariable: (state, action) => {
			const key = Object.keys(action.payload);
			state.addVariable[key[0]] = Object.assign({}, state.addVariable[key[0]], action.payload[key[0]]);
		},
		updateVariableListDirty: (state, action) => {
			state.variableListDirty = {
				...state.variableListDirty,
				...action.payload,
			};
		},
		clearAddVariable: state => {
			state.addVariable = {};
		},
		setTemplateList: (state, action) => {
			state.templateList = action.payload;
		},
		updateAutomationDirty: (state, action) => {
			state.automationDirty = {
				...state.automationDirty,
				[action.payload.key]: action.payload.isDirty,
			};
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getBindedPhoneAsync.fulfilled, (state, action) => {
				state.bindedPhoneList = action.payload.data;
			})
			.addCase(whatsappInit.fulfilled, (state, action) => {
				const wabaListData = action.payload.wabaList.data;
				state.wabaList = wabaListData;
				if (wabaListData.length > 0) {
					const curWabaIdInLocalStorage = localStorage.curWabaIdInLocalStorage;
					if (curWabaIdInLocalStorage && wabaListData.some((item: any) => item.id === curWabaIdInLocalStorage)) {
						state.curWabaId = curWabaIdInLocalStorage;
						const targetWaba = wabaListData.find((i: any) => i.id === curWabaIdInLocalStorage);
						state.curWabaStatus = targetWaba.accountReviewStatus;
					} else {
						const id = state.curWabaId;
						const target = wabaListData.find((i: any) => i.id === id);
						state.curWabaId = id && target ? id : wabaListData[0].id;
						state.curWabaStatus = id && target ? target.accountReviewStatus : wabaListData[0].accountReviewStatus;
					}
				}
				if (action.payload.phoneList) {
					const phoneListData = action.payload.phoneList.data;
					state.bindedPhoneList = phoneListData;

					const data: Array<any> = [];
					wabaListData.forEach((i: any) => {
						const phoneList = phoneListData.filter((j: any) => j.wabaId === i.id);
						if (phoneList.length > 0) {
							data.push({
								id: i.id,
								name: i.name,
								accountReviewStatus: i.accountReviewStatus,
								businessVerificationStatus: i.businessVerificationStatus,
								table: phoneList,
							});
						}
					});
					state.accountList = data;
				}
				if (action.payload.languages) {
					state.listLanguages = action.payload.languages.data;
				}
			})
			.addCase(getWabaListAsync.fulfilled, (state, action) => {
				state.wabaList = action.payload.data;
			})
			.addCase(getLanguagesAsync.fulfilled, (state, action) => {
				state.listLanguages = action.payload.data;
			})
			.addCase(getUsedLanguagesAsync.fulfilled, (state, action) => {
				state.usedLanguages = action.payload.data;
			})
			.addCase(getUsedCategorysAsync.fulfilled, (state, action) => {
				state.usedCategories = action.payload.data;
			})
			.addCase(updatePhoneNumberAliasAsync.fulfilled, (_, action) => {
				localStorage.setItem('currentEditPhone', JSON.stringify(action.payload.data));
			});
	},
});

export const selectCurrentEditPhoneId = (state: RootState) => state.whatsapp.currentEditPhoneId;

export const selectAutomationDirty = (state: RootState) => state.whatsapp.automationDirty;

export const { updateAutomationDirty, setCurrentEditPhoneId, setCurWabaId, setCurWaBaStatus, updateAddVariable, updateVariableListDirty, clearAddVariable, setTemplateList } = whatsappSlice.actions;

export default whatsappSlice.reducer;
