import { Checkbox } from '@mui/material';
import React, { FunctionComponent } from 'react';
export const handleSelectSingle = (selected: readonly string[], item: string) => {
  const selectedIndex = selected.indexOf(item);
  let newSelected: readonly string[] = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, item);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  }
  return newSelected;
};
const SelectAll: FunctionComponent<{
  isChecked?: boolean;
  allLen: number;
  selected: readonly string[];
  onAll: (event: any) => void;
  checkboxStyle?: any;
}> = ({
  isChecked = false,
  allLen,
  selected,
  onAll,
  checkboxStyle
}) => {
  return <Checkbox disabled={allLen === 0} checked={isChecked || allLen > 0 && selected.length === allLen} indeterminate={selected.length > 0 && selected.length < allLen} onChange={onAll} sx={{
    ...(checkboxStyle && checkboxStyle)
  }} />;
};
export default SelectAll;