import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import { selectIsLogin } from 'src/features/user/userSlice';
const ProtectedRoute = ({
  isAllowed = true
}: {
  isAllowed?: boolean;
}) => {
  const isLogin = useAppSelector(selectIsLogin);
  return isAllowed && isLogin ? <Outlet /> : <Navigate to="/entry/login" replace />;
};
export default ProtectedRoute;