import { defineMessages } from 'react-intl';

export enum WhatsAppFlowsStatusEnum {
	DRAFT = 'DRAFT',
	PUBLISHED = 'PUBLISHED',
	DEPRECATED = 'DEPRECATED',
	THROTTLED = 'THROTTLED',
	BLOCKED = 'BLOCKED',
}

export const whatsappFlowsStatusLabelMap = defineMessages({
	[WhatsAppFlowsStatusEnum.DRAFT]: {
		defaultMessage: 'Draft',
	},
	[WhatsAppFlowsStatusEnum.PUBLISHED]: {
		defaultMessage: 'Published',
	},
	[WhatsAppFlowsStatusEnum.DEPRECATED]: {
		defaultMessage: 'Deprecated',
	},
	[WhatsAppFlowsStatusEnum.THROTTLED]: {
		defaultMessage: 'Throttled',
	},
	[WhatsAppFlowsStatusEnum.BLOCKED]: {
		defaultMessage: 'Blocked',
	},
});

export const whatsappFlowsStatusColorMap = {
	[WhatsAppFlowsStatusEnum.DRAFT]: 'secondary',
	[WhatsAppFlowsStatusEnum.PUBLISHED]: 'success',
	[WhatsAppFlowsStatusEnum.DEPRECATED]: 'default',
	[WhatsAppFlowsStatusEnum.THROTTLED]: 'warning',
	[WhatsAppFlowsStatusEnum.BLOCKED]: 'error',
};

export interface SyncWhatsAppFlowsParams {
	wabaId: string;
}

export interface WhatsAppFlowListItem {
	id: string;
	wabaId: string;
	name: string;
	status: WhatsAppFlowsStatusEnum;
	updateTime: string;
}

export interface GetFlowsSubmissionsNumParams {
	flowIds: string;
}

export interface GetWhatsAppFlowInfoParams {
	id: string;
}

export type PayloadConfig = {
	key: string;
	label: string;
};

type Screen = {
	id: string;
};

type FlowJson = {
	screens: Screen[];
};

export interface WhatsAppFlowInfo {
	id: string;
	wabaId: string;
	name: string;
	status: WhatsAppFlowsStatusEnum;
	updateTime: string;
	payloadConfigs: PayloadConfig[];
	flowJson: FlowJson;
}

export enum WhatsAppFlowSourceEnum {
	Bot = 'bot',
	MarketingAutomation = 'marketing-automation',
	Dashboard = 'dashboard',
	Inbox = 'inbox',
	Rest = 'rest',
	RestSendDirectly = 'rest:sendDirectly',
	InboxAutomationWelcome = 'inbox:automation:welcome',
	InboxAutomationAutoReplyUnassignedDialog = 'inbox:automation:autoReplyUnassignedDialog',
	InboxAutomationInQueue = 'inbox:automation:inQueue',
	InboxAutomationAutoReplyIfNoAgentReply = 'inbox:automation:autoReplyIfNoAgentReply',
	InboxAutomationCsat = 'inbox:automation:csat',
}

export const WhatsAppFlowSourceLabelMap = defineMessages({
	[WhatsAppFlowSourceEnum.Bot]: {
		defaultMessage: 'Bot',
	},
	[WhatsAppFlowSourceEnum.MarketingAutomation]: {
		defaultMessage: 'Journey',
	},
	[WhatsAppFlowSourceEnum.Dashboard]: {
		defaultMessage: 'Campaign',
	},
	[WhatsAppFlowSourceEnum.Inbox]: {
		defaultMessage: 'Inbox',
	},
	[WhatsAppFlowSourceEnum.Rest]: {
		defaultMessage: 'API',
	},
	[WhatsAppFlowSourceEnum.RestSendDirectly]: {
		defaultMessage: 'API',
	},
	[WhatsAppFlowSourceEnum.InboxAutomationWelcome]: {
		defaultMessage: 'Welcome message',
	},
	[WhatsAppFlowSourceEnum.InboxAutomationAutoReplyUnassignedDialog]: {
		defaultMessage: 'Delayed response',
	},
	[WhatsAppFlowSourceEnum.InboxAutomationInQueue]: {
		defaultMessage: 'In queue',
	},
	[WhatsAppFlowSourceEnum.InboxAutomationAutoReplyIfNoAgentReply]: {
		defaultMessage: 'Away message',
	},
	[WhatsAppFlowSourceEnum.InboxAutomationCsat]: {
		defaultMessage: 'CSAT',
	},
});

export interface WhatsAppFlowLog {
	customerPhoneNumber: string;
	customerName: string;
	businessPhoneNumber: string;
	sendTime: string;
	source: WhatsAppFlowSourceEnum;
	sourceDisplayName: string;
	templateName?: string;
	templateLanguage?: string;
	templateLanguageDisplayName?: string;
	payload: object;
}
