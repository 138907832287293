import { Select, MenuItem, Typography, Box, Link } from '@mui/material';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import CustomChip from 'src/app/components/customChip';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { getWabaListAsync, selectWabaList } from 'src/features/whatsapp/whatsappSlice';
import { FC, useEffect } from 'react';
import EmptyData from 'src/app/components/table/EmptyData';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
const getLabelByValue = (value: any, options: Array<any>) => {
  for (let i = 0; i < options.length; i++) {
    if (options[i].id === value) {
      return options[i].name;
    }
  }
};
const WabaSelector: FC<{
  error?: boolean;
  helperText?: any;
  value: any;
  disabled?: boolean;
  onChange: (v: any) => void;
}> = ({
  ...props
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const wabaList = useAppSelector(selectWabaList);
  const isEmpty = wabaList.length === 0;
  const navigate = useNavigate();
  const handleAddWaba = () => {
    navigate('/app/dashboard/getStarted');
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!wabaList.length) {
      dispatch(getWabaListAsync());
    }
  }, []);
  return <>
			<Select {...props} disabled={props.disabled} size="small" renderValue={(e: any) => {
      if (!e) {
        return '';
      }
      const name = getLabelByValue(e, wabaList);
      return name;
    }} variant="outlined" error={props.error} placeholder={formatMessage({
      defaultMessage: 'Please Select Whatsapp account'
    })} sx={{
      width: '100%'
    }}>
				{isEmpty && <Box sx={{
        py: '21px'
      }}>
						<EmptyData imageSize={133.3} description={<>
									<span>
										<FormattedMessage defaultMessage={'There is no valid WABA.'} />
									</span>
									<Link onClick={handleAddWaba} sx={{
            ml: 0.5
          }}>
										<FormattedMessage defaultMessage={'Please add it first.'} />
									</Link>
								</>} />
					</Box>}
				{wabaList.map((waba: any) => {
        return <MenuItem value={waba.id} key={waba.id} disabled={waba.accountReviewStatus === 'REJECTED'} sx={{
          '&.Mui-disabled': {
            opacity: 1,
            color: 'text.disabled'
          }
        }}>
							<Box>
								<Typography variant="subtitle1" sx={{
              fontWeight: 400,
              display: 'flex',
              alignItems: 'center',
              columnGap: '4px'
            }}>
									{waba.name}
									{waba.accountReviewStatus === 'REJECTED' && <CustomChip color="error" label={formatMessage({
                defaultMessage: 'Disabled'
              })} />}
								</Typography>
								<Typography variant="caption">WABA Id: ({waba.id})</Typography>
							</Box>
						</MenuItem>;
      })}
			</Select>
			<ErrorHelperText>{props.helperText}</ErrorHelperText>
		</>;
};
export default WabaSelector;