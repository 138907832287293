import { Box, Typography } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { CustomLabel } from 'src/app/components/form';
import LongTooltipText from '../form/LongTooltipText';
const InfoItem: FunctionComponent<{
  label: string;
  value: string | ReactNode;
  width?: string;
  labelVariant?: 'subtitle2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit' | undefined;
  labelColor?: string;
  labelStyle?: any;
  valueVariant?: 'subtitle2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit' | undefined;
  valueColor?: string;
  valueStyle?: any;
  boxStyle?: any;
  tooltip?: string;
  valueMaxWidth?: number;
  valueMaxLine?: number;
}> = ({
  label,
  value,
  width = '100%',
  labelVariant = 'subtitle1',
  labelColor = 'primary.dark',
  labelStyle,
  valueVariant = 'body2',
  valueColor = 'primary',
  valueStyle,
  tooltip,
  boxStyle,
  valueMaxWidth,
  valueMaxLine
}) => {
  return <Box sx={{
    width: width,
    ...(boxStyle && boxStyle)
  }}>
			<CustomLabel tooltip={tooltip} variant={labelVariant} color={labelColor} sx={{
      ...(labelStyle && labelStyle)
    }}>
				{label}
			</CustomLabel>
			<Typography variant={valueVariant} color={valueColor} component="div" sx={{
      ...(valueStyle && valueStyle)
    }}>
				{valueMaxLine && valueMaxWidth ? <LongTooltipText variant="valueVariant" maxWidth={valueMaxWidth} maxLine={valueMaxLine}>
						{value}
					</LongTooltipText> : <>{value}</>}
			</Typography>
		</Box>;
};
export default InfoItem;