import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import {
	addContact,
	addSegment,
	batchAddTags,
	checkEmailDuplicate,
	delSegment,
	removeTag,
	downLoadExport,
	exportContact,
	getAllAdmin,
	getAllCustomAttr,
	getAllFilter,
	getAllSegment,
	getAllTags,
	getContactList,
	getImportProgress,
	importCreat,
	importProcess,
	importSave,
	importUpload,
	isPhoneExist,
	updateContact,
	updateContactFieldVisible,
	updateSegment,
	addTag,
	updateTag,
	deleteTag,
	addBlocklist,
	deleteBlocklist,
	getBlocklistSum,
} from './contactListAPI';
import { BatchAddTagParams, ContactItemParams, ContactListParams, DelTagParams, SegmentParams } from './type';
import { deepCopy } from 'src/utils/transform';
import { CODE } from 'src/app/const/code';

export interface ContactListState {
	allCustomAttr: Array<any>;
	allTags: Array<any>;
	allSegments: Array<any>;
	curSeg: any;
	curTag: string;
	allTotal: number;
	tableTotal: number;
	blocklistTotal: number;
	tableData: Array<any>;
	tablePagin: any;
	filterList: Array<any>;
	tagColor: Array<any>;
	tagColorMapping: any;
}

const initialState: ContactListState = {
	allCustomAttr: [],
	allTags: [],
	allSegments: [],
	curSeg: 'all',
	curTag: '',
	allTotal: 0,
	tableTotal: 0,
	blocklistTotal: 0,
	tableData: [],
	tablePagin: {
		pageNo: 1,
		pageSize: 20,
		totalCount: 0,
	},
	filterList: [],
	tagColor: [],
	tagColorMapping: {},
};

export const getAllCustomAttrAsync = createAsyncThunk('contactList/getAllCustomAttr', async (block?: boolean) => {
	const res = await getAllCustomAttr({ custom: false, block });
	return res.data;
});

export const getContactTableAsync = createAsyncThunk('contactList/getContactTable', async (params: ContactListParams) => {
	const res = await getContactList(params);
	return res.data;
});

export const checkEmailDuplicateAsync = createAsyncThunk('contactList/checkEmailDuplicate', async (params: any) => {
	const res = await checkEmailDuplicate(params);
	return res.data;
});

export const isPhoneExistAsync = createAsyncThunk('contactList/isPhoneExist', async (params: any) => {
	const res = await isPhoneExist(params);
	return res;
});

export const addContactAsync = createAsyncThunk('contactList/addContact', async (params: ContactItemParams) => {
	const res = await addContact(params);
	return res;
});

export const updateContactAsync = createAsyncThunk('contactList/updateContact', async (params: ContactItemParams) => {
	const res = await updateContact(params);
	return res;
});

export const importCreatAsync = createAsyncThunk('contactList/importCreat', async () => {
	const res = await importCreat();
	return res;
});

export const importUploadAsync = createAsyncThunk('contactList/importUpload', async (params: any) => {
	const res = await importUpload(params);
	return res;
});

export const importProcessAsync = createAsyncThunk('contactList/importProcess', async (params: any) => {
	const res = await importProcess(params);
	return res;
});

export const importSaveAsync = createAsyncThunk('contactList/importSave', async (params: any) => {
	const res = await importSave(params);
	return res;
});

export const getImportProgressAsync = createAsyncThunk('contactList/getImportProgress', async (params: any) => {
	const res = await getImportProgress(params);
	return res;
});

export const getAllTagsAsync = createAsyncThunk('contactList/getAllTags', async () => {
	const res = await getAllTags();
	return res.data;
});

export const getContactListAsync = createAsyncThunk('contactList/getContactList', async (params: ContactListParams, { dispatch }) => {
	await dispatch(getAllTagsAsync());
	const list = await getContactList(params);
	return {
		data: list.data,
		isAll: !params.segmentId && !params.blocked && JSON.stringify(params.query) === '{}' ? true : false,
	};
});

export const removeTagAsync = createAsyncThunk('contactList/removeTag', async (params: DelTagParams) => {
	const res = await removeTag(params);
	return res;
});

export const batchAddTagsAsync = createAsyncThunk('contactList/batchAddTags', async (params: BatchAddTagParams) => {
	const res = await batchAddTags(params);
	return res;
});

export const getAllAdminAsync = createAsyncThunk('contactList/getAllAdmin', async () => {
	const res = await getAllAdmin();
	return res;
});

export const exportContactAsync = createAsyncThunk('contactList/exportContact', async (params: any, { rejectWithValue }) => {
	try {
		const res = await exportContact(params);
		return res;
	} catch (e) {
		return rejectWithValue(e);
	}
});

export const downLoadExportAsync = createAsyncThunk('contactList/downLoadExport', async (params: any) => {
	const res = await downLoadExport(params);
	return res;
});

export const getAllSegmentAsync = createAsyncThunk('contactList/getAllSegment', async () => {
	const res = await getAllSegment();
	return res.data;
});

export const addSegmentAsync = createAsyncThunk('contactList/addSegment', async (params: SegmentParams) => {
	const res = await addSegment(params);
	return res;
});

export const updateSegmentAsync = createAsyncThunk('contactList/updateSegment', async (params: SegmentParams) => {
	const res = await updateSegment(params);
	return res;
});

export const delSegmentAsync = createAsyncThunk('contactList/delSegment', async (params: SegmentParams) => {
	const res = await delSegment(params);
	return res;
});

export const getAllFilterAsync = createAsyncThunk('contactList/getAllFilter', async () => {
	const res = await getAllFilter();
	return res.data;
});

export const updateContactFieldVisibleAsync = createAsyncThunk('contactList/updateContactFieldVisible', async (params: any) => {
	const res = await updateContactFieldVisible(params);
	return res;
});
export const addTagAsync = createAsyncThunk('contactList/addTag', async (params: any, { dispatch }) => {
	const res = await addTag(params);
	if (res.code === CODE.OK) {
		dispatch(getAllTagsAsync());
	}
	return res;
});

export const updateTagAsync = createAsyncThunk('contactList/updateTag', async (params: any) => {
	const res = await updateTag(params);
	return res;
});

export const deleteTagAsync = createAsyncThunk('contactList/deleteTag', async (params: any) => {
	const res = await deleteTag(params);
	return res;
});

export const addBlocklistAsync = createAsyncThunk('contactList/addBlocklist', async (params: any) => {
	const res = await addBlocklist(params);
	return res;
});

export const deleteBlocklistAsync = createAsyncThunk('contactList/deleteBlocklist', async (params: any) => {
	const res = await deleteBlocklist(params);
	return res;
});

export const getBlocklistSumAsync = createAsyncThunk('contactList/getBlocklistSum', async () => {
	const res = await getBlocklistSum();
	return res;
});

export const contactListSlice = createSlice({
	name: 'contactList',
	initialState,
	reducers: {
		setCurSeg: (state, action) => {
			state.curSeg = action.payload;
		},
		setCurTag: (state, action) => {
			state.curTag = action.payload;
		},
		addEditSeg: (state, action) => {
			const { type, data } = action.payload;
			if (type === 'add') {
				state.allSegments = [...state.allSegments, data];
			} else if (type === 'edit') {
				const preData = deepCopy(state.allSegments);
				const index = state.allSegments.findIndex((e: any) => e.id === data.id);
				preData[index] = data;
				state.allSegments = [...preData];
			}
		},
		setBlocklistTotal: (state, action) => {
			state.blocklistTotal = action.payload;
		},
		setAllTotal: (state, action) => {
			state.allTotal = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getAllCustomAttrAsync.fulfilled, (state, action) => {
				state.allCustomAttr = action.payload;
			})
			.addCase(getContactTableAsync.fulfilled, (state, action) => {
				const { records, pageNo, pageSize, totalCount } = action.payload;
				state.tableData = records;
				state.tableTotal = totalCount;
				state.tablePagin = {
					pageNo: pageNo,
					pageSize: pageSize,
					totalCount: totalCount,
				};
			})
			.addCase(getAllTagsAsync.fulfilled, (state, action) => {
				const tagMapping: any = {};
				action.payload.forEach((item: any) => {
					tagMapping[item.id] = item;
				});
				state.allTags = action.payload;
				state.tagColor = action.payload || [];
				state.tagColorMapping = tagMapping;
			})
			.addCase(getContactListAsync.fulfilled, (state, action) => {
				const { data, isAll } = action.payload;
				state.tableTotal = data.totalCount;
				if (isAll) {
					state.allTotal = data.totalCount;
				}
				state.tableData = data.records;
				state.tablePagin = {
					pageNo: data.pageNo,
					pageSize: data.pageSize,
					totalCount: data.totalCount,
				};
			})
			.addCase(getAllSegmentAsync.fulfilled, (state, action) => {
				state.allSegments = action.payload;
			})
			.addCase(getAllFilterAsync.fulfilled, (state, action) => {
				state.filterList = action.payload;
			});
	},
});

export const selectAllCustomAttr = (state: RootState) => state.contactList.allCustomAttr;
export const selectTagColor = (state: RootState) => state.contactList.tagColor;
export const selectTagColorMapping = (state: RootState) => state.contactList.tagColorMapping;
export const selectAllSegments = (state: RootState) => state.contactList.allSegments;
export const selectAllTags = (state: RootState) => state.contactList.allTags;
export const selectCurSeg = (state: RootState) => state.contactList.curSeg;
export const selectCurTag = (state: RootState) => state.contactList.curTag;
export const selectAllTotal = (state: RootState) => state.contactList.allTotal;
export const selectBlocklistTotal = (state: RootState) => state.contactList.blocklistTotal;
export const selectTableTotal = (state: RootState) => state.contactList.tableTotal;
export const selectTableData = (state: RootState) => state.contactList.tableData;
export const selectTablePagin = (state: RootState) => state.contactList.tablePagin;
export const selectFilterList = (state: RootState) => state.contactList.filterList;

export const { setCurSeg, setCurTag, addEditSeg, setBlocklistTotal, setAllTotal } = contactListSlice.actions;

export default contactListSlice.reducer;
