import { useIntl } from 'react-intl';
import { FunctionComponent } from 'react';
import { AskQuestionType } from 'src/features/bot/type';
import { Button, Box } from '@mui/material';
import list1 from './list1.png';
import text1 from './text1.png';
import list2 from './list2.png';
import flow from './flow.png';
import button1 from './button1.png';
import { CustomStyleTooltip } from 'src/app/components/form/customTooltip';
const QuestionTypeExample: FunctionComponent<{
  type: AskQuestionType;
}> = ({
  type
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const examplePopoverWidth = type === AskQuestionType.FLOW ? '626.292px' : '330px';
  return <CustomStyleTooltip hasMaxHeight={false} placement="right" tooltipStyle={{
    background: '#E6E0DB',
    padding: 0,
    width: examplePopoverWidth,
    borderRadius: '8px',
    maxWidth: examplePopoverWidth,
    '& .MuiTooltip-arrow': {
      color: '#E6E0DB !important'
    },
    ...(type === AskQuestionType.FLOW ? {
      background: 'none',
      height: '327.13px',
      '& .MuiTooltip-arrow': {
        color: '#E6E0DB !important'
      }
    } : {})
  }} PopperProps={{
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 24] // 第一个数字是水平偏移量, 第二个数字是垂直偏移量
      }
    }]
  }} title={<Box sx={{
    minHeight: type === AskQuestionType.BUTTON ? '393px' : '',
    width: examplePopoverWidth,
    background: '#E6E0DB',
    pt: 3,
    pb: type !== AskQuestionType.BUTTON && type !== AskQuestionType.LIST ? 2.5 : 0,
    borderRadius: '8px',
    ...(type === AskQuestionType.FLOW ? {
      pt: 0,
      pb: 0,
      background: 'none'
    } : {})
  }}>
					{type === AskQuestionType.LIST && <>
							<img src={list1} alt="list1" style={{
        width: '100%',
        marginBottom: '16px'
      }} />
							<img src={list2} alt="list2" style={{
        width: '100%'
      }} />
						</>}
					{type === AskQuestionType.BUTTON && <>
							<img src={button1} alt="button1" style={{
        width: '100%'
      }} />
						</>}
					{type === AskQuestionType.TEXT && <>
							<img src={text1} alt="text1" style={{
        width: '100%'
      }} />
						</>}
					{type === AskQuestionType.FLOW && <>
							<img src={flow} alt="text1" style={{
        width: '100%'
      }} />
						</>}
				</Box>}>
			<Button size="small" sx={{
      width: '70px',
      height: '24px',
      color: 'text.white',
      border: '1px solid rgba(255, 255, 255, 0.50)',
      background: 'none',
      borderRadius: '6px',
      '&:hover': {
        background: 'rgba(251, 250, 253, 0.20) !important'
      }
    }}>
				{formatMessage({
        defaultMessage: 'Example'
      })}
			</Button>
		</CustomStyleTooltip>;
};
export default QuestionTypeExample;