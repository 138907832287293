import loadComponent from '../loadComponent';
const ctwaRouter = [{
  path: 'introduce',
  element: loadComponent('ctwa/introduce')
}, {
  path: 'getStart',
  element: loadComponent('ctwa/getStart')
}, {
  path: 'authorize',
  element: loadComponent('ctwa/authorize')
}, {
  path: 'dashboard',
  element: loadComponent('ctwa/dashboard')
}, {
  path: 'insights',
  element: loadComponent('ctwa/insights')
}];
export default ctwaRouter;