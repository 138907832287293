import { Tooltip, Box, Typography } from '@mui/material';
import { FunctionComponent, useRef, useLayoutEffect, useState } from 'react';
const LongTooltipText: FunctionComponent<{
  variant?: any;
  children: any;
  maxWidth?: number;
  maxLine?: number;
  sx?: any;
  // 当超出最大宽度时，针对性自定义属性
  overWidthSX?: any;
}> = ({
  variant = 'body1',
  children,
  maxWidth,
  maxLine,
  sx,
  overWidthSX
}) => {
  const textStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  };
  const textRef: any = useRef();
  const [isCanHover, setIsCanHover] = useState<boolean>(false);
  useLayoutEffect(() => {
    const el: any = textRef?.current;
    if (el) {
      const textWidth = el.offsetWidth;
      const textHeight = el.getBoundingClientRect().height;
      if (maxLine) {
        setIsCanHover(textHeight > 22 * maxLine);
      } else if (maxWidth) {
        setIsCanHover(textWidth > maxWidth);
      }
    }
  }, [children, maxWidth]);
  return <>
			{isCanHover ? <Tooltip title={<Box sx={{
      maxHeight: '300px',
      overflowY: 'auto'
    }}>
							{children}
						</Box>} arrow={true} placement="top-start">
					<Typography variant={variant} component="div" sx={{
        ...textStyle,
        cursor: 'pointer',
        ...(maxLine && {
          whiteSpace: 'break-spaces',
          display: '-webkit-box',
          lineClamp: String(maxLine),
          WebkitLineClamp: String(maxLine),
          WebkitBoxOrient: 'vertical'
        }),
        ...(maxWidth && {
          maxWidth: maxWidth + 'px'
        }),
        ...(sx && sx),
        ...(overWidthSX && overWidthSX)
      }}>
						{children}
					</Typography>
				</Tooltip> : <Box sx={{
      maxWidth: maxWidth + 'px'
    }}>
					<Typography ref={textRef} variant={variant} component="span" sx={{
        ...(sx && sx)
      }}>
						{children}
					</Typography>
				</Box>}
		</>;
};
export default LongTooltipText;