import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { getIsExpand } from 'src/features/setting/settingSlice';
import { useAppSelector } from 'src/app/hooks';
import { isEmpty } from 'lodash-es';
import useTheme from '@mui/material/styles/useTheme';
const NavItem: FC<{
  item: any;
  level: number;
  handleClose?: () => void;
}> = ({
  item,
  level,
  handleClose
}) => {
  const theme = useTheme();
  const {
    pathname
  } = useLocation();
  const navigate = useNavigate();
  const isExpand = useAppSelector(getIsExpand);
  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon /> : false;
  const pathArray = pathname.split('/').filter(item => !!item);
  const isSelected = item?.activeKeys?.includes(pathArray[level]);
  const handleClick = () => {
    handleClose && handleClose();
    if (item.onClick) {
      item.onClick(item);
      return;
    }
    if (item.path) {
      navigate(item.path);
      return;
    }
    if (item.external) {
      window.open(item.path);
      return;
    }
    if (!isEmpty(item.children)) {
      navigate(item.children.find((child: any) => child.path)?.path);
      return;
    }
  };
  return <Tooltip arrow title={level === 1 && !isExpand ? item.name : null} placement="right" enterDelay={0} leaveDelay={0} slotProps={{
    popper: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, -20]
        }
      }],
      sx: {
        zIndex: 3000
      }
    }
  }}>
			<ListItemButton disabled={item.disabled} selected={isSelected} sx={{
      zIndex: 1201,
      color: theme.palette.text.primary,
      background: 'transparent',
      '&:hover': {
        background: theme.palette.fill.hover
      },
      '&.Mui-selected': {
        background: theme.palette.fill.hover
      },
      '&.Mui-selected:hover': {
        background: theme.palette.fill.hover
      },
      ...(isSelected && {
        '& .MuiListItemIcon-root': {
          background: theme.palette.secondary.main,
          color: theme.palette.text.white
        }
      }),
      /* 一级菜单 */
      ...(isExpand && level === 1 && {
        pl: isExpand ? '24px' : '4px',
        pr: isExpand ? '24px' : '4px',
        py: '8px'
      }),
      /* 非一级菜单 */
      ...(isExpand && level !== 1 && {
        padding: 0,
        borderLeft: `1px solid ${theme.palette.line.line2}`,
        ...(isSelected && {
          '&::before': {
            content: '""',
            position: 'absolute',
            left: '-1px',
            top: 0,
            width: 0,
            height: '100%',
            boxSizing: 'border-box',
            borderLeft: `solid 2px ${theme.palette.secondary.main}`
          }
        })
      }),
      ...(!isExpand && level === 1 && {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        px: 0,
        ...(isSelected && {
          '& .MuiListItemIcon-root': {
            background: theme.palette.secondary.main,
            color: theme.palette.text.white
          },
          '&.Mui-selected': {
            background: 'transparent'
          }
        })
      }),
      ...(!isExpand && level !== 1 && {
        px: '24px',
        py: '12px'
      })
    }} onClick={handleClick}>
				{itemIcon && <ListItemIcon sx={{
        borderRadius: '50%',
        minWidth: '30px',
        width: '30px',
        height: '30px',
        color: isSelected ? theme.palette.text.white : theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px',
        '& svg': {
          width: '30px',
          height: '30px'
        },
        ...(!isExpand && {
          marginRight: 0
        })
      }}>
						{itemIcon}
					</ListItemIcon>}
				{(isExpand || !isExpand && level !== 1) && <ListItemText sx={{
        margin: 0
      }} primary={<Typography variant="h6" sx={{
        /* 一级菜单 */
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        cursor: 'pointer',
        ...(isExpand && level !== 1 && {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: isSelected ? 600 : 400,
          color: isSelected ? theme.palette.secondary.main : theme.palette.text.secondary,
          padding: '9px 0 9px 24px'
        }),
        ...(!isExpand && level !== 1 && {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: isSelected ? 700 : 400,
          color: isSelected ? theme.palette.secondary.main : theme.palette.text.primary
        })
      }}>
								{item.name}
							</Typography>} />}
			</ListItemButton>
		</Tooltip>;
};
export default NavItem;