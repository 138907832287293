import { GET, POST } from 'src/utils/fetch';
import { WebhookParams, WebhookActionParams, FailedLogParams } from './type';

export async function getWebhookList() {
	return GET('/api/webhooks/listAll', undefined, true, false);
}

export async function getWebhookLogs(params: any) {
	return POST('/api/webhooks/getWebhookEventLogs', params, true);
}

export async function getEventTypeList(params: any) {
	return GET('/api/webhooks/getWebhookEventTypes', params, true, true);
}

export async function getListAllEvents() {
	return GET('/api/webhooks/listAllEvents', undefined, true, false);
}

export async function addWebhook(params: WebhookParams) {
	return POST('/api/webhooks/create', params, false, false);
}

export async function updateWebhook(params: WebhookParams) {
	return POST('/api/webhooks/update', params, false, false);
}

export async function enableWebhook(params: WebhookActionParams) {
	return POST('/api/webhooks/enable', params, false, false);
}

export async function disableWebhook(params: WebhookActionParams) {
	return POST('/api/webhooks/disable', params, false, false);
}

export async function delWebhook(params: WebhookActionParams) {
	return POST('/api/webhooks/delete', params, false, false);
}

export async function getFailedLog(params: FailedLogParams) {
	return POST('/api/requestLog/search', params, true, false);
}

export async function getAPIKeys() {
	return GET('/api/apikey/list', {}, true, false);
}

export async function createAPIKey(params: { name: string }) {
	return POST('/api/apikey/create', params, false, false);
}

export async function updateAPIKey(params: { name: string; id: string }) {
	return POST('/api/apikey/update', params, false, false);
}

export async function deleteAPIKey(params: { id: string }) {
	return POST('/api/apikey/delete', params, false, false);
}

export async function getIPWhitelist(params: { name: string }) {
	return POST('/api/apikey/ipWhiteList/search', params, true, false);
}

export async function addIPWhitelist(params: { ips: string[] }) {
	return POST('/api/apikey/ipWhiteList/add', params, false, false);
}

export async function deleteIPWhitelist(params: { id: string }) {
	return POST('/api/apikey/ipWhiteList/delete', params, false, false, true);
}

export async function getIPWhitelistStatus() {
	return GET('/api/apikey/ipWhiteList/enabled', {}, true, false);
}

export async function changeIPWhitelistStatus(params: { enabled: boolean }) {
	return POST('/api/apikey/ipWhiteList/enabled/switch', params, false, false, true);
}
