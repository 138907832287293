const STTIPE_KEY = {
	TEST: '6LePTnkeAAAAAGj1pe9kAfm8cwaGGzxd65D_XtiF',
	ONLINE: '6Ldj-nweAAAAAH9bpOTYz5iHbOdaIw_EnEqQA_MV',
};

const GLOBAL_ENV = {
	DEV: 'dev',
	TEST: 'test',
	PREPARE: 'prepare',
	ONLINE: 'online',
};

export const TEMPLATE_SELECT_PAGE_DEFAULT = {
	PAGE_NO: 1,
	PAGE_SIZE: 100,
};

const ENV = (typeof process !== 'undefined' && process.env.PUBLIC_APP_ENV) || '';

export const hostnameAndPrev = `${window.location.origin}/console/#`;

export const DEFAULT_STTIPE_KEY = [GLOBAL_ENV.DEV, GLOBAL_ENV.TEST, ''].includes(ENV) ? STTIPE_KEY.TEST : STTIPE_KEY.ONLINE;

export const isMock = false && !ENV;
