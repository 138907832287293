import httpRequest from 'src/utils/request';
import { GetJourneyParams } from './type';

export async function getJourneys(params: GetJourneyParams) {
	return httpRequest.get({
		url: '/api/marketing/automation/list',
		params,
		options: {
			returnData: true,
		},
	});
}

export async function deleteJourney(automationTaskId: string) {
	return httpRequest.post({
		url: `/api/marketing/automation/${automationTaskId}/delete`,
		params: {},
		options: {
			returnData: true,
			showSuccessMessage: true,
		},
	});
}

export async function changeJourneyStatus(automationTaskId: string) {
	return httpRequest.post({
		url: `/api/marketing/automation/${automationTaskId}/launchToggle`,
		params: {},
		options: {
			returnData: true,
			showSuccessMessage: true,
		},
	});
}

export async function getJourneyById(automationTaskId: string) {
	return httpRequest.get({
		url: `/api/marketing/automation/${automationTaskId}`,
		params: {},
		options: {
			returnData: true,
		},
	});
}

export async function getJourneyTemplates() {
	return httpRequest.get({
		url: '/api/marketing/automation/templates',
		params: {},
		options: {
			returnData: true,
		},
	});
}

export async function updateJourney({ id: templateId, ...params }: any) {
	return httpRequest.post({
		url: `/api/marketing/automation/${templateId}`,
		params,
		options: {
			returnData: true,
			showSuccessMessage: true,
		},
	});
}

export async function createEmptyJourney(templateId?: string) {
	return httpRequest.post({
		url: templateId ? `/api/marketing/automation/createEmptyGraph?templateId=${templateId}` : '/api/marketing/automation/createEmptyGraph',
		params: {},
		options: {
			returnData: true,
			showSuccessMessage: true,
		},
	});
}
