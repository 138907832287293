import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import React, { forwardRef, useRef, useEffect, useMemo } from 'react';
import { TagIcon, ManageIcon } from 'src/app/icons';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectTagColor, addTagAsync, getAllTagsAsync, selectTagColorMapping } from 'src/features/contact/contactList/contactListSlice';
import ColorChip, { tagColors } from 'src/app/components/colorChip';
import { differenceBy } from 'lodash-es';
import { CODE } from 'src/app/const/code';
import { selectUser } from 'src/features/user/userSlice';
const filter = createFilterOptions<any>();
const optionStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
};
interface IProps {
  value: Array<any>;
  onChange: (e: any) => void;
  placeholder?: string;
  maxLength?: number;
  refresh?: () => void;
}
export type Ref = any;
const AddTagInput = forwardRef<Ref, IProps>(({
  value = [],
  onChange,
  refresh,
  placeholder,
  maxLength
}, ref) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const allTagColor = useAppSelector(selectTagColor);
  const allTagColorMapping = useAppSelector(selectTagColorMapping);
  const dispatch = useAppDispatch();
  const {
    role
  } = useAppSelector(selectUser);
  const selectOption = useRef<any>(null);
  const computedValue = useMemo(() => {
    return value.map((item: any) => typeof item === 'string' ? allTagColorMapping[item] : item).filter((item: any) => item?.tag);
  }, [value, allTagColorMapping]);
  const handleAddTag = async (tag: string) => {
    const createTag = {
      tag,
      color: tagColors[Math.floor(Math.random() * 6)]
    };
    /* 调用接口创建tag */
    const res = await dispatch(addTagAsync(createTag)).unwrap();
    if (res.code === CODE.OK) {
      refresh && refresh();
      if (maxLength && computedValue.length >= maxLength) {
        return;
      }
      onChange([...computedValue, res?.data]);
    }
  };
  const goTags = () => {
    window.open('/console#/app/contact/settings/tags', '_blank');
  };
  const onEnterKeyDown = async (current: any) => {
    /* 创建tag */
    if (current?.action === 'add tag') {
      await handleAddTag(current.tag);
      /* 管理tag */
    } else if (current?.action === 'manage tag') {
      goTags();
      /* 选择已有tag */
    } else {
      if (maxLength && computedValue.length >= maxLength) {
        return;
      }
      onChange([...computedValue, current]);
    }
  };
  useEffect(() => {
    dispatch(getAllTagsAsync());
  }, []);
  return <Box ref={ref} sx={{
    position: 'relative'
  }}>
			<SearchRoundedIcon color="primary" sx={{
      position: 'absolute',
      left: '12px',
      top: '8px'
    }} />
			<Autocomplete multiple value={computedValue} options={[...differenceBy(allTagColor, value, 'id'), {
      action: 'manage'
    }]} onChange={(e, values: any[]) => {
      if (maxLength && values.length > maxLength && values.length > computedValue.length) {
        return;
      }
      onChange(values);
    }} onHighlightChange={(event: any, option) => selectOption.current = option} onClose={() => selectOption.current = null} onKeyDown={async event => {
      if (event.key === 'Enter' && selectOption.current) {
        event.defaultMuiPrevented = true;
        await onEnterKeyDown(selectOption.current);
      }
    }} getOptionLabel={option => option.tag ? option.tag : ''} filterSelectedOptions isOptionEqualToValue={(option: any, myValue: any) => {
      return option.id === myValue.id;
    }} renderTags={(e: any, getTagProps) => {
      return e.map((option: any, index: number) => <ColorChip size="small" label={option.tag} value={{
        ...option.color
      }} {...getTagProps({
        index
      })} key={option.tag} sx={{
        pr: 0
      }} />);
    }} renderOption={(props: any, option: any) => {
      if (role !== 'SALE') {
        if (option.action === 'manage tag') {
          return <Box {...props} onClick={(event: any) => {
            goTags();
            event.stopPropagation();
          }}>
									<ManageIcon sx={{
              fontSize: '18px',
              mr: 1
            }} />
									<Typography variant="body1">
										<FormattedMessage defaultMessage={'Manage tags'} />
									</Typography>
								</Box>;
        }
        if (option.action === 'add tag') {
          return <Box {...props} onClick={async (event: any) => {
            await handleAddTag(option.tag);
            event.stopPropagation();
          }}>
									<TagIcon sx={{
              fontSize: '18px',
              mr: 1
            }} />
									<Typography variant="body1" sx={optionStyle}>
										<FormattedMessage defaultMessage={'Create new tag'} />: ‘{option.tag}’
									</Typography>
								</Box>;
        }
      }
      if (option.tag) {
        return <Box {...props}>
								<Typography component="span" sx={optionStyle}>
									{option.tag}
								</Typography>
							</Box>;
      }
      return null;
    }} autoComplete={false} filterOptions={(values, params) => {
      const filtered = filter(values, params);
      const {
        inputValue
      } = params;
      const formatInputValue = inputValue.replace(/^\s+|\s+$/g, '');
      const isNew = !allTagColor.some(option => inputValue === option.tag);
      if (formatInputValue !== '' && isNew && role !== 'SALE') {
        filtered.push({
          tag: inputValue,
          action: 'add tag'
        });
      }
      return [...filtered, {
        action: 'manage tag'
      }];
    }} renderInput={params => <TextField {...params} placeholder={placeholder || formatMessage({
      defaultMessage: 'Select or create a new tag'
    })} />} sx={{
      '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
        pl: '44px !important',
        pr: '13px !important',
        py: value && value.length > 0 ? '4px !important' : '8px !important'
      },
      '& input': {
        padding: '0px !important',
        height: '24px !important'
      },
      '& .MuiAutocomplete-endAdornment': {
        display: 'none'
      }
    }} />
		</Box>;
});
export default AddTagInput;