import { GET, POST } from 'src/utils/fetch';
import { GetBatchShortLinkInfoParams, ShortLinkClicksByDateData, RemainShortUrlNumberData, GetShortLinkClicksByDateParams, GetRemainShortUrlNumberParams, CreateShortLinkParams, CreatedShortLinkData, GetBatchShortLinkInfoData } from './type';
import { getSlugFromShortUrl } from 'src/app/components/form/slateEditor/utils';

export async function getRemainShortUrlNumber(params: GetRemainShortUrlNumberParams) {
	return (await GET('/api/shortlink/limits', params, true, true)) as RemainShortUrlNumberData;
}

export async function createShortLink(params: CreateShortLinkParams) {
	return (await POST('/api/shortlink/create', params)) as CreatedShortLinkData;
}

export async function getShortLinkClicksByDate(params: GetShortLinkClicksByDateParams) {
	return (await GET('/api/shortlink/clicks/timeSeries', params, true, true)) as ShortLinkClicksByDateData;
}

export async function getBatchShortLinkInfo(params: GetBatchShortLinkInfoParams) {
	const slugsList = params.shortUrls
		.map((shortUrl: string) => {
			return getSlugFromShortUrl(shortUrl);
		})
		.filter(item => item.length === 6);
	const apiParams = {
		slugs: slugsList.join(','),
	};
	return (await GET('/api/shortlink/batchGet', apiParams, true, true)) as GetBatchShortLinkInfoData;
}
