import { POST, GET } from 'src/utils/fetch';
import { BillingTotalParams, BillingLogsParams, InvoiceConfigParams, StripeCheckoutParams, StripeSessionParams, ShopifyBillingParams, ShopifyChargeQueryParams } from './type';

export async function getBillingTotal(params: BillingTotalParams) {
	return POST('/api/dailyBill/total/get', params, true, false);
}

export async function getBillingLogs(params: BillingLogsParams) {
	return POST('/api/dailyBill/search', params, true, false);
}

export async function saveInvoiceConfig(params: InvoiceConfigParams) {
	return POST('/api/user/saveInvoiceConfig', params, true, false);
}

export async function stripeCheckout(params: StripeCheckoutParams) {
	return POST('/api/recharge/stripe/checkout/createSession', params, true, false);
}

export async function stripeSession(params: StripeSessionParams) {
	return GET('/api/recharge/stripe/checkout/retrieveSession', params, true, false);
}

export async function shopifyCheckoutCharge(params: ShopifyBillingParams) {
	return POST('/api/recharge/shopify/createCharge', params, true, false);
}

export async function shopifyChargeStatus(params: ShopifyChargeQueryParams) {
	return GET('/api/recharge/shopify/retrieve', params, true, false);
}

export async function getInvoiceLogs(params: BillingLogsParams) {
	return POST('/api/recharge/search', params, true, false);
}

export async function submitInvoiceConfig(params: InvoiceConfigParams) {
	return POST('/api/recharge/submitInvoiceConfig', params, false, false);
}

export async function exportBilling(params: BillingLogsParams) {
	return POST('/api/dailyBill/exports/create', params, false, false);
}
