import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

export interface SettingState {
	isExpand: boolean;
}

const initialState: SettingState = {
	isExpand: localStorage.getItem('isExpand') ? localStorage.getItem('isExpand') === 'true' : true,
};

export const getIsExpand = (state: RootState) => state.setting.isExpand;

export const settingSlice = createSlice({
	name: 'setting',
	initialState,
	reducers: {
		updateIsExpand: (state, action) => {
			localStorage.setItem('isExpand', action.payload);
			state.isExpand = action.payload;
		},
	},
});

export const { updateIsExpand } = settingSlice.actions;

export default settingSlice.reducer;
