import { FunctionComponent, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { selectIsOtherBrand, selectSecondaryColor } from 'src/features/brand/brandSlice';
import { useAppSelector } from 'src/app/hooks';
import { convertStringColorToObj } from 'src/styles/color';
const TypeButton: FunctionComponent<{
  title: string;
  desc: string;
  example?: ReactNode;
  icon?: any;
  disabled?: boolean;
  onClick: any;
}> = ({
  title,
  onClick,
  desc,
  example,
  icon,
  disabled
}) => {
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const secondaryColor = useAppSelector(selectSecondaryColor);
  const rgb = convertStringColorToObj(secondaryColor);
  return <Box onClick={() => {
    if (!disabled) {
      onClick();
    }
  }} sx={{
    cursor: disabled ? 'not-allowed' : 'pointer',
    borderRadius: '8px',
    color: '#FBFAFD',
    padding: '16px 24px',
    minHeight: '110px',
    boxSizing: 'border-box',
    background: isOtherBrand ? `linear-gradient(93deg, #602DED -4.14%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.86) -4.13%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9) 92.52%)` : 'linear-gradient(93deg, #602DED -4.14%, rgba(96, 45, 237, 0.86) -4.13%, #9747FF 92.52%)'
  }}>
			<Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      mb: 1
    }}>
				<Typography variant="h3" sx={{
        display: 'flex',
        alignItems: 'center'
      }}>
					{icon}
					{title}
				</Typography>
				{example}
			</Box>
			<Typography variant="body1">{desc}</Typography>
		</Box>;
};
export default TypeButton;