import { isValidPhoneNumber } from 'libphonenumber-js';
import { defineMessages } from 'react-intl';
import { transformPhoneNumber } from 'src/utils/transform';

export const numberRegexp = /^[0-9]*$/;

export const emailRegexp = /^([a-zA-Z0-9.+_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
export const emailRegexp2 = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;

export const phoneRegexp = /^86:(1[3-9][0-9])\d{8}$/;

export const globalPhoneRegexp = /^[0-9]{1,4}:[0-9]\d{6,14}$/;

export const passwordRegexp = /^(?=.*[A-Z])(?=.*[a-z]).*$/;

export const EmojiTextReg = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\\A9|\\AE]\u3030|\uA9|\uAE|\u3030/g;

export const emojiRegexp = /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]|(\ud83e[\udd00-\uddff])/;

export const domainRegexp = /^([a-zA-Z0-9][-a-zA-Z0-9]{1,99}(\.[a-zA-Z0-9][-a-zA-Z0-9]{1,99})){1,100}$/;

export const websiteRegexp = /^http(s)?:\/\/[\w.-]+(.*)+$/;
// export const websiteRegexp = /^http(s)?:\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?%#[\]@!$&'*+,;=.]+$/;

// yyyy－MM－dd校验正则
export const DateRegexp =
	/^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))$/;

// yyyy－MM－dd hh:mm:ss校验正则
export const DateTimeRegexp =
	/^((\d{2}(([02468][048])|([13579][26]))[\-\/\s]?((((0?[13578])|(1[02]))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(3[01])))|(((0?[469])|(11))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[\-\/\s]?((0?[1-9])|([1-2][0-9])))))|(\d{2}(([02468][1235679])|([13579][01345789]))[\-\/\s]?((((0?[13578])|(1[02]))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(3[01])))|(((0?[469])|(11))[\-\/\s]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[\-\/\s]?((0?[1-9])|(1[0-9])|(2[0-8]))))))(\s((([0-1][0-9])|(2?[0-3]))\:([0-5]?[0-9])((\s)|(\:([0-5]?[0-9])))))?$/;

export const patternMessageMap = defineMessages({
	cnPhoneNumber: {
		defaultMessage: 'Invalid Phone Number',
	},
	phoneNumber: {
		defaultMessage: 'Invalid Phone Number',
	},
	website: {
		defaultMessage: 'Please enter a valid website',
	},
	symbol: {
		defaultMessage: 'Only support numbers, letters, blank space, partial characters(.,-_)',
	},
	email: {
		defaultMessage: 'Please enter a valid email address',
	},
	password: {
		defaultMessage: 'contains both lower (a-z) and upper case letters (A-Z)',
	},
	number: {
		defaultMessage: 'Please enter number',
	},
});

export const validPhonePattern = (value: string) => {
	const phone = transformPhoneNumber(value);
	if (!isValidPhoneNumber(phone)) {
		return 'Invalid Phone Number';
	}
	return true;
};

export const validatorSymbolRegexp = /^[a-z0-9\s,.\-_\\]+$/i;

export const checkIsAllSpace = (value: string) => {
	if (!value.trim().length) {
		return 'All characters are spaces';
	}
	return true;
};
