import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetInboxOverviewDataParams, GetAgentRankingParams, GetAgentOverviewDataParams, GetOverviewDataParams, GetConversationsByAgentsParams, GetTeamsRankingParams, GetTeamOverviewDataParams, GetCsatLogsParams } from './type';
import { getInboxOverviewData, getAgentsRanking, getAgentOverviewData, getOverview, getConversationsByAgents, getTeamOverviewData, getTeamsRanking, getCsatLogs, exportCSATLogs } from './inboxAnalyticsAPI';
import { searchInboxes, searchAgent } from '../inbox/inboxAPI';

export const getOverviewDataAsync = createAsyncThunk('inboxAnalytics/getOverviewPageData', async (params: GetOverviewDataParams) => {
	const response = await getOverview(params);
	return response;
});

export const getConversationsByAgentsAsync = createAsyncThunk('inboxAnalytics/getConversationsByAgents', async (params: GetConversationsByAgentsParams) => {
	const response = await getConversationsByAgents(params);
	return response;
});

export const getInboxOverviewDataAsync = createAsyncThunk('inboxAnalytics/getInboxOverviewData', async (params: GetInboxOverviewDataParams) => {
	const response = await getInboxOverviewData(params);
	return response;
});

export const getAgentsRankingAsync = createAsyncThunk('inboxAnalytics/getAgentsRanking', async (params: GetAgentRankingParams) => {
	const response = await getAgentsRanking(params);
	return response;
});

export const getAgentOverviewDataAsync = createAsyncThunk('inboxAnalytics/getAgentOverviewData', async (params: GetAgentOverviewDataParams) => {
	const response = await getAgentOverviewData(params);
	return response;
});
export const getTeamsRankingAsync = createAsyncThunk('inboxAnalytics/getTeamsRanking', async (params: GetTeamsRankingParams) => {
	const response = await getTeamsRanking(params);
	return response;
});
export const getTeamOverviewDataAsync = createAsyncThunk('inboxAnalytics/getTeamOverviewData', async (params: GetTeamOverviewDataParams) => {
	const response = await getTeamOverviewData(params);
	return response;
});
export const getAllAgentsAsync = createAsyncThunk('inboxAnalytics/getAgentList', async () => {
	const response = await searchAgent({
		pageNo: 1,
		pageSize: 10000,
	});
	return response;
});

export const getCsatLogsAsync = createAsyncThunk('inboxAnalytics/getCsatLogs', async (params: GetCsatLogsParams) => {
	const response = await getCsatLogs(params);
	return response;
});

export const exportCSATLogsAsync = createAsyncThunk('inboxAnalytics/exportCSATLogs', async (params: GetCsatLogsParams) => {
	const response = await exportCSATLogs(params);
	return response;
});

export const getInboxListAsync = createAsyncThunk('inboxAnalytics/getInboxList', async () => {
	const response = await searchInboxes({});
	return response;
});

export const inboxSlice = createSlice({
	name: 'inboxAnalytics',
	initialState: {},
	reducers: {},
});

export default inboxSlice.reducer;
