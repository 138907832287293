import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
const ImageContainer: FunctionComponent<{
  backgroundImage: any;
  sx?: object;
}> = ({
  backgroundImage,
  sx
}) => {
  return <Box sx={{
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    ...sx
  }} />;
};
export default ImageContainer;