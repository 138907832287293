import { useIntl } from 'react-intl';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ItemEditor, { DragHandleContent, style } from '../ItemEditor';
import { ItemTypes } from './ItemTypes';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { Box } from '@mui/material';
import { isUndefined } from 'lodash-es';
export interface CardProps {
  id: any;
  text: string;
  index: any;
  onValueChange: any;
  onDelete: any;
  error: boolean;
  sectionIndex: number;
  myIndex: any;
  mySectionIndex: number;
  desc: string;
  onDescChange: any;
  onBlur: any;
  moveCard: any;
}
interface DragItem {
  index: any;
  id: string;
  sectionIndex: number;
  type: string;
}
export const BoxWithHandle: FC<CardProps> = ({
  onBlur,
  mySectionIndex,
  myIndex,
  id,
  error,
  text,
  desc,
  index,
  sectionIndex,
  onDelete,
  moveCard,
  onValueChange,
  onDescChange
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();

  // export const BoxWithHandle: FC<CardProps> = ({ onBlur, mySectionIndex, myIndex, onDescChange, desc, descVisible, id, error, text, index, sectionIndex, onDelete, moveCard, onValueChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const [{
    handlerId
  }, drop] = useDrop<DragItem, void, {
    handlerId: any;
  }>({
    accept: ItemTypes.BOX,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: any, monitor) {
      if (!ref.current || item.sectionIndex === undefined) {
        return;
      }
      const dragIndex = item.myIndex;
      const hoverIndex = myIndex;
      const dragSectionIndex = item.mySectionIndex;
      const hoverSectionIndex = mySectionIndex;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex && dragSectionIndex === hoverSectionIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, dragSectionIndex, hoverSectionIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.myIndex = hoverIndex;
      // item.index = hoverIndex;
      // item.sectionIndex = hoverSectionIndex;
      item.mySectionIndex = hoverSectionIndex;
    }
  });
  const [{
    isDragging
  }, drag, preview] = useDrag({
    type: ItemTypes.BOX,
    item: {
      myIndex,
      index,
      sectionIndex,
      id
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(dragRef);
  drop(preview(ref));
  return <Box ref={ref} style={{
    ...style,
    opacity
  }} data-handler-id={handlerId}>
			<ItemEditor onBlur={onBlur} error={error} handle={<div ref={dragRef}>
						<DragHandleContent isDragging={isDragging} />
					</div>} value={text} onChange={newValue => onValueChange(myIndex, newValue, sectionIndex)} onDelete={() => onDelete(myIndex, sectionIndex)} />
			{error && <ErrorHelperText>
					{formatMessage({
        defaultMessage: 'Required'
      })}
				</ErrorHelperText>}

			{!isUndefined(desc) && <ItemEditor placeholder={formatMessage({
      defaultMessage: 'Click to edit description(optional)'
    })} sx={{
      background: '#FBFAFD',
      border: '1px solid #D7D5DF',
      mt: 0.5
    }} maxLength={72} value={desc} onChange={newValue => onDescChange(myIndex, newValue, sectionIndex)} />}
		</Box>;
};