import { useIntl } from 'react-intl';
import { Alert, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import { getPlatformSyncStatusAsync } from 'src/features/integration/integrationSlice';
import { IStatusInfo } from 'src/features/integration/type';
export default function IntegrationPlatformSyncStatus() {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const [isSyncing, setIsSyncing] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const loadData = async () => {
    const res = await dispatch(getPlatformSyncStatusAsync());
    if (res?.payload) {
      const data = (res.payload as any).data as IStatusInfo;
      if (data.state === 'RUNNING') {
        setIsSyncing(true);
      }
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Box sx={{
    mb: 3
  }}>
			{isSyncing && showAlert && <Alert severity="info" onClose={() => {
      setShowAlert(false);
    }}>
					{formatMessage({
        defaultMessage: 'Data is being synchronized from shopify store, and you will receive an email notification upon completion.'
      })}
				</Alert>}
		</Box>;
}