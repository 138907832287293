import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

export const getIsAuthenticated = (state: RootState) => state.ctwa.isAuthenticated;
export const getFilters = (state: RootState) => state.ctwa.filters;

export const ctwaSlice = createSlice({
	name: 'ctwa',
	initialState: {
		isAuthenticated: false,
		filters: [],
	},
	reducers: {
		changeIsAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		updateFilters: (state, action) => {
			state.filters = action.payload;
		},
	},
});

export const { changeIsAuthenticated, updateFilters } = ctwaSlice.actions;
export default ctwaSlice.reducer;
