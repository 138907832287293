import { defineMessages } from 'react-intl';
import { PaginationParams } from 'src/app/types/common';
import { Data } from 'src/utils/fetch';

export type MyToolsSearchForm = {
	status: string;
	type: string;
	condition: string;
	startTime?: number;
	endTime?: number;
};

export enum MyToolsStatus {
	ACTIVE = 'ACTIVE',
	PAUSE = 'PAUSED',
}

export enum MyToolsType {
	LINK = 'LINK',
	QRCODE = 'QR_CODE',
	CHAT = 'ONLINE_CHAT',
}

export const growthToolStatusLabel = defineMessages({
	[MyToolsStatus.ACTIVE]: {
		defaultMessage: 'Active',
	},
	[MyToolsStatus.PAUSE]: {
		defaultMessage: 'Paused',
	},
});

export const growthToolTypeLabel = defineMessages({
	[MyToolsType.LINK]: {
		defaultMessage: 'Link',
	},
	[MyToolsType.QRCODE]: {
		defaultMessage: 'QR Code',
	},
	[MyToolsType.CHAT]: {
		defaultMessage: 'Chat widget',
	},
});

export interface AddGrowthToolData extends Data {
	data: {
		id: string;
	};
}

export type ToolResult = {
	shortLinkUrl: string;
	qrCodeUrl: string;
	script: string;
	button: OnlineChatButtonConfig;
};
export interface GetToolResultData extends Data {
	data: ToolResult;
}

export type TemplateItem = {
	name: string;
	type: MyToolsType;
	keyword: string;
	quickReply: string;
	headerUrl: string;
	desc: string;
};

export interface GetTemplateListData extends Data {
	data: Array<TemplateItem>;
}

export type GetMyToolsListParams = MyToolsSearchForm & PaginationParams;

export type MyToolsListItem = {
	name: string;
	status: MyToolsStatus;
	phoneNumber: string;
	type: MyToolsType;
	id: string;
};

type OnlineChatButtonConfig = {
	square: boolean;
	iconBgColor: string;
	iconColor: string;
	iconType: string;
	position: string;
	heightOffset: number;
	edgeOffset: number;
};

type OnlineChatWidgetConfig = {
	logo: string;
	title: string;
	subTitle: string;
	btnText: string;
	color: string;
};

type OnlineChatGreetingConfig = {
	text: string;
	delayTime: number;
};

export type OnlineChatConfig = {
	button: OnlineChatButtonConfig;
	widget: OnlineChatWidgetConfig;
	greeting: OnlineChatGreetingConfig;
};

export interface GetGrowthToolConfigData extends Data {
	data: GrowthToolConfig;
}

export type GrowthToolConfig = {
	id?: string;
	name: string;
	channel: {
		wabaId: string;
		phoneNumber: string;
		type: MyToolsType;
		keyword: string;
		quickReply: string;
		config: OnlineChatConfig;
	};
};
