import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from 'src/App';
import 'src/app/fonts/fonts.css';
import './index.css';
import fixGoogleTranslateError from './utils/fixGoogleTranslateError';

// 避免因为google翻译报错导致的页面奔溃
fixGoogleTranslateError();
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<Provider store={store}>
		<App />
	</Provider>);