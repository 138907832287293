import { format } from 'date-fns';

// 86:18812345678 => +8618812345678
export const transformPhoneNumber = (phoneNumber: string) => {
	if (
		!phoneNumber ||
		phoneNumber === ':' ||
		!phoneNumber.split(':')[1] ||
		phoneNumber.includes('undefined') // phoneNumber 变成可选了
	) {
		return '';
	}
	const phoneArr = phoneNumber.split(':');
	return `+${phoneArr[0]}${phoneArr[1] || ''}`;
};

export const transformNumber = (num: number = 0, digit: number = 2) => {
	return num.toFixed(digit);
};

export const formatAmount = (amount: number, currencyCode: string, numUSD: number = 5) => {
	return currencyCode === 'IDR' ? amount.toFixed(0) : amount.toFixed(numUSD);
};

export const formatDate = (num: any, pattern: string = 'yyyy-MM-dd'): string => {
	if (!num) {
		return '';
	}
	return format(new Date(num), pattern);
};

export const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

export const formatDateTime = (num: any) => {
	return formatDate(num, dateTimeFormat);
};

export const concatWithUniq = (arr1: Array<any>, arr2: Array<any>) => {
	const res = [...arr1];
	for (let i = 0; i < arr2.length; i++) {
		if (!res.includes(arr2[i])) {
			res.push(arr2[i]);
		}
	}
	return res;
};

//深拷贝
export const deepCopy = (obj: any) => {
	return JSON.parse(JSON.stringify(obj));
};

export const someReplace = (srcText: string) => {
	let filteredText = srcText.replace(/[^\d.]/g, ''); // 过滤掉非数字和小数点的字符
	filteredText = filteredText.replace(/\.(\.)+/, '.'); // 合并左侧第一次出现的连续的小数点为一个小数点

	const regCompleteFormula = /^\d*(?:\.\d{0,2})?.*$/g;
	const regMissingIntPart = /^\.\d{1,2}/; // 缺失整数部分

	let mc;

	mc = regCompleteFormula.exec(filteredText);
	if (mc) {
		const text = mc[0];
		const lastT = text.slice(-1);
		const index = text.indexOf('.');
		if (lastT === '.' && index !== text.length - 1) {
			return mc[0].slice(0, -1);
		} else {
			return mc[0];
		}
	}

	mc = regMissingIntPart.exec(filteredText);
	if (mc) return '0' + mc[0]; // 补上左侧的 0

	return '';
};

// 截取字符串，加...
export const sliceStr = (text: any, len: number) => {
	if (!text || text === '') return '';

	if (text.length <= len) return text;

	return text.slice(0, len) + '...';
};
