import { POST, GET, UPLOAD } from 'src/utils/fetch';
import {
	AddAgentParams,
	UpdateAgentParams,
	RemoveAgentParams,
	SearchAgentParams,
	SearchInboxesParams,
	SwitchStatusParams,
	AddAgentToInboxesParams,
	RemoveAgentToInboxesParams,
	GetAddableAgentsParams,
	SearchInboxesRes,
	SearchAgentRes,
	AddTeamParams,
	UpdateTeamParams,
	DeleteTeamParams,
	SearchTeamParams,
	ResendInvitationEmailParams,
	GetAutomationParams,
	UpdateAssignmentRulesParams,
	GetAssignmentRulesParams,
	GetCannedResponseListParams,
	GetCannedResponseListRes,
	AddCannedResponseParams,
	EditCannedResponseParams,
	DeleteCannedResponseParams,
} from './type';

export async function searchAgent(params: SearchAgentParams) {
	return POST('/api/inbox/agent/search', params, true, false) as Promise<SearchAgentRes>;
}

export async function updateAssignmentRules(params: UpdateAssignmentRulesParams) {
	return POST('/api/inbox/inboxes/settings/assignRule/update', params);
}

export async function getAssignmentRules(params: GetAssignmentRulesParams) {
	return GET('/api/inbox/inboxes/settings/get', params, true);
}

export async function addAgent(params: AddAgentParams) {
	return POST('/api/inbox/agent/add', params, false, false);
}
export async function updateAgent(params: UpdateAgentParams) {
	return POST('/api/inbox/agent/update', params, false, false);
}
export async function resendInvitationEmail(params: ResendInvitationEmailParams) {
	return POST('/api/inbox/agent/resendInvitationEmail', params, true, false);
}
export async function removeAgent(params: RemoveAgentParams) {
	return POST('/api/inbox/agent/remove', params, false, false);
}

export async function searchInboxes(params: SearchInboxesParams) {
	return POST('/api/inbox/inboxes/search', params, true, false) as Promise<SearchInboxesRes>;
}
export async function switchStatus(params: SwitchStatusParams) {
	return POST('/api/inbox/inboxes/switchStatus', params, false, false);
}
export async function addAgentToInboxes(params: AddAgentToInboxesParams) {
	return POST('/api/inbox/inboxes/addAgents', params, false, false);
}
export async function removeAgentToInboxes(params: RemoveAgentToInboxesParams) {
	return POST('/api/inbox/inboxes/removeAgent', params, false, false);
}

export async function getAddableAgents(params: GetAddableAgentsParams) {
	return GET('/api/inbox/inboxes/addableAgents', params, true, false);
}
export async function updateAutomation(params: any) {
	return POST('/api/inbox/inboxes/settings/automation/update', params, false, false);
}
export async function getAutomation(params: GetAutomationParams) {
	return GET('/api/inbox/inboxes/settings/automation/get', params, true, false);
}
export async function deleteCannedResponse(params: DeleteCannedResponseParams) {
	return POST('/api/inbox/cannedResponse/delete', params);
}

export async function addTeam(params: AddTeamParams) {
	return POST('/api/inbox/team/create', params, false, false);
}

export async function deleteTeam(params: DeleteTeamParams) {
	return POST('/api/inbox/team/delete', params, false, false);
}

export async function updateTeam(params: UpdateTeamParams) {
	return POST('/api/inbox/team/update', params, false, false);
}

export async function searchTeam(params: SearchTeamParams) {
	return POST('/api/inbox/team/search', params, true, false);
}
export async function getTeams() {
	return GET('/api/inbox/team/all', {}, true, false);
}
export async function getAllAgents() {
	return GET('/api/inbox/agent/all', {}, true, false);
}
export async function getCannedResponseList(params: GetCannedResponseListParams) {
	return POST('/api/inbox/cannedResponse/search', params, true, false) as Promise<GetCannedResponseListRes>;
}
export async function uploadInboxFile(params: any) {
	return UPLOAD('/inbox-api/attachment/upload', params, true, false);
}
export async function addCannedResponse(params: AddCannedResponseParams) {
	return POST('/api/inbox/cannedResponse/add', params);
}
export async function editCannedResponse(params: EditCannedResponseParams) {
	return POST('/api/inbox/cannedResponse/update', params);
}
