import loadComponent from '../loadComponent';
const campaignsRouter = [{
  path: 'logs',
  element: loadComponent('campaign/logs')
}, {
  path: 'send',
  element: loadComponent('campaign/create')
}, {
  path: 'whatsapp/send',
  element: loadComponent('campaign/create/whatsapp')
}, {
  path: 'detail/:type/:tab/:activityId',
  element: loadComponent('campaign/detail')
}];
export default campaignsRouter;