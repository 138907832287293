import { Box } from '@mui/material';
import { FunctionComponent, ReactNode, useState } from 'react';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import useTheme from '@mui/material/styles/useTheme';
import { useIntl } from 'react-intl';
export const handleStyle: any = {
  backgroundColor: 'none',
  width: '24px',
  height: '24px',
  display: 'block',
  cursor: 'move'
};
export const DragHandleContent: FunctionComponent<{
  isDragging: boolean;
}> = ({
  isDragging
}) => {
  const theme = useTheme();
  return <Box sx={{
    ...handleStyle,
    background: 'none',
    '&:hover': {
      background: isDragging ? 'none' : theme.palette.secondary.bg
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px'
  }}>
			<DragIndicatorRoundedIcon sx={{
      color: 'secondary.main',
      width: '16px',
      height: '16px'
    }} />
		</Box>;
};
export const style = {
  background: 'none'
};
const ItemEditor: FunctionComponent<{
  handle?: ReactNode;
  value: string;
  sx?: object;
  disabled?: boolean;
  deletable?: boolean;
  maxLength?: number;
  error?: any;
  placeholder?: string;
  onBlur?: any;
  onDelete?: () => void;
  onChange: (newValue: string) => void;
}> = ({
  disabled = false,
  deletable = true,
  onBlur = () => {},
  maxLength = 24,
  error = false,
  value,
  handle,
  onChange,
  onDelete,
  placeholder,
  sx = {}
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const theme = useTheme();
  const handleFocus = () => {
    setIsFocus(true);
  };
  const handleBlur = () => {
    setIsFocus(false);
    onBlur();
  };
  const {
    formatMessage
  } = useIntl();
  if (placeholder === undefined) {
    placeholder = formatMessage({
      defaultMessage: 'Click to edit (Up to 24 characters)'
    });
  }
  const border = error ? `2px solid ${theme.palette.error.main}` : isFocus ? `1px solid ${theme.palette.secondary.main}` : '';
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    borderRadius: '4px',
    backgroundColor: 'secondary.activeBg',
    padding: '8px 12px 8px 8px',
    ...sx,
    border
  }}>
			<input style={{
      height: '22px',
      border: 'none',
      outline: 'none',
      flex: 1,
      padding: '8px',
      boxSizing: 'border-box',
      background: 'none'
    }} disabled={disabled} onBlur={handleBlur} onFocus={handleFocus} value={value} placeholder={placeholder} onChange={(e: any) => {
      if (e.target.value.length > maxLength) {
        return;
      }
      onChange(e.target.value);
    }} />
			{handle}
			{Boolean(onDelete) && <Box sx={{
      background: 'none',
      '&:hover': {
        background: deletable ? theme.palette.secondary.bg : ''
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      cursor: deletable ? 'pointer' : 'not-allowed',
      borderRadius: '6px'
    }} onClick={() => {
      if (deletable && onDelete) {
        onDelete();
      }
    }}>
					<DeleteOutlineRoundedIcon sx={{
        color: deletable ? 'secondary.main' : 'fill.disabled',
        width: '16px',
        height: '16px'
      }} />
				</Box>}
		</Box>;
};
export default ItemEditor;