import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, InputAdornment, Typography, Box, MenuItem, ClickAwayListener } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React, { FunctionComponent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getCoupons } from 'src/features/bot/botAPI';
import { isEmpty } from 'lodash-es';
const CouponsSelect: FunctionComponent<{
  onClick: (e: any) => void;
  onClose: () => void;
  updatePlacement: (v: any) => void;
  ignoreVariables?: string[];
  filterVariables?: (v: any) => boolean;
  creatable?: boolean;
}> = ({
  onClick,
  onClose,
  updatePlacement
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const [inputValue, setInputValue] = useState<any>('');
  const [list, setList] = useState<any[]>([]);
  const filterResult = useMemo(() => {
    return list.filter((item: any) => item.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
  }, [inputValue, list]);
  const handleInput = (e: any) => {
    setInputValue(e.target.value);
  };
  const handleChange = (myValue: any) => {
    if (myValue) {
      onClick(myValue);
    }
  };
  const boxRef = useRef<any>(null);
  useLayoutEffect(() => {
    updatePlacement(boxRef.current?.getBoundingClientRect()?.height || 0);
  }, []);
  useEffect(() => {
    getCoupons().then(res => {
      if (res && res.data) {
        setList(res.data);
      }
    });
  }, []);
  return <ClickAwayListener onClickAway={onClose}>
			<Box ref={boxRef}>
				<TextField autoFocus={true} placeholder={formatMessage({
        defaultMessage: 'Search'
      })} InputProps={{
        startAdornment: <InputAdornment position="start">
								<SearchRoundedIcon sx={{
            color: 'primary.main'
          }} />
							</InputAdornment>,
        ...(inputValue !== '' && {
          endAdornment: <InputAdornment position="end">
									<ClearRoundedIcon onClick={() => setInputValue('')} sx={{
              color: 'primary.dark',
              cursor: 'pointer'
            }} />
								</InputAdornment>
        })
      }} value={inputValue} onChange={handleInput} sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          px: '12px!important',
          borderRadius: '4px 4px 0px 0px',
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
          boxSizing: 'border-box',
          '& input': {
            height: '38px',
            px: 0,
            py: 1,
            color: '#1A1E22'
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          display: 'none'
        }
      }} />

				<>
					{!isEmpty(filterResult) ? filterResult.map((item: any) => {
          return <MenuItem key={item.id} onClick={() => handleChange(`{{coupon:${item.id}:${JSON.stringify({
            name: item.name
          })}}}`)}>
									<Box sx={{
              wordBreak: 'noWrap',
              width: '100%',
              whiteSpace: 'break-spaces',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
										{item?.name}
									</Box>
								</MenuItem>;
        }) : <Typography sx={{
          textAlign: 'center',
          py: '30px'
        }}>
							<FormattedMessage defaultMessage={'No results found'} />
						</Typography>}
				</>
			</Box>
		</ClickAwayListener>;
};
export default CouponsSelect;