import { Box } from '@mui/material';
import Logo from './components/logo';
import CustomAvatar from './components/customAvatar';
import { useEffect, useLayoutEffect, useState } from 'react';
import { changePageStatus, getCountryCodesAsync, getIntegrationStatusAsync, getIPAsync, getUserAsync, getUserBalanceAsync, selectUser } from 'src/features/user/userSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { getIsExpand, updateIsExpand } from 'src/features/setting/settingSlice';
import Loading from 'src/app/components/loading';
import Content from './components/content';
import Navigation from './components/navigation';
import Balance from './components/balance';
import useEvent from 'src/hooks/useEvent';
import useLoadInbox from './useLoadInbox';
import useBroadcastChannelEvent from './useBroadcastChannelEvent';
import { useIntercom } from 'react-use-intercom';
import { setLang } from 'src/features/intl/intlSlice';
const Layout = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isExpand = useAppSelector(getIsExpand);
  const navigate = useNavigate();
  const {
    pathname
  } = useLocation();
  const [isInit, setIsInit] = useState(false);
  useLoadInbox();
  useEvent();
  useBroadcastChannelEvent();
  const {
    boot
  } = useIntercom();
  useLayoutEffect(() => {
    const fullScreenPaths = ['/app/inbox/chat', '/app/chatbot/detail', '/app/journey/detail', '/app/contact/contactList'];
    const content = document.getElementById('pageContent');
    if (content && isInit) {
      if (fullScreenPaths.some(item => pathname.includes(item))) {
        content.style.padding = '0';
        content.style.height = '100%';
      } else {
        content.style.padding = '32px 24px';
      }
    }
    if (pathname === '/app/inbox/chat') {
      dispatch(updateIsExpand(false));
    }
  }, [pathname, isInit]);
  useEffect(() => {
    const initApp = async () => {
      const res = await Promise.all([dispatch(getUserAsync(false)), dispatch(getIntegrationStatusAsync()), dispatch(changePageStatus()), dispatch(getUserBalanceAsync()), dispatch(getIPAsync()), dispatch(getCountryCodesAsync())]);
      const userRes: any = res[0];
      if (userRes?.payload?.data?.dashboardLanguage) {
        dispatch(setLang(userRes.payload.data.dashboardLanguage));
      }
    };
    initApp().then(() => {
      setIsInit(true);
    });
  }, [dispatch]);
  useEffect(() => {
    if (user.isFirstLogin) {
      if (user && user.registerSource) {
        switch (user.registerSource) {
          case 'growthTool':
            navigate('/app/contact/growthTools');
        }
      }
    }
  }, [user]);
  useEffect(() => {
    boot({
      name: user?.firstName + user?.lastName,
      email: user?.loginAccount,
      phone: user?.mobile ? '+' + user?.mobile.replace(':', '') : '',
      userHash: user?.hmac
    });
  }, [user, boot]);
  return <>
			{isInit ? <Box sx={{
      width: '100vw',
      height: '100vh',
      boxSizing: 'border-box',
      display: 'flex',
      overflow: 'hidden'
    }}>
					<Box sx={{
        width: isExpand ? '240px' : '64px',
        boxSizing: 'border-box',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }} key={Number(isExpand)}>
						<Logo />
						<Box sx={{
          flex: 1,
          width: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden auto'
        }}>
							<Navigation />
						</Box>
						<Balance />
						<CustomAvatar />
					</Box>
					<Box sx={{
        width: 0,
        flex: 1,
        boxSizing: 'border-box',
        height: '100%',
        overflow: 'hidden auto',
        borderLeft: 'solid 1px #E7E6EF'
      }}>
						<Content />
					</Box>
				</Box> : <Loading open={true} />}
		</>;
};
export default Layout;