import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useFocused, useSelected } from 'slate-react';
import { CustomStyleTooltip } from 'src/app/components/form/customTooltip';
import { varElementStyle } from './VariableElement';
import useTheme from '@mui/material/styles/useTheme';
import { whatsAppVariablePattern } from '../regexp';
import { formatTextToJson } from '../utils';
const WhatsappVariableElement: FunctionComponent<{
  attributes?: any;
  element?: any;
  children?: any;
  position: string;
  variables?: any[];
}> = ({
  attributes = {},
  element = {},
  children
}) => {
  const selected = useSelected();
  const focused = useFocused();
  const theme = useTheme();
  const {
    groups = {}
  } = new RegExp(whatsAppVariablePattern, 'g').exec(element.character) as any || {};
  const {
    variable,
    params
  } = groups || {};
  const formatParams = formatTextToJson(params);
  return <span {...attributes} contentEditable={false} style={{
    display: 'inline-block'
  }} key={formatParams?.id}>
			<CustomStyleTooltip title={<Typography>
						Sample:
						<Typography component="span"> {formatParams?.value}</Typography>
					</Typography>} followCursor={true} tooltipStyle={{
      color: '#1A1E22',
      backgroundColor: '#fff',
      boxShadow: '0px 2px 8px rgba(26, 30, 34, 0.15) !important',
      p: '12px !important',
      fontSize: '13px !important',
      borderRadius: '8px',
      marginTop: '10px',
      '& .MuiTooltip-arrow': {
        color: '#fff !important'
      }
    }}>
				<Typography sx={{
        ...varElementStyle,
        color: theme.palette.secondary.main,
        background: theme.palette.secondary.activeBg,
        cursor: 'pointer',
        boxShadow: selected && focused ? `0 0 0 2px ${theme.palette.secondary.main}` : 'none'
      }}>
					{children}
					{`{{${variable || ''}}}`}
				</Typography>
			</CustomStyleTooltip>
		</span>;
};
export default WhatsappVariableElement;