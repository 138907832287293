import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse, ClickAwayListener, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Typography, Box, Tooltip } from '@mui/material';
import NavItem from './NavItem';
import { getIsExpand } from 'src/features/setting/settingSlice';
import { useAppSelector } from 'src/app/hooks';
import { isEmpty } from 'lodash-es';
import useTheme from '@mui/material/styles/useTheme';
const NavCollapse = ({
  menu,
  level
}: any) => {
  const theme = useTheme();
  const isExpand = useAppSelector(getIsExpand);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const Icon = menu.icon;
  const menuIcon = menu.icon ? <Icon /> : false;
  const {
    pathname
  } = useLocation();
  const pathArray = pathname.split('/').filter((item: any) => !!item);
  const isSelected = menu.activeKeys.includes(pathArray[level]);
  const handleClick = (event: any) => {
    if (!isExpand && !isEmpty(menu.children)) {
      setAnchorEl(event?.currentTarget);
      return;
    }
    if (menu.onClick) {
      menu.onClick(menu);
      return;
    }
    if (menu.path) {
      navigate(menu.path);
      return;
    }
    if (menu.external) {
      window.open(menu.path);
      return;
    }
    if (!isEmpty(menu.children)) {
      navigate(menu.children.find((child: any) => child.path)?.path);
      return;
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navCollapse = menu.children?.map((item: any) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse handleClose={handleClose} key={item.id} menu={item} level={item.level} />;
      case 'item':
        return <NavItem handleClose={handleClose} key={item.id} item={item} level={item.level} />;
      default:
        return null;
    }
  });
  useEffect(() => {
    if (anchorEl && isExpand) {
      setAnchorEl(null);
    }
  }, [anchorEl, isExpand]);
  return <>
			<Tooltip arrow title={!isExpand && level === 1 ? menu.name : null} placement="right" enterDelay={0} leaveDelay={0} slotProps={{
      popper: {
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, -20]
          }
        }],
        sx: {
          zIndex: 3000
        }
      }
    }}>
				<ListItemButton disableRipple selected={isSelected} onClick={handleClick} sx={{
        color: theme.palette.text.primary,
        pl: isExpand ? `${level * 24}px` : '4px',
        pr: isExpand ? '24px' : '4px',
        py: '8px',
        background: 'transparent',
        '&.Mui-selected': {
          background: 'transparent'
        },
        '&:hover': {
          background: theme.palette.fill.hover
        },
        '&.Mui-selected:hover': {
          background: theme.palette.fill.hover
        },
        ...(isSelected && {
          '& .MuiListItemIcon-root': {
            background: theme.palette.secondary.main,
            color: theme.palette.text.white
          }
        }),
        ...(!isExpand && {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        })
      }}>
					{menuIcon && <ListItemIcon sx={{
          borderRadius: '50%',
          minWidth: '30px',
          width: '30px',
          height: '30px',
          color: isSelected ? theme.palette.text.white : theme.palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '16px',
          '& svg': {
            width: '30px',
            height: '30px'
          },
          ...(!isExpand && {
            marginRight: 0
          })
        }}>
							{menuIcon}
						</ListItemIcon>}
					{(isExpand || !isExpand && level !== 1) && <ListItemText sx={{
          margin: 0
        }} primary={<Typography variant="h6" sx={{
          fontSize: '16px',
          lineHeight: '24px',
          boxSizing: 'border-box',
          color: theme.palette.text.primary,
          fontWeight: 600,
          ...(isExpand && {}),
          ...(!isExpand && level !== 1 && {})
        }}>
									{menu.name}
								</Typography>} />}
				</ListItemButton>
			</Tooltip>
			{isExpand && <Collapse in={isSelected} timeout="auto" unmountOnExit>
					<List sx={{
        py: 0,
        pr: 0,
        pl: isExpand ? `${level * 16 + 24}px` : 0
      }}>{navCollapse}</List>
				</Collapse>}
			{!isExpand && <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="right-start" style={{
      zIndex: 2001,
      borderRadius: '8px',
      boxShadow: '0px 2px 15px 0px rgba(26, 30, 35, 0.14), 0px 4px 5px -3px rgba(26, 30, 35, 0.15)'
    }} popperOptions={{
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }]
    }}>
					<Paper sx={{
        overflow: 'hidden'
      }}>
						<ClickAwayListener onClickAway={handleClose}>
							<Box sx={{
            overflowX: 'hidden',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 240px)',
            padding: '8px 0',
            boxSizing: 'border-box'
          }}>
								<Box sx={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 700,
              color: theme.palette.text.primary,
              padding: '12px 24px'
            }}>
									{menu.name}
								</Box>
								{navCollapse}
							</Box>
						</ClickAwayListener>
					</Paper>
				</Popper>}
		</>;
};
export default NavCollapse;