import { FormattedMessage } from 'react-intl';
import { Button, Box } from '@mui/material';
import { useState, FunctionComponent } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import TimeZone from '../TimeZone';
import VariablesPopper from './components/VariablesPopper';
import { VariableNamespace } from '../../../const';
const defaultTimeZone = {
  show: false,
  data: {
    value: '',
    label: ''
  }
};
const VariableButton: FunctionComponent<{
  isJsonVariable?: boolean;
  ignoreVariables?: any[];
  filterVariables?: any;
  onChange: (v: any) => void;
  [index: string]: any;
}> = props => {
  const {
    isJsonVariable = true
  } = props;
  const [open, setOpen] = useState(false);
  const [timeZone, setTimeZone] = useState<typeof defaultTimeZone>(defaultTimeZone);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClose: any = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  return <Box sx={{
    position: 'relative',
    display: 'inline-block'
  }}>
			<Button onClick={handleClick} startIcon={<AddRoundedIcon />} sx={{
      boxSizing: 'border-box'
    }} size="small">
				<FormattedMessage defaultMessage={'Variables'} />
			</Button>
			{open && anchorEl && <VariablesPopper open anchorEl={anchorEl} onChange={(newValue: string, record: any) => {
      /* 变量支持插入Contact变量 */
      const {
        namespace,
        displayName,
        type
      } = record;
      if (namespace === VariableNamespace.CONTACT && isJsonVariable) {
        if (type === 'Time') {
          setTimeZone({
            show: true,
            data: {
              value: newValue,
              label: displayName
            }
          });
        } else {
          props.onChange(`{{${newValue}:${JSON.stringify({
            displayName,
            type: type.toUpperCase()
          })}}}`);
        }
        handleClose();
      } else {
        props.onChange(`{{${newValue}}}`);
        handleClose();
      }
    }} onClose={handleClose} ignoreVariables={props?.ignoreVariables || []} filterVariables={props?.filterVariables} />}

			{timeZone?.show && <TimeZone open onSubmit={(v: any) => {
      props.onChange(`{{${timeZone?.data?.value}:${JSON.stringify({
        type: 'TIME',
        key: timeZone?.data?.value?.replace('contact:', ''),
        timezone: v.timezone,
        timezoneDisplay: v.timezoneDisplay,
        displayName: timeZone?.data?.label
      })}}}`);
      setTimeZone(defaultTimeZone);
    }} onClose={() => setTimeZone(defaultTimeZone)} />}
		</Box>;
};
export default VariableButton;