import { POST, GET } from 'src/utils/fetch';
import { ContactSettingParams, CustomAttrParams } from './type';

export async function getContactSettingList(params: ContactSettingParams) {
	return POST('/api/contacts/customAttributeDefinition/search', params, true, false);
}

export async function addCustomAttr(params: CustomAttrParams) {
	return POST('/api/contacts/customAttributeDefinition/add', params, false, false);
}

export async function updateCustomAttr(params: CustomAttrParams) {
	return POST('/api/contacts/customAttributeDefinition/update', params, false, false);
}

export async function delCustomAttr(params: CustomAttrParams) {
	return POST('/api/contacts/customAttributeDefinition/delete', params, false, true);
}

export async function getAttrType() {
	return GET('/api/contacts/customAttributeDefinition/attrType/all', undefined, true, false);
}

export async function getAllLanguages() {
	return GET('/api/common/listDashboardLanguage', undefined, true, false);
}
export async function getAllEventList(params: any) {
	return GET('/api/events/tenantDefinitions', params, true, false);
}
