import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, TextField } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FunctionComponent } from 'react';
import { CustomLabel } from '.';
const AddSearch: FunctionComponent<{
  value: string;
  onChange: (...event: any[]) => void;
  hasAdd?: boolean;
  onAdd?: (...event: any[]) => void;
  addMarginLeft?: string;
  placeholder?: string;
  inputStyle?: any;
  label?: string;
}> = ({
  value,
  onChange,
  hasAdd = true,
  onAdd,
  addMarginLeft = '14px',
  placeholder,
  inputStyle,
  label
}) => {
  const {
    formatMessage
  } = useIntl();
  if (placeholder === undefined) {
    placeholder = formatMessage({
      defaultMessage: 'Search'
    });
  }
  return <Box sx={{
    display: 'flex',
    alignItems: label ? 'start' : 'center',
    flexDirection: label ? 'column' : 'row',
    columnGap: hasAdd ? addMarginLeft : 0
  }}>
			{hasAdd && <Button variant="contained" size="medium" startIcon={<AddRoundedIcon />} onClick={onAdd} sx={{
      flexShrink: 0
    }}>
					<FormattedMessage defaultMessage={'Add'} />
				</Button>}
			{label && <CustomLabel>{label}</CustomLabel>}
			<TextField fullWidth autoComplete="off" placeholder={placeholder} value={value} onChange={onChange} InputProps={{
      startAdornment: <SearchRoundedIcon />,
      endAdornment: <CloseRoundedIcon sx={{
        display: 'none',
        cursor: 'pointer'
      }} onClick={() => onChange('')} />
    }} sx={{
      '& .MuiOutlinedInput-input': {
        pl: '10px'
      },
      ...(hasAdd && {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent !important'
        }
      }),
      '&:hover': {
        ...(hasAdd && {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1A1E22 !important'
          }
        }),
        '& .MuiSvgIcon-root': {
          display: 'block'
        }
      },
      '& .Mui-focused': {
        ...(hasAdd && {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1A1E22 !important'
          }
        }),
        '& .MuiSvgIcon-root': {
          display: 'block'
        }
      },
      ...(inputStyle && inputStyle)
    }} />
		</Box>;
};
export default AddSearch;