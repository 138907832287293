import { FunctionComponent } from 'react';
import ImageContainer from 'src/app/components/imageContainer';
import Empty from 'src/app/images/Empty.png';
import Empty_neutral from 'src/app/images/Empty_neutral.png';
import { useAppSelector } from 'src/app/hooks';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
const EmptyImage: FunctionComponent<{
  sx?: object;
}> = ({
  sx = {}
}) => {
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  return <ImageContainer backgroundImage={isOtherBrand ? Empty_neutral : Empty} sx={{
    width: '220px',
    height: '220px',
    margin: 'auto',
    ...sx
  }} />;
};
export default EmptyImage;