import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetChatBotListParams, ChatBotInfo, DeleteChatBotParams, CreateChatBotParams, GetChatBotInfoByIdParams, GetChatbotQaListParams, AddQaParams, EditQaParams } from './type';
import {
	getChatBotList,
	deleteChatBot,
	createChatBot,
	getChatBotInfoById,
	updateChatBot,
	getChatbotQaList,
	addQa,
	updateQa,
	updateQaStatus,
	retryQa,
	retryWebsite,
	deleteQa,
	deleteWebsite,
	getChatbotWebsiteList,
	addWebsite,
	getTrainList,
	deleteTrainItem,
	getQaById,
	getWebsiteById,
} from './chatBotAPI';
import { RootState } from 'src/app/store';

export const getChatBotListAsync = createAsyncThunk('chatBot/getChatBotList', async (params: GetChatBotListParams) => {
	const response = await getChatBotList(params);
	return response;
});

export const deleteChatBotAsync = createAsyncThunk('chatBot/deleteChatBot', async (params: DeleteChatBotParams) => {
	const response = await deleteChatBot(params);
	return response;
});

export const createChatBotAsync = createAsyncThunk('chatBot/createChatBot', async (params: CreateChatBotParams) => {
	const response = await createChatBot(params);
	return response;
});

export const updateChatBotAsync = createAsyncThunk('chatBot/updateChatBot', async (params: any) => {
	const response = await updateChatBot(params);
	return response;
});

export const getCurrentChatBotInfoAsync = createAsyncThunk('chatBot/getCurrentChatBotInfo', async (params: GetChatBotInfoByIdParams) => {
	const response = await getChatBotInfoById(params);
	return response;
});

export const getChatBotInfoByIdAsync = createAsyncThunk('chatBot/getChatBotInfoById', async (params: GetChatBotInfoByIdParams) => {
	const response = await getChatBotInfoById(params);
	return response;
});

export const getChatbotQaListAsync = createAsyncThunk('chatBot/getChatbotQaList', async (params: GetChatbotQaListParams) => {
	const response = await getChatbotQaList(params);
	return response;
});

export const addQaAsync = createAsyncThunk('chatBot/addQa', async (params: AddQaParams) => {
	const response = await addQa(params);
	return response;
});

export const updateQaAsync = createAsyncThunk('chatBot/updateQa', async (params: EditQaParams) => {
	const response = await updateQa(params);
	return response;
});

export const updateQaStatusAsync = createAsyncThunk('chatBot/updateQaStatus', async (params: any) => {
	const response = await updateQaStatus(params);
	return response;
});

export const retryQaAsync = createAsyncThunk('chatBot/retryQa', async (params: any) => {
	const response = await retryQa(params);
	return response;
});

export const retryWebsiteAsync = createAsyncThunk('chatBot/retryWebsite', async (params: any) => {
	const response = await retryWebsite(params);
	return response;
});

export const deleteQaAsync = createAsyncThunk('chatBot/deleteQa', async (params: any) => {
	const response = await deleteQa(params);
	return response;
});

export const deleteWebsiteAsync = createAsyncThunk('chatBot/deleteWebsite', async (params: any) => {
	const response = await deleteWebsite(params);
	return response;
});

export const getChatbotWebsiteListAsync = createAsyncThunk('chatBot/getChatbotWebsiteList', async (params: any) => {
	const response = await getChatbotWebsiteList(params);
	return response;
});

export const addWebsiteAsync = createAsyncThunk('chatBot/addWebsite', async (params: any) => {
	const response = await addWebsite(params);
	return response;
});

export const getTrainListAsync = createAsyncThunk('chatBot/getTrainList', async (params: any) => {
	const response = await getTrainList(params);
	return response;
});

export const deleteTrainItemAsync = createAsyncThunk('chatBot/deleteTrainItem', async (params: any) => {
	const response = await deleteTrainItem(params);
	return response;
});

export const getQaByIdAsync = createAsyncThunk('chatBot/getQaById', async (params: any) => {
	const response = await getQaById(params);
	return response;
});

export const getWebsiteByIdAsync = createAsyncThunk('chatBot/getWebsiteById', async (params: any) => {
	const response = await getWebsiteById(params);
	return response;
});

export interface ChatBotState {
	currentChatBotInfo?: ChatBotInfo;
}

const initialState: ChatBotState = {
	currentChatBotInfo: undefined,
};

export const chatBotSlice = createSlice({
	name: 'chatBot',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getCurrentChatBotInfoAsync.fulfilled, (state, action) => {
			state.currentChatBotInfo = action.payload.data;
		});
	},
});

export const selectCurrentChatBotInfo = (state: RootState) => state.chatBot.currentChatBotInfo;

export default chatBotSlice.reducer;
