import { omit, isEmpty, assign, pick, omitBy } from 'lodash-es';

import { CompositeTriggerType, NodeType, SendMessageType } from './type';

/* 获取数据后根据节点类型处理数据 */
export const formatInput = (data: any, type: string) => {
	if (isEmpty(data)) {
		return {};
	}
	if (type === NodeType.KEYWORD && !data?.enableAi) {
		return assign({}, data, { enableAi: false, description: '' });
	}

	return data;
};

/* 提交数据前根据节点类型处理数据 */

export const formatOutput = (data: any, type: string) => {
	if (isEmpty(data)) {
		return {};
	}

	const formatFunctions = {
		[NodeType.KEYWORD]: () => (!data?.enableAi ? omit(data, ['enableAi', 'description']) : data),
		[NodeType.UNSUBSCRIBE]: () =>
			data?.autoReply
				? {
						autoReply: data.autoReply,
						message: { type: 'text', text: { body: data?.message || '' } },
					}
				: {},
		[NodeType.COMPOSITE_TRIGGER]: () => {
			if (data?.components?.length) {
				return {
					components: data.components.map((item: any) => {
						if (item.type === CompositeTriggerType.TEMPLATE_BUTTON) {
							return {
								type: CompositeTriggerType.TEMPLATE_BUTTON,
								[CompositeTriggerType.TEMPLATE_BUTTON]: {
									officialTemplateId: item[CompositeTriggerType.TEMPLATE_BUTTON].officialTemplateId,
									buttonValidityEnabled: item[CompositeTriggerType.TEMPLATE_BUTTON].buttonValidityEnabled,
									buttonValiditySeconds: item[CompositeTriggerType.TEMPLATE_BUTTON].buttonValiditySeconds,
									triggerOnce: item[CompositeTriggerType.TEMPLATE_BUTTON].triggerOnce,
								},
							};
						}
						return item;
					}),
				};
			} else {
				return {
					components: [],
				};
			}
		},
		[NodeType.SEND_MESSAGE]: () =>
			!isEmpty(data?.messages)
				? {
						messages: data.messages.map((item: any) => {
							if ([SendMessageType.IMAGE, SendMessageType.VIDEO, SendMessageType.DOC].includes(item.type)) {
								return { type: item.type, [item.type]: omitBy(item?.[item?.type] || {}, isEmpty) };
							} else if (item.type === 'interactive') {
								const { header = {}, footer = {}, ...params } = item.interactive as any;
								if (header.type === SendMessageType.TEXT) {
									return { ...item, interactive: { ...params, header: { type: 'text', text: header?.text }, ...(footer?.text ? { footer } : {}) } };
								} else if ([SendMessageType.IMAGE, SendMessageType.VIDEO, SendMessageType.DOC].includes(header.type)) {
									return { ...item, interactive: { ...params, header: pick(header, ['type', header?.type]), ...(footer?.text ? { footer } : {}) } };
								} else {
									return { ...item, interactive: { ...params, ...(footer?.text ? { footer } : {}) } };
								}
							} else {
								return item;
							}
						}),
					}
				: { messages: [] },
		[NodeType.ADD_TAG]: () => (!isEmpty(data?.tagList) ? { tagIds: data.tagList.filter(Boolean).map((item: any) => item.id) } : {}),
		[NodeType.ASSIGN_AGENT]: () => ({
			assignType: data.assignType,
			...(data.assignType === 'team' ? { assignId: data.team.id } : {}),
			...(data.assignType === 'agent' ? { assignId: data.agent.id } : {}),
		}),
		[NodeType.BRANCHING]: () =>
			!isEmpty(data?.branches)
				? {
						branches: [
							...data.branches.map((item: any) => ({
								...item,
								conditions: item.conditions.map((conditionItem: any) => ({
									column: conditionItem.namespace ? `{{${conditionItem.namespace}:${conditionItem.column}}}` : conditionItem.column,
									operator: conditionItem.operator,
									value: conditionItem.namespace === 'contact' && conditionItem.column === 'tags' ? conditionItem.value?.id : conditionItem.value,
								})),
							})),
							{ handle: '-1', conditions: [] },
						],
					}
				: {},
	};

	return formatFunctions[type as keyof typeof formatFunctions] ? formatFunctions[type as keyof typeof formatFunctions]() : data;
};
