import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import useFilterRouter, { mainRouters } from 'src/routes/Routes';
const Content = () => {
  const theme = useTheme();
  const element = useFilterRouter(mainRouters);
  return <Box sx={{
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    background: theme.palette.fill.bg
  }}>
			{element}
			<Outlet />
		</Box>;
};
export default Content;