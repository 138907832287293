import loadComponent from '../loadComponent';
const botRouter = [{
  path: 'list',
  element: loadComponent('chatBot')
}, {
  path: ':chatbotId',
  element: loadComponent('chatBot/detail'),
  children: [{
    path: 'flows',
    element: loadComponent('chatBot/detail/flows')
  }, {
    path: 'settings',
    element: loadComponent('chatBot/detail/settings')
  }, {
    path: 'knowledge',
    element: loadComponent('chatBot/detail/knowledge')
  }, {
    path: 'train',
    element: loadComponent('chatBot/detail/train')
  }]
}, {
  path: 'detail/:botId',
  element: loadComponent('bot/detail'),
  children: [{
    path: 'build',
    element: loadComponent('bot/detail/build')
  }, {
    path: 'analysis',
    element: loadComponent('bot/detail/analytics'),
    children: [{
      path: 'overview',
      element: loadComponent('bot/detail/analytics/overview')
      // element: loadComponent('bot/detail/analytics/flowOverview'),
    }, {
      path: 'logs',
      element: loadComponent('bot/detail/analytics/logs')
    }]
  }]
}];
export default botRouter;