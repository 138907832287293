import { FunctionComponent, ReactNode } from 'react';
import { Box } from '@mui/material';
const RoundButton: FunctionComponent<{
  children: ReactNode;
  onClick?: () => void;
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
  size?: 'small' | 'medium';
  style?: object;
  disabled?: boolean;
  className?: string;
}> = ({
  children,
  onClick,
  borderColor = '#1A1E22',
  size = 'medium',
  textColor = '#fff',
  backgroundColor = '#1A1E22',
  style,
  disabled = false,
  className
}) => {
  const styleMap = {
    small: {
      border: disabled ? 'none' : `1px solid ${borderColor}`,
      padding: '7px 16px',
      borderRadius: '16px',
      fontSize: '13px'
    },
    medium: {
      border: disabled ? 'none' : `2px solid ${borderColor}`,
      padding: '14px 0',
      borderRadius: '100px',
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 600
    }
  };
  const cursor = onClick && !disabled ? 'pointer' : disabled ? 'not-allowed' : 'none';
  const onButtonClick = () => {
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };
  return <Box onClick={onButtonClick} sx={{
    textAlign: 'center',
    backgroundColor: disabled ? 'fill.disabled' : backgroundColor,
    color: disabled ? 'text.disabled' : textColor,
    '&:hover': !disabled ? {
      color: backgroundColor === '#fff' ? textColor : backgroundColor,
      background: '#fff'
    } : {},
    cursor,
    ...styleMap[size],
    ...style
  }} className={className}>
			{children}
		</Box>;
};
export default RoundButton;