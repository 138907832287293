import { useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import React, { FunctionComponent, useState, useEffect } from 'react';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import AddTagInput from '../components/AddTagInput';
const AddTagDialog: FunctionComponent<{
  count: number;
  open: boolean;
  onClose: () => void;
  onSubmit: (e: any) => void;
}> = ({
  count,
  open,
  onClose,
  onSubmit
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const [addTag, setAddTag] = useState<any>([]);
  useEffect(() => {
    if (!open) {
      setAddTag([]);
    }
  }, [open]);
  return <Dialog open={open} maxWidth="md">
			<CustomDialogTitle title={count > 1 ? formatMessage({
      defaultMessage: 'Tag these {count} contacts'
    }, {
      count
    }) : formatMessage({
      defaultMessage: 'Tag {count} contact'
    }, {
      count
    })} onClose={onClose} />
			<DialogContent sx={{
      width: '552px',
      maxHeight: '530px',
      minHeight: '170px'
    }}>
				<AddTagInput value={addTag} onChange={(e: any) => setAddTag(e)} />
			</DialogContent>
			<DialogActions sx={{
      pt: '12px',
      pr: 2,
      pb: 2
    }}>
				<Button variant="contained" onClick={() => onSubmit(addTag)} disabled={addTag.length === 0}>
					{count > 1 ? formatMessage({
          defaultMessage: 'Tag {count} contacts'
        }, {
          count
        }) : formatMessage({
          defaultMessage: 'Tag {count} contact'
        }, {
          count
        })}
				</Button>
			</DialogActions>
		</Dialog>;
};
export default AddTagDialog;