import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, InputAdornment, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React, { FunctionComponent, useState } from 'react';
import LongTooltipText from 'src/app/components/form/LongTooltipText';
import { useAppSelector } from 'src/app/hooks';
import { selectFilterList } from 'src/features/contact/contactList/contactListSlice';
import { systemAttributeDisplayNameMap } from '../..';
import useSafetyIntl from 'src/hooks/useSafetyIntl';
import { filterFn } from 'src/utils/tools';
const AddFilter: FunctionComponent<{
  onAdd: (e: any) => void;
}> = ({
  onAdd
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const filterList = useAppSelector(selectFilterList);
  const [options, setOptions] = useState<Array<any>>(filterList);
  const [inputValue, setInputValue] = useState<any>('');
  const handleClear = () => {
    setInputValue('');
    setOptions(filterList);
  };
  const handleInput = (e: any) => {
    if (e) {
      if (e.target) {
        const value = e.target.value;
        setInputValue(value);
        const searchOptions = filterList.filter((i: any) => filterFn({
          ...i,
          displayName: safetyFormatMessage(systemAttributeDisplayNameMap[i.column as keyof typeof systemAttributeDisplayNameMap], i.column)
        }, ['column', 'displayName'], value));
        setOptions(searchOptions);
      }
    } else {
      handleClear();
    }
  };
  const handleChange = (e: any, myValue: any) => {
    if (myValue) {
      handleClear();
      onAdd(myValue);
    }
  };
  const {
    safetyFormatMessage
  } = useSafetyIntl();
  return <>
			<TextField autoFocus={true} placeholder={formatMessage({
      defaultMessage: 'Search'
    })} InputProps={{
      startAdornment: <InputAdornment position="start">
							<SearchRoundedIcon sx={{
          color: 'primary.main'
        }} />
						</InputAdornment>,
      ...(inputValue !== '' && {
        endAdornment: <InputAdornment position="end">
								<ClearRoundedIcon onClick={handleClear} sx={{
            color: 'primary.dark',
            cursor: 'pointer'
          }} />
							</InputAdornment>
      })
    }} value={inputValue} onChange={handleInput} sx={{
      width: '100%',
      '& .MuiInputBase-root': {
        px: '12px!important',
        borderRadius: '4px 4px 0px 0px',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        '& input': {
          height: '38px',
          px: 0,
          py: 1,
          color: '#1A1E22'
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none'
      }
    }} />
			{options.length > 0 ? <List sx={{
      width: '272px',
      p: 0,
      overflow: 'hidden'
    }}>
					{options.map((e: any) => <ListItemButton key={e.column} onClick={event => handleChange(event, e)} sx={{
        py: '9px'
      }}>
							<ListItemText primary={<LongTooltipText variant="body1" maxWidth={240}>
										{safetyFormatMessage(systemAttributeDisplayNameMap[e.column as keyof typeof systemAttributeDisplayNameMap], e.column)}
									</LongTooltipText>} />
						</ListItemButton>)}
				</List> : <Typography sx={{
      textAlign: 'center',
      py: '30px'
    }}>
					<FormattedMessage defaultMessage={'No results found'} />
				</Typography>}
		</>;
};
export default AddFilter;