import { useFocused, useSelected } from 'slate-react';
import useTheme from '@mui/material/styles/useTheme';
export const varElementStyle = {
  display: 'inline-block',
  color: 'secondary.main',
  marginLeft: '4px',
  borderRadius: '16px',
  fontSize: '13px',
  height: '16px',
  lineHeight: '16px',
  padding: '4px 8px'
};
const VariableElement = (props: any) => {
  const selected = useSelected();
  const focused = useFocused();
  const theme = useTheme();
  return <span {...props.attributes} contentEditable={false} style={{
    ...varElementStyle,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.activeBg,
    boxShadow: selected && focused ? `0 0 0 2px ${theme.palette.secondary.main}` : 'none'
  }}>
			{props.children}
			{props.element.character}
		</span>;
};
export default VariableElement;