import { POST, GET } from 'src/utils/fetch';
import { GetMyToolsListParams, GrowthToolConfig, GetTemplateListData, AddGrowthToolData, GetGrowthToolConfigData, GetToolResultData } from './type';

export async function getMyToolsList(params: GetMyToolsListParams) {
	return POST('/api/growthTools/search', params, true);
}

export async function getWabaPhoneList() {
	return GET('/api/whatsapp/phone/boundPhoneList', {}, true, true);
}

export async function addMyTool(params: GrowthToolConfig) {
	return (await POST('/api/growthTools/add', params, true)) as AddGrowthToolData;
}

export async function editMyTool(params: GrowthToolConfig) {
	return POST('/api/growthTools/edit', params, true);
}

export async function getToolConfigById(params: any) {
	return (await GET('/api/growthTools/get', params, true)) as GetGrowthToolConfigData;
}

export async function getToolResult(params: any) {
	return (await GET('/api/growthTools/show', params, true)) as GetToolResultData;
}

export async function toggleToolStatus(params: any) {
	return POST('/api/growthTools/switch', params);
}

export async function getOverviewData(params: any) {
	return POST('/api/growthTools/statistics', params, true);
}

export async function getTemplateList() {
	return (await POST('/api/growthTools/channel/template/all', {}, true)) as GetTemplateListData;
}
