import { useEffect } from 'react';
import WujieReact from 'wujie-react';
const {
  setupApp,
  preloadApp,
  destroyApp
} = WujieReact;
const degrade = window.localStorage.getItem('degrade') === 'true' || !window.Proxy || !window.CustomElementRegistry;
const useLoadInbox = () => {
  useEffect(() => {
    destroyApp('yCloud-inbox-web');
    setupApp({
      name: 'yCloud-inbox-web',
      url: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/inbox-web' : `/inbox-web`,
      exec: true,
      alive: true,
      degrade,
      attrs: {
        src: `${process.env.ASSET_PREFIX}/empty.html`
      }
    });
    if (window.localStorage.getItem('preload') !== 'false') {
      preloadApp({
        name: 'yCloud-inbox-web'
      } as any);
    }
  }, []);
  return null;
};
export default useLoadInbox;