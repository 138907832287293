import loadComponent from '../loadComponent';
const dashboardRouter = [{
  path: 'getStarted',
  element: loadComponent('dashboard/getStarted')
}, {
  path: 'template',
  element: loadComponent('dashboard/templates')
}, {
  path: 'whatsappFlows',
  element: loadComponent('dashboard/whatsappFlows')
}, {
  path: 'whatsappFlows/:flowId',
  element: loadComponent('dashboard/whatsappFlows/logs')
}, {
  path: 'templateInsights',
  element: loadComponent('dashboard/templates/templateInsights')
}, {
  path: 'templateSetting/:action',
  included: 'template',
  element: loadComponent('dashboard/templates/templateSetting')
}, {
  path: 'account',
  element: loadComponent('dashboard/accounts')
}, {
  path: 'accountSetting',
  element: loadComponent('dashboard/accounts/setting'),
  children: [{
    path: 'profile',
    element: loadComponent('dashboard/accounts/setting/profile')
  }, {
    path: 'assignment',
    element: loadComponent('dashboard/accounts/setting/assignment')
  }, {
    path: 'automations',
    element: loadComponent('dashboard/accounts/setting/automations')
  }, {
    path: 'chatLinks',
    element: loadComponent('dashboard/accounts/setting/chatLinks')
  }, {
    path: 'chatBot',
    element: loadComponent('dashboard/accounts/setting/chatBot')
  }]
}, {
  path: 'analytics',
  element: loadComponent('dashboard/analytics')
}];
export default dashboardRouter;