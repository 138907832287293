import { FunctionComponent } from 'react';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// 备注：如果需要非全屏展示loading，需保证loading的父组件position为relative

const Loading: FunctionComponent<{
  open: boolean;
  fullScreen?: boolean;
  sx?: object;
  backdropStyle?: object;
}> = ({
  open,
  fullScreen = false,
  sx = {},
  backdropStyle = {}
}) => {
  return <Backdrop open={open} sx={{
    background: 'transparent',
    position: fullScreen ? 'fixed' : 'absolute',
    zIndex: 100,
    ...backdropStyle
  }}>
			<CircularProgress color="inherit" sx={{
      ...sx
    }} />
		</Backdrop>;
};
export default Loading;