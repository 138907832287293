import { Box, Typography } from '@mui/material';
import React, { Fragment, FunctionComponent } from 'react';
import FilterItem, { operateStyle } from './components/FilterItem';
const FilterList: FunctionComponent<{
  openFilter: boolean;
  isDisabledAdd: boolean;
  isNotOperate: boolean;
  data: any;
  filterType: string;
  preFilterType: string;
  filterPosition: any;
  onOpenFilter: (e: any, i: any, j: string, k: any) => void;
  onAddPop: (e: any, i: any) => void;
}> = ({
  openFilter,
  isDisabledAdd,
  isNotOperate,
  data,
  filterType,
  preFilterType,
  filterPosition,
  onOpenFilter,
  onAddPop
}) => {
  return <>
			{data && data.connector && data.filters && data.filters.length > 0 && <Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    }}>
					{data.filters.map((e: any, index: number) => <Fragment key={'list' + index}>
							<FilterItem openFilter={openFilter} isDisabledAdd={isDisabledAdd} isNotOperate={isNotOperate} filterType={filterType} preFilterType={preFilterType} filterPosition={filterPosition} itemIndex={index} type={e.connector} list={e.conditions} onClick={onOpenFilter} onAddPop={onAddPop} />
							{index < data.filters.length - 1 && <Box sx={operateStyle} onClick={(event: any) => !isNotOperate && onOpenFilter(event, data.connector, 'operate', 'list')}>
									<Typography variant="subtitle2" component="span" sx={{
            color: '#5E596A'
          }}>
										{data.connector.toLocaleLowerCase()}
									</Typography>
								</Box>}
						</Fragment>)}
				</Box>}
		</>;
};
export default FilterList;