import { FunctionComponent, ReactNode } from 'react';
import { Box, Menu } from '@mui/material';
import { useState, MouseEvent } from 'react';
const CustomMenu: FunctionComponent<{
  actionArea: ReactNode;
  children: ReactNode;
  boxStyle?: any;
  areaStyle?: any;
  anchorOrigin?: any;
  transformOrigin?: any;
}> = ({
  actionArea,
  children,
  boxStyle,
  areaStyle,
  anchorOrigin = {},
  transformOrigin = {}
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return <Box sx={{
    ...(boxStyle && boxStyle)
  }}>
			<Box onClick={handleClick} sx={{
      ...areaStyle
    }}>
				{actionArea}
			</Box>
			<Menu open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
      ...anchorOrigin
    }} transformOrigin={transformOrigin}>
				<Box onClick={(e: any) => {
        e.stopPropagation();
        handleClose();
      }}>
					{children}
				</Box>
			</Menu>
		</Box>;
};
export default CustomMenu;