import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addMyTool, getMyToolsList, getWabaPhoneList, getOverviewData, getTemplateList, toggleToolStatus, editMyTool, getToolConfigById, getToolResult } from './growthToolsAPI';
import { GrowthToolConfig, MyToolsType } from './type';
import { RootState } from 'src/app/store';
import { timeZoneOffset } from 'src/utils/tools';

export interface GrowthToolsState {
	growthToolConfig: GrowthToolConfig;
	currentCreateToolStep: number;
}

const defaultGrowthToolConfig = {
	id: undefined,
	name: '',
	channel: {
		wabaId: '',
		phoneNumber: '',
		type: MyToolsType.LINK,
		keyword: '',
		quickReply: '',
		timeZoneOffset,
		config: {
			button: {
				square: true,
				iconBgColor: '#7C4DFF',
				iconColor: '#fff',
				iconType: 'icona-filled1',
				position: 'right',
				heightOffset: 30,
				edgeOffset: 30,
			},
			widget: {
				logo: '',
				title: 'Hi there!!! 👋',
				subTitle: 'We are here to help. Chat with us on WhatsApp for any queries.',
				btnText: 'Start chat on WhatsApp',
				color: '#602DED',
			},
			greeting: {
				text: 'Hi there! How can we help you?',
				delayTime: 30,
			},
		},
	},
};

const initialState: GrowthToolsState = {
	currentCreateToolStep: 0,
	growthToolConfig: defaultGrowthToolConfig,
};

export const getMyToolsListAsync = createAsyncThunk('growthTools/getMyToolsList', async (params: any) => {
	const res = await getMyToolsList(params);
	return res;
});

export const toggleToolStatusAsync = createAsyncThunk('growthTools/toggleToolStatus', async (params: any) => {
	const res = await toggleToolStatus(params);
	return res;
});

export const getToolsConfigByIdAsync = createAsyncThunk('growthTools/getToolConfigById', async (params: any) => {
	const res = await getToolConfigById(params);
	return res;
});

export const getTemplateListAsync = createAsyncThunk('growthTools/getTemplateList', async () => {
	const res = await getTemplateList();
	return res;
});

export const addMyToolAsync = createAsyncThunk('growthTools/addMyTool', async (params: GrowthToolConfig) => {
	const res = await addMyTool(params);
	return res;
});

export const editMyToolAsync = createAsyncThunk('growthTools/editMyTool', async (params: GrowthToolConfig) => {
	const res = await editMyTool(params);
	return res;
});

export const getToolResultAsync = createAsyncThunk('growthTools/getToolResult', async (params: any) => {
	const res = await getToolResult(params);
	return res;
});

export const getWabaPhoneListAsync = createAsyncThunk('growthTools/getWabaPhoneList', async () => {
	const res = await getWabaPhoneList();
	return res;
});

export const getOverviewDataAsync = createAsyncThunk('growthTools/getOverviewData', async (params: any) => {
	const res = await getOverviewData(params);
	return res;
});

export const growthToolsSlice = createSlice({
	name: 'growthTools',
	initialState,
	reducers: {
		updateToolTypeAndId: (state, action) => {
			state.growthToolConfig.id = action.payload.id;
			state.growthToolConfig.channel.type = action.payload.type;
		},
		updateToolConfig: (state, action) => {
			state.growthToolConfig = action.payload;
		},
		goNextStep: state => {
			state.currentCreateToolStep++;
		},
		goBackStep: state => {
			state.currentCreateToolStep--;
		},
		resetAll: state => {
			state.currentCreateToolStep = 0;
			state.growthToolConfig = defaultGrowthToolConfig;
		},
		updateToolId: (state, action) => {
			state.growthToolConfig.id = action.payload;
		},
		initToolName: (state, action) => {
			state.growthToolConfig.name = action.payload.name;
			state.growthToolConfig.channel.phoneNumber = action.payload.phoneNumber;
			state.growthToolConfig.channel.wabaId = action.payload.wabaId;
		},
		updateFromTemplate: (state, action) => {
			state.growthToolConfig.name = action.payload.name;
			state.growthToolConfig.name = action.payload.name;
			state.growthToolConfig.channel.keyword = action.payload.keyword;
			state.growthToolConfig.channel.quickReply = action.payload.quickReply;
			state.growthToolConfig.channel.phoneNumber = action.payload.phoneNumber;
		},
	},
	extraReducers: builder => {
		builder.addCase(getToolsConfigByIdAsync.fulfilled, (state, action) => {
			state.growthToolConfig = action.payload.data;
		});
	},
});

export default growthToolsSlice.reducer;

export const { updateToolTypeAndId, initToolName, updateToolId, updateToolConfig, updateFromTemplate, goNextStep, resetAll, goBackStep } = growthToolsSlice.actions;

export const selectCurrentCreateToolType = (state: RootState) => state.growthTools.growthToolConfig.channel.type;

export const selectCurrentCreateToolId = (state: RootState) => state.growthTools.growthToolConfig.id;

export const selectCurrentCreateToolStep = (state: RootState) => state.growthTools.currentCreateToolStep;

export const selectGrowthToolConfig = (state: RootState) => state.growthTools.growthToolConfig;

export const selectIsCreatingFinished = (state: RootState) => {
	const type = selectCurrentCreateToolType(state);
	const step = selectCurrentCreateToolStep(state);
	return type === MyToolsType.CHAT ? step > 2 : step > 0;
};

export const selectIsLastStep = (state: RootState) => {
	const type = selectCurrentCreateToolType(state);
	const step = selectCurrentCreateToolStep(state);
	return type === MyToolsType.CHAT ? step === 2 : step === 0;
};
