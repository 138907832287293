import { FormattedMessage, useIntl } from 'react-intl';
import { Box } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import { getIsExpand } from 'src/features/setting/settingSlice';
import { transformNumber } from 'src/utils/transform';
import { selectBalance } from 'src/features/user/userSlice';
import CustomTooltip from 'src/app/components/form/customTooltip';
import Link from '@mui/material/Link';
import LongTextEllipsis from 'src/app/components/LongTextEllipsis';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
import { useDocsAddress } from 'src/hooks/useDocsPath';
const Balance = () => {
  const navigate = useNavigate();
  const isExpand = useAppSelector(getIsExpand);
  const balance = useAppSelector(selectBalance);
  const {
    formatMessage
  } = useIntl();
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const {
    docsAddressPrev
  } = useDocsAddress();
  const onClick = () => {
    if (!isOtherBrand) {
      navigate('/app/billing/buyCredits');
    }
  };
  return <Box sx={{
    boxSizing: 'border-box',
    padding: '8px'
  }}>
			{isExpand ? <Box sx={{
      width: '100%',
      height: '100%',
      background: '#FFF1E9',
      color: '#FE7624',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      justifyContent: 'center',
      boxSizing: 'border-box',
      padding: '4px 10px',
      cursor: 'pointer',
      '&:hover svg': {
        display: 'unset'
      }
    }}>
					{balance.creditLine ? <LongTextEllipsis sx={{
        fontWeight: 600
      }} text={formatMessage({
        defaultMessage: '{a} {b} {c}'
      }, {
        a: formatMessage({
          defaultMessage: 'Available credit:'
        }),
        b: balance.creditLine.availableBalance,
        c: balance.currency
      })} /> : <LongTextEllipsis sx={{
        fontWeight: 600
      }} text={formatMessage({
        defaultMessage: '{a} {b} {c}'
      }, {
        a: formatMessage({
          defaultMessage: 'Balance:'
        }),
        b: transformNumber(balance.balance, 5),
        c: balance.currency
      })} />}
					{balance.creditLine && <CustomTooltip tip={<FormattedMessage defaultMessage={'$ {frozenBalance} are frozen.'} values={{
        frozenBalance: balance.creditLine.frozenBalance
      }} />} sx={{
        ml: 0.5,
        color: 'rgba(254, 118, 36,.6)'
      }} iconStyle={{
        width: '14px',
        height: '14px',
        mt: '2px'
      }} />}
					{!balance.creditLine && <>
							{Boolean(balance.frozenBalance) && <CustomTooltip tip={<FormattedMessage defaultMessage={'$ {frozenBalance} are frozen. <a>Learn more</a>'} values={{
          frozenBalance: balance.frozenBalance,
          a: chunks => <Link href={`${docsAddressPrev}/ding-jia-he-ji-fei/jinedong-jie-he-jie-dong`} target="__blank" color="warning.main">
														{chunks}
													</Link>
        }} />} sx={{
          ml: 0.5,
          color: 'rgba(254, 118, 36,.6)'
        }} iconStyle={{
          width: '14px',
          height: '14px',
          mt: '2px'
        }} />}
							<ArrowForwardIosRoundedIcon onClick={onClick} sx={{
          fontSize: '14px',
          marginLeft: '8px',
          display: 'none'
        }} />
						</>}
				</Box> : <Box sx={{
      width: '100%',
      height: '100%',
      background: '#FFF1E9',
      color: '#FE7624',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 600,
      justifyContent: 'center',
      boxSizing: 'border-box',
      padding: '4px',
      cursor: 'pointer',
      '&:hover svg': {
        display: 'unset'
      }
    }} onClick={onClick}>
					<Box sx={{
        width: 0,
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
						<FormattedMessage defaultMessage={'Top up'} />
					</Box>
					<ArrowForwardIosRoundedIcon sx={{
        fontSize: '12px',
        display: 'none'
      }} />
				</Box>}
		</Box>;
};
export default Balance;