import { POST, GET, UPLOAD } from 'src/utils/fetch';
import { updateTenantBaseInfoParams, UpdateTenantBillInfoParams, UpdateUserProfileParams, UpdatePwdType, CurrencyCodeParams, BalanceAlertParams, UserFactorsParams, FactorsSwitchParams, FactorsCodeParams, GetApiKeyParams } from './type';

export async function getUser(shouldHideError: boolean) {
	return POST('/api/user/get', undefined, true, shouldHideError);
}

export async function updateTenantBaseInfo(params: updateTenantBaseInfoParams) {
	return POST('/api/tenant/updateBaseInfo', params, false, false);
}

export async function updateTenantBillInfo(params: UpdateTenantBillInfoParams) {
	return POST('/api/tenant/updateBillInfo', params, false, false);
}

export async function getNotificationConfig(params: any) {
	return GET('/api/notify', params, true);
}

export async function saveNotificationConfig(params: any) {
	return POST('/api/notify', params);
}

export async function updateUserProfile(params: UpdateUserProfileParams) {
	return POST('/api/user/updateUserProfile', params, false, false);
}

export async function updatePwd(params: UpdatePwdType) {
	return POST('/api/user/password/put', params, true, true);
}

export async function getUserBalance() {
	return POST('/api/balance/get', undefined, true, true);
}

export async function getUserCurrency() {
	return POST('/api/user/currency/get', undefined, true, false);
}

export async function getCurrencyCode(params: CurrencyCodeParams) {
	return POST('/api/currencyCode/get', params, true, false);
}

export async function getExChangeRate() {
	return GET('/api/exchange/rate/get', undefined, true, false);
}

export async function getBalanceAlert() {
	return POST('/api/balance/alert/get', undefined, true, false);
}

export async function setBalanceAlert(params: BalanceAlertParams) {
	return POST('/api/balance/alert/put', params, false, false);
}

export async function getIP() {
	return GET('/api/common/ip', undefined, true, false);
}

export async function getCountryCodes() {
	return GET('/api/common/countryCallingCodes', undefined, true, false);
}

export async function getSignature() {
	return GET('/api/sms/signature/listAll', undefined, true, false);
}

export async function getUserFactors(params: UserFactorsParams) {
	return GET('/api/user/multiFactors/factors', params, true, false);
}

export async function setFactorsSwitch(params: FactorsSwitchParams) {
	return POST('/api/user/updateMultiFactorsSetting', params, true, true);
}

export async function factorsSendCode(params: FactorsCodeParams) {
	return POST('/api/user/multiFactors/sendCode', params, false, false);
}

export async function factorsVerify(params: FactorsCodeParams) {
	return POST('/api/user/multiFactors/verify', params, true, params.isHideFail ? params.isHideFail : false);
}

export async function getApiKey(params: GetApiKeyParams) {
	return POST('/api/apikey/reveal', params, true, false);
}

export async function getIntegrationStatus() {
	return GET('/api/integration/status', undefined, true, false);
}

export async function getAgentInfo() {
	return GET('/inbox-api/agent/get', undefined, true, false);
}

export const updateNotifications = (params: any) => {
	return POST('/inbox-api/settings/updateNotifications', params, true, false);
};

export const uploadAvatar = (params: any) => {
	return UPLOAD('/api/user/uploadAvatar', params, true, true);
};
