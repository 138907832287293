import { useIntl } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Box, IconButton } from '@mui/material';
import { forwardRef } from 'react';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { dateTimeFormat } from 'src/utils/transform';
import CloseIcon from '@mui/icons-material/Close';
import { es, id, pt, zhCN } from 'date-fns/locale';
interface IProps {
  error?: boolean;
  minDate?: any;
  value?: number;
  allowClear?: boolean;
  onChange?: any;
  helperText?: any;
  [index: string]: any;
}
export type Ref = any;
const CustomDateTimePicker = forwardRef<Ref, IProps>(({
  allowClear,
  onChange,
  value = 0,
  minDate,
  error = false,
  helperText,
  ...props
}, ref) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const handleChange = (myValue: any) => {
    if (onChange) {
      onChange(myValue.getTime());
    }
  };
  const localeMap = {
    zh: zhCN,
    id,
    pt,
    es
  };
  const {
    locale
  } = useIntl();
  const handleClear = () => {
    onChange(null);
  };
  return <>
			<Box ref={ref}>
				<Box sx={{
        display: 'flex',
        '&:hover': {
          '& .close-button': {
            display: 'flex'
          }
        }
      }}>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale as keyof typeof localeMap]}>
						<DateTimePicker minDate={minDate} views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']} localeText={{
            okButtonLabel: formatMessage({
              defaultMessage: 'Confirm'
            })
          }} ampm={false} {...props} slotProps={{
            textField: {
              error,
              sx: {
                width: '100%'
              }
            }
          }} format={dateTimeFormat} value={value ? new Date(value) : null} onChange={handleChange} />
					</LocalizationProvider>
					{allowClear && value && <IconButton className="close-button" size="small" sx={{
          position: 'absolute',
          display: 'none',
          right: '40px',
          top: '29px',
          width: '40px',
          height: '40px',
          padding: 0,
          alignItems: 'center',
          justifyContent: 'center'
        }} onClick={handleClear}>
							<CloseIcon sx={{
            width: '24px',
            height: '24px'
          }} />
						</IconButton>}
				</Box>
				{error && <ErrorHelperText>
						{helperText || formatMessage({
          defaultMessage: 'Please select a valid time'
        })}
					</ErrorHelperText>}
			</Box>
		</>;
});
export default CustomDateTimePicker;