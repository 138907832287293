import { GET, POST } from 'src/utils/fetch';
import { GetSenderIdListParams, CreateSenderIdParams, SmsSenderIdData, SetDefaultSenderIdParams, SenderIdAvailableCountriesListData } from './type';

export async function getSenderIdList(params: GetSenderIdListParams) {
	return (await GET('/api/senders/list', params, true, true)) as SmsSenderIdData;
}

export async function setDefaultSenderId(params: SetDefaultSenderIdParams) {
	return (await POST('/api/senders/update', params)) as SmsSenderIdData;
}

export async function getSenderIdAvailableCountriesList() {
	return (await GET('/api/senders/availableCountries', {}, true, false)) as SenderIdAvailableCountriesListData;
}

export async function submitAddSenderId(params: CreateSenderIdParams) {
	return POST('/api/senders/submit', params, true, false);
}

export async function deleteSenderId(params: { id: string }) {
	return POST('/api/senders/delete', params);
}
