export const CODE = {
	// 货币类型未设置
	NO_CURRENCY_SETTING: -3,

	// 没有基础价格，不支持当地地区发送
	NO_BASE_PRICE: -2,

	// 通用错误
	ERR: -1,

	// 重定向，目前就shopify绑定需要用到
	REDIRECT: 302,

	// 参数格式错误
	BAD_ARGUMENT: 2,

	CAPTCHA_ERROR: 9988,

	OK: 0,

	// 敏感词检测
	SENSITIVE_WORD: 52,

	// 未登录
	NEED_LOGIN: 10001,

	// 余额不足
	NO_BALANCE: 10015,

	// 邮箱已被注册
	OVER_REGISTER: 9995,

	// 账户未完成激活
	NOT_ACTIVE: 10004,

	// 账户已激活
	OVER_ACTIVE: 10005,

	// 确认码超时
	TIME_ERROR: 9994,

	// 邮箱不存在
	NO_ACCOUNT: 10000,

	// 5min 内不可重复发送
	VERIFY_LIMIT: 9990,

	// 手机验证码错误
	VERIFY_CODE_ERROR: 9991,

	// 测试短信频率限制（30秒1条)
	SMS_TEST_LIMIT: 10008,

	// 手机验证码过期
	VERIFY_CODE_OVERDUE: 9992,

	// 修改密码原密码错误
	ORIGINAL_PASSWORD_ERROR: 9987,

	// 注册需要行为验证
	NEED_VERIFY: 10007,

	// 文件上传失败
	UPLOAD_ERROR: 10009,

	// 文件行数超过限制(100万行)
	UPLOAD_ROWS_ERROR: 10010,

	// 大小超过限制、注册确认账号创建中不要重试.
	UPLOAD_SIZE_ERROR: 10011,

	// 登录时，如果没有租户
	LOGIN_NO_ACCOUNT: 10046,

	// 双因素验证
	TWO_FACTOR_AUTH: 10047,

	// 邀请租户失败
	INVITE_ERROR: 10053,

	// 用户是禁用
	USER_DISABLE: 10057,

	// 无权限
	NO_POWER: 403,

	// 有正在进程中的导出
	ON_EXPORT: 10059,

	// 错误的验证码
	ERROR_VERIFY_CODE: 10060,

	// 属性被应用于分组
	CUSTOM_ATTR_IS_USED: 10062,
	// 没有绑定手机号
	NO_PHONE_NUMBER: 10072,
};
