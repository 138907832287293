import { useIntl } from 'react-intl';
import { Dialog, DialogContent } from '@mui/material';
import React, { FunctionComponent } from 'react';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import { useAppSelector } from 'src/app/hooks';
import { selectUser } from 'src/features/user/userSlice';
import TwoFactorAuthForm from './twoFactorAuthForm';
const TwoFactorAuthDialog: FunctionComponent<{
  open: boolean;
  action: string;
  onClose: () => void;
  onOk: (e?: any) => void;
}> = ({
  open,
  action,
  onClose,
  onOk
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const user = useAppSelector(selectUser);
  return <Dialog open={open} maxWidth="md">
			<CustomDialogTitle title={formatMessage({
      defaultMessage: 'To continue, verify your account'
    })} onClose={onClose} />
			<DialogContent sx={{
      width: '600px',
      boxSizing: 'border-box',
      pb: 3,
      pt: '24px !important'
    }}>
				<TwoFactorAuthForm action={action} userName={user?.loginAccount} isDialog={true} onOk={onOk} />
			</DialogContent>
		</Dialog>;
};
export default TwoFactorAuthDialog;