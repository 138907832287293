import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, CircularProgress, FormControl, Stack, TextField, Typography } from '@mui/material';
import React, { FC, useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import RoundButton from 'src/app/components/roundButton';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { useAppDispatch } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { needUpdatePasswordAsync } from 'src/features/login/loginSlice';
import { getUserAsync, getUserFactorsAsync, factorsSendCodeAsync, factorsVerifyAsync } from 'src/features/user/userSlice';
import { showMessage } from 'src/utils/message';
const actionMap: any = {
  login: 'LOGIN',
  changePwd: 'CHANGE_PASSWORD',
  switch: 'UPDATE_USER_MULTI_FACTORS_SETTING',
  tenant: 'UPDATE_TENANT_MULTI_FACTORS_SETTING',
  'switch-tenant': 'SWITCH_TENANT',
  apikey: 'APIKEY_REVEAL',
  'export-contacts': 'EXPORT_CONTACTS'
};
const TwoFactorAuthForm: FC<{
  action: string;
  userName: string | null;
  isDialog?: boolean;
  onOk?: (e?: any) => void;
}> = ({
  action,
  userName,
  isDialog = false,
  onOk
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lastPath = searchParams.get('lastPath');
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [sendTypeList, setSendTypeList] = useState<Array<any>>([]);
  const [sendType, setSendType] = useState<string>('EMAIL');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const interval = useRef<any>(null);
  const [downCount, setDownCount] = useState<number>(0);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({
    defaultValues: {
      vcode: ''
    }
  });
  const vcode: string = watch('vcode');

  /* 倒计时 */
  const triggerCountDown = () => {
    setIsFirst(false);
    if (interval.current) {
      clearInterval(interval.current);
    }
    setDownCount(60);
    interval.current = setInterval(() => {
      setDownCount(v => {
        if (v <= 0) {
          clearInterval(interval.current);
          return 0;
        }
        return --v;
      });
    }, 1000);
  };

  /* 发送验证码 */
  const handleSendCode = async (type: string) => {
    const {
      code
    }: any = await dispatch(factorsSendCodeAsync({
      factor: type,
      bizType: actionMap[action],
      userName: userName
    })).unwrap();
    if (code === CODE.OK) {
      triggerCountDown();
    }
  };

  /* 切换验证方式 */
  const handleChangeSendType = async () => {
    const newType = sendType === 'EMAIL' ? 'SMS' : 'EMAIL';
    setSendType(newType);
    setDownCount(0);
    setIsFirst(true);
    setValue('vcode', '');
  };
  const judgeUser = async () => {
    const needUpdatePasswordRes: any = await dispatch(needUpdatePasswordAsync()).unwrap();
    if (needUpdatePasswordRes.data === true) {
      navigate('/entry/changeTemporaryPassword', {
        replace: true
      });
      return;
    }
    const res: any = await dispatch(getUserAsync(true));
    if (res.payload?.code === CODE.OK) {
      const data = res.payload?.data;
      if (window.BroadcastChannel) {
        const broadcastChannel = new BroadcastChannel('broadcastChannelEvent');
        broadcastChannel.postMessage({
          type: 'userChange'
        });
      }
      if (data && (data.isMobileRequired ? data.mobile : data.accountId)) {
        if (lastPath && !lastPath.includes('/app/noPower') && !lastPath.includes('/app/notFound')) {
          window.location.href = decodeURIComponent(lastPath);
        } else {
          window.location.href = '/console/#/app/dashboard/getStarted';
        }
      } else {
        navigate('/entry/registerConfirm', {
          replace: true
        });
      }
    } else if (res.payload?.code === CODE.LOGIN_NO_ACCOUNT) {
      navigate('/entry/createCompany', {
        replace: true
      });
    } else {
      showMessage({
        message: res.payload?.msg,
        type: 'error'
      });
    }
  };

  /* 校验验证码 */
  const handleVerify = async () => {
    setSubmitLoading(true);
    try {
      const {
        code,
        data
      }: any = await dispatch(factorsVerifyAsync({
        code: vcode,
        factor: sendType,
        bizType: actionMap[action],
        userName: userName
      })).unwrap();
      if (code === CODE.OK) {
        if (isDialog) {
          if (onOk) {
            onOk(data);
          }
        } else {
          judgeUser();
        }
      }
      setSubmitLoading(false);
      window.localStorage.removeItem('loginAccount');
    } catch (e) {
      setSubmitLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    const initData = async () => {
      try {
        const {
          code,
          data
        } = await dispatch(getUserFactorsAsync({
          userName: userName
        })).unwrap();
        if (code === CODE.OK && data && data.length > 0) {
          let type: string;
          const hasEmail = data.filter((i: any) => i.factor === 'EMAIL');
          const hasPhone = data.filter((i: any) => i.factor === 'SMS');
          if (hasEmail && hasEmail.length > 0 && hasEmail[0].to) {
            type = 'EMAIL';
            const userEmail = hasEmail[0].to;
            const index = userEmail.indexOf('@');
            setEmail(userEmail.slice(0, 2) + '****' + userEmail.slice(index));
          } else {
            type = data[0].factor;
          }
          if (hasPhone && hasPhone.length > 0 && hasPhone[0].to) {
            const userPhone = hasPhone[0].to;
            setPhone(userPhone.slice(0, 3) + '****' + userPhone.slice(userPhone.length - 4));
          }
          setSendType(type);
          setSendTypeList(data.filter((item: any) => item.to));
        }
      } catch (e) {
        console.log(e);
      }
    };
    initData();
  }, [userName, dispatch]);
  return <>
			<Stack spacing={isDialog ? 3 : 2}>
				<Typography variant="body2" sx={{
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.87)'
      }}>
					<FormattedMessage defaultMessage={'Input the verification code we sent to <span>{destination}</span>'} values={{
          destination: sendType === 'EMAIL' ? email : phone,
          span: chunks => <Typography variant="body2" component="span" sx={{
            lineHeight: '24px',
            fontWeight: 700,
            ml: '4px'
          }}>
									{chunks}
								</Typography>
        }} />
				</Typography>

				<FormControl fullWidth sx={{
        display: 'flex',
        flexDirection: 'row'
      }}>
					<Controller name="vcode" control={control} rules={{
          required: formatMessage({
            defaultMessage: 'Please enter a valid code'
          }),
          minLength: {
            value: 6,
            message: formatMessage({
              defaultMessage: 'Please enter a valid code'
            })
          },
          maxLength: {
            value: 6,
            message: formatMessage({
              defaultMessage: 'Please enter a valid code'
            })
          }
        }} render={({
          field
        }) => <TextField {...field} autoComplete="off" variant="outlined" placeholder={formatMessage({
          defaultMessage: 'Please enter the code'
        })} error={Boolean(errors.vcode && errors.vcode?.type)} helperText={<ErrorHelperText>{errors.vcode && errors.vcode?.message}</ErrorHelperText>} sx={{
          width: '360px',
          '& input': {
            height: '56px !important',
            py: 2,
            fontSize: '16px',
            lineHeight: '24px'
          }
        }} />} />

					<Typography variant="body2" color={downCount ? 'text.disabled' : 'secondary.main'} onClick={() => !downCount && handleSendCode(sendType)} sx={{
          lineHeight: '56px',
          ml: 2,
          cursor: downCount ? 'not-allowed' : 'pointer',
          flexGrow: 1,
          flexShrink: 0
        }}>
						<span>
							{downCount ? formatMessage({
              defaultMessage: 'Resend in {num} s'
            }, {
              num: downCount
            }) : isFirst ? formatMessage({
              defaultMessage: 'Send'
            }) : formatMessage({
              defaultMessage: 'Resend'
            })}
						</span>
					</Typography>
				</FormControl>
				{/* 登陆页面 */}
				{!isDialog && <RoundButton disabled={submitLoading || !vcode} onClick={handleSubmit(handleVerify)} style={{
        lineHeight: '28px',
        py: '10px',
        cursor: submitLoading || !vcode ? 'not-allowed' : 'pointer'
      }}>
						{submitLoading && <CircularProgress sx={{
          color: '#fff !important',
          width: '25px !important',
          height: '25px !important',
          mr: 1,
          verticalAlign: 'middle'
        }} />}
						Verify
					</RoundButton>}
			</Stack>
			<Box sx={{
      mt: isDialog ? 6 : 7,
      position: 'relative',
      ...(isDialog && {
        lineHeight: '40px'
      })
    }}>
				{sendTypeList.length > 1 && <>
						<Typography variant="body1" component="span" sx={{
          color: 'rgba(0, 0, 0, 0.87)'
        }}>
							{formatMessage({
            defaultMessage: 'Choose another method: {method}'
          }, {
            method: <Typography variant="body1" component="span" color="secondary.main" onClick={handleChangeSendType} sx={{
              cursor: 'pointer'
            }}>
											{sendType === 'EMAIL' ? formatMessage({
                defaultMessage: 'Via SMS'
              }) : formatMessage({
                defaultMessage: 'Via Email'
              })}
										</Typography>
          })}
						</Typography>
					</>}
				{isDialog && <span style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        cursor: submitLoading || !vcode ? 'not-allowed' : 'pointer'
      }}>
						<Button variant="contained" disabled={submitLoading || !vcode} onClick={handleSubmit(handleVerify)}>
							{submitLoading && <CircularProgress sx={{
            color: '#fff !important',
            width: '25px !important',
            height: '25px !important',
            mr: 1
          }} />}
							<FormattedMessage defaultMessage={'Verify'} />
						</Button>
					</span>}
			</Box>
		</>;
};
export default TwoFactorAuthForm;