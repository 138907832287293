import { Box, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { FunctionComponent, useMemo } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { screenMenusByRole } from 'src/routes/menuData';
import { cloneDeep } from 'lodash-es';
import { useAppSelector } from 'src/app/hooks';
import { getBlacklist, selectCustomerType, selectUser } from 'src/features/user/userSlice';
import { getIsExpand } from 'src/features/integration/integrationSlice';
import { defineMessages, useIntl } from 'react-intl';
export const titleMap = defineMessages({
  sms: {
    defaultMessage: 'SMS'
  },
  email: {
    defaultMessage: 'Email'
  },
  voice: {
    defaultMessage: 'Voice Code'
  },
  verify: {
    defaultMessage: 'Verify'
  }
});
const IntegrationsPageContainer: FunctionComponent<{
  titleKey: string;
}> = ({
  titleKey
}) => {
  const {
    role
  } = useAppSelector(selectUser);
  const blacklist = useAppSelector(getBlacklist);
  const isExpand = useAppSelector(getIsExpand);
  const customerType = useAppSelector(selectCustomerType);
  const {
    formatMessage
  } = useIntl();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const pathArray = location.pathname.split('/').filter(item => !!item);
  const smsMenuData = [{
    name: formatMessage({
      defaultMessage: 'Getting started'
    }),
    path: '/app/integrations/sms/getStarted',
    activeKeys: ['getStarted']
  }, {
    name: formatMessage({
      defaultMessage: 'Sender ID'
    }),
    path: '/app/integrations/sms/senderId',
    activeKeys: ['senderId', 'addSenderId']
  }, {
    name: formatMessage({
      defaultMessage: 'Analytics'
    }),
    path: '/app/integrations/sms/analytics/logs',
    activeKeys: ['analytics']
  }];
  const emailMenuData = [{
    name: formatMessage({
      defaultMessage: 'Getting started'
    }),
    path: '/app/integrations/email/getStarted',
    activeKeys: ['getStarted']
  }, {
    name: formatMessage({
      defaultMessage: 'Domains'
    }),
    path: '/app/integrations/email/domains',
    activeKeys: ['domains']
  }, {
    name: formatMessage({
      defaultMessage: 'Analytics'
    }),
    path: '/app/integrations/email/analytics/overview',
    activeKeys: ['analytics']
  }];
  const voiceMenuData = [{
    name: formatMessage({
      defaultMessage: 'Getting started'
    }),
    path: '/app/integrations/voice/getStarted',
    activeKeys: ['getStarted']
  }, {
    name: formatMessage({
      defaultMessage: 'Analytics'
    }),
    path: '/app/integrations/voice/analytics/logs',
    activeKeys: ['analytics']
  }];
  const verifyMenuData = [{
    name: formatMessage({
      defaultMessage: 'Getting started'
    }),
    path: '/app/integrations/verify/getStarted',
    activeKeys: ['getStarted']
  }, {
    name: formatMessage({
      defaultMessage: 'Settings'
    }),
    path: '/app/integrations/verify/setting',
    activeKeys: ['setting']
  }, {
    name: formatMessage({
      defaultMessage: 'Security'
    }),
    path: '/app/integrations/verify/security',
    activeKeys: ['security']
  }, {
    name: formatMessage({
      defaultMessage: 'Analytics'
    }),
    path: '/app/integrations/verify/analytics',
    activeKeys: ['analytics']
  }];
  const menuMap = {
    sms: smsMenuData,
    email: emailMenuData,
    voice: voiceMenuData,
    verify: verifyMenuData
  };
  const menu = menuMap[titleKey as keyof typeof menuMap];
  const menuItemClick = (item: any) => {
    if (item.onClick) {
      item.onClick(item);
      return;
    }
    if (item.isLink) {
      window.open(item.path);
      return;
    }
    navigate(item.redirect || item.path);
  };
  const whitelist = useMemo(() => {
    return screenMenusByRole(cloneDeep(menu), role, blacklist, customerType);
  }, [role, menu, blacklist]);
  return <Box sx={{
    width: '100%',
    height: '100%',
    display: 'flex'
  }}>
			<Box sx={{
      width: isExpand ? '240px' : 0,
      padding: isExpand ? '24px 42px' : 0,
      flexShrink: 0,
      background: '#F5F3FA',
      boxSizing: 'border-box',
      overflowY: 'auto',
      overflowX: 'hidden'
    }}>
				<Box>
					<Box sx={{
          fontSize: '20px',
          fontWeight: 500,
          letterSpacing: '0.15px',
          lineHeight: '52px'
        }}>
						{formatMessage(titleMap[titleKey as keyof typeof titleMap])}
					</Box>
					{whitelist.map((item: any) => {
          const itemActive = (item?.activeKeys || []).includes(pathArray[3]);
          return <Box onClick={() => menuItemClick(item)} key={item.path} sx={{
            cursor: 'pointer',
            borderLeft: `solid 1px #D7D5DF`,
            color: itemActive ? theme.palette.secondary.main : theme.palette.text.secondary,
            '&:hover': {
              background: '#EFEDF5'
            }
          }}>
								<Typography variant={itemActive ? 'subtitle2' : 'body1'} sx={{
              padding: '8px 20px',
              ...(itemActive && {
                borderLeft: `solid 2px ${theme.palette.secondary.main}`
              }),
              position: 'relative',
              left: '-1px'
            }}>
									{item.name}
								</Typography>
							</Box>;
        })}
				</Box>
			</Box>

			<Box sx={{
      height: '100%',
      flex: 1,
      width: 0,
      overflowY: 'auto',
      position: 'relative',
      boxSizing: 'border-box',
      padding: 0
    }}>
				<Outlet />
			</Box>
		</Box>;
};
export default IntegrationsPageContainer;