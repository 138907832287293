import { DialogTitle, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, { FunctionComponent, ReactNode } from 'react';
const CustomDialogTitle: FunctionComponent<{
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  hasCloseIcon?: boolean;
  onClose?: () => void;
  sx?: any;
}> = ({
  title,
  subTitle,
  hasCloseIcon = true,
  onClose,
  sx
}) => {
  return <DialogTitle sx={{
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '30px',
    ...(sx && sx)
  }}>
			{title}
			{hasCloseIcon && onClose && <CloseRoundedIcon onClick={onClose} sx={{
      position: 'absolute',
      top: '20px',
      right: '24px',
      cursor: 'pointer'
    }} />}
			{subTitle && <Typography variant="body1">{subTitle}</Typography>}
		</DialogTitle>;
};
export default CustomDialogTitle;