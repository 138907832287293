import { Box } from '@mui/material';
import { FunctionComponent, ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from 'src/app/components/loading';
const PageContainer: FunctionComponent<{
  children?: ReactNode;
}> = ({
  children
}) => {
  return <Box id="pageContent" sx={{
    flexGrow: 1,
    padding: '32px 24px',
    height: 'calc(100% - 64px)',
    overflowY: 'auto',
    position: 'relative',
    flex: 1
  }}>
			{children || <Suspense fallback={<Loading open={true} />}>
					<Outlet />
				</Suspense>}
		</Box>;
};
export default PageContainer;