import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography, Pagination, PaginationItem, TextField, MenuItem } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { MoreIcon, ArrowIcon } from 'src/app/icons';
import { showMessage } from 'src/utils/message';
const CustomPagination: FunctionComponent<{
  totalCount: number;
  pageSize: number;
  pageNo: number;
  pageSizeOptions?: Array<number>;
  onPageNoChange: (e: any) => void;
  onPageSizeChange: (e: any) => void;
  disabled?: boolean;
  isFixed?: boolean;
  isExpand?: boolean;
  isChange?: boolean;
  offset?: number;
  sx?: object;
}> = ({
  totalCount,
  pageSize,
  pageNo,
  sx = {},
  pageSizeOptions = [10, 20, 50],
  onPageNoChange,
  onPageSizeChange,
  disabled = false,
  isFixed = false,
  isChange,
  offset = 0
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const inputStyle = {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'line.line2'
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary.main'
      }
    }
  };
  const arrowStyle = {
    color: 'secondary.main',
    fontSize: '11px',
    p: '9.5px',
    verticalAlign: 'middle',
    cursor: 'pointer'
  };
  const moreStyle = {
    color: 'primary.main',
    fontSize: '25px',
    p: '2.5px',
    verticalAlign: 'middle'
  };
  const [searchNo, setSearchNo] = useState<any>('');
  const [isStartEllipsis, setIsStartEllipsis] = useState<boolean>(false);
  const [isEndEllipsis, setIsEndEllipsis] = useState<boolean>(false);
  const handlePageNoChange = (e: any, value: number) => {
    setSearchNo('');
    onPageNoChange(value);
  };
  const onEllipsisClick = (e: any, type: string) => {
    if (type === 'start-ellipsis') {
      onPageNoChange(pageNo - 3);
    } else if (type === 'end-ellipsis') {
      onPageNoChange(pageNo + 3);
    }
  };
  const handleSearchNo = () => {
    if (searchNo) {
      const value = Number(searchNo);
      if (isNaN(value)) {
        showMessage({
          type: 'error',
          message: formatMessage({
            defaultMessage: 'Please enter a number'
          })
        });
      } else {
        if (pageNo !== value) {
          onPageNoChange(value);
        }
      }
    } else {
      onPageNoChange(1);
    }
  };
  useEffect(() => {
    setSearchNo('');
  }, [isChange]);
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1,
    mt: 3,
    ...(isFixed && {
      position: 'fixed',
      bottom: 0,
      right: 0,
      background: '#fff',
      px: 6,
      py: 2,
      width: `calc(100% - ${offset}px)`,
      boxSizing: 'border-box'
    }),
    ...sx
  }}>
			<Typography variant="caption" color="rgba(0, 0, 0, 0.87)" sx={{
      mr: '5px'
    }}>
				{formatMessage({
        defaultMessage: 'Total {totalCount} items'
      }, {
        totalCount: totalCount
      })}
			</Typography>
			<Pagination size="small" shape="rounded" disabled={disabled} page={pageNo} count={Math.ceil(totalCount / pageSize)} onChange={handlePageNoChange} renderItem={item => {
      if (item.type === 'start-ellipsis') {
        return isStartEllipsis ? <ArrowIcon onMouseLeave={() => setIsStartEllipsis(false)} onClick={(e: any) => onEllipsisClick(e, item.type)} sx={{
          transform: 'rotate(180deg)',
          ...arrowStyle
        }} /> : <MoreIcon onMouseEnter={() => setIsStartEllipsis(true)} sx={moreStyle} />;
      } else if (item.type === 'end-ellipsis') {
        return isEndEllipsis ? <ArrowIcon onMouseLeave={() => setIsEndEllipsis(false)} onClick={(e: any) => onEllipsisClick(e, item.type)} sx={arrowStyle} /> : <MoreIcon onMouseEnter={() => setIsEndEllipsis(true)} sx={moreStyle} />;
      } else {
        return <PaginationItem {...item} />;
      }
    }} sx={{
      '& .MuiPaginationItem-root': {
        mx: '3px',
        minWidth: '30px',
        height: '30px',
        borderRadius: '6px',
        fontSize: '14px',
        color: 'primary.main',
        '&.Mui-disabled': {
          color: 'text.disabled'
        },
        '&.Mui-selected': {
          color: 'secondary.main',
          backgroundColor: 'secondary.bg',
          '&:hover': {
            backgroundColor: 'secondary.activeBg'
          },
          '&.Mui-disabled': {
            color: 'text.disabled',
            backgroundColor: 'line.line2'
          }
        },
        '&:hover': {
          backgroundColor: 'rgba(26, 30, 34, 0.06)'
        },
        '& .MuiPaginationItem-icon': {
          fontSize: '20px'
        },
        '&.MuiPaginationItem-ellipsis': {
          backgroundColor: 'fill.w'
        }
      },
      '& li:first-of-type, & li:last-child': {
        '& .MuiPaginationItem-root': {
          '&:hover': {
            backgroundColor: 'rgba(26, 30, 34, 0.06)'
          }
        }
      }
    }} />
			<TextField select variant="outlined" disabled={disabled} value={pageSize} onChange={onPageSizeChange} sx={{
      ml: 2,
      '& .MuiOutlinedInput-root': {
        borderRadius: '6px',
        height: '30px',
        '& .MuiSelect-select': {
          py: 0,
          pl: 1
        },
        '& input': {
          height: '30px',
          p: 0
        },
        '&.Mui-disabled': {
          '& .MuiSelect-select': {
            color: 'text.disabled',
            textFillColor: '#A29DAE'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'text.disabled'
          }
        },
        ...inputStyle
      }
    }}>
				{pageSizeOptions.map(i => <MenuItem key={i} value={i}>
						<FormattedMessage defaultMessage={'{num}/page'} values={{
          num: i
        }} />
					</MenuItem>)}
			</TextField>
			<Typography variant="caption" color="rgba(0, 0, 0, 0.87)" sx={{
      ml: 1,
      mr: '4px'
    }}>
				<FormattedMessage defaultMessage={'Go to'} />
			</Typography>
			<TextField variant="outlined" autoComplete="off" disabled={disabled} value={searchNo} onChange={(event: any) => setSearchNo(event.target.value)} onBlur={handleSearchNo} onKeyPress={event => {
      if (event.code === 'Enter') {
        handleSearchNo();
      }
    }} sx={{
      width: '48px',
      '& .MuiOutlinedInput-root': {
        borderRadius: '6px',
        '& input': {
          height: '30px',
          padding: '8px 12px'
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'text.disabled'
          }
        },
        ...inputStyle
      }
    }} />
		</Box>;
};
export default CustomPagination;