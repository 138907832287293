import loadComponent from '../loadComponent';
const contactRouter = [{
  path: 'contactList',
  element: loadComponent('contact/contactList')
}, {
  path: 'contactList/:search/:toolName',
  element: loadComponent('contact/contactList')
}, {
  path: 'upload',
  element: loadComponent('contact/contactList/user/UploadUser')
}, {
  path: 'growthTools',
  element: loadComponent('contact/growthTools')
}, {
  path: 'growthTools/myTools',
  element: loadComponent('contact/growthTools/myToolsPage')
}, {
  path: 'growthTools/analytics/:toolId/:toolName',
  element: loadComponent('contact/growthTools/analytics')
}, {
  path: 'growthTools/createEditTool/:typeFromUrl/:idFromUrl',
  element: loadComponent('contact/growthTools/createEditTool')
}, {
  path: 'unsubscribe',
  element: loadComponent('contact/unsubscribe')
}, {
  path: 'settings',
  element: loadComponent('contact/settings'),
  children: [{
    path: 'attributes',
    element: loadComponent('contact/settings/attributes')
  }, {
    path: 'tags',
    element: loadComponent('contact/settings/tags')
  }, {
    path: 'events',
    element: loadComponent('contact/settings/events')
  }]
}];
export default contactRouter;