import { GetChatBotListParams, DeleteChatBotParams, GetChatBotListParamsData, GetChatBotInfoByIdData, CreateChatBotParams, GetChatBotInfoByIdParams, GetChatbotQaListParams, AddQaParams, EditQaParams } from './type';
import { GET, POST } from 'src/utils/fetch';

export async function getChatBotList(params: GetChatBotListParams) {
	return GET('/api/chatbot/list', params, true, false) as Promise<GetChatBotListParamsData>;
}

export async function getChatBotInfoById(params: GetChatBotInfoByIdParams) {
	return GET('/api/chatbot/get', params, true, true) as Promise<GetChatBotInfoByIdData>;
}

export async function deleteChatBot(params: DeleteChatBotParams) {
	return POST('/api/chatbot/delete', params);
}

export async function createChatBot(params: CreateChatBotParams) {
	return POST('/api/chatbot/create', params);
}

export async function updateChatBot(params: any) {
	return POST('/api/chatbot/update', params);
}

export async function getChatbotQaList(params: GetChatbotQaListParams) {
	return GET('/api/chatbotAi/knowledge/qa/list', params, true);
}

export async function addQa(params: AddQaParams) {
	return POST('/api/chatbotAi/knowledge/qa/create', params);
}

export async function updateQa(params: EditQaParams) {
	return POST('/api/chatbotAi/knowledge/qa/update', params);
}

export async function updateQaStatus(params: any) {
	return POST('/api/chatbotAi/knowledge/qa/updateStatus', params);
}

export async function retryQa(params: any) {
	return POST('/api/chatbotAi/knowledge/qa/retry', params);
}

export async function retryWebsite(params: any) {
	return POST('/api/chatbotAi/knowledge/website/retry', params);
}

export async function deleteQa(params: any) {
	return POST('/api/chatbotAi/knowledge/website/delete', params);
}

export async function deleteWebsite(params: any) {
	return POST('/api/chatbotAi/knowledge/website/delete', params);
}

export async function getChatbotWebsiteList(params: any) {
	return GET('/api/chatbotAi/knowledge/website/list', params, true);
}

export async function addWebsite(params: any) {
	return POST('/api/chatbotAi/knowledge/website/batchAdd', params, true);
}

export async function getTrainList(params: any) {
	return GET('/api/chatbotAi/train/unknownQuestion/list', params, true);
}

export async function deleteTrainItem(params: any) {
	return POST('/api/chatbotAi/train/unknownQuestion/delete', params);
}

export async function getQaById(params: any) {
	return GET('/api/chatbotAi/knowledge/qa/get', params, true, true);
}

export async function getWebsiteById(params: any) {
	return GET('/api/chatbotAi/knowledge/website/get', params, true, true);
}
