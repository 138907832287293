import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { memberListParams, updateMemberParams, activeConfirmParams, newUserRegisterParams, addTenantParams, tenantSwitchParams, editAgentCapacityParams } from './type';
import { FactorsSwitchParams } from 'src/features/user/type';
import { getMemberList, addMember, editMember, delMember, resendMember, activeConfirm, newUserRegister, getTenantList, addTenant, tenantSwitch, setTenantFactorsSwitch, makeOwner, editAgentCapacity } from './memberAPI';

export interface MemberState {}

const initialState: MemberState = {};

export const getMemberListAsync = createAsyncThunk('member/list', async (params: memberListParams) => {
	const res = await getMemberList(params);
	return res;
});

export const addMemberAsync = createAsyncThunk('member/add', async (params: updateMemberParams) => {
	const res = await addMember(params);
	return res;
});

export const editMemberAsync = createAsyncThunk('member/edit', async (params: updateMemberParams) => {
	const res = await editMember(params);
	return res;
});

export const editAgentCapacityAsync = createAsyncThunk('member/editAgentCapacity', async (params: editAgentCapacityParams) => {
	const res = await editAgentCapacity(params);
	return res;
});

export const delMemberAsync = createAsyncThunk('member/del', async (params: updateMemberParams) => {
	const res = await delMember(params);
	return res;
});

export const resendMemberAsync = createAsyncThunk('member/resend', async (params: updateMemberParams) => {
	const res = await resendMember(params);
	return res;
});

export const activeConfirmAsync = createAsyncThunk('member/activeConfirm', async (params: activeConfirmParams, { rejectWithValue }) => {
	try {
		const res = await activeConfirm(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const newUserRegisterAsync = createAsyncThunk('member/newUserRegister', async (params: newUserRegisterParams, { rejectWithValue }) => {
	try {
		const res = await newUserRegister(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getTenantListAsync = createAsyncThunk('tenant/list', async () => {
	const res = await getTenantList();
	return res;
});

export const addTenantAsync = createAsyncThunk('tenant/add', async (params: addTenantParams) => {
	const res = await addTenant(params);
	return res;
});

export const tenantSwitchAsync = createAsyncThunk('tenant/switch', async (params: tenantSwitchParams, { rejectWithValue }) => {
	try {
		const res = await tenantSwitch(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const setTenantFactorsSwitchAsync = createAsyncThunk('tenant/factorsSwitch', async (params: FactorsSwitchParams, { rejectWithValue }) => {
	try {
		const res = await setTenantFactorsSwitch(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const makeMemberOwnerAsync = createAsyncThunk('member/makeOwner', async (params: updateMemberParams) => {
	const res = await makeOwner(params);
	return res;
});

export const memberSlice = createSlice({
	name: 'member',
	initialState,
	reducers: {},
	// extraReducers: (builder) => {
	//
	// },
});

export default memberSlice.reducer;
