import { useIntl } from 'react-intl';
import { Box, Tooltip, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { CustomStyleTooltip } from 'src/app/components/form/customTooltip';
import { stringOptions, timeOptions, numberOptions, timeNumArr, timeArr } from 'src/features/contact/contactList/type';
import { getOptionLabel, hasValue } from 'src/utils/tools';
import { itemHoverStyle, itemStyle, firstItemStyle, errorItemStyle } from './FilterItem';
import { useAppSelector } from 'src/app/hooks';
import { selectTagColorMapping } from 'src/features/contact/contactList/contactListSlice';
import useTheme from '@mui/material/styles/useTheme';
import { systemAttributeDisplayNameMap } from '../..';
import useSafetyIntl from 'src/hooks/useSafetyIntl';
let timer: any = null;
const FilterItemEdit: FunctionComponent<{
  openFilter: boolean;
  filterType: string;
  preFilterType: string;
  filterPosition: any;
  isNotOperate: boolean;
  itemIndex: number;
  index: number;
  item: any;
  onClick: (e: any, i: any, j: string, k: any) => void;
  onAddPop: (e: any, i: any) => void;
}> = ({
  openFilter,
  filterType,
  preFilterType,
  isNotOperate,
  filterPosition,
  itemIndex,
  index,
  item,
  onClick,
  onAddPop
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const theme = useTheme();
  const ref: any = useRef();
  const textRef: any = useRef();
  const [isCanHover, setIsCanHover] = useState<boolean>(false);
  const [isShowTip, setIsShowTip] = useState<boolean>(false);
  const tagColorMapping = useAppSelector(selectTagColorMapping);
  const {
    safetyFormatMessage
  } = useSafetyIntl();
  const handleHover = (e: boolean) => {
    let count = 0;
    if (e) {
      timer = setInterval(() => {
        count++;
        if (count > 2) {
          count = 0;
          setIsShowTip(true);
          clearInterval(timer);
          timer = null;
        }
      }, 1000);
    } else {
      count = 0;
      setIsShowTip(false);
      clearInterval(timer);
      timer = null;
    }
  };
  const handleDel = (event: any, isNotOperate: boolean) => {
    event.stopPropagation();
    if (isNotOperate) {
      onClick(null, '', 'del', itemIndex + '-' + index);
    }
  };
  const getItemValue = (e: any) => {
    if (!hasValue(e.value)) {
      return '';
    }
    if (item.type === 'Time') {
      if (e.operator === 'BETWEEN') {
        if (e.value) {
          const [start, end] = e.value.split(',');
          return (hasValue(start) ? format(Number(start), 'yyyy-MM-dd') : '') + (hasValue(start) ? '~' : '') + (hasValue(end) ? format(Number(end), 'yyyy-MM-dd') : '');
        }
      }
      if (timeArr.includes(item.operator)) {
        return format(e.value, 'yyyy-MM-dd');
      }
    }
    if (e.operator === 'BETWEEN' && item.type === 'Number') {
      if (e.value) {
        const [start, end] = e.value.split(',');
        return (hasValue(start) ? start : '') + (hasValue(start) ? '~' : '') + (hasValue(end) ? end : '');
      }
    }
    if (e.column === 'Tag') {
      return tagColorMapping?.[e.value]?.tag || '';
    }
    return e.value;
  };
  useEffect(() => {
    if (filterType === 'add' && filterPosition === itemIndex + '-' + index && ref?.current) {
      onAddPop(ref.current, item);
    }
    if (item && textRef?.current) {
      const textWidth = textRef.current.getBoundingClientRect()?.width;
      setIsCanHover(textWidth > 364);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, filterPosition, itemIndex, index, item]);
  return <Box ref={ref} sx={{
    ...(index === 0 ? firstItemStyle : itemStyle),
    ...(isCanHover && item.value && isShowTip && {
      maxWidth: '376px',
      p: '0 12px 0 0'
    }),
    ...(filterType === 'edit' && openFilter && Number(filterPosition.split('-')[0]) === itemIndex && Number(filterPosition.split('-')[1]) === index && itemHoverStyle),
    ...((!item.value && (!openFilter && filterType !== 'add' || openFilter && preFilterType !== 'add' && Number(filterPosition.split('-')[0]) === itemIndex && Number(filterPosition.split('-')[1]) === index) || item.column === 'Tag' && item.value && !tagColorMapping?.[item?.value]) && errorItemStyle),
    ...(isNotOperate && {
      cursor: 'default',
      color: '#A29DAE',
      '&:hover': {
        background: '#EFEDF5'
      }
    })
  }} onClick={event => !isNotOperate && onClick(event, item, 'edit', itemIndex + '-' + index)} onMouseEnter={() => !isNotOperate && handleHover(true)} onMouseLeave={() => !isNotOperate && handleHover(false)} className="notranslate">
			{isCanHover && item.value && isShowTip ? <CustomStyleTooltip title={<Typography variant="caption" component="div">
							{getOptionLabel([...timeOptions, ...stringOptions, ...numberOptions], item.operator).toLocaleLowerCase()}
							<Typography variant="caption" component="div">
								{getItemValue(item)}
							</Typography>
						</Typography>} placement="top" tooltipStyle={{
      transform: 'translateY(8px) !important'
    }}>
					<Typography ref={textRef} variant="subtitle2" component="div" sx={{
        p: '8px 12px',
        overflow: 'hidden'
      }}>
						{item.column}
						{' ' + getOptionLabel([...timeOptions, ...stringOptions, ...numberOptions], item.operator).toLocaleLowerCase()}
						<Typography variant="body1" component="span">
							{' '}
							{getItemValue(item)}
						</Typography>
						{item.type === 'Time' && timeNumArr.includes(item.operator) && formatMessage({
          defaultMessage: 'days ago'
        })}
					</Typography>
				</CustomStyleTooltip> : <Typography ref={textRef} variant="subtitle2" component="span">
					{safetyFormatMessage(systemAttributeDisplayNameMap[item.column as keyof typeof systemAttributeDisplayNameMap], item.column)}
					{item.value && !(item.column === 'Tag' && !tagColorMapping?.[item?.value]) && ' ' + getOptionLabel([...timeOptions, ...stringOptions, ...numberOptions], item.operator).toLocaleLowerCase()}{' '}
					{!item.value && (!openFilter && filterType !== 'add' || openFilter && preFilterType !== 'add' && Number(filterPosition.split('-')[0]) === itemIndex && Number(filterPosition.split('-')[1]) === index) || item.column === 'Tag' && item.value && !tagColorMapping?.[item?.value] ? ' ' + formatMessage({
        defaultMessage: 'is missing value'
      }) : <>
							<Typography variant="body1" component="span">
								{item.operator === 'IS_UNKNOWN' ? formatMessage({
            defaultMessage: 'is unknown'
          }) : getItemValue(item)}
							</Typography>
							{item.type === 'Time' && timeNumArr.includes(item.operator) && formatMessage({
          defaultMessage: 'days ago'
        })}
						</>}
				</Typography>}
			<Box onClick={event => handleDel(event, !isNotOperate)} className="delIcon" sx={{
      display: 'none',
      position: 'absolute',
      top: '8px',
      right: '12px'
    }}>
				<Box sx={{
        display: 'inline-block',
        width: '8px',
        height: '24px',
        background: !item.value && !openFilter && filterType !== 'add' || item.column === 'Tag' && item.value && !tagColorMapping?.[item?.value] ? 'linear-gradient(90deg, #FFE7D9 -11.53%, rgba(255, 231, 217, 0.61) 67.49%)' : `linear-gradient(90deg, rgba(223, 213, 251, 0) -11.53%, ${theme.palette.secondary.activeBg} 67.49%)`
      }}></Box>
				{!isNotOperate && <Tooltip arrow placement="top" title={formatMessage({
        defaultMessage: 'Remove this filter'
      })}>
						<CloseRoundedIcon sx={{
          fontSize: '24px'
        }} />
					</Tooltip>}
			</Box>
		</Box>;
};
export default FilterItemEdit;