import { CustomerType } from 'src/features/user/type';
import loadComponent from '../loadComponent';
const billingRouter = [{
  path: 'overview',
  element: loadComponent('billing/overview'),
  roles: ['ADMIN']
}, {
  path: 'buyCredits',
  element: loadComponent('billing/buyCredits'),
  unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER]
}, {
  path: 'logs',
  element: loadComponent('billing/logs'),
  roles: ['ADMIN'],
  unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER]
}, {
  path: 'notification',
  element: loadComponent('billing/notification'),
  roles: ['ADMIN'],
  unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER]
}];
export default billingRouter;