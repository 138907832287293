import { Chip } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { isEmpty } from 'lodash-es';
import { defineMessages } from 'react-intl';
export const colorMapping = {
  '176,35,84': {
    r: 176,
    g: 35,
    b: 84,
    labelColor: 'rgba(253, 76, 125, 1)',
    background: 'rgb(255, 240, 241)'
  },
  '173,104,0': {
    r: 173,
    g: 104,
    b: 0,
    labelColor: 'rgba(255, 214, 102, 1)',
    background: 'rgb(254, 246, 227)'
  },
  '0,110,80': {
    r: 0,
    g: 110,
    b: 80,
    labelColor: 'rgba(17, 187, 130, 1)',
    background: 'rgb(234, 249, 244)'
  },
  '16,35,158': {
    r: 16,
    g: 35,
    b: 158,
    labelColor: 'rgba(68, 87, 255, 1)',
    background: 'rgb(234, 238, 253)'
  },
  '58,27,142': {
    r: 58,
    g: 27,
    b: 142,
    labelColor: 'rgba(96, 45, 237, 1)',
    background: 'rgb(239, 234, 253)'
  },
  '94,89,106': {
    r: 94,
    g: 89,
    b: 106,
    labelColor: 'rgba(137, 131, 149, 1)',
    background: 'rgb(239, 237, 245)'
  }
};
export const colorChipLabelMap = defineMessages({
  '176,35,84': {
    defaultMessage: 'Pink'
  },
  '173,104,0': {
    defaultMessage: 'Yellow'
  },
  '0,110,80': {
    defaultMessage: 'Green'
  },
  '16,35,158': {
    defaultMessage: 'Blue'
  },
  '58,27,142': {
    defaultMessage: 'Purple'
  },
  '94,89,106': {
    defaultMessage: 'Grey'
  }
});
export const tagColors = Object.values(colorMapping).map((v: any) => ({
  r: v.r,
  g: v.g,
  b: v.b
}));
const getTagStyle = (v: any) => {
  let key = '';
  if (isEmpty(v)) {
    key = '58,27,142';
  } else {
    key = `${v?.r},${v?.g},${v?.b}`;
    if (!Object.keys(colorMapping).includes(key)) {
      key = '58,27,142';
    }
  }
  const current = colorMapping?.[key as keyof typeof colorMapping];
  return {
    fontSize: '13px',
    lineHeight: '18px',
    my: '3px!important',
    px: '8px',
    py: '3px',
    color: `rgba(${key}, 1)`,
    background: current.background,
    '&:hover': {
      color: `rgba(${key}, 1)`,
      background: current.background
    },
    '& .MuiChip-label': {
      p: 0
    },
    '& .MuiChip-deleteIcon': {
      mx: '4px',
      color: `rgba(${key}, 0.3)`,
      '&:hover': {
        color: `rgba(${key}, 0.5)`
      }
    }
  };
};
const ColorChip: FunctionComponent<any> = ({
  value,
  sx,
  ...props
}) => {
  return <Chip size="small" {...props} sx={{
    fontWeight: '500',
    ...getTagStyle(value || tagColors[Math.floor(Math.random() * 6)]),
    ...sx
  }} />;
};
export default ColorChip;