import { Tooltip, Box } from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { styled } from '@mui/material/styles';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { FunctionComponent, ReactNode, Fragment } from 'react';
export const CustomStyleTooltip: FunctionComponent<{
  title?: any;
  placement?: 'right' | 'top' | 'bottom' | 'left' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | undefined;
  children?: any;
  arrow?: boolean;
  followCursor?: boolean;
  tooltipStyle?: any;
  PopperProps?: object;
  showTooltip?: boolean;
  hasMaxHeight?: boolean;
}> = ({
  title,
  placement = 'top',
  PopperProps = {},
  children,
  arrow = true,
  followCursor = false,
  tooltipStyle,
  showTooltip = true,
  hasMaxHeight = true
}) => {
  const CustomTooltip = styled(({
    className,
    ...props
  }: TooltipProps) => <Tooltip {...props} classes={{
    popper: className
  }} />)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      ...(tooltipStyle && tooltipStyle)
    }
  }));
  if (!showTooltip) {
    return <>{children}</>;
  }
  return <CustomTooltip title={title && <Box sx={hasMaxHeight ? {
    whiteSpace: 'pre-line',
    maxHeight: '400px',
    overflowY: 'auto'
  } : {}}>
						{title}
					</Box>} PopperProps={PopperProps} placement={placement} arrow={arrow} followCursor={followCursor}>
			{children}
		</CustomTooltip>;
};
const CustomTooltip: FunctionComponent<{
  tip: any;
  sx?: any;
  error?: boolean;
  PopperProps?: object;
  maxWidth?: string;
  icon?: ReactNode;
  placement?: 'right' | 'top' | 'bottom' | 'left' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | undefined;
  iconStyle?: any;
}> = ({
  tip,
  sx = {},
  icon,
  PopperProps = {},
  maxWidth = undefined,
  placement = 'top',
  error = false,
  iconStyle = {}
}) => {
  const lineArr = typeof tip === 'string' ? tip.split('\\n') : tip;
  const TooltipWithMaxWidth = styled(({
    className,
    ...props
  }: TooltipProps) => <Tooltip {...props} classes={{
    popper: className
  }} />)(() => ({
    ...(maxWidth ? {
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth
      }
    } : {})
  }));
  return <TooltipWithMaxWidth arrow color={error ? 'error' : 'primary'} PopperProps={PopperProps} title={<Box sx={{
    whiteSpace: 'pre-line',
    maxHeight: '200px',
    overflowY: 'auto'
  }}>
					{typeof tip === 'string' ? lineArr.map((line: string, index: number) => <Fragment key={index}>
									<span>{line}</span>
									{index < line.length - 1 ? <br /> : null}
								</Fragment>) : tip}
				</Box>} placement={placement}>
			<Box component="span" sx={{
      color: '#babbbd',
      cursor: 'pointer',
      marginLeft: '4px',
      ...sx
    }}>
				{icon ? icon : error ? <InfoRoundedIcon color="error" sx={{
        fontSize: '16px',
        verticalAlign: 'text-top'
      }} /> : <HelpRoundedIcon sx={{
        fontSize: '16px',
        verticalAlign: 'text-top',
        ...iconStyle
      }} />}
			</Box>
		</TooltipWithMaxWidth>;
};
export default CustomTooltip;