import { FormattedMessage, useIntl } from 'react-intl';
import { Stack, Box, Typography, Divider, Chip } from '@mui/material';
import { FunctionComponent } from 'react';
import InfoItem from 'src/app/components/infoItem';
import { formatDateTime } from 'src/utils/transform';
import ColorChip from 'src/app/components/colorChip';
import { tipMap } from './UserForm';
const UserInfo: FunctionComponent<{
  info: any;
  flag: any;
  tag: Array<any>;
  customAttrs: Array<any>;
}> = ({
  info,
  flag,
  tag,
  customAttrs
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const infoLabelStyle = {
    fontSize: '14px',
    wordWrap: 'break-word'
  };
  const infoValueStyle = {
    ...infoLabelStyle,
    pt: '1px',
    mb: '9px'
  };
  return <Stack spacing={1}>
			<Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '40px'
    }}>
				<Typography variant="subtitle1">
					<FormattedMessage defaultMessage={'System attributes'} />
				</Typography>
				<Box>
					<Typography variant="subtitle2" component="span" sx={{
          mr: 1
        }}>
						<FormattedMessage defaultMessage={'ID'} />
					</Typography>
					<Typography variant="body1" component="span">
						{info.contactId}
					</Typography>
				</Box>
			</Box>
			<Divider variant="middle" sx={{
      m: 0,
      color: 'line.line2'
    }} />
			{info.Nickname && <InfoItem label={formatMessage({
      defaultMessage: 'Nickname'
    })} labelStyle={infoLabelStyle} value={info.Nickname} valueColor="#212B36" valueStyle={infoValueStyle} />}
			{info.Owner && <InfoItem tooltip={formatMessage(tipMap['owner'])} label={formatMessage({
      defaultMessage: 'Owner'
    })} labelStyle={infoLabelStyle} value={info.Owner} valueColor="#212B36" valueStyle={infoValueStyle} />}
			{info['Phone number'] && <InfoItem label={formatMessage({
      defaultMessage: 'Phone number'
    })} labelStyle={infoLabelStyle} value={flag + ' ' + info['Phone number']} valueColor="#212B36" valueStyle={infoValueStyle} />}
			{info.Email && <InfoItem label={formatMessage({
      defaultMessage: 'Email'
    })} labelStyle={infoLabelStyle} value={info.Email} valueColor="#212B36" valueStyle={infoValueStyle} />}
			{tag && tag.length > 0 && <InfoItem label={formatMessage({
      defaultMessage: 'Tag'
    })} labelStyle={infoLabelStyle} value={<>
							{tag.filter((item: any) => !!item.tag).map((item: any) => <ColorChip key={item.tag} label={item.tag} size="small" value={item.color} sx={{
        mr: '6px',
        mt: '0px !important',
        mb: '8px !important',
        '& .MuiChip-label': {
          px: '11px'
        }
      }} />)}
						</>} valueStyle={{
      mb: 2
    }} boxStyle={{
      '& .MuiChip-root:last-child': {
        mr: 0
      }
    }} />}
			{customAttrs && customAttrs.length > 0 && customAttrs.filter((e: any) => Array.isArray(e.value) ? e.value.length : e.value === 0 || Boolean(e.value)).length > 0 && <>
					<Typography variant="subtitle1" sx={{
        py: '4px'
      }}>
						<FormattedMessage defaultMessage={'Customized attributes'} />
					</Typography>
					<Divider variant="middle" sx={{
        m: 0,
        color: 'line.line2'
      }} />
					{customAttrs.filter((e: any) => e.value || e.value === 0).map((e: any) => e.displayType === 'Array' ? Array.isArray(e.value) && e.value.length > 0 && <InfoItem label={e.displayName} labelStyle={infoLabelStyle} key={e.id} value={<>
												{e.value?.map((t: any) => <Chip key={t} label={t} size="small" sx={{
          mr: '6px',
          mt: '0px !important',
          mb: '8px !important',
          '& .MuiChip-label': {
            px: '11px'
          }
        }} />)}
											</>} valueStyle={{
        mb: 2
      }} boxStyle={{
        '& .MuiChip-root:last-child': {
          mr: 0
        }
      }} /> : <InfoItem key={e.displayName} label={e.displayName} labelStyle={infoLabelStyle} value={e.displayType && e.displayType === 'Time' ? formatDateTime(e.value) : e.value} valueColor="#212B36" valueStyle={infoValueStyle} valueMaxWidth={392} valueMaxLine={3} />)}
				</>}
		</Stack>;
};
export default UserInfo;