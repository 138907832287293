import { Box, Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import ImageContainer from 'src/app/components/imageContainer';
import searchEmpty from 'src/app/images/searchEmpty.png';
import searchEmpty_neutral from 'src/app/images/searchEmpty_neutral.png';
import { useAppSelector } from 'src/app/hooks';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
import { useIntl } from 'react-intl';
const NoSearchData: FunctionComponent<{
  sx?: any;
  desc?: string;
  footer?: ReactNode;
}> = ({
  sx,
  desc,
  footer
}) => {
  const {
    formatMessage
  } = useIntl();
  if (desc === undefined) {
    desc === formatMessage({
      defaultMessage: 'No results'
    });
  }
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  return <Box sx={{
    pt: '107px',
    ...(sx && sx)
  }}>
			<ImageContainer backgroundImage={isOtherBrand ? searchEmpty_neutral : searchEmpty} sx={{
      width: '220px',
      height: '220px',
      margin: 'auto'
    }} />
			<Typography variant="body1" color="rgba(0, 0, 0, 0.85)" sx={{
      mt: 2,
      textAlign: 'center'
    }}>
				{desc}
			</Typography>
			{footer && <Box sx={{
      textAlign: 'center',
      mt: 2
    }}>{footer}</Box>}
		</Box>;
};
export default NoSearchData;