import { POST } from 'src/utils/fetch';
import { GetSendCountryListParams, GetSmsOverviewSummaryParams, GetSmsOverviewParams } from './type';

export async function getSendCountryList(params: GetSendCountryListParams) {
	return POST('/api/sms/statistics/history/area/search', params, true, true);
}

export async function getSmsOverviewSummary(params: GetSmsOverviewSummaryParams) {
	return POST('/api/sms/statistics/history/summary', params, true, true);
}

export async function getSmsOverviewData(params: GetSmsOverviewParams) {
	return POST('/api/sms/statistics/history/search', params, true, true);
}

export async function getSmsLogs(params: any) {
	return POST('/api/sms/search', params, true, false);
}
