import { GET, POST } from 'src/utils/fetch';
import {
	GetInboxOverviewDataParams,
	GetInboxOverviewDataRes,
	GetAgentRankingParams,
	GetAgentRankingRes,
	GetAgentOverviewDataRes,
	GetAgentOverviewDataParams,
	GetOverviewDataParams,
	GetOverviewDataRes,
	GetConversationsByAgentsParams,
	GetConversationsByAgentsDataRes,
	GetTeamsRankingParams,
	GetTeamOverviewDataParams,
	GetCsatLogsParams,
} from './type';

export async function getOverview(params: GetOverviewDataParams) {
	return GET('/api/inbox/statistics/overview', params, true) as Promise<GetOverviewDataRes>;
}

export async function getAgentOverviewData(params: GetAgentOverviewDataParams) {
	return POST('/api/inbox/statistics/agents/overview', params, true) as Promise<GetAgentOverviewDataRes>;
}

export async function getInboxOverviewData(params: GetInboxOverviewDataParams) {
	return POST('/api/inbox/statistics/inbox/overview', params, true) as Promise<GetInboxOverviewDataRes>;
}

export async function getAgentsRanking(params: GetAgentRankingParams) {
	return POST('/api/inbox/statistics/agents/ranking', params, true) as Promise<GetAgentRankingRes>;
}

export async function getTeamOverviewData(params: GetTeamOverviewDataParams) {
	return POST('/api/inbox/statistics/teams/overview', params, true) as Promise<GetInboxOverviewDataRes>;
}

export async function getTeamsRanking(params: GetTeamsRankingParams) {
	return POST('/api/inbox/statistics/teams/ranking', params, true) as Promise<GetAgentRankingRes>;
}

export async function getConversationsByAgents(params: GetConversationsByAgentsParams) {
	return GET('/api/inbox/statistics/overview/conversationsByAgents', params, true) as Promise<GetConversationsByAgentsDataRes>;
}

export async function getAnalyticsTeams(params: GetTeamsRankingParams) {
	return POST('/api/inbox/statistics/teams/all', params, true, false);
}

export async function getCsatLogs(params: GetCsatLogsParams) {
	return GET('/api/inbox/csat/ratings/list', params, true, false);
}

export async function exportCSATLogs(params: GetCsatLogsParams) {
	return POST('/api/inbox/csat/ratings/export', params, true, false);
}
