import { Autocomplete, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { useAppSelector } from 'src/app/hooks';
import { selectTagColor, selectTagColorMapping } from 'src/features/contact/contactList/contactListSlice';
const TagSelect: FunctionComponent<{
  value: string;
  onChange: (e: any) => void;
}> = ({
  value,
  onChange
}) => {
  const allTagColor = useAppSelector(selectTagColor);
  const tagColorMapping = useAppSelector(selectTagColorMapping);
  return <Autocomplete disableClearable options={allTagColor} size="small" renderInput={params => <TextField {...params} autoFocus={true} size="small" />} value={tagColorMapping?.[value] || null} onChange={(event: any, newValue: any) => {
    onChange(newValue?.id);
  }} getOptionLabel={option => option.tag ? option.tag : ''} filterSelectedOptions isOptionEqualToValue={(option: any, myValue: any) => {
    return option.id === myValue.id;
  }} sx={{
    width: '212px',
    ml: '36px'
  }} />;
};
export default TagSelect;