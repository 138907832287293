import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

export interface BotState {
	tasks: { activityId: string; uploadTaskId: string }[];
}

const getTasks = () => {
	return localStorage.getItem('tasks') ? JSON.parse(localStorage.getItem('tasks') as string) : [];
};

const initialState: BotState = {
	tasks: getTasks(),
};

export const selectTasks = (state: RootState) => state.coupon.tasks || [];

export const couponSlice = createSlice({
	name: 'coupon',
	initialState,
	reducers: {
		addTask: (state, action) => {
			const newTasks = [...getTasks(), action.payload];
			localStorage.setItem('tasks', JSON.stringify(newTasks));
			state.tasks = newTasks;
		},
		removeTask: (state, action) => {
			const task = action.payload;
			const newTasks = getTasks().filter((item: any) => item.activityId !== task.activityId && item.uploadTaskId !== task.uploadTaskId);
			localStorage.setItem('tasks', JSON.stringify(newTasks));
			state.tasks = newTasks;
		},
	},
});

export const { addTask, removeTask } = couponSlice.actions;

export default couponSlice.reducer;
