import { useIntl } from 'react-intl';
import { Box, TextField, FormHelperText } from '@mui/material';
import { useRef, forwardRef, useState, useEffect } from 'react';
import CountrySelect from 'src/app/components/contrySelect';
import { outlinedInputErrorStyle } from 'src/app/components/commonStyle';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { useAppSelector } from 'src/app/hooks';
import { selectCountryCodes } from 'src/features/user/userSlice';
interface IProps {
  size?: 'small' | 'medium';
  autoFocus?: boolean;
  areaNo?: string;
  value: string;
  disableClearable?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: any | undefined;
  onChange?: (...event: any[]) => void;
  showFlag?: boolean;
  boxStyle?: any;
}
export type Ref = any;
const CountryPhone = forwardRef<Ref, IProps>(({
  size = 'small',
  autoFocus,
  areaNo,
  value,
  disableClearable = false,
  disabled = false,
  error = false,
  helperText,
  onChange,
  showFlag = true,
  boxStyle
}, ref) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const countryCodes = useAppSelector(selectCountryCodes);
  const anchorEl = useRef();
  const [popWidth, setPopWidth] = useState<string>('');
  const [selectValue, setSelectValue] = useState<any | null>(null);
  const [inputValue, setInputValue] = useState<any>('');
  const changeCountry = (e: any) => {
    setSelectValue(e);
    const countryCode = e ? e.countryCode : '';
    const recipient = e ? e.callingCode : '';
    const newPhoneNum = `${recipient}:${inputValue}`;
    if (onChange) {
      onChange({
        mobile: newPhoneNum,
        areaNo: countryCode
      });
    }
  };
  const changeNumber = (e: any) => {
    const newNum = e.target.value;
    setInputValue(newNum);
    const countryCode = selectValue ? selectValue.countryCode : '';
    const recipient = selectValue ? selectValue.callingCode : '';
    const newPhoneNum = `${recipient}:${newNum}`;
    if (onChange) {
      onChange({
        mobile: newPhoneNum,
        areaNo: countryCode
      });
    }
  };
  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        const valueArr = value.split(':');
        let targetCountry: any;
        if (areaNo) {
          targetCountry = countryCodes.filter(i => i.callingCode === Number(valueArr[0]) && i.countryCode === areaNo);
        } else {
          targetCountry = countryCodes.filter(i => i.callingCode === Number(valueArr[0]));
        }
        if (targetCountry.length > 0) {
          setSelectValue(targetCountry[0]);
          setInputValue(valueArr[1]);
        }
      }
    } else {
      setSelectValue(null);
      setInputValue('');
    }
  }, [areaNo, value, countryCodes]);
  useEffect(() => {
    const el: any = anchorEl?.current;
    if (el) {
      setPopWidth(el.clientWidth + 'px');
    }
  }, [anchorEl]);
  return <Box ref={ref}>
			<Box ref={anchorEl} sx={{
      display: 'flex',
      ...(boxStyle && boxStyle)
    }}>
				<CountrySelect size={size} popWidth={popWidth} disableClearable={disableClearable} disabled={disabled} value={selectValue} getOptionLabel={option => `${showFlag && option?.countryFlag ? option?.countryFlag : ''}  +${option?.callingCode}`} onChange={(event: any, newValue: any | null) => changeCountry(newValue)} cssStyle={{
        width: '35%',
        '& .MuiAutocomplete-inputRoot': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          pr: '20px !important',
          '& .MuiAutocomplete-input': {
            py: '7px !important'
          }
        },
        ...(error && outlinedInputErrorStyle)
      }} />
				<TextField size={size} variant="outlined" autoComplete="off" disabled={disabled} autoFocus={autoFocus} value={inputValue} placeholder={formatMessage({
        defaultMessage: 'Phone number'
      })} onChange={(event: any) => changeNumber(event)} sx={{
        flexGrow: 1,
        '& .MuiInputBase-root': {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            ...(error && {
              border: '2px solid #FF4842'
            }),
            borderLeftWidth: 0
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              ...(error && {
                border: '2px solid #FF4842'
              }),
              borderLeftWidth: error ? 0 : 1
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderLeftWidth: error ? 0 : 2
            }
          }
        }
      }} />
			</Box>
			{error && <FormHelperText error={error}>
					<ErrorHelperText>{helperText}</ErrorHelperText>
				</FormHelperText>}
		</Box>;
});
export default CountryPhone;