import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Popover, Typography, Checkbox, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { systemAttributeDisplayNameMap } from '..';
import useSafetyIntl from 'src/hooks/useSafetyIntl';
interface IProps {
  open: boolean;
  onClose: () => void;
  authorEl: Element;
  sourceColumnData: Array<any>;
  onChange: (keys: string[]) => void;
}
export default function FilterFieldPopover({
  open,
  onClose,
  authorEl,
  sourceColumnData = [],
  onChange
}: IProps) {
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [data, setData] = useState<{
    name: string;
    data: any[];
  }[]>([]);
  const handleChangeCheckedKeys = (event: any) => {
    let keys = [];
    if (event.target.checked) {
      keys = [...checkedKeys, event.target.name];
    } else {
      keys = [...checkedKeys.filter(item => item !== event.target.name)];
    }
    setCheckedKeys(keys);
    onChange(keys);
  };
  useEffect(() => {
    if (sourceColumnData.length > 0 && checkedKeys.length === 0) {
      setCheckedKeys([...sourceColumnData.filter(item => item.isShown !== false).map(item => item.id)]);
    }
  }, [sourceColumnData, checkedKeys]);
  const {
    formatMessage
  } = useIntl();
  useEffect(() => {
    const newData = [{
      name: formatMessage({
        defaultMessage: 'System attributes'
      }),
      data: sourceColumnData.filter(item => !item.isCustomAttr)
    }, {
      name: formatMessage({
        defaultMessage: 'Customized attributes'
      }),
      data: sourceColumnData.filter(item => item.isCustomAttr)
    }];
    setData(newData);
  }, [sourceColumnData]);
  const {
    safetyFormatMessage
  } = useSafetyIntl();
  return <Popover open={open} anchorEl={authorEl} onClose={onClose} anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right'
  }} transformOrigin={{
    vertical: 'top',
    horizontal: 'right'
  }}>
			<Box sx={{
      width: '272px',
      maxHeight: '300px',
      overflowY: 'scroll'
    }}>
				<Typography sx={{
        padding: '9px 16px'
      }} variant="body1">
					<FormattedMessage defaultMessage={'Set Displayed attributes'} />
				</Typography>
				{data.filter(item => item.data.length > 0).map(group => <Box key={group.name}>
							<Typography sx={{
          padding: '9px 16px',
          color: 'text.disabled'
        }} variant="body1">
								{group.name}
							</Typography>
							<Box>
								{group.data.map(item => <Box key={item.id} sx={{
            paddingLeft: '4px',
            display: 'flex',
            alignItems: 'center',
            pr: 1
          }}>
										<Checkbox disabled={item.displayName === 'Phone number'} checked={checkedKeys.includes(item.id as string)} onChange={handleChangeCheckedKeys} name={item.id} />
										<Box sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
											<Tooltip title={item.displayName} arrow>
												<Typography variant="body1" component="span">
													{safetyFormatMessage(systemAttributeDisplayNameMap[item.displayName as keyof typeof systemAttributeDisplayNameMap], item.displayName)}
												</Typography>
											</Tooltip>
										</Box>
									</Box>)}
							</Box>
						</Box>)}
			</Box>
		</Popover>;
}