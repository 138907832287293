import { FormattedMessage, useIntl } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BoxWithHandle } from './BoxWithHandle';
import { ListWithHandle } from './ListWithHandle';
import React, { FunctionComponent, useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Box, Stack } from '@mui/material';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { cloneDeep, isEmpty, isUndefined, omit } from 'lodash-es';
import { CustomLabel } from 'src/app/components/form';
const ListDrag: FunctionComponent<{
  [index: string]: any;
}> = ({
  ...props
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const [sections, setSections] = useState<any>([]);
  const hasRow = useMemo(() => {
    return sections.some((section: any) => !isEmpty(section?.rows));
  }, [sections]);
  const hasDescription = useMemo(() => {
    return sections.some((section: any) => section.rows.some((item: any) => !isUndefined(item?.description)));
  }, [sections]);
  useEffect(() => {
    if (!isEmpty(props.value)) {
      const fillDescription = props.value.some((section: any) => section.rows.some((item: any) => !isUndefined(item?.description)));
      if (fillDescription) {
        const newSections = props.value.map((section: any) => ({
          ...section,
          rows: section.rows.map((child: any) => ({
            description: '',
            ...child
          }))
        }));
        setSections(newSections);
        props.onChange(newSections);
      } else {
        setSections(props.value);
      }
    } else {
      const defaultList: any = [{
        title: '',
        rows: [{
          title: '',
          handleId: '-1'
        }],
        sectionId: '-1'
      }];
      setSections(defaultList);
      props.onChange(defaultList);
    }
  }, []);
  const error = props.error;
  const helperText = props.helperText;
  const helperTextList = error === 'required' && helperText ? helperText.split('-') : [];
  const requiredTitleHelperText = error === 'titleRequired' && helperText ? helperText.split('-') : [];
  const rowRequiredList = error === 'rowRequired' && helperText ? helperText.split('-') : [];
  let maxSectionIdNumber = sections?.length ? Math.max.apply(null, sections.map((item: any) => Number(item.sectionId))) : 0;
  const handleListMove = useCallback((dragIndex: number, hoverIndex: number, dragSectionIndex: number, itemId: string) => {
    if (dragSectionIndex !== undefined) {
      if (itemId) {
        // 做一下index相关的修正，有的情况会出现错乱。。
        for (let i = 0; i < sections.length; i++) {
          for (let j = 0; j < sections[i].rows.length; j++) {
            if (sections[i].rows[j].handleId === itemId) {
              dragSectionIndex = i;
              dragIndex = j;
              break;
            }
          }
        }
      }
      // 说明是拖拽一个item到一个空的列表
      setSections((prevCards: any) => {
        const newSections = cloneDeep(prevCards);
        newSections[dragSectionIndex].rows.splice(dragIndex, 1);
        newSections[hoverIndex].rows.push(prevCards[dragSectionIndex].rows[dragIndex]);
        props.onChange(newSections);
        return newSections;
      });
    } else {
      setSections((prevCards: any) => {
        const newSections = cloneDeep(prevCards);
        newSections.splice(dragIndex, 1);
        newSections.splice(hoverIndex, 0, prevCards[dragIndex]);
        props.onChange(newSections);
        return newSections;
      });
    }
  }, [sections]);
  const handleMove = useCallback((dragIndex: number, hoverIndex: number, dragSectionIndex: number, hoverSectionIndex: number) => {
    if (dragSectionIndex === undefined || hoverSectionIndex === undefined) {
      return;
    }
    setSections((prevCards: any) => {
      // box move 1 0 1 1持续运行
      const newSections: any = cloneDeep(prevCards);
      const deletes = newSections[dragSectionIndex].rows.splice(dragIndex, 1);
      if (deletes.length) {
        newSections[hoverSectionIndex].rows.splice(hoverIndex, 0, cloneDeep(deletes[0]));
      }
      props.onChange(newSections);
      return newSections;
    });
  }, []);
  const handleAddSection = () => {
    setSections((prevCards: any) => {
      const newSections: any = [...prevCards, {
        title: '',
        sectionId: `${maxSectionIdNumber + 1}`,
        rows: []
      }];
      maxSectionIdNumber = maxSectionIdNumber + 1;
      props.onChange(newSections);
      return newSections;
    });
  };
  const handleAddItem = useCallback((sectionIndex: number) => {
    setSections((prevCards: any) => {
      const newSections: any = [...prevCards];
      newSections[sectionIndex].rows.push({
        title: '',
        ...(hasDescription && {
          description: ''
        }),
        handleId: `${props.maxNodeHandleNumber + 1}`
      });
      props.onChange(newSections);
      props.onAddButton();
      return newSections;
    });
  }, [props.maxNodeHandleNumber, hasDescription]);
  const handleValueChange = useCallback((index: number, newValue: string, sectionIndex: number) => {
    setSections((prevCards: any) => {
      const newSections: any = [...prevCards];
      newSections[sectionIndex].rows[index].title = newValue;
      props.onChange(newSections);
      return newSections;
    });
  }, []);
  const handleDescChange = useCallback((index: number, newValue: string, sectionIndex: number) => {
    setSections((prevCards: any) => {
      const newSections: any = [...prevCards];
      newSections[sectionIndex].rows[index].description = newValue;
      props.onChange(newSections);
      return newSections;
    });
  }, []);
  const handleDelete = useCallback((deleteIndex: number, sectionIndex: number) => {
    setSections((prevCards: any) => {
      const newSections: any = [...prevCards];
      newSections[sectionIndex].rows.splice(deleteIndex, 1);
      props.onChange(newSections);
      return newSections;
    });
  }, []);
  const handleListValueChange = (listIndex: number, newValue: string) => {
    setSections((prevCards: any) => {
      const newSections: any = [...prevCards];
      newSections[listIndex].title = newValue;
      props.onChange(newSections);
      return newSections;
    });
  };
  const handleListDelete = useCallback((listIndex: number) => {
    setSections((prevCards: any) => {
      const newSections: any = [...prevCards];
      newSections.splice(listIndex, 1);
      props.onChange(newSections);
      return newSections;
    });
  }, []);
  const renderItem = useCallback((row: any, rowIndex: number, sectionIndex: number) => {
    const myIndex = `${sectionIndex}_${rowIndex}`;
    return <BoxWithHandle onDelete={handleDelete} error={Boolean(helperTextList.includes(myIndex))} onValueChange={handleValueChange}
    // descVisible={descVisible}
    onBlur={props.onBlur} sectionIndex={sectionIndex} index={rowIndex} myIndex={rowIndex} mySectionIndex={sectionIndex} moveCard={handleMove} text={row.title} onDescChange={handleDescChange} desc={row.description} id={row.handleId} key={row.handleId} />;
    // }, [helperTextList, descVisible])
  }, [helperTextList]);
  const renderList = useCallback((item: any, sectionIndex: number) => {
    let totalRowNumber = 0;
    sections.forEach((section: any) => {
      totalRowNumber += section.rows?.length || 0;
    });
    return <ListWithHandle id={item.sectionId} index={sectionIndex} myIndex={sectionIndex} key={item.sectionId} onBlur={props.onBlur} sections={sections} onAddItem={handleAddItem} onDelete={handleListDelete} text={item.title} disabledAdd={totalRowNumber >= 10} error={requiredTitleHelperText.includes(`${sectionIndex}`) || rowRequiredList.includes(`${sectionIndex}`) ? error : ''} onValueChange={handleListValueChange} moveCard={handleListMove}>
					<Stack spacing={1}>
						{item.rows.map((row: any, rowIndex: number) => {
          return renderItem(row, rowIndex, sectionIndex);
        })}
					</Stack>
				</ListWithHandle>;
  }, [requiredTitleHelperText, sections, rowRequiredList]);
  const addDescription = () => {
    const newSections = cloneDeep(sections).map((section: any) => ({
      ...section,
      rows: (section.rows || []).map((item: any) => ({
        ...item,
        description: ''
      }))
    }));
    setSections(newSections);
    props.onChange(newSections);
  };
  const deleteDescription = () => {
    const newSections = cloneDeep(sections).map((section: any) => ({
      ...section,
      rows: (section.rows || []).map((item: any) => omit(item, ['description']))
    }));
    setSections(newSections);
    props.onChange(newSections);
  };
  return <>
			<Stack spacing={1} direction={'row'} sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 1
    }}>
				<CustomLabel>
					<FormattedMessage defaultMessage={'Sections and rows'} />
				</CustomLabel>

				{hasRow && <>
						{hasDescription ? <Button color="error" variant="text" onClick={deleteDescription}>
								{formatMessage({
            defaultMessage: 'Delete description'
          })}
							</Button> : <Button color="secondary" variant="text" onClick={addDescription}>
								{formatMessage({
            defaultMessage: 'Add description'
          })}
							</Button>}
					</>}
			</Stack>

			<DndProvider backend={HTML5Backend}>
				<Stack spacing={1}>
					{sections.map((listItem: any, sectionIndex: number) => {
          return renderList(listItem, sectionIndex);
        })}
					<Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
						<Button color="secondary" startIcon={<AddRoundedIcon />} variant="text" disabled={sections?.length >= 10} onClick={handleAddSection}>
							{formatMessage({
              defaultMessage: 'Add section'
            })}
						</Button>
					</Box>
				</Stack>
				{error === 'required' && !helperText && <ErrorHelperText>
						{formatMessage({
          defaultMessage: 'Required'
        })}
					</ErrorHelperText>}
			</DndProvider>
		</>;
};
export default ListDrag;