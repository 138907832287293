export function rgbaToHexWithOpacity(
	rgbColor: {
		r: number;
		g: number;
		b: number;
	},
	a = 1,
) {
	// 提取RGB通道和透明度
	const { r, g, b } = rgbColor;

	// 融合RGB通道和透明度
	const blendedR = Math.round(r + (1 - a) * (255 - r));
	const blendedG = Math.round(g + (1 - a) * (255 - g));
	const blendedB = Math.round(b + (1 - a) * (255 - b));

	// 转换为十六进制
	const hexColor = `#${((1 << 24) | (blendedR << 16) | (blendedG << 8) | blendedB).toString(16).slice(1).toUpperCase()}`;

	return hexColor;
}

export const convertStringColorToObj = (color?: string) => {
	if (!color) {
		return {
			r: 0,
			g: 0,
			b: 0,
		};
	}

	const rgb = color.split(',');
	return {
		r: parseInt(rgb[0]),
		g: parseInt(rgb[1]),
		b: parseInt(rgb[2]),
	};
};

export function darkenColor(
	rgbColor: {
		r: number;
		g: number;
		b: number;
	},
	amount: number,
) {
	// 对RGB值进行深化处理
	let { r, g, b } = rgbColor;

	r = Math.max(0, r - amount);
	g = Math.max(0, g - amount);
	b = Math.max(0, b - amount);

	// 将深化后的RGB值转换回十六进制颜色代码
	const newHexColor = `#${Math.floor(r).toString(16).padStart(2, '0')}${Math.floor(g).toString(16).padStart(2, '0')}${Math.floor(b).toString(16).padStart(2, '0')}`;

	return newHexColor;
}

export const hexToRGBA = (hex: string, alpha = 1) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const pink = {
	color: '#FF5F85',
	backgroundColor: '#FFE5E9',
};

export const orange = {
	color: '#FE7624',
	backgroundColor: '#FFEADE',
};
