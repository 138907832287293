import { POST, GET, UPLOAD } from 'src/utils/fetch';
import { ContactListParams, ContactItemParams, BatchAddTagParams, DelTagParams, SegmentParams } from './type';

export async function getAllCustomAttr(params: any) {
	return GET('/api/contacts/customAttributeDefinition/all', params, true, false);
}

export async function checkEmailDuplicate(params: any) {
	return GET('/api/contacts/checkEmailExist', params, true, true);
}

export async function getContactList(params: ContactListParams) {
	return POST('/api/contacts/search', params, true);
}

export async function isPhoneExist(params: any) {
	return GET('/api/contacts/checkPhoneNumberExist', params, true, false);
}

export async function addContact(params: ContactItemParams) {
	return POST('/api/contacts/add', params, false, false);
}

export async function updateContact(params: ContactItemParams) {
	return POST('/api/contacts/update', params, false, false);
}

export async function importCreat() {
	return GET('/api/contacts/import/create', undefined, true, false);
}

export async function importUpload(params: any) {
	return UPLOAD('/api/contacts/import/upload', params, false, false);
}

export async function importProcess(params: any) {
	return POST('/api/contacts/import/process', params, true, false);
}

export async function importSave(params: any) {
	return POST('/api/contacts/import/save', params, true, false);
}

export async function getImportProgress(params: any | undefined) {
	return GET('/api/contacts/import/progress/get', params, true, false);
}

export async function removeTag(params: DelTagParams) {
	return POST('/api/contacts/removeTag', params, true, false);
}

export async function getAllTags() {
	return GET('/api/contacts/tags/all', undefined, true, false);
}

export async function batchAddTags(params: BatchAddTagParams) {
	return POST('/api/contacts/tagAll', params, false, false);
}

export async function getAllAdmin() {
	return GET('/api/tenant/admin/all', undefined, true, false);
}

export async function exportContact(params: any) {
	return POST('/api/contacts/search/export', params, true, true);
}

export async function downLoadExport(params: any) {
	return GET('/api/contacts/search/export/download', params, true, false);
}

export async function getAllSegment() {
	return GET('/api/contacts/segments/all', undefined, true, false);
}

export async function addSegment(params: SegmentParams) {
	return POST('/api/contacts/segments/add', params, true, false);
}

export async function updateSegment(params: SegmentParams) {
	return POST('/api/contacts/segments/update', params, false, false);
}

export async function delSegment(params: SegmentParams) {
	return POST('/api/contacts/segments/delete', params, false, false);
}

export async function getAllFilter() {
	return GET('/api/contacts/segments/filters/all', undefined, true, false);
}

export async function updateContactFieldVisible(params: any) {
	return POST(' /api/contacts/customAttributeDefinition/display/update', params, true, false);
}

export async function addTag(params: { tag: string; color: any }) {
	return POST('/api/contacts/tags/add', params, false, false);
}

export async function updateTag(params: { tag: string; color: any; id: string }) {
	return POST('/api/contacts/tags/update', params, false, false);
}

export async function deleteTag(params: { id: string }) {
	return POST('/api/contacts/tags/delete', params, false, true, false, true);
}

export async function addBlocklist(params: { phoneNumber: string }) {
	return POST('/api/blockList/add', params, false, false);
}

export async function deleteBlocklist(params: { phoneNumber: string }) {
	return GET('/api/blockList/delete', params, false, false);
}

export async function getBlocklistSum() {
	return GET('/api/blockList/count', undefined, true, false);
}
