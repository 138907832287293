import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogActions, Stack, Typography, FormControl, Select, OutlinedInput, MenuItem, TextField, Button } from '@mui/material';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { useAppDispatch } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { getAllAdminAsync } from 'src/features/contact/contactList/contactListSlice';
import { factorsSendCodeAsync, factorsVerifyAsync } from 'src/features/user/userSlice';
import { showMessage } from 'src/utils/message';
let sendInterval: any = undefined;
const AdminVerify: FunctionComponent<{
  open: boolean;
  onClose: () => void;
  onOk: () => void;
}> = ({
  open,
  onClose,
  onOk
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const [list, setList] = useState<Array<any>>([]);
  const [hasSend, setHasSend] = useState<boolean>(false);
  const [sendCountDown, setSendCountDown] = useState<number>(0);
  const {
    reset,
    watch,
    control,
    handleSubmit,
    setError,
    formState: {
      errors
    }
  } = useForm({
    defaultValues: {
      account: '',
      vcode: ''
    }
  });
  const account = watch('account');
  const vcode = watch('vcode');
  const triggerCountDown = () => {
    setHasSend(true);
    setSendCountDown(60);
    sendInterval = setInterval(() => {
      setSendCountDown(v => {
        if (v === 1) {
          clearInterval(sendInterval);
        }
        return v - 1;
      });
    }, 1000);
  };
  const handleSendCode = async () => {
    const res: any = await dispatch(factorsSendCodeAsync({
      factor: 'EMAIL',
      bizType: 'EXPORT_CONTACTS',
      userName: account
    })).unwrap();
    if (res.code === CODE.OK) {
      triggerCountDown();
    }
  };
  const handleContinue = async () => {
    const res: any = await dispatch(factorsVerifyAsync({
      code: vcode,
      factor: 'EMAIL',
      bizType: 'EXPORT_CONTACTS',
      userName: account,
      isHideFail: true
    }));
    const resData = res.payload;
    if (resData.code === CODE.OK) {
      onOk();
    } else if (resData.code === CODE.ERROR_VERIFY_CODE) {
      setError('vcode', {
        type: 'custom',
        message: formatMessage({
          defaultMessage: "The code you entered doesn't match the one sent in the email. Please check the number and try again."
        })
      });
    } else {
      showMessage({
        type: 'error',
        message: resData.msg
      });
    }
  };
  useEffect(() => {
    const initData = async () => {
      const res: any = await dispatch(getAllAdminAsync()).unwrap();
      if (res.code === CODE.OK) {
        setList(res.data);
      }
    };
    if (open) {
      initData();
    } else {
      reset({
        account: '',
        vcode: ''
      });
      setHasSend(false);
      setSendCountDown(0);
    }
  }, [open, reset, dispatch]);
  return <Dialog open={open} maxWidth="md">
			<CustomDialogTitle title={formatMessage({
      defaultMessage: 'Get permission to export'
    })} onClose={onClose} />
			<DialogContent sx={{
      width: '552px'
    }}>
				<Stack spacing={3}>
					<Typography variant="body1">
						{formatMessage({
            defaultMessage: 'We will send a confirmation email with a verification code to an admin user you selected, please fill in the code to continue export'
          })}
					</Typography>
					<FormControl fullWidth>
						<Controller name="account" control={control} render={({
            field
          }) => <Select {...field} input={<OutlinedInput placeholder={formatMessage({
            defaultMessage: 'Select an admin user '
          })} />} renderValue={(e: any) => {
            const target = list.filter((i: any) => i.email === e);
            if (target && target.length > 0) {
              return <Typography variant="subtitle2" color="#212B36">
													{target[0].userName}
													<Typography variant="body1" component="span">
														({target[0].email})
													</Typography>
												</Typography>;
            } else {
              return;
            }
          }} sx={{
            height: '40px',
            '& input': {
              height: '40px',
              opacity: account ? 0 : 0.42,
              border: 0,
              pl: '12px'
            }
          }}>
									{list.map((item: any) => <MenuItem key={item.userId} value={item.email}>
											<Typography variant="subtitle2" color="#212B36">
												{item.userName}
												<Typography variant="body1" component="span">
													({item.email})
												</Typography>
											</Typography>
										</MenuItem>)}
								</Select>} />
					</FormControl>
					<FormControl fullWidth sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1
        }}>
						<Controller name="vcode" control={control} rules={{
            required: formatMessage({
              defaultMessage: 'Required'
            })
          }} render={({
            field
          }) => <TextField {...field} autoComplete="off" variant="outlined" placeholder={formatMessage({
            defaultMessage: 'Verification code'
          })} error={Boolean(errors.vcode && errors.vcode?.type)} helperText={<ErrorHelperText>{errors.vcode && errors.vcode?.message}</ErrorHelperText>} sx={{
            width: '200px'
          }} />} />
						<Button variant="text" color="secondary" disabled={account === '' || sendCountDown > 0} onClick={handleSendCode}>
							{!hasSend ? formatMessage({
              defaultMessage: 'Send'
            }) : sendCountDown > 0 ? formatMessage({
              defaultMessage: 'Resend in {sendCountDown}s'
            }, {
              sendCountDown
            }) : formatMessage({
              defaultMessage: 'Resend'
            })}
						</Button>
					</FormControl>
				</Stack>
			</DialogContent>
			<DialogActions sx={{
      pt: '12px',
      pr: 2,
      pb: 2
    }}>
				<Button disabled={account === '' || vcode === ''} variant="contained" onClick={handleSubmit(handleContinue)}>
					<FormattedMessage defaultMessage={'Continue'} />
				</Button>
			</DialogActions>
		</Dialog>;
};
export default AdminVerify;