import { Box } from '@mui/material';
import { useAppSelector } from 'src/app/hooks';
import TableEmptyData from 'src/app/images/emptyData.png';
import TableEmpty from 'src/app/images/Empty.png';
import TableEmptyNeutral from 'src/app/images/Empty_neutral.png';
import TableEmptyDataNeutral from 'src/app/images/emptyData_neutral.png';
import SearchEmpty from 'src/app/images/searchEmpty.png';
import SearchEmptyNeutral from 'src/app/images/searchEmpty_neutral.png';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
import ImageContainer from 'src/app/components/imageContainer';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
export enum StateType {
  empty = 'empty',
  no_contacts = 'no_contacts',
  no_result = 'no_result',
}
const stateImageMap = {
  [StateType.empty]: {
    neutral: TableEmptyDataNeutral,
    default: TableEmptyData
  },
  [StateType.no_contacts]: {
    neutral: TableEmptyNeutral,
    default: TableEmpty
  },
  [StateType.no_result]: {
    neutral: SearchEmptyNeutral,
    default: SearchEmpty
  }
};
export const NoResultState = ({
  size = 220
}: {
  size?: number;
}) => {
  const {
    formatMessage
  } = useIntl();
  return <StateTemplate type={StateType.no_result} size={size} desc={formatMessage({
    defaultMessage: 'No results here. A new search might do the trick!'
  })} />;
};
export const EmptyState = ({
  size = 220
}: {
  size?: number;
}) => {
  const {
    formatMessage
  } = useIntl();
  return <StateTemplate size={size} desc={formatMessage({
    defaultMessage: 'No data'
  })} />;
};
export const NoContactsState = ({
  size = 220
}: {
  size?: number;
}) => {
  const {
    formatMessage
  } = useIntl();
  return <StateTemplate type={StateType.no_contacts} size={size} desc={formatMessage({
    defaultMessage: "You don't have any WhatsApp Flows in current Waba"
  })} />;
};
export default function StateTemplate({
  type = StateType.empty,
  size = 220,
  title,
  desc,
  extra
}: {
  type?: StateType;
  size?: number;
  title?: string;
  desc?: string;
  extra?: ReactNode;
}) {
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const stateImage = stateImageMap[type][isOtherBrand ? 'neutral' : 'default'];
  return <Box>
			<ImageContainer sx={{
      width: `${size}px`,
      height: `${size}px`,
      margin: 'auto'
    }} backgroundImage={stateImage} />
			{title && <Box sx={{
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      margin: 'auto',
      textAlign: 'center',
      mt: 2
    }}>
					{title}
				</Box>}
			{desc && <Box sx={{
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      margin: 'auto',
      mt: 1,
      textAlign: 'center'
    }}>
					{desc}
				</Box>}
			{extra && <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      mt: 2
    }}>
					{extra}
				</Box>}
		</Box>;
}