import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTestTemplates, sendVoiceTest } from './voiceAPI';
import { SendVoiceTestParams } from './type';

export const getTestTemplatesAsync = createAsyncThunk('voice/getTemplates', async () => {
	const res = await getTestTemplates();
	return res;
});

export const sendVoiceTestAsync = createAsyncThunk('voice/sendTest', async (params: SendVoiceTestParams) => {
	const res = await sendVoiceTest(params);
	return res;
});

export const voiceSlice = createSlice({
	name: 'voice',
	initialState: {},
	reducers: {},
});

export default voiceSlice.reducer;
