import { useEffect } from 'react';
let inputLastTime: any = 0;
const useInput = (func: any) => {
  // input连续输入时，只调用一次请求
  const handleInputSearch = (timeStamp: any, params: any) => {
    inputLastTime = timeStamp;
    const timer = setTimeout(() => {
      if (inputLastTime === timeStamp) {
        func(params);
        clearTimeout(timer);
      }
    }, 500);
  };
  useEffect(() => {
    return () => {
      inputLastTime = null;
    };
  }, []);
  return {
    handleInputSearch
  };
};
export default useInput;