const confirmDefaultOptions: any = {
	titleProps: {
		sx: {
			pt: 4,
			pl: 4,
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 600,
		},
	},
	contentProps: {
		sx: {
			pl: '68px',
			fontSize: '14px',
			lineHeight: '22px',
			fontWeight: 400,
			color: 'rgba(0, 0, 0, 0.85)',
		},
	},
	confirmationButtonProps: {
		variant: 'contained',
		size: 'small',
		sx: {
			mb: 3,
			mr: 3,
		},
	},
	cancellationButtonProps: {
		variant: 'outlined',
		size: 'small',
		sx: {
			mb: 3,
		},
	},
	dialogProps: {
		PaperProps: {
			sx: {
				width: '460px',
			},
		},
	},
};

export default confirmDefaultOptions;
