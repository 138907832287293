import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAppSelector } from 'src/app/hooks';
import { selectMessage } from 'src/features/toast/toastSlice';
import { useLocation, Outlet } from 'react-router-dom';
// import useVersionUpdate from 'src/hooks/useVersionUpdate';

const WrapPageContainer = () => {
  // useVersionUpdate();
  const location = useLocation();
  const {
    enqueueSnackbar
  } = useSnackbar();
  const [enterPath, setEnterPath] = useState<string>('');
  const [leavePath, setLeavePath] = useState<string>('');
  const showMessage = useCallback((message: string, type?: 'success' | 'error' | 'default' | 'warning', duration?: number) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top'
      },
      variant: type,
      ...(type === 'success' ? {
        autoHideDuration: duration || 2000
      } : duration && duration > 0 ? {
        autoHideDuration: duration
      } : {})
    });
  }, [enqueueSnackbar]);
  const message = useAppSelector(selectMessage);
  useEffect(() => {
    if (message.message) {
      showMessage(message.message, message.type, message.duration);
    }
  }, [message, showMessage]);
  useEffect(() => {
    const enter = location.pathname;
    setEnterPath(enter);
    if (enter === '/app/bulkMessages/logs' && !leavePath || !enter.includes('/app/bulkMessages') && leavePath.includes('/app/bulkMessages')) {
      window.localStorage.removeItem('campaignForm');
    }
    return () => {
      const leave = location.pathname;
      setLeavePath(leave);
      const isDetail = leave.includes('/app/bulkMessages/detail');
      if (enterPath === '/app/bulkMessages/logs' && !isDetail) {
        window.localStorage.removeItem('campaignForm');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return <Outlet />;
};
export default WrapPageContainer;