import { Autocomplete, Box, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CustomChip from 'src/app/components/customChip';
import LongTextEllipsis from 'src/app/components/LongTextEllipsis';
import { useAppDispatch } from 'src/app/hooks';
import { NoContactsState, NoResultState } from 'src/app/template/state';
import { WhatsAppFlowsStatusEnum, whatsappFlowsStatusLabelMap } from 'src/features/whatsappFlows/type';
import { searchWhatsAppFlowsListAsync } from 'src/features/whatsappFlows/whatsappFlowsSlice';
import useSafetyIntl from 'src/hooks/useSafetyIntl';
import { createFilterOptions } from '@mui/material/Autocomplete';
export default function WhatsAppFlowSelector({
  value,
  wabaId,
  disabled = false,
  error = false,
  helperText = '',
  onChange
}: {
  value: string;
  onChange: (id: string) => void;
  wabaId: string;
  helperText?: any;
  error?: boolean;
  disabled?: boolean;
}) {
  const {
    formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState([]);
  const filterOptions = createFilterOptions({
    stringify: (option: any) => `${option.name}${option.id}`
  });
  const getWhatsAppFlowsList = async (wabaId: string) => {
    const params = {
      wabaId,
      status: 'PUBLISHED,THROTTLED',
      pageNo: 1,
      pageSize: 1000
    };
    const res = await dispatch(searchWhatsAppFlowsListAsync(params));
    if (searchWhatsAppFlowsListAsync.fulfilled.match(res)) {
      setOptions(res.payload.data.records);
    }
  };
  const selectedValue = options.find(item => item.id === value) || undefined;
  useEffect(() => {
    if (wabaId) {
      getWhatsAppFlowsList(wabaId);
    }
  }, [wabaId]);
  const {
    safetyFormatMessage
  } = useSafetyIntl();
  return <Autocomplete options={options} value={selectedValue} getOptionLabel={(option: any) => option.name} noOptionsText={<Box sx={{
    py: 4
  }}>
					{options.length ? <NoResultState size={120} /> : <NoContactsState size={120} />}
				</Box>} key={selectedValue?.id || ''} filterOptions={filterOptions} renderOption={(props: any, option: any) => <MenuItem {...props} key={option.id} sx={{
    display: 'flex',
    gap: '4px',
    justifyContent: 'space-between',
    alignItems: 'center'
  }}>
					<Box sx={{
      flexGrow: 1,
      minWidth: 0
    }}>
						<LongTextEllipsis text={option.name} />
						<Box>
							<Typography sx={{
          color: 'text.secondary'
        }}>
								ID:{option.id}
							</Typography>
						</Box>
					</Box>
					{option.status === WhatsAppFlowsStatusEnum.THROTTLED && <Box sx={{
      flexShrink: 0
    }}>
							<CustomChip color="warning" label={safetyFormatMessage(whatsappFlowsStatusLabelMap[option.status as keyof typeof whatsappFlowsStatusLabelMap])} />
						</Box>}
				</MenuItem>} onChange={(event, newValue) => {
    if (!newValue) {
      onChange('');
      return;
    }
    onChange(newValue.id);
  }} disabled={disabled} renderInput={params => <TextField sx={{
    '& .MuiInputBase-root': {
      backgroundColor: 'white'
    }
  }} placeholder={formatMessage({
    defaultMessage: 'Please select'
  })} {...params} error={error} helperText={helperText} />} />;
}