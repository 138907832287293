import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogContent, Stack, Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import { ContactUserIcon, UploadIcon } from 'src/app/icons';
import { useAppSelector } from 'src/app/hooks';
import { selectCurSeg } from 'src/features/contact/contactList/contactListSlice';
import useTheme from '@mui/material/styles/useTheme';
const AddDialog: FunctionComponent<{
  open: boolean;
  onOneUser: (e: string, i?: any) => void;
  onClose: () => void;
}> = ({
  open,
  onOneUser,
  onClose
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const boxStyle = {
    cursor: 'pointer',
    width: '208px',
    py: '20px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'action.hover',
      borderRadius: 1
    }
  };
  const iconContainerStyle = {
    margin: 'auto',
    width: '68px',
    height: '68px',
    borderRadius: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 1
  };
  const iconStyle = {
    width: '48px',
    height: '48px',
    color: 'white'
  };
  const navigate = useNavigate();
  const curSeg = useAppSelector(selectCurSeg);
  const theme = useTheme();
  const handleAdd = (type: string) => {
    if (type === 'one') {
      onClose();
      onOneUser('add');
    } else if (type === 'upload') {
      navigate(`/app/contact/upload?curSeg=${curSeg === 'all' ? 'all' : curSeg.id}`);
    }
  };
  return <Dialog open={open} maxWidth="md">
			<CustomDialogTitle title={formatMessage({
      defaultMessage: 'Add contacts'
    })} onClose={onClose} />
			<DialogContent sx={{
      width: '600px',
      boxSizing: 'border-box',
      pt: '64px !important',
      px: 4,
      pb: '92px'
    }}>
				<Stack direction="row" spacing={2} sx={{
        justifyContent: 'center'
      }}>
					<Box sx={boxStyle} onClick={() => handleAdd('one')}>
						<Box sx={{
            ...iconContainerStyle,
            background: theme.palette.secondary.main
          }}>
							<ContactUserIcon sx={iconStyle} />
						</Box>
						<Typography variant="h3" color="primary.dark">
							<FormattedMessage defaultMessage={'Add a new contact'} />
						</Typography>
					</Box>
					<Box sx={boxStyle} onClick={() => handleAdd('upload')}>
						<Box sx={{
            ...iconContainerStyle,
            background: theme.palette.success.main
          }}>
							<UploadIcon sx={iconStyle} />
						</Box>
						<Typography variant="h3" color="primary.dark">
							<FormattedMessage defaultMessage={'Upload a file'} />
						</Typography>
					</Box>
				</Stack>
			</DialogContent>
		</Dialog>;
};
export default AddDialog;