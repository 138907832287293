import GET, { POST } from 'src/utils/fetch';
import { GetFlowsSubmissionsNumParams, GetWhatsAppFlowInfoParams, SyncWhatsAppFlowsParams } from './type';

export async function syncWhatsAppFlows(params: SyncWhatsAppFlowsParams) {
	return POST('/api/whatsapp/flows/sync', params);
}

export async function searchWhatsAppFlowsList(params: any) {
	return GET('/api/whatsapp/flows/list', params, true);
}

export async function getFlowsSubmissionsNum(params: GetFlowsSubmissionsNumParams) {
	return GET('/api/whatsapp/flows/analytics/batchGetLatest', params, true, true);
}

export async function getWhatsAppFlowInfo(params: GetWhatsAppFlowInfoParams) {
	return GET('/api/whatsapp/flows/get', params, true);
}

export async function getWhatsAppFlowLogs(params: any) {
	return GET('/api/whatsapp/flows/replies/list', params, true);
}

export async function exportWhatsAppFlowLogs(params: any) {
	return POST('/api/whatsapp/flows/replies/export', params, true);
}

export async function getExportTaskStatus(params: any) {
	return GET('/api/whatsapp/flows/replies/export/get', params, true);
}

export async function getWhatsAppFlowsTemplateList(params: any) {
	return GET('/api/whatsapp/flows/replies/aggregation', params, true);
}
