import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBillingTotal, getBillingLogs, saveInvoiceConfig, stripeCheckout, stripeSession, shopifyCheckoutCharge, shopifyChargeStatus, getInvoiceLogs, submitInvoiceConfig, exportBilling } from './billingAPI';
import { BillingTotalParams, BillingLogsParams, InvoiceConfigParams, StripeCheckoutParams, StripeSessionParams, ShopifyBillingParams, ShopifyChargeQueryParams } from './type';

export const getBillingTotalAsync = createAsyncThunk('billing/getTotal', async (params: BillingTotalParams) => {
	const res = await getBillingTotal(params);
	return res;
});

export const getBillingLogsAsync = createAsyncThunk('billing/getLogs', async (params: BillingLogsParams) => {
	const res = await getBillingLogs(params);
	return res;
});

export const saveInvoiceConfigAsync = createAsyncThunk('billing/saveInvoiceConfig', async (params: InvoiceConfigParams) => {
	const res = await saveInvoiceConfig(params);
	return res;
});

export const stripeCheckoutAsync = createAsyncThunk('billing/stripeCheckout', async (params: StripeCheckoutParams) => {
	const res = await stripeCheckout(params);
	return res;
});

export const stripeSessionAsync = createAsyncThunk('billing/stripeSession', async (params: StripeSessionParams) => {
	const res = await stripeSession(params);
	return res;
});

export const shopifyCheckoutAsync = createAsyncThunk('billing/shopifyCharge', async (params: ShopifyBillingParams) => {
	const res = await shopifyCheckoutCharge(params);
	return res;
});

export const shopifyChargeStatusAsync = createAsyncThunk('billing/shopifyChargeStatus', async (params: ShopifyChargeQueryParams) => {
	const res = await shopifyChargeStatus(params);
	return res;
});

export const getInvoiceLogsAsync = createAsyncThunk('billing/getInvoice', async (params: BillingLogsParams) => {
	const res = await getInvoiceLogs(params);
	return res;
});

export const submitInvoiceConfigAsync = createAsyncThunk('billing/submitInvoiceConfig', async (params: InvoiceConfigParams) => {
	const res = await submitInvoiceConfig(params);
	return res;
});

export const exportBillingAsync = createAsyncThunk('billing/exportBilling', async (params: BillingLogsParams) => {
	const res = await exportBilling(params);
	return res;
});

export const billingSlice = createSlice({
	name: 'billing',
	initialState: {},
	reducers: {},
});

export default billingSlice.reducer;
