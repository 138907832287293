import { Box, Tooltip, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
const LongTextEllipsis = ({
  sx,
  ...props
}: any) => {
  const myRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    const contentRef: any = myRef.current;
    if (contentRef) {
      setShowTooltip(contentRef.offsetWidth < contentRef.scrollWidth);
    }
  }, [props.children]);
  const content = <Typography {...props} ref={myRef} sx={{
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    wordBreak: 'break-all',
    ...sx
  }} />;
  return showTooltip ? <Tooltip arrow placement="top-start" title={<Box sx={{
    maxHeight: '300px',
    overflowY: 'auto',
    maxWidth: '400px'
  }}>
					{props.children}
				</Box>}>
			{content}
		</Tooltip> : content;
};
export default LongTextEllipsis;