import { useState, useEffect } from 'react';
import httpRequest from 'src/utils/request';

const useFetchData = ({ type = 'get', url, params = {}, formatDataFn = v => v, callback }: { type?: 'get' | 'post'; url: string; params?: any; formatDataFn?: (v: any[]) => any[]; callback?: (v: any[]) => void }) => {
	const [data, setData] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const fetchData = async () => {
		if (['get', 'post'].includes(type)) {
			const requestConfig = {
				url: url,
				params: params,
				options: {
					returnData: true,
				},
			};
			const [error, data] = await (type === 'get' ? httpRequest.get(requestConfig) : httpRequest.post(requestConfig));
			if (!error) {
				const formatValue = formatDataFn(data);
				setData(formatValue);

				if (callback && typeof callback === 'function') {
					callback(formatValue);
				}
			}
			setLoading(false);
		} else {
			throw new Error('Invalid request type');
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const refresh = async () => {
		await fetchData();
	};

	return { data, loading, refresh };
};

export default useFetchData;
