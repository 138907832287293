import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { classify } from 'src/utils/tools';
import {
	getUser,
	updateTenantBaseInfo,
	updateTenantBillInfo,
	updateUserProfile,
	updatePwd,
	getUserBalance,
	getUserCurrency,
	getExChangeRate,
	getCurrencyCode,
	getBalanceAlert,
	setBalanceAlert,
	getIP,
	getCountryCodes,
	getSignature,
	getUserFactors,
	setFactorsSwitch,
	factorsSendCode,
	factorsVerify,
	getApiKey,
	getNotificationConfig,
	saveNotificationConfig,
	getIntegrationStatus,
	getAgentInfo,
	updateNotifications,
	uploadAvatar,
} from './userAPI';

import { updateTenantBaseInfoParams, UpdateTenantBillInfoParams, UpdateUserProfileParams, UpdatePwdType, CurrencyCodeParams, BalanceAlertParams, UserFactorsParams, FactorsSwitchParams, FactorsCodeParams, GetApiKeyParams } from './type';
import { uniq } from 'lodash-es';
import { PartnerCustomer } from '../partners/type';
import { selectIsOtherBrand } from '../brand/brandSlice';

export interface UserState {
	isLogin: boolean;
	user: any;
	curAreaNo: string;
	allCountryCodes: any[];
	availableCountries: any[];
	signatureList: any[];
	balance: {
		balance: number;
		currency: string;
		frozenBalance: number;
		creditLine?: PartnerCustomer['creditLine'];
	};
	blacklist: string[];
	status: any;
	isReady: boolean;
	agentInfo?: any;
}

const defaultBlacklist = ['SMS', 'EMAIL', 'VOICE_CODE', 'VERIFY', 'SHOPIFY', 'COUPON'];

const initialState: UserState = {
	isLogin: true,
	user: {},
	balance: {
		balance: 0,
		currency: 'USD',
		frozenBalance: 0,
	},
	curAreaNo: '',
	allCountryCodes: [],
	availableCountries: [],
	signatureList: [],
	blacklist: defaultBlacklist,
	status: [],
	isReady: false,
	agentInfo: {},
};

async function imageToBase64(url: string) {
	const response = await fetch(url);
	const blob = await response.blob();
	const reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onloadend = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
}

export const getUserAsync = createAsyncThunk('user/getUser', async (shouldHideError: boolean, { rejectWithValue }) => {
	try {
		const res = await getUser(shouldHideError);
		if (res && res.data) {
			try {
				if (res.data.avatar) {
					res.data.avatar = await imageToBase64(res.data.avatar);
				}
			} catch (err) {
				console.log(err);
			}
		}
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const updateTenantInfoAsync = createAsyncThunk('user/updateTenantBaseInfo', async (params: updateTenantBaseInfoParams) => {
	const res = await updateTenantBaseInfo(params);
	return res;
});

export const updateTenantBillInfoAsync = createAsyncThunk('user/updateTenantBillInfo', async (params: UpdateTenantBillInfoParams) => {
	const res = await updateTenantBillInfo(params);
	return res;
});

export const updateUserProfileAsync = createAsyncThunk('user/updateUserProfile', async (params: UpdateUserProfileParams) => {
	const res = await updateUserProfile(params);
	return res;
});

export const updatePwdAsync = createAsyncThunk('user/updatePwd', async (params: UpdatePwdType, { rejectWithValue }) => {
	try {
		const res = await updatePwd(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getUserBalanceAsync = createAsyncThunk('user/getUserBalance', async () => {
	const res = await getUserBalance();
	return res.data;
});

export const getUserCurrencyAsync = createAsyncThunk('user/getUserCurrency', async () => {
	const res = await getUserCurrency();
	return res.data;
});

export const getCurrencyCodeAsync = createAsyncThunk('user/getCurrencyCode', async (params: CurrencyCodeParams) => {
	const res = await getCurrencyCode(params);
	return res.data;
});

export const getExChangeRateAsync = createAsyncThunk('user/getExChangeRate', async () => {
	const res = await getExChangeRate();
	return res.data;
});

export const getBalanceAlertAsync = createAsyncThunk('user/getBalanceAlert', async () => {
	const res = await getBalanceAlert();
	return res.data;
});

export const setBalanceAlertAsync = createAsyncThunk('user/setBalanceAlert', async (params: BalanceAlertParams) => {
	const res = await setBalanceAlert(params);
	return res;
});

export const getIPAsync = createAsyncThunk('user/getUserIP', async () => {
	const res = await getIP();
	return res.data;
});

export const getNotificationConfigAsync = createAsyncThunk('user/getNotificationConfig', async (params: any) => {
	const res = await getNotificationConfig(params);
	return res.data;
});

export const saveNotificationConfigAsync = createAsyncThunk('user/saveNotificationConfig', async (params: any) => {
	const res = await saveNotificationConfig(params);
	return res.data;
});

export const getCountryCodesAsync = createAsyncThunk('user/getCountryCodes', async () => {
	const res = await getCountryCodes();
	return res.data;
});

export const getSignatureAsync = createAsyncThunk('user/getUserSignature', async () => {
	const res = await getSignature();
	return res.data;
});

export const getUserFactorsAsync = createAsyncThunk('user/getUserFactors', async (params: UserFactorsParams) => {
	const res = await getUserFactors(params);
	return res;
});

export const setFactorsSwitchAsync = createAsyncThunk('user/setFactorsSwitch', async (params: FactorsSwitchParams, { rejectWithValue }) => {
	try {
		const res = await setFactorsSwitch(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const factorsSendCodeAsync = createAsyncThunk('user/factorsSendCode', async (params: FactorsCodeParams, { rejectWithValue }) => {
	try {
		const res = await factorsSendCode(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const factorsVerifyAsync = createAsyncThunk('user/factorsSendCode', async (params: FactorsCodeParams, { rejectWithValue }) => {
	try {
		const res = await factorsVerify(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getApiKeyAsync = createAsyncThunk('user/getApiKey', async (params: GetApiKeyParams) => {
	const res = await getApiKey(params);
	return res;
});

export const getIntegrationStatusAsync = createAsyncThunk('user/getIntegrationStatusAsync', async () => {
	return getIntegrationStatus();
});

export const getAgentInfoAsync = createAsyncThunk('user/getAgentInfo', async () => {
	const response = await getAgentInfo();
	return response;
});

export const updateSettingAsync = createAsyncThunk('user/updateNotifications', async (params: any) => {
	const response = await updateNotifications(params);
	return response;
});

export const uploadAvatarAsync = createAsyncThunk('user/uploadAvatar', async (params: any, { rejectWithValue }) => {
	try {
		const res = await uploadAvatar(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const selectUser = (state: RootState) => state.user.user;
export const selectIsLogin = (state: RootState) => state.user.isLogin;
export const selectCurAreaNo = (state: RootState) => state.user.curAreaNo;

export const selectHasTopUp = (state: RootState) => state.user.user?.level === 3; // 用户是否充值过
// 1未绑定手机且未充值  2绑定了手机但未充值 3冲了值，绑没绑手机不知道
export const selectBalance = (state: RootState) => state.user.balance;

export const selectCountryCodes = (state: RootState) => state.user.allCountryCodes;
export const selectAvailableCountries = (state: RootState) => state.user.availableCountries;
export const selectSignatureList = (state: RootState) => state.user.signatureList;
export const getBlacklist = (state: RootState) => state.user.blacklist;

export const getIntegrationStatusData = (state: RootState) => state.user.status;

export const getPageStatus = (state: RootState) => state.user.isReady;

export const selectAgentInfo = (state: RootState) => state.user.agentInfo;

export const selectIsKnowledgeBaseDisabled = (state: RootState) => !(state.user.user?.tags?.CHATBOT_KNOWLEDGE_BASE_ENABLED || false);

export const selectCustomerType = (state: RootState) => (selectIsOtherBrand(state) ? 'channelCustomer' : state.user.user.partnerType);

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		changePageStatus: state => {
			state.isReady = true;
		},
		resetBlacklist: state => {
			state.blacklist = defaultBlacklist;
		},
		resetApiKeys: (state, action) => {
			state.user = {
				...state.user,
				apikeys: action.payload,
			};
		},
		forbiddenAddWaba: state => {
			state.user.tags.whatsapp_embedded_signup_enabled = false;
		},
		updateOnlineStatus: (state, action) => {
			state.user = {
				...state.user,
				onlineStatus: action.payload,
			};
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getUserAsync.fulfilled, (state, action) => {
				const data = action.payload.data;
				state.user = data;
				state.isLogin = data.isMobileRequired ? Boolean(data.mobile) : Boolean(data.accountId);
			})
			.addCase(getUserBalanceAsync.fulfilled, (state, action) => {
				state.balance = action.payload;
			})
			.addCase(getIPAsync.fulfilled, (state, action) => {
				state.curAreaNo = action.payload.countryCode;
			})
			.addCase(getCountryCodesAsync.fulfilled, (state, action) => {
				const data = action.payload;
				const filterData = data.filter((i: any) => i.countryCode !== state.curAreaNo);
				const current = data.filter((i: any) => i.countryCode === state.curAreaNo);
				state.allCountryCodes = current ? [...current, ...filterData] : data;
				state.availableCountries = classify(data, 'continent');
			})
			.addCase(getSignatureAsync.fulfilled, (state, action) => {
				state.signatureList = action.payload;
			})
			.addCase(getIntegrationStatusAsync.fulfilled, (state, action) => {
				const data = action.payload.data || [];
				state.status = data;
				const whitelist = data.filter((item: any) => item.status === 'enable').map((item: any) => item.integration);
				state.blacklist = uniq([...state.blacklist].filter(item => !whitelist.includes(item)));
			})
			.addCase(getIntegrationStatusAsync.rejected, state => {
				state.status = [];
				state.blacklist = defaultBlacklist;
			})
			.addCase(getAgentInfoAsync.fulfilled, (state, action) => {
				state.agentInfo = action.payload.data;
			});
	},
});

export const { changePageStatus, resetBlacklist, resetApiKeys, forbiddenAddWaba, updateOnlineStatus } = userSlice.actions;

export const selectIsAddWabaEnabled = (state: RootState) => state.user?.user?.tags?.whatsapp_embedded_signup_enabled || false;

export const selectIsButtonTracked = (state: RootState): boolean => state.user?.user?.tags?.WHATSAPP_TEMPLATE_TRACK_LINK_ENABLED || false;

export default userSlice.reducer;
