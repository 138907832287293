export enum VariableNamespace {
	PRESET = 'preset',
	BOT = '',
	CONTACT = 'contact',
}

export type Variable = {
	namespace: VariableNamespace;
	groupName: string;
	variables: Array<any>;
};

export const defaultVariables: Array<Variable> = [
	{
		namespace: VariableNamespace.PRESET,
		groupName: 'Preset variables',
		variables: ['name', 'phone_number', 'country'],
	},
];

export const getDefaultVariablesInText = () => {
	const list: Array<string> = [];
	defaultVariables.forEach(item => {
		item.variables.forEach(variable => {
			list.push(`{{${item.namespace}:${variable}}}`);
		});
	});
	return list;
};
