import { useIntl } from 'react-intl';
import { Box, Tooltip } from '@mui/material';
import { goFrontPage } from 'src/utils/navigate';
import ExpandLogo from './images/expandLogo.svg';
import CloseLogo from './images/closeLogo.svg';
import { useAppSelector, useAppDispatch } from 'src/app/hooks';
import { selectIsOtherBrand, selectLogo, selectLogoType, selectSquareLogo } from 'src/features/brand/brandSlice';
import { getIsExpand, updateIsExpand } from 'src/features/setting/settingSlice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useTheme from '@mui/material/styles/useTheme';
import { hexToRGBA } from 'src/styles/color';
import { LogoType } from 'src/features/brand/type';
const Logo = () => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const theme = useTheme();
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const brandLogo = useAppSelector(selectLogo);
  const brandShortLogo = useAppSelector(selectSquareLogo);
  const isExpand = useAppSelector(getIsExpand);
  const dispatch = useAppDispatch();
  const handleClick = () => {
    if (isOtherBrand) {
      return;
    }
    goFrontPage();
  };
  const logoSrc = isExpand ? isOtherBrand ? brandLogo : ExpandLogo : isOtherBrand ? brandShortLogo : CloseLogo;
  const logoType = useAppSelector(selectLogoType);
  const logoStyle = {
    [LogoType.Square]: {
      width: '50px',
      height: '50px'
    },
    [LogoType.Width]: {
      width: '110px',
      height: 'auto'
    },
    [LogoType.Height]: {
      width: 'auto',
      height: '50px'
    },
    [LogoType.Small_Width]: {
      width: 'auto',
      height: '50px'
    }
  };
  return <Box sx={{
    width: '100%',
    boxSizing: 'border-box',
    padding: isExpand ? '24px' : '24px 0'
  }}>
			<Box sx={{
      maxHeight: '50px',
      width: '100%',
      position: 'relative'
    }}>
				<Box sx={{
        width: '100%',
        maxHeight: '50px',
        display: 'flex',
        justifyContent: isExpand ? 'flex-start' : 'center',
        alignItems: 'center'
      }}>
					<img style={isExpand ? {
          maxHeight: '50px',
          ...(logoType ? logoStyle[logoType] : {})
        } : {
          width: '32.73px',
          height: '32.73px'
        }} src={logoSrc} alt="" onClick={handleClick} />
				</Box>

				<Tooltip arrow title={isExpand ? formatMessage({
        defaultMessage: 'Minimize menu'
      }) : formatMessage({
        defaultMessage: 'Expand menu'
      })}>
					<Box sx={{
          position: 'absolute',
          right: isExpand ? '-24px' : '-21px',
          top: '-1px',
          zIndex: 1000
        }}>
						<Box onClick={() => dispatch(updateIsExpand(!isExpand))} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            background: theme.palette.fill.hover,
            color: theme.palette.secondary.main,
            border: `solid 1px ${hexToRGBA(theme.palette.secondary.main, 0.5)}`,
            borderRadius: isExpand ? '4px 0px 0px 4px' : '0px 4px 4px 0px',
            boxSizing: 'border-box',
            width: '20px',
            height: '20px'
          }}>
							{isExpand ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</Box>
					</Box>
				</Tooltip>
			</Box>
		</Box>;
};
export default Logo;