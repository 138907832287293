import { FormattedMessage } from 'react-intl';
import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField, Typography, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import React, { FunctionComponent, useState, useEffect } from 'react';
import NumberInput from 'src/app/components/numberInput';
import CountrySelect from 'src/app/components/contrySelect';
import { useAppSelector } from 'src/app/hooks';
import { selectCountryCodes } from 'src/features/user/userSlice';
import { stringOptions, timeOptions, timeNumArr, timeArr, booleanOptions, numberOptions } from 'src/features/contact/contactList/type';
import { getDateStartTime } from 'src/utils/tools';
import DateRange from './DateRange';
import TagSelect from './TagSelect';
import OptionEditor from 'src/app/components/form/OptionEditor';
import { selectAllCustomAttr } from 'src/features/contact/contactList/contactListSlice';
import NumberRange from 'src/pages/bot/detail/build/components/configEditor/branchingConfigEditor/components/filter/components/NumberRange';
import { now, isNumber } from 'lodash-es';
const EditFilter: FunctionComponent<{
  preType: string;
  item: any;
  onChange: (e: any) => void;
  onClose: () => void;
}> = ({
  preType,
  item,
  onChange,
  onClose
}) => {
  const allCustomAttr = useAppSelector(selectAllCustomAttr);
  const findedCustomAttr = allCustomAttr && allCustomAttr.find((myItem: any) => myItem.displayName === item.column);
  const customAttrValues = findedCustomAttr ? findedCustomAttr.values : [];
  const inputStyle = {
    '& input': {
      height: '32px',
      p: '8px 12px'
    }
  };
  const countryCodes = useAppSelector(selectCountryCodes);
  const [type, setType] = useState<string>('');
  const [value, setValue] = useState<any>();
  const [country, setCountry] = useState<any>(null);
  const [tag, setTag] = useState<any>(null);
  const [arrayValue, setArrayValue] = useState<Array<string>>([]);
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const e = (event.target as HTMLInputElement).value;
    let newValue = value;
    if (item.type === 'Time') {
      if (e === 'BETWEEN') {
        newValue = getDateStartTime(now()) + ',';
      } else if (timeNumArr.includes(e)) {
        newValue = '';
      } else if (timeArr.includes(e)) {
        newValue = getDateStartTime(now());
      }
    } else if (item.type === 'True/False') {
      if (e === 'IS_UNKNOWN') {
        newValue = 'unknown';
      } else if (newValue === 'unknown') {
        newValue = '';
      }
    } else if (item.type === 'Number') {
      if (e === 'BETWEEN') {
        newValue = '';
      } else {
        newValue = isNumber(newValue) ? newValue : '';
      }
    } else {
      if (item.column === 'Country') {
        if (e !== 'CONTAINS') {
          if (typeof newValue === 'string') {
            const target = countryCodes.find((v: any) => v.countryName === newValue);
            if (target) {
              newValue = target;
            }
          }
        } else {
          newValue = newValue?.countryName;
        }
      }
      if (item.column === 'Tag') {
        newValue = tag;
      }
    }
    setType(e);
    setValue(newValue);
    if (item.type === 'Array' && item.column !== 'Tag' && e !== 'CONTAINS') {
      newValue = arrayValue;
    }
    onChange({
      operator: e,
      value: item.column === 'Country' && e !== 'CONTAINS' ? newValue.countryName : newValue,
      timeStamp: 0
    });
  };
  useEffect(() => {
    if (item.column === 'Tag' && !tag) {
      setTag(item.value);
    }
    if (item.column === 'Country' && !country) {
      const target = countryCodes.find((e: any) => e.countryName === item.value);
      if (target && !country) {
        setCountry(target);
      }
    }
  }, [item]);
  const handleValueChange = (e: any) => {
    setValue(e);
    onChange({
      operator: type,
      value: timeArr.includes(type) ? getDateStartTime(new Date(e)) : e,
      timeStamp: 0
    });
  };
  const handleCountryChange = (e: any) => {
    setCountry(e);
    onChange({
      operator: type,
      value: e?.countryName ? e?.countryName : '',
      timeStamp: 0
    });
  };
  const handleTagChange = (e: any) => {
    setTag(e);
    onChange({
      operator: type,
      value: e,
      timeStamp: 0
    });
  };
  const handleTextChange = (e: any) => {
    setValue(e.target.value);
    onChange({
      operator: type,
      value: e.target.value,
      timeStamp: parseInt(e.timeStamp)
    });
  };
  const handleNumberChange = (e: any, event?: any) => {
    setValue(e);
    onChange({
      operator: type,
      value: e,
      timeStamp: parseInt(event?.timeStamp || now())
    });
  };
  const handleArrayValueChange = (e: any, event: any) => {
    setArrayValue(e);
    onChange({
      operator: type,
      value: e,
      timeStamp: parseInt(event.timeStamp)
    });
  };
  useEffect(() => {
    setType(item.operator);
    let newValue = item.value;
    if (!newValue) {
      if (item.type === 'Time') {
        if (item.operator === 'BETWEEN') {
          newValue = getDateStartTime(now()) + ',';
        } else if (timeArr.includes(item.operator)) {
          setValue(item.value ? item.value : getDateStartTime(now()));
        } else if (item.column === 'Country') {
          newValue = void 0;
        }
      }
    } else {
      if (item.column === 'Country' && item.operator !== 'CONTAINS') {
        const target = countryCodes.find((e: any) => e.countryName === item.value);
        if (target) {
          newValue = target;
        } else {
          newValue = '';
        }
      }
    }
    if (item.type === 'Array' && item.column !== 'Tag' && item.operator !== 'CONTAINS') {
      setArrayValue(newValue);
    } else {
      setValue(newValue);
    }
  }, [item, countryCodes]);
  return <>
			<Box sx={{
      pb: '46px',
      boxSizing: 'border-box'
    }}>
				<RadioGroup value={type} onChange={handleTypeChange} sx={{
        '& .MuiFormControlLabel-root': {
          mx: 0,
          display: 'block',
          '& .MuiButtonBase-root': {
            p: '10px',
            mr: '4px'
          }
        }
      }}>
					{item.type === 'Time' ? <>
							{timeOptions.map((e: any) => <Box key={e.value} sx={{
            mb: 1,
            '& .MuiPickerStaticWrapper-content': {
              border: '1px solid #D7D5DF',
              borderRadius: 2,
              ml: '36px',
              '& .MuiPickersDay-root.Mui-selected': {
                color: '#fff !important'
              },
              '& .MuiPickersDay-root[aria-current="date"]': {
                border: 0,
                fontWeight: 500,
                color: 'secondary.main'
              }
            }
          }}>
									<FormControlLabel value={e.value} control={<Radio size="small" />} label={e.label} />
									{type === e.value && <>
											{e.value === 'MORE_THAN' || e.value === 'LESS_THAN' ? <Box>
													<NumberInput autoFocus={true} size="small" value={value} onChange={(i: any, event: any) => handleNumberChange(isNaN(i) ? '' : i, event)} hasEvent={true} isReturnNum={false} sx={{
                  width: '193px',
                  ml: '36px',
                  mr: '10px',
                  ...inputStyle
                }} />
													<Typography variant="caption" color="primary.dark">
														<FormattedMessage defaultMessage={'days ago'} />
													</Typography>
												</Box> : e.value === 'BETWEEN' ? <Box sx={{
                mt: 1
              }}>
													<DateRange value={value} onChange={handleValueChange} />
												</Box> : <LocalizationProvider dateAdapter={AdapterDateFns}>
													<StaticDatePicker displayStaticWrapperAs="desktop" value={value} onChange={(i: any) => handleValueChange(i)}
                // renderInput={(params) => <TextField {...params} />}
                />
												</LocalizationProvider>}
										</>}
								</Box>)}
						</> : item.type === 'True/False' ? <>
							{booleanOptions.map((e: any) => <Box key={e.value} sx={{
            mb: 1
          }}>
									<FormControlLabel value={e.value} control={<Radio size="small" />} label={e.label} sx={{
              ...(e.value === 'IS_UNKNOWN' && {
                width: '248px'
              })
            }} />
									{type === e.value && e.value !== 'IS_UNKNOWN' && <TextField select size="small" variant="outlined" value={value} onChange={handleTextChange} sx={{
              width: '212px',
              ml: '36px'
            }}>
											<MenuItem value="True">
												<FormattedMessage defaultMessage={'True'} />
											</MenuItem>
											<MenuItem value="False">
												<FormattedMessage defaultMessage={'False'} />
											</MenuItem>
										</TextField>}
								</Box>)}
						</> : item.type === 'Number' ? <>
							{numberOptions.map((e: any) => <Box key={e.value} sx={{
            mb: 1
          }}>
									<FormControlLabel value={e.value} control={<Radio size="small" />} label={e.label} />
									{type === e.value && <Box sx={{
              width: 'calc(100% - 36px)',
              pl: '36px',
              boxSize: 'border-box'
            }}>
											{e.value === 'BETWEEN' ? <NumberRange preType={preType} value={value} onChange={(i: any) => handleNumberChange(i)} /> :
              //   ok
              <NumberInput autoFocus={true} size="small" value={value} onChange={(i: any, event: any) => handleNumberChange(isNaN(i) ? '' : i, event)} hasEvent={true} isReturnNum={true} sx={{
                width: '100%',
                ...(!value && preType !== 'add' && {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '2px solid #FF4842 !important'
                  }
                })
              }} />}
										</Box>}
								</Box>)}
						</> : <>
							{stringOptions.filter(i => {
            if (['Country', 'Phone number'].includes(item.column) || item.type === 'Array') {
              return i.value !== 'CONTAINS';
            }
            if (item.column === 'Ctwa source') {
              return i.value == 'IS';
            }
            return true;
          }).map((e: any) => <Box key={e.value} sx={{
            mb: 1
          }}>
										<FormControlLabel value={e.value} control={<Radio size="small" />} label={e.label} />
										{item.column === 'Country' && (type === e.value && type === 'IS' || type === e.value && type === 'IS_NOT') ? <CountrySelect autoFocus={true} value={country} onChange={(event: any, newValue: string | null) => {
              handleCountryChange(newValue);
            }} popWidth="212px" cssStyle={{
              width: '212px',
              ml: '36px'
            }} /> : item.type === 'Array' && (type === e.value && type === 'IS' || type === e.value && type === 'IS_NOT') ? item.column === 'Tag' ? <TagSelect value={tag} onChange={handleTagChange} /> : <OptionEditor multiple={false} freeSolo={false} value={arrayValue} onChange={handleArrayValueChange} options={customAttrValues} sx={{
              width: '212px',
              ml: '36px'
            }} /> : type === e.value && <TextField autoFocus={true} variant="outlined" autoComplete="off" value={value} onChange={handleTextChange} sx={{
              width: '212px',
              ml: '36px',
              ...(!value && preType !== 'add' && {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '2px solid #FF4842 !important'
                }
              })
            }} />}
									</Box>)}
						</>}
				</RadioGroup>
			</Box>
			<Box sx={{
      textAlign: 'right',
      position: 'absolute',
      bottom: '16px',
      right: '16px'
    }}>
				<Button variant="contained" size="small" sx={{
        minWidth: 'auto',
        px: '12px'
      }} onClick={onClose}>
					<FormattedMessage defaultMessage={'Done'} />
				</Button>
			</Box>
		</>;
};
export default EditFilter;