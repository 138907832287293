export enum Locale {
	En = 'en',
	Zh = 'zh',
	Es = 'es',
	Pt = 'pt',
	Id = 'id',
}

export interface GetIntlContentParams {
	language: Locale;
	translateContentType: 'DASHBOARD';
}
