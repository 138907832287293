import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import { getWhatsAppFlowInfoAsync } from 'src/features/whatsappFlows/whatsappFlowsSlice';

export default function useWhatsAppFlowScreen(id: string) {
	const [screenId, setScreenId] = useState('');

	const dispatch = useAppDispatch();

	const setWhatsAppFlowNavigateScreen = async (flowId: string) => {
		if (!flowId) {
			setScreenId('');
			return;
		}
		const res = await dispatch(
			getWhatsAppFlowInfoAsync({
				id: flowId,
			}),
		);
		if (getWhatsAppFlowInfoAsync.fulfilled.match(res)) {
			const screens = res.payload?.data?.flowJson?.screens || [];
			if (screens.length) {
				setScreenId(screens[0].id);
			}
		}
	};

	useEffect(() => {
		setWhatsAppFlowNavigateScreen(id);
	}, [id]);

	return {
		screenId,
	};
}
