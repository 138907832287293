import { useIntl } from 'react-intl';
import { Box, Tooltip, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { FunctionComponent, useState } from 'react';
import FilterItemEdit from './FilterItemEdit';
export const itemHoverStyle = {
  backgroundColor: 'secondary.activeBg',
  color: 'secondary.main',
  '& .delIcon': {
    display: 'block',
    '& .MuiSvgIcon-root': {
      backgroundColor: 'secondary.activeBg',
      color: 'secondary.main'
    }
  }
};
const itemHoverDisabledStyle = {
  backgroundColor: '#EFEDF5',
  color: '#A29DAE',
  cursor: 'default',
  '&:hover': {
    backgroundColor: '#EFEDF5',
    color: '#A29DAE'
  }
};
const filterSty = {
  position: 'relative',
  maxWidth: '364px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: 'fill.disabled',
  px: '12px',
  py: 1,
  mb: 1,
  cursor: 'pointer',
  '& span': {
    lineHeight: '24px'
  },
  '&:hover': itemHoverStyle
};
export const itemStyle = {
  ...filterSty,
  borderRadius: 0
};
export const firstItemStyle = {
  ...filterSty,
  borderRadius: '4px 0px 0px 4px'
};
export const errorItemStyle = {
  backgroundColor: '#FFE7D9 !important',
  color: '#FF4842 !important',
  '& .delIcon': {
    '& .MuiSvgIcon-root': {
      backgroundColor: '#FFE7D9 !important',
      color: '#FF4842 !important'
    }
  }
};
const iconActiveStyle = {
  backgroundColor: 'secondary.activeBg',
  color: 'secondary.main',
  '& .MuiSvgIcon-root': {
    color: 'secondary.main'
  }
};
const iconDisabledStyle = {
  cursor: 'not-allowed',
  backgroundColor: '#F5F4F9 !important',
  '& .MuiSvgIcon-root': {
    color: '#D7D5DF !important'
  }
};
const addStyle = {
  ...filterSty,
  borderRadius: '0px 6px 6px 0px',
  px: 1,
  ml: '1px',
  verticalAlign: 'middle',
  '&:hover': iconActiveStyle
};
export const operateStyle = {
  ...filterSty,
  backgroundColor: 'transparent',
  mx: 2,
  '&:hover': 'none'
};
const FilterItem: FunctionComponent<{
  ref?: any;
  openFilter: boolean;
  isDisabledAdd: boolean;
  isNotOperate: boolean;
  filterType: string;
  preFilterType: string;
  filterPosition: any;
  itemIndex: number;
  type: string;
  list: Array<any>;
  onClick: (e: any, i: any, j: string, k: any) => void;
  onAddPop: (e: any, i: any) => void;
}> = ({
  openFilter,
  isDisabledAdd,
  isNotOperate = false,
  filterType,
  preFilterType,
  filterPosition,
  itemIndex,
  type,
  list,
  onClick,
  onAddPop
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const handleOperateHover = (e: boolean, i: number) => {
    setIsHover(e);
    setHoverIndex(i);
  };
  console.log(list);
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }}>
			{list.length > 0 && list.map((e: any, index: number) => <Box key={index} sx={{
      display: 'flex',
      alignItems: 'center'
    }}>
						<FilterItemEdit openFilter={openFilter} filterType={filterType} preFilterType={preFilterType} filterPosition={filterPosition} isNotOperate={isNotOperate} itemIndex={itemIndex} index={index} item={e} onClick={onClick} onAddPop={onAddPop} />
						{index < list.length - 1 && <Box sx={{
        ...itemStyle,
        ...(isHover && hoverIndex === itemIndex && itemHoverStyle),
        ...(filterType === 'operate' && Number(filterPosition.split('-')[0]) === itemIndex && openFilter && iconActiveStyle),
        ...(isNotOperate && itemHoverDisabledStyle)
      }} onClick={event => !isNotOperate && onClick(event, type, 'operate', itemIndex + '-' + index)} onMouseEnter={() => !isNotOperate && handleOperateHover(true, itemIndex)} onMouseLeave={() => handleOperateHover(false, itemIndex)}>
								<Typography variant="subtitle2" component="span">
									{type.toLocaleLowerCase()}
								</Typography>
							</Box>}
						{index === list.length - 1 && <Box sx={{
        ...addStyle,
        ...(filterType === 'add' && filterPosition === itemIndex && openFilter && iconActiveStyle),
        ...(isDisabledAdd && iconDisabledStyle)
      }} onClick={event => !isDisabledAdd && onClick(event, '', 'add', itemIndex)}>
								<Tooltip arrow placement="top" title={isNotOperate ? '' : isDisabledAdd ? formatMessage({
          defaultMessage: 'One of your filters is missing a value. Finish editing it before adding another'
        }) : formatMessage({
          defaultMessage: 'Create a filter group'
        })}>
									<AddRoundedIcon sx={{
            verticalAlign: 'middle'
          }} />
								</Tooltip>
							</Box>}
					</Box>)}
		</Box>;
};
export default FilterItem;