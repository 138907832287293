import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Box, Button, Menu, MenuItem, Popper, Tooltip, Typography } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useState, useEffect, FunctionComponent, ReactNode } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import SwitchAccount from 'src/app/template/setting/switchAccount';
import TwoFactorAuthDialog from 'src/app/template/setting/twoFactorAuthDialog';
import { Logout, Billing, Setting, SwitchIcon, Company, Help } from 'src/app/icons';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { logoutAsync } from 'src/features/login/loginSlice';
import { selectUser } from 'src/features/user/userSlice';
import { tenantSwitchAsync } from 'src/features/member/memberSlice';
import { showMessage } from 'src/utils/message';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
import Cookies from 'js-cookie';
import { getIsExpand } from 'src/features/setting/settingSlice';
import LongTextEllipsis from 'src/app/components/LongTextEllipsis';
import { updateJourneyHasUnsavedChange } from 'src/features/journey/journeySlice';
import { updateBotHasUnsavedChange } from 'src/features/bot/botHistorySlice';
import { useDocsAddress } from 'src/hooks/useDocsPath';
import { clearAllData } from 'src/utils/tools';
export enum AgentOnlineStatus {
  ONLINE = 'ONLINE',
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE',
}
export const onlineStatusColorMap = {
  [AgentOnlineStatus.ONLINE]: '#11BB82',
  [AgentOnlineStatus.BUSY]: '#FFC107'
};
export const AvatarComponent: FunctionComponent<{
  title: string;
  avatar?: string;
  sx?: any;
  status?: AgentOnlineStatus;
  showStatus?: boolean;
  hasFilter?: boolean;
}> = ({
  title,
  sx,
  avatar,
  status,
  showStatus = true,
  hasFilter = true
}) => {
  const hasStatus = showStatus && status && onlineStatusColorMap[status as keyof typeof onlineStatusColorMap];
  return <Box sx={{
    position: 'relative'
  }}>
			<Avatar {...avatar ? {
      src: avatar
    } : {}} sx={{
      width: '32px',
      height: '32px',
      backgroundColor: 'secondary.bg',
      color: 'secondary.main',
      fontSize: '14px',
      fontWeight: 400,
      textAlign: 'center',
      ...(avatar && status === AgentOnlineStatus.OFFLINE && hasFilter && {
        '& img': {
          filter: 'grayscale(100%)!important'
        }
      }),
      ...(sx && sx)
    }}>
				{avatar ? null : title}
			</Avatar>
			{hasStatus && <Box sx={{
      display: 'inline-block',
      width: '6px',
      height: '6px',
      background: onlineStatusColorMap[status as keyof typeof onlineStatusColorMap],
      borderRadius: '50%',
      position: 'absolute',
      bottom: '1px',
      right: '1px'
    }} />}
		</Box>;
};
export const getFirstLetter = (name: string) => {
  if (!name) {
    return '';
  } else {
    return name[0].toUpperCase();
  }
};
const MenuItemComponent: FunctionComponent<{
  title: string;
  icon: ReactNode;
}> = ({
  title,
  icon
}) => {
  return <Box sx={{
    padding: '6px 8px',
    width: '100%'
  }}>
			<Typography variant="subtitle1" sx={{
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      columnGap: 2
    }}>
				{icon} {title}
			</Typography>
		</Box>;
};
const CustomAvatar = () => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const isExpand = useAppSelector(getIsExpand);
  const user = useAppSelector(selectUser);
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [helpCenterAnchorEl, setHelpCenterAnchorEl] = useState<null | HTMLElement>(null);
  const [loginUser, setLoginUser] = useState<string>('');
  const [isSwitch, setIsSwitch] = useState<boolean>(false);
  const [FAOpen, setFAOpen] = useState<boolean>(false);
  const handleLogout = async () => {
    const res = await dispatch(logoutAsync());
    if (res.payload) {
      const lastPath = encodeURIComponent(window.location.href);
      clearAllData();
      navigate(`/entry/login?lastPath=${lastPath}`);
    }
  };
  const open = Boolean(anchorEl);
  const openHelpCenter = Boolean(helpCenterAnchorEl);
  const handleClose = () => {
    setHelpCenterAnchorEl(null);
    setAnchorEl(null);
  };
  const handleNavigateAndRemember = (path: string) => {
    handleClose();
    const currentLocation = location.pathname;
    sessionStorage.setItem('subMenuBackPath', currentLocation);
    navigate(path);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const helpCenterItemClick = (url: string, e: any) => {
    setHelpCenterAnchorEl(null);
    e.stopPropagation();
    window.open(url);
  };
  const handleSwitchClose = () => {
    handleClose();
    setIsSwitch(false);
  };
  const avatarTitle = getFirstLetter(user?.firstName);
  const handleChangeLoginUser = async (e: any) => {
    if (!e) {
      return;
    }
    const tenantId = e.tenantId;
    const res: any = await dispatch(tenantSwitchAsync({
      tenantId
    }));
    if (res.payload.code === CODE.OK) {
      Cookies.remove('isAuthenticated');
      Cookies.remove('reAuthenticated');
      dispatch(updateJourneyHasUnsavedChange(false));
      dispatch(updateBotHasUnsavedChange(false));
      setLoginUser(tenantId);
      handleSwitchClose();
      setTimeout(() => {
        const redirectPath = params.get('redirectPath');
        if (redirectPath) {
          navigate(decodeURIComponent(redirectPath));
        }
        if (window.BroadcastChannel) {
          const broadcastChannel = new BroadcastChannel('broadcastChannelEvent');
          broadcastChannel.postMessage({
            type: 'userChange'
          });
        }
      }, 300);
    } else if (res.payload.code === CODE.LOGIN_NO_ACCOUNT) {
      navigate('/entry/createCompany', {
        replace: true
      });
    } else if (res.payload.code === CODE.TWO_FACTOR_AUTH) {
      setFAOpen(true);
    } else {
      showMessage({
        message: res.payload.msg,
        type: 'error'
      });
    }
  };
  const onTFAOk = () => {
    handleSwitchClose();
    navigate(0);
  };
  useEffect(() => {
    setLoginUser(user?.accountId);
  }, [user]);
  const {
    docsAddressPrev
  } = useDocsAddress();
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }}>
			<Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: isExpand ? 'space-between' : 'center',
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: isExpand ? '16px 24px' : '16px 0',
      '&:hover': {
        background: '#F5F4F9'
      }
    }} onClick={handleClick}>
				<Box sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
        width: 0,
        flex: 1,
        justifyContent: 'center'
      }}>
					<AvatarComponent status={user?.onlineStatus} hasFilter={false} avatar={user?.avatar} title={avatarTitle} sx={{
          margin: 0
        }} />
					{isExpand && <Box sx={{
          width: 0,
          flex: 1
        }}>
							<LongTextEllipsis sx={{
            fontSize: '14px',
            color: '#1A1E22',
            fontWeight: 600,
            lineHeight: '22px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
          }} text={user?.firstName} />
							<LongTextEllipsis sx={{
            fontSize: '12px',
            color: '#A29DAE',
            fontWeight: 400,
            lineHeight: '18px'
          }} text={user?.companyName} />
						</Box>}
				</Box>
				{isExpand && <ArrowForwardIosRoundedIcon sx={{
        fontSize: '14px'
      }} />}
			</Box>
			<Menu anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} anchorEl={anchorEl} open={open} onClose={handleClose} sx={{
      '& .MuiMenu-list': {
        padding: 0
      }
    }}>
				<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 24px',
        boxSizing: 'border-box',
        width: '300px',
        '&:hover': {
          background: 'transparent!important'
        }
      }}>
					<AvatarComponent showStatus={false} hasFilter={false} avatar={user?.avatar} title={avatarTitle} sx={{
          margin: 0,
          width: '42px',
          height: '42px'
        }} />
					<LongTextEllipsis text={formatMessage({
          defaultMessage: '{a} {b}'
        }, {
          a: user?.firstName,
          b: user?.lastName
        })} sx={{
          color: '#1A1E22',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '22px',
          marginTop: '8px',
          textAlign: 'center'
        }} />
					<LongTextEllipsis text={user?.loginAccount} sx={{
          color: '#898395',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '18px',
          textAlign: 'center'
        }} />
					<Button size="small" onClick={() => handleNavigateAndRemember('/app/userSettings/myProfile')} sx={{
          px: '12px',
          color: 'secondary.main',
          '&:hover': {
            backgroundColor: 'action.hover'
          },
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '22px',
          marginTop: '8px',
          cursor: 'pointer'
        }}>
						<FormattedMessage defaultMessage={'Edit'} />
					</Button>
				</Box>

				<Box sx={{
        borderTop: 'solid 1px #E7E6EF'
      }}>
					{user?.role === 'ADMIN' && <MenuItem onClick={() => handleNavigateAndRemember('/app/settings/companyInfo')}>
							<MenuItemComponent icon={<Setting />} title={formatMessage({
            defaultMessage: 'Settings'
          })} />
						</MenuItem>}
					{user?.role !== 'ADMIN' && isOtherBrand ? null : <MenuItem onClick={() => {
          if (user?.role === 'ADMIN') {
            handleNavigateAndRemember('/app/billing/overview');
          } else {
            handleNavigateAndRemember('/app/billing/buyCredits');
          }
        }}>
							<MenuItemComponent icon={<Billing />} title={formatMessage({
            defaultMessage: 'Billing'
          })} />
						</MenuItem>}
					{!isOtherBrand && <MenuItem onMouseEnter={(event: any) => setHelpCenterAnchorEl(event.currentTarget)} onMouseLeave={() => setHelpCenterAnchorEl(null)}>
							<MenuItemComponent icon={<Help />} title={formatMessage({
            defaultMessage: 'Help center'
          })} />
							<Popper anchorEl={helpCenterAnchorEl} placement="right-end" open={openHelpCenter} sx={{
            zIndex: 3000
          }}>
								<Box sx={{
              backgroundColor: '#fff',
              boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);',
              borderRadius: '4px'
            }}>
									<MenuItem sx={{
                padding: '12px 24px',
                fontSize: '16px',
                color: '#212B36',
                fontWeight: 400,
                lineHeight: '24px'
              }} onClick={e => helpCenterItemClick('https://docs.ycloud.com/reference/introduction', e)}>
										<FormattedMessage defaultMessage={'API docs'} />
									</MenuItem>
									<MenuItem sx={{
                padding: '12px 24px',
                fontSize: '16px',
                color: '#212B36',
                fontWeight: 400,
                lineHeight: '24px'
              }} onClick={e => helpCenterItemClick(docsAddressPrev, e)}>
										<FormattedMessage defaultMessage={'Product docs'} />
									</MenuItem>
								</Box>
							</Popper>
						</MenuItem>}
				</Box>
				<Box sx={{
        borderTop: 'solid 1px #D7D5DF'
      }}>
					{user?.tenantNums > 1 && <MenuItem sx={{
          padding: '9px 16px'
        }}>
							<Box sx={{
            padding: '6px 8px',
            display: 'flex',
            alignItems: 'center',
            columnGap: '16px',
            width: '100%',
            boxSizing: 'border-box'
          }}>
								<Company />
								<Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 0,
              flex: 1
            }}>
									<Box sx={{
                width: 0,
                flex: 1
              }}>
										<LongTextEllipsis text={user?.companyName} sx={{
                  lineHeight: '24px',
                  cursor: 'pointer',
                  color: 'rgb(26, 30, 34)',
                  fontSize: '16px',
                  fontWeight: 400,
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                }} />
									</Box>

									<Tooltip arrow title={formatMessage({
                defaultMessage: 'Switch Account'
              })} placement="top">
										<Button size="small" onClick={() => setIsSwitch(true)} sx={{
                  minWidth: '30px',
                  width: '30px!important',
                  height: '30px',
                  boxSizing: 'border-box',
                  color: 'secondary.main',
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                }}>
											<SwitchIcon sx={{
                    margin: 0,
                    padding: 0
                  }} />
										</Button>
									</Tooltip>
								</Box>
							</Box>
						</MenuItem>}
					<MenuItem onClick={handleLogout}>
						<MenuItemComponent icon={<Logout />} title={formatMessage({
            defaultMessage: 'Log out'
          })} />
					</MenuItem>
				</Box>
			</Menu>
			{isSwitch && <SwitchAccount open={isSwitch} title={formatMessage({
      defaultMessage: 'Switch Account'
    })} subTitle={formatMessage({
      defaultMessage: 'Please select an account from the following list'
    })} curAccount={loginUser} onChoose={handleChangeLoginUser} onClose={handleSwitchClose}>
					<TwoFactorAuthDialog open={FAOpen} action="switch-tenant" onClose={() => setFAOpen(false)} onOk={onTFAOk} />
				</SwitchAccount>}
		</Box>;
};
export default CustomAvatar;