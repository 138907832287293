import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import { getBlacklist, selectCustomerType, selectUser } from 'src/features/user/userSlice';
import { useMemo } from 'react';
import { isEmpty, cloneDeep } from 'lodash-es';
import WrapPageContainer from 'src/routes/pageLayout/wrapPageContainer';
import ProtectedRoute from 'src/app/components/protectedRoute';
import PageContainer from 'src/routes/pageLayout/pageContainer';
import CtwaPageContainer from 'src/routes/pageLayout/ctwaPageContainer';
import loadComponent from './loadComponent';
/* 菜单的布局 */
// import MenuPageContainer from 'src/routes/pageLayout/menuPageContainer';
import PageContainerBack from 'src/routes/pageLayout/pageContainerBack';
import EmptyPage from 'src/app/template/empty';

/* 子路由模块 */
import dashboardRouter from './modules/dashboard';
import campaignsRouter from './modules/campaigns';
import contactRouter from './modules/contact';
import botRouter from './modules/bot';
import inboxRouter from './modules/inbox';
import ctwaRouter from './modules/ctwa';
import journeyRouter from './modules/journey';
import integrationsRouter from './modules/integrations';
import partnerRouter from './modules/partners';
import billingRouter from './modules/billing';
import settingsRouter from './modules/settings';
import userSettingsRouter from './modules/userSettings';
import developersRouter from './modules/developers';
import Layout from 'src/layout';
import { CustomerType } from 'src/features/user/type';
export const mainRouters = [{
  path: 'dashboard',
  element: <PageContainer />,
  children: dashboardRouter,
  unRoles: ['AGENT', 'SALE'] // 不允许的角色
}, {
  element: <PageContainer />,
  path: 'inbox',
  children: inboxRouter
}, {
  path: 'contact',
  element: <PageContainer />,
  children: contactRouter,
  unRoles: ['OPERATOR', 'AGENT'] // 不允许的角色
}, {
  path: 'bulkMessages',
  element: <PageContainer />,
  children: campaignsRouter,
  unRoles: ['AGENT', 'SALE'] // 不允许的角色
}, {
  path: 'journey',
  element: <PageContainer />,
  children: journeyRouter,
  unRoles: ['AGENT', 'SALE'] // 不允许的角色
}, {
  element: <PageContainer />,
  path: 'chatbot',
  children: botRouter,
  unRoles: ['OPERATOR', 'AGENT', 'SALE'] // 不允许的角色
}, {
  path: 'ctwa',
  element: <Outlet />,
  children: ctwaRouter,
  unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER],
  unRoles: ['AGENT', 'SALE'] // 不允许的角色
}, {
  path: 'integrations',
  unRoles: ['OPERATOR', 'AGENT', 'SALE'],
  // 不允许的角色
  element: <Outlet />,
  unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER],
  children: integrationsRouter
}, {
  path: 'developers',
  roles: ['ADMIN', 'DEVELOPER'],
  element: <PageContainer />,
  children: developersRouter
}, {
  path: 'billing',
  element: <PageContainerBack titleKey="billing" />,
  children: billingRouter
}, {
  path: 'partners',
  element: <PageContainer />,
  partnerTypes: [CustomerType.CHANNEL],
  children: partnerRouter
}, {
  path: 'settings',
  roles: ['ADMIN'],
  element: <PageContainerBack titleKey="settings" />,
  children: settingsRouter
}, {
  path: 'userSettings',
  element: <PageContainerBack titleKey="userSettings" />,
  children: userSettingsRouter
}, {
  path: 'notFound',
  element: <EmptyPage type="404" />
}, {
  path: 'noPower',
  element: <EmptyPage type="noPower" />
}, {
  path: '*',
  index: true,
  element: <Navigate to="/app/dashboard/getStarted" />
}];
export const rootRouters = [{
  path: '/',
  element: <WrapPageContainer />,
  children: [{
    element: <ProtectedRoute />,
    children: [{
      path: 'app/*',
      element: <Layout />
    }]
  }, {
    path: '/entry',
    children: [{
      path: '/entry/login',
      element: loadComponent('login')
    }, {
      path: '/entry/register',
      element: loadComponent('register')
    }, {
      path: '/entry/registerConfirm',
      element: loadComponent('registerActive')
    }, {
      path: '/entry/forgotPassword',
      element: loadComponent('forgot')
    }, {
      path: '/entry/resetPassword',
      element: loadComponent('resetPassword')
    }, {
      path: '/entry/changeTemporaryPassword',
      element: loadComponent('login/ChangeTemporaryPassword')
    }, {
      path: '/entry/twoFactorAuth',
      element: loadComponent('login/twoFactorAuth')
    }, {
      path: '/entry/activeResult',
      element: loadComponent('activeUserResult')
    }, {
      path: '/entry/createCompany',
      element: loadComponent('createCompany')
    }, {
      path: '*',
      index: true,
      element: <Navigate to="/entry/login" />
    }]
  }, {
    path: '/partner/wabaBind',
    element: loadComponent('wabaBind')
  }, {
    path: '/stripeResult/:status',
    element: loadComponent('billing/components/stripeResult')
  }, {
    path: '/',
    index: true,
    element: <Navigate to="/entry/login" />
  }]
}];
export const screenRoutesByRole = (routes: any[], role: string, blacklist: string[] = [], customerType?: CustomerType) => {
  return routes.map(route => {
    const {
      roles: canIn,
      unRoles: cantIn,
      name,
      alignName
    } = route;
    let noPower = false;
    if (route.partnerTypes && !route.partnerTypes.includes(customerType) || route.unPartnerTypes && route.unPartnerTypes.includes(customerType)) {
      noPower = true;
    }
    if (!isEmpty(canIn) || !isEmpty(cantIn)) {
      // 以unRoles 优先
      if (Array.isArray(cantIn) && cantIn.includes(role)) {
        noPower = true;
      }
      if (Array.isArray(canIn) && !canIn.includes(role)) {
        noPower = true;
      }
    }
    if (blacklist.find(item => item === alignName || item === name)) {
      noPower = true;
    }
    if (noPower) {
      return {
        ...route,
        element: <Navigate to="/app/noPower" />
      };
    }
    if (isEmpty(route.children)) {
      return route;
    }
    const lastChildrenRoute = route.children[route.children.length - 1];
    if (lastChildrenRoute.path !== '*' && !lastChildrenRoute.index) {
      route.children.push({
        path: '*',
        index: true,
        element: <Navigate to="/app/notFound" />
      });
    }
    route.children = screenRoutesByRole(route.children, role, blacklist, customerType);
    return route;
  });
};
const useFilterRouter = (routers: any[]) => {
  const {
    role
  } = useAppSelector(selectUser);
  const customerType = useAppSelector(selectCustomerType);
  const blacklist = useAppSelector(getBlacklist);
  const curRoutes = useMemo(() => {
    return screenRoutesByRole(cloneDeep(routers), role, blacklist, customerType);
  }, [role, blacklist, customerType]);
  const router: any = useRoutes(curRoutes);
  return router;
};
export default useFilterRouter;