import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogActions, Stack, Button, FormControl, Switch, Box, MenuItem, Select } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import { CustomLabel } from 'src/app/components/form';
import { formatDateTime } from 'src/utils/transform';
const TimeZone: FunctionComponent<{
  open: boolean;
  onClose: () => void;
  onSubmit: (v: any) => void;
}> = ({
  open,
  onClose,
  onSubmit
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const form = useForm({
    defaultValues: {
      timezoneDisplay: true,
      timezone: 8
    }
  });
  const {
    control,
    handleSubmit,
    watch
  } = form;
  const timezoneDisplay = watch('timezoneDisplay');
  const timezone = watch('timezone');
  const handleAddEditSubmit = async (v: any) => {
    onSubmit(v);
  };
  const getNowByZone = (zone: number = 8) => {
    const offsetGMT: number = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
    const nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
    const targetDate = new Date(nowDate + offsetGMT * 60 * 1000 + zone * 60 * 60 * 1000);
    return formatDateTime(targetDate);
  };
  return <Dialog open={open} maxWidth="md">
			<CustomDialogTitle title={formatMessage({
      defaultMessage: 'Please set the Time display'
    })} onClose={onClose} sx={{
      pl: 4,
      py: 3
    }} />
			<DialogContent sx={{
      width: '600px',
      boxSizing: 'border-box',
      pb: 3,
      px: 4
    }}>
				<Box sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        mb: 3,
        fontWeight: 400,
        lineHeight: '20px',
        color: 'secondary.main'
      }}>
					<FormattedMessage defaultMessage={'Preview'} />:
					<Box sx={{
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '30px',
          ml: '6px'
        }}>
						<>
							{getNowByZone(timezone)}&ensp;{timezoneDisplay && timezone && `GMT${timezone < 0 ? timezone : `+${timezone}`}`}
						</>
					</Box>
				</Box>

				<FormProvider {...form}>
					<Stack spacing={1}>
						<FormControl fullWidth>
							<CustomLabel>
								<FormattedMessage defaultMessage={'Select time zone'} />
							</CustomLabel>
							<Controller name="timezone" control={control} render={({
              field
            }) => <Select {...field} size="small" variant="outlined" sx={{
              width: '254px'
            }} MenuProps={{
              sx: {
                '& .MuiMenu-paper': {
                  maxHeight: '400px'
                },
                '& .MuiMenu-list': {
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }
            }}>
										{[-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(v => <MenuItem key={v} value={v}>
												GMT{v < 0 ? v : `+${v}`}
											</MenuItem>)}
									</Select>} />
						</FormControl>
						<FormControl fullWidth>
							<CustomLabel>
								<FormattedMessage defaultMessage={'Show time zone'} />
							</CustomLabel>
							<Controller name="timezoneDisplay" control={control} render={({
              field: {
                value,
                ...field
              }
            }) => <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
										<Switch {...field} checked={value} />
									</div>} />
						</FormControl>
					</Stack>
				</FormProvider>
			</DialogContent>
			<DialogActions sx={{
      px: 4,
      py: 2,
      boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)'
    }}>
				<Button variant="outlined" size="medium" sx={{
        mr: 1
      }} onClick={onClose}>
					<FormattedMessage defaultMessage={'Cancel'} />
				</Button>
				<Button variant="contained" size="medium" onClick={handleSubmit(handleAddEditSubmit)}>
					<FormattedMessage defaultMessage={'OK'} />
				</Button>
			</DialogActions>
		</Dialog>;
};
export default TimeZone;