import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { Message } from './type';

export interface ToastState {
	message: Message;
}

const initialState: ToastState = {
	message: {
		type: 'default',
		message: '',
		duration: 0,
	},
};

export const selectMessage = (state: RootState) => state.toast.message;

export const ToastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		updateMessage: (state, action) => {
			state.message = Object.assign({}, action.payload);
		},
	},
});

export const { updateMessage } = ToastSlice.actions;

export default ToastSlice.reducer;
