import { FormattedMessage } from 'react-intl';
import { Box, FormHelperText, Typography } from '@mui/material';
import { useState, FunctionComponent } from 'react';
import VariablesPopper from './VariableButton/components/VariablesPopper';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CustomChip from 'src/app/components/customChip';
const VariableInput: FunctionComponent<{
  onChange: (v: any) => void;
  error?: boolean;
  value: any;
  helperText?: any;
  [index: string]: any;
}> = ({
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClose: any = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const clearValue = (e: any) => {
    e.stopPropagation();
    props.onChange('');
  };
  return <>
			<Box>
				<Box sx={{
        height: '40px',
        p: '8px 12px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        border: props.error ? '1px solid #FF4842' : '1px solid #A29DAE',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          svg: {
            opacity: props.value ? 1 : 0
          }
        }
      }} onClick={handleClick}>
					<Box sx={{
          flex: 1,
          width: 0
        }}>
						{props.value ? <CustomChip color="secondary" label={`{{${props.value}}}`} sx={{
            maxWidth: '100%'
          }} /> : <Typography variant="body2" sx={{
            color: '#A29DAE'
          }}>
								<FormattedMessage defaultMessage={'variable'} />
							</Typography>}
					</Box>
					<ClearOutlinedIcon sx={{
          opacity: 0,
          cursor: 'pointer'
        }} onClick={clearValue} />
				</Box>
				{props.error && <FormHelperText error={Boolean(props.error)}>{props.helperText}</FormHelperText>}
			</Box>
			{open && anchorEl && <VariablesPopper {...props} open anchorEl={anchorEl} onChange={(newValue: string) => {
      props.onChange(newValue);
      handleClose();
    }} onClose={handleClose} />}
		</>;
};
export default VariableInput;