import loadComponent from '../loadComponent';
import IntegrationsPageContainer from 'src/routes/pageLayout/integrationsPageContainer';
import MainPage from 'src/app/template/mainPage';
import PageContainer from 'src/routes/pageLayout/pageContainer';
import { FormattedMessage } from 'react-intl';
const smsSubMenu = {
  title: <FormattedMessage defaultMessage={'Analytics'} />,
  paths: [{
    name: <FormattedMessage defaultMessage={'Logs'} />,
    path: '/app/integrations/sms/analytics/logs'
  }, {
    name: <FormattedMessage defaultMessage={'Overview'} />,
    path: '/app/integrations/sms/analytics/overview'
  }]
};
const emailSubMenu = {
  title: <FormattedMessage defaultMessage={'Analytics'} />,
  paths: [{
    name: <FormattedMessage defaultMessage={'Overview'} />,
    path: '/app/integrations/email/analytics/overview'
  }, {
    name: <FormattedMessage defaultMessage={'Logs'} />,
    path: '/app/integrations/email/analytics/logs'
  }]
};
const verifySubMenu = {
  title: <FormattedMessage defaultMessage={'Analytics'} />,
  paths: [{
    name: <FormattedMessage defaultMessage={'Logs'} />,
    path: '/app/integrations/voice/analytics/logs'
  }]
};
const integrationsRouter = [{
  path: 'all',
  element: loadComponent('integrations/all')
}, {
  path: 'sms',
  name: <FormattedMessage defaultMessage={'SMS'} />,
  element: <IntegrationsPageContainer titleKey="sms" />,
  children: [{
    path: 'getStarted',
    element: loadComponent('integrations/sms/getStarted')
  }, {
    path: 'senderId',
    element: loadComponent('integrations/sms/senderId')
  }, {
    path: 'addSenderId',
    element: loadComponent('integrations/sms/senderId/components/add')
  }, {
    path: 'analytics',
    element: <MainPage hasExpand {...smsSubMenu} />,
    children: [{
      path: 'logs',
      element: loadComponent('integrations/sms/analytics/components/logs')
    }, {
      path: 'overview',
      element: loadComponent('integrations/sms/analytics/components/overview')
    }]
  }]
}, {
  path: 'email',
  name: <FormattedMessage defaultMessage={'EMAIL'} />,
  element: <IntegrationsPageContainer titleKey="email" />,
  children: [{
    path: 'getStarted',
    element: loadComponent('integrations/email/getStarted')
  }, {
    path: 'domains',
    element: loadComponent('integrations/email/domains')
  }, {
    path: 'analytics',
    element: <MainPage hasExpand {...emailSubMenu} />,
    children: [{
      path: 'logs',
      element: loadComponent('integrations/email/analytics/components/logs')
    }, {
      path: 'overview',
      element: loadComponent('integrations/email/analytics/components/overview')
    }]
  }]
}, {
  path: 'voice',
  name: 'VOICE_CODE',
  element: <IntegrationsPageContainer titleKey="voice" />,
  children: [{
    path: 'getStarted',
    element: loadComponent('integrations/voiceCode/getStarted')
  }, {
    path: 'analytics',
    element: <MainPage hasExpand {...verifySubMenu} />,
    children: [{
      path: 'logs',
      element: loadComponent('integrations/voiceCode/analytics/components/logs')
    }]
  }]
}, {
  path: 'verify',
  name: 'VERIFY',
  element: <IntegrationsPageContainer titleKey="verify" />,
  children: [{
    path: 'getStarted',
    element: loadComponent('integrations/verification')
  }, {
    path: 'setting',
    element: loadComponent('integrations/verification/setting')
  }, {
    path: 'security',
    element: loadComponent('integrations/verification/security')
  }, {
    path: 'analytics',
    element: loadComponent('integrations/verification/analytics')
  }]
}, {
  name: 'SHOPIFY',
  path: 'shopify',
  element: loadComponent('integrations/shopify')
}, {
  name: 'COUPON',
  path: 'coupon',
  element: <PageContainer />,
  children: [{
    path: 'list',
    element: loadComponent('integrations/coupon/list')
  }, {
    path: 'detail',
    element: loadComponent('integrations/coupon/detail')
  }]
}];
export default integrationsRouter;