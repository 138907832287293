import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Autocomplete, Box, Button, FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { FunctionComponent, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { CustomLabel } from 'src/app/components/form';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import TextCountInput from 'src/app/components/form/textCountInput';
import CountryPhone from 'src/app/components/form/countryPhone';
import NumberInput from 'src/app/components/numberInput';
import CustomDateTimePicker from 'src/app/components/form/CustomDateTimePicker';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hostnameAndPrev } from 'src/app/const';
import { isPhoneExistAsync, checkEmailDuplicateAsync } from 'src/features/contact/contactList/contactListSlice';
import { DateTimeRegexp, phoneRegexp, patternMessageMap, globalPhoneRegexp } from 'src/utils/regexp';
import { transformPhoneNumber, formatDateTime } from 'src/utils/transform';
import AddTagInput from '../../components/AddTagInput';
import { getAllAgentsAsync } from 'src/features/inboxAnalytics/inboxAnalyticsSlice';
import OptionEditor from 'src/app/components/form/OptionEditor';
import { selectUser } from 'src/features/user/userSlice';
export const tipMap = defineMessages({
  owner: {
    defaultMessage: 'The email address of the contacts’ dedicated agent, to whom conversation can be assigned priority.'
  }
});
export const renderOwner = (ownerEmail?: string, ownerName?: string) => {
  if (!ownerEmail) {
    return '';
  } else if (ownerName) {
    return `${ownerName} (${ownerEmail})`;
  } else {
    return ownerEmail;
  }
};
const UserForm: FunctionComponent<{
  contactId: string;
}> = ({
  contactId
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const {
    control,
    watch,
    setValue,
    formState: {
      errors
    }
  } = useFormContext();
  const areaNo = watch('areaNo');
  const customAttrs = watch('customAttrs');
  const [allAgents, setAllAgents] = useState([]);
  const {
    role,
    loginAccount,
    lastName,
    firstName
  } = useAppSelector(selectUser);
  const [changeSaleOwner, setChangeSaleOwner] = useState(false);
  const getAllAgents = async () => {
    const res: any = await dispatch(getAllAgentsAsync());
    if (res?.payload?.data?.records) {
      setAllAgents(res.payload.data?.records);
    }
  };
  const checkOwner = (newValue: string) => {
    if (!newValue) {
      setChangeSaleOwner(true);
      return;
    }
    const reg = /\(([^)]*)\)/;
    const match = newValue.match(reg);
    const owner = match ? match[1] : newValue;
    if (owner !== loginAccount) {
      setChangeSaleOwner(true);
    } else {
      setChangeSaleOwner(false);
    }
  };
  useEffect(() => {
    if (role === 'SALE') {
      setValue('owner', renderOwner(loginAccount, firstName || lastName ? `${firstName} ${lastName}` : undefined));
    }
    getAllAgents();
  }, []);
  const handleMobile = (e: any) => {
    if (e) {
      setValue('mobile', e.mobile, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue('areaNo', e.areaNo, {
        shouldDirty: true
      });
    }
  };
  return <Stack spacing={1}>
			<Typography variant="subtitle1" sx={{
      py: '4px'
    }}>
				<FormattedMessage defaultMessage={'System attributes'} />
			</Typography>
			<FormControl sx={{
      width: '300px'
    }}>
				<CustomLabel>
					<FormattedMessage defaultMessage={'Nickname'} />
				</CustomLabel>
				<Controller name="nickName" control={control} render={({
        field
      }) => <TextCountInput {...field} placeholder={formatMessage({
        defaultMessage: 'Please enter'
      })} maxLen="250" pRight="60px" error={Boolean(errors.nickName?.type)} helperText={<ErrorHelperText>{errors.nickName?.message}</ErrorHelperText>} />} />
			</FormControl>
			<FormControl sx={{
      width: '368px'
    }}>
				<CustomLabel>
					{formatMessage({
          defaultMessage: 'Phone number (Required)'
        })}
				</CustomLabel>
				<Controller name="mobile" control={control} rules={{
        validate: {
          validPhone: (value: string) => {
            const code = value.split(':')[1];
            if (areaNo === 'CN' && !phoneRegexp.test(value)) {
              return formatMessage(patternMessageMap['cnPhoneNumber']);
            } else if (!globalPhoneRegexp.test(value)) {
              return formatMessage(patternMessageMap['phoneNumber']);
            }
            const mobile = transformPhoneNumber(value);
            if (code && !isValidPhoneNumber(mobile)) {
              return formatMessage({
                defaultMessage: 'Invalid Phone Number'
              });
            }
            return true;
          },
          duplicate: async (value: string) => {
            const mobile = transformPhoneNumber(value);
            const res: any = await dispatch(isPhoneExistAsync({
              phoneNumber: mobile,
              contactId
            })).unwrap();
            if (res.data) {
              return formatMessage({
                defaultMessage: 'The phone number already exist.'
              });
            }
          }
        }
      }} render={({
        field
      }) => <CountryPhone {...field} areaNo={areaNo} onChange={handleMobile} error={Boolean(errors.mobile?.type)} helperText={<>{errors.mobile?.message}</>} />} />
			</FormControl>
			<FormControl sx={{
      width: '400px'
    }}>
				<CustomLabel tooltip={formatMessage(tipMap['owner'])}>
					<FormattedMessage defaultMessage={'Owner'} />
				</CustomLabel>
				<Controller name="owner" control={control} rules={{
        validate: {
          validEmail: (value: string) => {
            if (role === 'SALE') {
              checkOwner(value);
            }
            if (value && !/[_\w-.]+@([\w-]+\.)+[\w-]{2,4}/.test(value)) {
              return formatMessage(patternMessageMap['email']);
            }
            return true;
          }
        }
      }} render={({
        field
      }) => <Autocomplete freeSolo {...field} onChange={(e, newValue) => {
        setValue('owner', newValue);
      }} options={allAgents.map((item: any) => renderOwner(item.email, item.firstName || item.lastName ? `${item.firstName} ${item.lastName}` : undefined))} renderInput={params => {
        return <TextField {...params} error={Boolean(errors.owner?.type)} helperText={<>{errors.owner?.message}</>} placeholder={formatMessage({
          defaultMessage: 'Please input or select'
        })} {...field} />;
      }} />} />
			</FormControl>
			{role !== 'SALE' || !changeSaleOwner ? null : <Box sx={{
      display: 'flex',
      mt: 1
    }}>
					<InfoOutlinedIcon sx={{
        color: 'info.main',
        fontSize: '16px',
        mt: '2px',
        mr: '4px'
      }} />
					<Typography variant="caption" color="text.secondary">
						<FormattedMessage defaultMessage={'The owner is empty or belongs to someone else, you will not be able to see this data in the contacts.'} />
					</Typography>
				</Box>}
			<FormControl sx={{
      width: '400px'
    }}>
				<CustomLabel>
					<FormattedMessage defaultMessage={'Email'} />
				</CustomLabel>
				<Controller name="email" control={control} rules={{
        validate: {
          validEmail: (value: string) => {
            if (value && !/^[_\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
              return formatMessage({
                defaultMessage: 'Incorrect mailbox format'
              });
            }
            return true;
          },
          emailDuplicate: async (value: string) => {
            if (!value) {
              return true;
            } else {
              const emailDuplicateRes = await dispatch(checkEmailDuplicateAsync({
                email: value,
                contactId
              }));
              if (emailDuplicateRes && emailDuplicateRes.payload) {
                return formatMessage({
                  defaultMessage: 'The email already exist.'
                });
              }
            }
          }
        }
      }} render={({
        field
      }) => <TextCountInput {...field} placeholder={formatMessage({
        defaultMessage: 'Please enter'
      })} maxLen="250" pRight="60px" error={Boolean(errors.email?.type)} helperText={<>{errors.email?.message}</>} />} />
			</FormControl>
			<FormControl sx={{
      width: '400px'
    }}>
				<CustomLabel>
					<FormattedMessage defaultMessage={'Tag'} />
				</CustomLabel>
				<Controller name="tag" control={control} render={({
        field
      }) => <AddTagInput {...field} />} />
			</FormControl>
			{customAttrs.length === 0 && role !== 'SALE' ? <Button variant="text" color="secondary" startIcon={<AddRoundedIcon />} onClick={() => window.open(`${hostnameAndPrev}/app/contact/settings`, '_blank')} sx={{
      backgroundColor: 'transparent !important',
      mt: '24px !important',
      width: '190px'
    }}>
					<FormattedMessage defaultMessage={'Customized attributes'} />
				</Button> : <>
					<Typography variant="subtitle1" sx={{
        py: '4px',
        mt: '24px !important'
      }}>
						<FormattedMessage defaultMessage={'Customized attributes'} />
					</Typography>
					{customAttrs.map((e: any, index: number) => <FormControl key={e.displayName} sx={{
        width: '400px'
      }}>
							<CustomLabel sx={{
          wordWrap: 'break-word'
        }}>{e.displayName}</CustomLabel>
							<Controller name={`customAttrs.${index}.value`} control={control} rules={{
          validate: (value: any) => {
            if (e.displayType === 'Time') {
              if (value !== undefined && isNaN(value)) {
                return formatMessage({
                  defaultMessage: 'Please select or enter a valid time'
                });
              } else if (value) {
                const dateTime = formatDateTime(value);
                if (!DateTimeRegexp.test(dateTime)) {
                  return formatMessage({
                    defaultMessage: 'Please select or enter a valid time'
                  });
                }
              }
            }
            return true;
          }
        }} render={({
          field
        }) => {
          const targetValue = watch(`customAttrs.${index}.value`);
          return <>
											{e.displayType === 'Number' ? <NumberInput {...field} size="small" isReturnNum={false} placeholder={formatMessage({
              defaultMessage: 'Please enter'
            })} /> : e.displayType === 'True/False' ? <TextField {...field} select size="small" variant="outlined" placeholder={formatMessage({
              defaultMessage: 'Please select'
            })} sx={{
              '& input': {
                opacity: targetValue ? 0 : 0.42,
                pl: '12px'
              }
            }}>
													<MenuItem value="">
														<FormattedMessage defaultMessage={'None'} />
													</MenuItem>
													<MenuItem value="True">
														<FormattedMessage defaultMessage={'True'} />
													</MenuItem>
													<MenuItem value="False">
														<FormattedMessage defaultMessage={'False'} />
													</MenuItem>
												</TextField> : e.displayType === 'Time' ? <CustomDateTimePicker {...field} allowClear error={Boolean(errors.customAttrs && (errors.customAttrs as any)[index]?.value.type)} /> : e.displayType === 'Long Text' ? <TextCountInput {...field} placeholder={formatMessage({
              defaultMessage: 'Please enter'
            })} maxLen="5000" pRight="80px" /> : e.displayType === 'Array' ? <OptionEditor options={e.values || []} {...field} /> : <TextCountInput {...field} placeholder={formatMessage({
              defaultMessage: 'Please enter'
            })} maxLen="250" pRight="60px" />}
										</>;
        }} />
						</FormControl>)}
				</>}
		</Stack>;
};
export default UserForm;