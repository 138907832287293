import { useIntl } from 'react-intl';
import type { FC } from 'react';
import { useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDrag, useDrop } from 'react-dnd';
import ItemEditor, { DragHandleContent, style } from '../ItemEditor';
import { ItemTypes } from './ItemTypes';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { Box } from '@mui/material';
export interface CardProps {
  id: any;
  text: string;
  index: number;
  onValueChange: any;
  onDelete: any;
  error: string;
  onBlur: any;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}
export const BoxWithHandle: FC<CardProps> = ({
  onBlur,
  id,
  error,
  text,
  index,
  onDelete,
  moveCard,
  onValueChange
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const [{
    handlerId
  }, drop] = useDrop<DragItem, void, {
    handlerId: any;
  }>({
    accept: ItemTypes.BOX,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{
    isDragging
  }, drag, preview] = useDrag({
    type: ItemTypes.BOX,
    item: () => {
      return {
        id,
        index
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(dragRef);
  drop(preview(ref));
  return <Box ref={ref} style={{
    ...style,
    opacity
  }} data-handler-id={handlerId}>
			<ItemEditor maxLength={20} onBlur={onBlur} error={error} handle={<div ref={dragRef}>
						<DragHandleContent isDragging={isDragging} />
					</div>} value={text} onChange={newValue => onValueChange(index, newValue)} onDelete={() => onDelete(index)} />
			{error && <ErrorHelperText>
					{error === 'required' ? formatMessage({
        defaultMessage: 'Required'
      }) : formatMessage({
        defaultMessage: 'Duplicate'
      })}
				</ErrorHelperText>}
		</Box>;
};