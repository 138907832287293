import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetSenderIdListParams, SetDefaultSenderIdParams, AvailableSenderCountry, CreateSenderIdParams } from './type';
import { getSenderIdList, setDefaultSenderId, getSenderIdAvailableCountriesList, submitAddSenderId, deleteSenderId } from './smsSenderIdAPI';
import { RootState } from 'src/app/store';

export interface SmsSenderIdState {
	availableSenderCountries: Array<AvailableSenderCountry>;
}

const initialState: SmsSenderIdState = {
	availableSenderCountries: [],
};

export const getSmsSenderIdListAsync = createAsyncThunk('user/getSmsSenderIdList', async (params: GetSenderIdListParams) => {
	const response = await getSenderIdList(params);
	return response;
});

export const setDefaultSenderIdAsync = createAsyncThunk('user/setDefaultSenderId', async (params: SetDefaultSenderIdParams) => {
	const response = await setDefaultSenderId(params);
	return response;
});

export const getSenderIdAvailableCountriesListAsync = createAsyncThunk('user/getSenderIdAvailableCountriesList', async () => {
	const response = await getSenderIdAvailableCountriesList();
	return response;
});

export const submitAddSenderIdAsync = createAsyncThunk('user/submitAddSenderId', async (params: CreateSenderIdParams) => {
	const response = await submitAddSenderId(params);
	return response;
});

export const deleteSenderIdAsync = createAsyncThunk('user/deleteSenderId', async (params: { id: string }) => {
	const response = await deleteSenderId(params);
	return response;
});

export const smsSenderIdSlice = createSlice({
	name: 'smsSenderId',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getSenderIdAvailableCountriesListAsync.fulfilled, (state, action) => {
			state.availableSenderCountries = action.payload.data;
		});
	},
});

export const selectSenderCountryList = (state: RootState) =>
	state.smsSenderId.availableSenderCountries.map((item: AvailableSenderCountry) => {
		return {
			continent: item.groupName,
			list: item.countries.map(country => {
				return {
					countryCode: country.code,
					countryName: country.name,
				};
			}),
		};
	});

export default smsSenderIdSlice.reducer;
