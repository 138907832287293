import { Data, Pagin } from 'src/utils/fetch';
import { defineMessages } from 'react-intl';
import { WhatsAppFlowsStatusEnum } from '../whatsappFlows/type';

export enum NodeType {
	KEYWORD = 'keyword',
	COMPOSITE_TRIGGER = 'compositeTrigger',
	ASK_QUESTION = 'askQuestion',
	SEND_MESSAGE = 'sendMessage',
	CALL_API = 'callApi',
	BRANCHING = 'branching',
	ASSIGN_AGENT = 'assignAgent',
	UNSUBSCRIBE = 'unsubscribe',
	ADD_TAG = 'addTag',
}

export type Variable = {
	name: string;
	type: string;
};

export enum AskQuestionType {
	BUTTON = 'button',
	TEXT = 'text',
	LIST = 'list',
	FLOW = 'flow',
}

export enum KeywordType {
	EXACT = 'exact',
	CONTAINING = 'containing',
}

export enum SendMessageType {
	TEXT = 'text',
	INTERACTIVE = 'interactive',
	IMAGE = 'image',
	VIDEO = 'video',
	DOC = 'document',
	LOCATION = 'location',
}

export const sendMessageTypeLabelMap = defineMessages({
	[SendMessageType.TEXT]: {
		defaultMessage: 'Text',
	},
	[SendMessageType.INTERACTIVE]: {
		defaultMessage: 'Interactive',
	},
	[SendMessageType.IMAGE]: {
		defaultMessage: 'Image',
	},
	[SendMessageType.VIDEO]: {
		defaultMessage: 'Video',
	},
	[SendMessageType.DOC]: {
		defaultMessage: 'Document',
	},
	[SendMessageType.LOCATION]: {
		defaultMessage: 'Location',
	},
});

type SendMessageTextItem = {
	type: SendMessageType.TEXT;
	text: {
		body: string;
	};
};

type SendMessageInteractiveItem = {
	type: SendMessageType.INTERACTIVE;
	interactive: {
		type: string;
		body: {
			text: string;
		};
		action: {
			name: string;
			parameters: {
				display_text: string;
				url: string;
			};
		};
	};
};

type SendMessageImageItem = {
	type: SendMessageType.IMAGE;
	image: {
		link: string;
	};
};

type SendMessageVideoItem = {
	type: SendMessageType.VIDEO;
	video: {
		link: string;
	};
};

type SendMessageDocItem = {
	type: SendMessageType.DOC;
	document: {
		link: string;
	};
};

type SendMessageLocationItem = {
	type: SendMessageType.LOCATION;
	location: {
		longitude: string;
		latitude: string;
		name: string;
		address: string;
	};
};

export type SendMessageItem = SendMessageDocItem | SendMessageImageItem | SendMessageTextItem | SendMessageVideoItem | SendMessageInteractiveItem | SendMessageLocationItem;

export const sendMessageTypeConfig = defineMessages({
	[SendMessageType.TEXT]: {
		defaultMessage: 'Text',
	},
	[SendMessageType.INTERACTIVE]: {
		defaultMessage: 'URL Button',
	},
	[SendMessageType.IMAGE]: {
		defaultMessage: 'Media',
	},
	[SendMessageType.VIDEO]: {
		defaultMessage: 'Media',
	},
	[SendMessageType.DOC]: {
		defaultMessage: 'Media',
	},
	[SendMessageType.LOCATION]: {
		defaultMessage: 'Location',
	},
});

export enum NodeErrorType {
	INFINITE_CIRCLE,
	TRIGGER_WITHOUT_NEXT,
	NODE_NO_CONFIG,
	MISSING_VARIABLE,
	ABNORMAL_PARAMETER,
	WRONG_CONNECTION_LINE,
	WABA_NOT_CONSISTENT,
	WHATSAPP_FLOW_STATUS_ERROR,
}

export const nodeErrorMessageMap = defineMessages({
	[NodeErrorType.INFINITE_CIRCLE]: {
		defaultMessage: 'Infinite Flow Detected',
	},
	[NodeErrorType.TRIGGER_WITHOUT_NEXT]: {
		defaultMessage: 'Trigger Missing connection — Trigger must be linked',
	},
	[NodeErrorType.NODE_NO_CONFIG]: {
		defaultMessage: 'Please fill out the form',
	},
	[NodeErrorType.MISSING_VARIABLE]: {
		defaultMessage: 'Missing variable',
	},
	[NodeErrorType.ABNORMAL_PARAMETER]: {
		defaultMessage: 'Abnormal parameter',
	},
	[NodeErrorType.WRONG_CONNECTION_LINE]: {
		defaultMessage: 'Incorrect connections',
	},
	[NodeErrorType.WABA_NOT_CONSISTENT]: {
		defaultMessage: 'Ask a question block error — The selected WhatsApp Flows belongs to different WABAs',
	},
	[NodeErrorType.WHATSAPP_FLOW_STATUS_ERROR]: {
		defaultMessage: 'The WhatsApp Flow is deleted, blocked or deprecated',
	},
});

export type NodeError = {
	nodeId: string;
	type: NodeErrorType;
};

export type GetBotByIdParams = {
	id: string;
};

export type UpdateBotParams = {
	id: string;
	name?: string;
	status?: string;
	flowData?: {
		nodes: Array<any>;
	};
	reactflow?: {
		nodes: Array<any>;
		edges: Array<any>;
	};
};

enum NodeGroup {
	ACTION,
	TRIGGER,
	RULES,
}

export const groupConfig = {
	[NodeGroup.TRIGGER]: {
		nodeHeaderColor: 'text.white',
		nodeHeaderBg: 'secondary.main',
		nodeBorderColor: 'secondary.main',
	},
	[NodeGroup.ACTION]: {
		nodeHeaderColor: 'primary.dark',
		nodeHeaderBg: '#DFDAFF',
		nodeBorderColor: 'secondary.main',
	},
	[NodeGroup.RULES]: {
		nodeHeaderColor: 'primary.dark',
		nodeHeaderBg: '#FBD5E5',
		nodeBorderColor: 'secondary.main',
	},
};

export const nodeConfigMap = {
	[NodeType.KEYWORD]: {
		label: 'Trigger',
		group: NodeGroup.TRIGGER,
		limit: 1,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
	[NodeType.COMPOSITE_TRIGGER]: {
		label: 'Trigger',
		group: NodeGroup.TRIGGER,
		limit: 1,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
	[NodeType.CALL_API]: {
		label: 'Call API',
		group: NodeGroup.ACTION,
		limit: Number.MAX_SAFE_INTEGER,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
	[NodeType.SEND_MESSAGE]: {
		label: 'Send messages',
		group: NodeGroup.ACTION,
		limit: Number.MAX_SAFE_INTEGER,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
	[NodeType.ASK_QUESTION]: {
		label: 'Ask a question',
		group: NodeGroup.ACTION,
		limit: Number.MAX_SAFE_INTEGER,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
	[NodeType.ASSIGN_AGENT]: {
		label: 'Agent takeover',
		group: NodeGroup.ACTION,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
	[NodeType.UNSUBSCRIBE]: {
		label: 'Unsubscribe',
		group: NodeGroup.ACTION,
		guideLink: '',
		cannotConnect: [NodeType.UNSUBSCRIBE],
		checkForm: false,
	},
	[NodeType.ADD_TAG]: {
		label: 'Add Tags',
		group: NodeGroup.ACTION,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
	[NodeType.BRANCHING]: {
		label: 'Branch',
		group: NodeGroup.RULES,
		limit: Number.MAX_SAFE_INTEGER,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
	},
};

export const nodeConfigLabelMap = defineMessages({
	[NodeType.COMPOSITE_TRIGGER]: {
		defaultMessage: 'Trigger',
	},
	[NodeType.CALL_API]: {
		defaultMessage: 'Call API',
	},
	[NodeType.SEND_MESSAGE]: {
		defaultMessage: 'Send messages',
	},
	[NodeType.ASK_QUESTION]: {
		defaultMessage: 'Ask a question',
	},
	[NodeType.ASSIGN_AGENT]: {
		defaultMessage: 'Agent takeover',
	},
	[NodeType.UNSUBSCRIBE]: {
		defaultMessage: 'Unsubscribe',
	},
	[NodeType.ADD_TAG]: {
		defaultMessage: 'Add Tags',
	},
	[NodeType.BRANCHING]: {
		defaultMessage: 'Branch',
	},
});

export const botNodeLabelMap = defineMessages({
	[NodeType.KEYWORD]: {
		defaultMessage: 'Keyword trigger',
		description: 'bot keyword trigger',
	},
	[NodeType.COMPOSITE_TRIGGER]: {
		defaultMessage: 'Trigger',
		description: 'bot Trigger',
	},
	[NodeType.CALL_API]: {
		defaultMessage: 'Call API',
		description: 'bot Call API',
	},
	[NodeType.SEND_MESSAGE]: {
		defaultMessage: 'Send messages',
		description: 'bot Send messages',
	},
	[NodeType.ASK_QUESTION]: {
		defaultMessage: 'Ask a question',
		description: 'bot Ask a question',
	},
	[NodeType.ASSIGN_AGENT]: {
		defaultMessage: 'Agent takeover',
		description: 'bot Agent takeover',
	},
	[NodeType.UNSUBSCRIBE]: {
		defaultMessage: 'Unsubscribe',
		description: 'bot Unsubscribe',
	},
	[NodeType.ADD_TAG]: {
		defaultMessage: 'Add Tags',
		description: 'bot Add Tags',
	},
	[NodeType.BRANCHING]: {
		defaultMessage: 'Branch',
		description: 'bot Branch',
	},
});

export type BotStatus = 'active' | 'inactive' | '';

export enum BotStatusEnum {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export const BotStatusLabel = defineMessages({
	[BotStatusEnum.ACTIVE]: {
		defaultMessage: 'Active',
	},
	[BotStatusEnum.INACTIVE]: {
		defaultMessage: 'Inactive',
	},
});

export interface PageParams {
	pageNo?: number;
	pageSize?: number;
}

export interface GetBotsParams extends PageParams {
	wabaId: string;
	nameLike?: string;
	status?: string;
	updateTimeGte?: number;
	updateTimeLte?: number;
	includes?: string;
	hasTrigger?: boolean;
}

export interface WhatsAppFlowInBotData {
	id: string;
	wabaId: string;
	name: string;
	status: WhatsAppFlowsStatusEnum;
}

export interface GetBotsDataItem {
	wabaId: string;
	id: string;
	name: string;
	status: string;
	updateTime: number;
	createTime: number;
	flowData: { nodes: any[] };
	reactflow: { nodes: any[] };
	whatsappFlows: Array<WhatsAppFlowInBotData>;
}

export interface GetTriggerCountByIdsParams {
	ids: string;
}

export interface GetBotsData extends Data {
	records: Array<GetBotsDataItem>;
	pagin: Pagin;
}

export interface CreateBotParams {
	chatbotId: string;
	name: string;
}

export interface DeleteBotParams {
	id: string;
}

export interface GetBotConfigParams {
	id: string;
}

export interface UpdateBotConfigParams {
	id: string;
	basicReplyText?: string;
	basicReplyEnabled?: boolean;
}

export interface GetTriggerLogsParams extends PageParams {
	id: string;
	startTime: number;
	endTime: number;
}

export interface GetTriggerCountDataItem {
	pt: string;
	triggerCount: number;
}

export interface GetTriggerCountParams {
	id: string;
	startTime: number | null;
	endTime: number | null;
}

export enum CompositeTriggerType {
	KEYWORD = 'keyword',
	NATURAL_LANGUAGE = 'naturalLanguage',
	TEMPLATE_BUTTON = 'templateButton',
}

export interface GetColumnsParams {
	botId: string;
	startTime: number;
	endTime: number;
}

export interface GetAnswersParams {
	botId: string;
	startTime: number;
	endTime: number;
	pageNo: number;
	pageSize: number;
}
export interface ExportAnswersParams {
	botId: string;
	startTime: number;
	endTime: number;
	timezone: string;
}
