import { useIntl } from 'react-intl';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import QuestionTypeExample from './questionTypeExample';
import { FunctionComponent } from 'react';
import { AskQuestionType } from 'src/features/bot/type';
import { Stack } from '@mui/material';
import TypeButton from './TypeButton';
const AskQuestionTypeSelector: FunctionComponent<{
  [index: string]: any;
}> = ({
  ...props
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const error = props.error;
  const handleChange = (type: AskQuestionType) => {
    props.onChange(type);
  };
  return <>
			<Stack sx={{
      width: '100%'
    }} spacing={2}>
				<TypeButton title={formatMessage({
        defaultMessage: 'Text'
      })} desc={formatMessage({
        defaultMessage: 'Ask anything to the user'
      })} onClick={() => handleChange(AskQuestionType.TEXT)} example={<QuestionTypeExample type={AskQuestionType.TEXT} />} />
				<TypeButton title={formatMessage({
        defaultMessage: 'Buttons'
      })} desc={formatMessage({
        defaultMessage: 'Quick reply buttons. Choices based on buttons（maximum of 3 buttons)'
      })} onClick={() => handleChange(AskQuestionType.BUTTON)} example={<QuestionTypeExample type={AskQuestionType.BUTTON} />} />
				<TypeButton title={formatMessage({
        defaultMessage: 'List'
      })} desc={formatMessage({
        defaultMessage: 'Choices based on sections and rows（maximum of 10 rows)'
      })} onClick={() => handleChange(AskQuestionType.LIST)} example={<QuestionTypeExample type={AskQuestionType.LIST} />} />
				<TypeButton title={formatMessage({
        defaultMessage: 'WhatsApp Flow'
      })} desc={formatMessage({
        defaultMessage: 'Use structured interactions for feedback survey, product inquiries, self onboarding service, etc.'
      })} example={<QuestionTypeExample type={AskQuestionType.FLOW} />} onClick={() => handleChange(AskQuestionType.FLOW)} />
			</Stack>
			{Boolean(error) && <ErrorHelperText>
					{formatMessage({
        defaultMessage: 'Required'
      })}
				</ErrorHelperText>}
		</>;
};
export default AskQuestionTypeSelector;