import { useIntl } from 'react-intl';
import { Dialog, DialogContent, FormControl, RadioGroup, FormControlLabel, Radio, Box, Typography } from '@mui/material';
import React, { FunctionComponent, useState, useEffect, ReactNode } from 'react';
import CustomDialogTitle from 'src/app/components/dialog/CustomDialogTitle';
import Loading from 'src/app/components/loading';
import { useAppDispatch } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { getTenantListAsync } from 'src/features/member/memberSlice';
import { roleLabelMap } from 'src/features/member/type';
import CustomChip from 'src/app/components/customChip';
const SwitchAccount: FunctionComponent<{
  open: boolean;
  title: string;
  subTitle?: string;
  curAccount: string;
  onChoose: (e: any) => void;
  onClose: () => void;
  children?: ReactNode;
}> = ({
  open,
  title,
  subTitle = '',
  curAccount,
  onChoose,
  onClose,
  children
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allAccount, setAllAccount] = useState<Array<any>>([]);
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const tenant = allAccount.find((i: any) => i.tenantId === value);
    onChoose(tenant);
  };
  useEffect(() => {
    const initData = async () => {
      try {
        const res: any = await dispatch(getTenantListAsync()).unwrap();
        if (res.code === CODE.OK) {
          const data = res.data;
          setAllAccount(data.tenants);
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    if (open) {
      initData();
    }
  }, [dispatch, open]);
  return <Dialog open={open} maxWidth="md">
			<CustomDialogTitle title={title} subTitle={subTitle} onClose={onClose} />
			<DialogContent sx={{
      width: '600px',
      maxHeight: '596px',
      pb: 3,
      boxSizing: 'border-box',
      position: 'relative'
    }}>
				{allAccount.length > 0 ? <FormControl fullWidth>
						<RadioGroup value={curAccount} onChange={handleClick}>
							{allAccount.map((i: any) => <FormControlLabel key={i.tenantId} value={i.tenantId} control={<Radio />} label={<Box sx={{
            ml: 3
          }}>
											<Typography variant="subtitle1" color="text.secondary" sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
												<Typography variant="subtitle1" component="div" sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
													{i.company}
												</Typography>
												{i.isOwner && <CustomChip label={formatMessage({
                defaultMessage: 'Owner',
                description: '位置：切换租户-租户列表'
              })} color="secondary" sx={{
                ml: '9px'
              }} />}
											</Typography>
											<Typography variant="body1" color="text.secondary">
												{roleLabelMap[i.role as keyof typeof roleLabelMap]}
											</Typography>
										</Box>} sx={{
            m: 0,
            py: 1,
            width: '100%',
            '& .MuiFormControlLabel-label': {
              overflow: 'hidden'
            },
            '&:hover': {
              backgroundColor: 'action.hover'
            }
          }} />)}
						</RadioGroup>
					</FormControl> : isLoading && <Loading open={isLoading} />}
			</DialogContent>
			{children}
		</Dialog>;
};
export default SwitchAccount;