import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetIntlContentParams, Locale } from './type';
import { RootState } from 'src/app/store';
import { getIntlContent } from './intlAPI';

const computeDefaultLocale = () => {
	const wwwLocale = localStorage.getItem('www-locale');
	if (!wwwLocale) {
		const navigatorLang = navigator.language;
		if (navigatorLang.indexOf('zh') > -1) {
			return Locale.Zh;
		}
		if (navigatorLang.indexOf('id') > -1) {
			return Locale.Id;
		}
		if (navigatorLang.indexOf('es') > -1) {
			return Locale.Es;
		}
		if (navigatorLang.indexOf('pt') > -1) {
			return Locale.Pt;
		}
		return Locale.En;
	} else {
		return wwwLocale as Locale;
	}
};

export interface IntlState {
	locale: Locale;
	messages: Record<string, string>;
}

const defaultLocale = computeDefaultLocale();

const initialState: IntlState = {
	locale: defaultLocale || Locale.En,
	messages: {},
};

export const intlSlice = createSlice({
	name: 'intl',
	reducers: {
		setLang: (state, action) => {
			state.locale = action.payload;
		},
	},
	initialState,
	extraReducers: builder => {
		builder.addCase(getIntlContentAsync.fulfilled, (state, action) => {
			state.messages = action.payload.data || {};
		});
	},
});

export const selectLocale = (state: RootState) => state.intl.locale;

export const selectIntlMessages = (state: RootState) => state.intl.messages;

export const getIntlContentAsync = createAsyncThunk('intl/getIntlContent', async (params: GetIntlContentParams) => {
	const res = await getIntlContent(params);
	return res;
});

export const { setLang } = intlSlice.actions;

export default intlSlice.reducer;
