import { useState, useCallback, useImperativeHandle, forwardRef, useEffect, useMemo, useRef } from 'react';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { createEditor, Transforms, Editor } from 'slate';
import { withInlines, convertTextToNodes, serialize, computeTextType, getNoParamsText } from './utils';
import DefaultElement from './components/DefaultElement';
import { Box, Alert, Link } from '@mui/material';
import WhatsappVariableElement from './components/WhatsappVariableElement';
import VariableElement from './components/VariableElement';
import BotVariableElement from './components/BotVariableElement';
import ShortLinkElement from './components/ShortLinkElement';
import { EmojiTextReg } from 'src/utils/regexp';
import { smsVariablePattern, whatsAppVariablePattern, shortLinkPattern, botVariablePattern } from './regexp';
import { uniq, isEqual, difference } from 'lodash-es';
import { getBatchShortLinkInfoAsync } from 'src/features/shortLink/shortLinkSlice';
import { useAppDispatch } from 'src/app/hooks';
import { VariableType } from './type';
import { useAppSelector } from 'src/app/hooks';
import { selectAddVariable } from 'src/features/whatsapp/whatsappSlice';
import { selectAllShortUrlInfoList } from 'src/features/shortLink/shortLinkSlice';
import { ShortUrlInfo } from 'src/features/shortLink/type';
import { formatDateTime } from 'src/utils/transform';
import DragButton from './DragButton';
export { getNoParamsText };
const generateShortUrlErrorOrWarning = (expiredLinkInfo: Array<ShortUrlInfo>, expiringLinkInfo: Array<ShortUrlInfo>, usedLinksInfo: Array<ShortUrlInfo>, unknownLinks: Array<string>) => {
  return <Box>
			{expiredLinkInfo.map((item, index) => <Box key={index}>
					<Link sx={{
        color: 'line.main',
        cursor: 'not-allowed'
      }}>{item.url}</Link> will have expired by your scheduled time, you should generate a new short link.
				</Box>)}
			{unknownLinks.map((item, index) => <Box key={index}>
					<Link sx={{
        color: 'line.main',
        cursor: 'not-allowed'
      }}>{item}</Link> does not exist.
				</Box>)}
			{expiringLinkInfo.map((item, index) => <Box key={index}>
					<Link href={item.url} target="__blank">
						{item.url}
					</Link>{' '}
					expires in 7 days by your scheduled time.
				</Box>)}
			{usedLinksInfo.map((item, index) => <Box key={index}>
					<Link href={item.url} target="__blank">
						{item.url}
					</Link>{' '}
					expires on {formatDateTime(item.expireTime)}, has been used in another campaign. It is recommended that you generate a new short link to ensure the accuracy of the tracking data.
				</Box>)}
		</Box>;
};
let shouldConvertAllLine = false;
interface IProps {
  isAr?: boolean;
  isCanEdit?: boolean;
  error: any;
  helperText: any;
  onChange: any;
  type: VariableType;
  sendTimestamp?: number | undefined;
  placeholder?: string;
  footer: any;
  ref?: any;
  value?: any;
  onBlur?: any;
  isInput?: boolean;
  hasEmoji?: boolean;
  hasX?: boolean;
  width?: string;
  ignoreVariable?: boolean; // 是否忽略变量输入
  onHasShortUrlErrorChange?: any;
  position?: string;
  defaultHeight?: number;
  resizable?: boolean; // 输入框是否可以通过拖拽右下角进行高度调整
  variables?: any[]; // 额外的变量
}
export type Ref = any;
const SlateEditor = forwardRef<Ref, IProps>(({
  onBlur,
  ignoreVariable = false,
  onChange,
  defaultHeight = 262,
  isAr = false,
  isCanEdit = true,
  error,
  helperText,
  type = 'sms',
  footer,
  value,
  sendTimestamp = undefined,
  width = '100%',
  hasEmoji = true,
  hasX = true,
  placeholder = '',
  isInput = false,
  onHasShortUrlErrorChange = () => {},
  position,
  resizable = false,
  variables = []
}, componentRef) => {
  const dispatch = useAppDispatch();
  const [shortLinkUrlArray, setShortLinkUrlArray] = useState<any>([]);
  const addVariable = useAppSelector(selectAddVariable);
  const allShortUrlInfoList = useAppSelector(selectAllShortUrlInfoList);
  const [editor] = useState(() => withInlines(withReact(createEditor())));
  const [shortUrlWarning, setShortUrlWarning] = useState<any>('');
  const [shortUrlError, setShortUrlError] = useState<any>('');
  const footerRef = useRef<HTMLDivElement>(null);
  const [footerWidth, setFooterWidth] = useState<string>('');
  error = error || Boolean(shortUrlError);
  useEffect(() => {
    setFooterWidth('' + ((footerRef?.current?.clientWidth as number) + 20) + 'px' || '');
  }, [footerRef?.current?.clientWidth]);
  useEffect(() => {
    onHasShortUrlErrorChange(Boolean(shortUrlError));
  }, [shortUrlError, onHasShortUrlErrorChange]);

  // 这里是计算出来的文本编辑器中所有短链的相关信息，用于给用户做出提示
  useEffect(() => {
    if (shortLinkUrlArray && shortLinkUrlArray.length) {
      const expiredLinkInfo = [];
      const expiringLinkInfo = [];
      const usedLinksInfo = [];
      const knownLinks = [];
      const currentTime = sendTimestamp ? new Date(sendTimestamp).getTime() : Date.now();
      const sevenDaysTimeStamp = 7 * 24 * 60 * 60 * 1000;
      for (let j = 0; j < allShortUrlInfoList.length; j++) {
        const currentShortLinkInfo = allShortUrlInfoList[j];
        const currentShortLinkInfoExpireTime = new Date(currentShortLinkInfo.expireTime).getTime();
        const linkIsExpired = currentShortLinkInfoExpireTime <= currentTime;
        if (shortLinkUrlArray.includes(currentShortLinkInfo.url)) {
          knownLinks.push(currentShortLinkInfo.url);
          if (linkIsExpired) {
            expiredLinkInfo.push(currentShortLinkInfo);
          }
          if (currentShortLinkInfoExpireTime - currentTime <= sevenDaysTimeStamp && !linkIsExpired) {
            expiringLinkInfo.push(currentShortLinkInfo);
          }
          if (currentShortLinkInfoExpireTime - currentTime > sevenDaysTimeStamp && currentShortLinkInfo.refActivities && currentShortLinkInfo.refActivities.length) {
            usedLinksInfo.push(currentShortLinkInfo);
          }
        }
      }
      const unknownLinks = difference(shortLinkUrlArray, knownLinks);
      if (expiredLinkInfo.length || unknownLinks.length) {
        setShortUrlError(generateShortUrlErrorOrWarning(expiredLinkInfo, expiringLinkInfo, usedLinksInfo, unknownLinks));
        setShortUrlWarning('');
      } else if (usedLinksInfo.length || expiringLinkInfo.length) {
        setShortUrlWarning(generateShortUrlErrorOrWarning(expiredLinkInfo, expiringLinkInfo, usedLinksInfo, unknownLinks));
        setShortUrlError('');
      } else {
        setShortUrlWarning('');
        setShortUrlError('');
      }
    } else {
      setShortUrlError('');
      setShortUrlWarning('');
    }
  }, [shortLinkUrlArray, allShortUrlInfoList, sendTimestamp]);
  const includeSpecialText = (nodeText: string) => {
    if (ignoreVariable) {
      return false;
    }
    if (type === 'whatsapp') {
      return new RegExp(whatsAppVariablePattern, 'g').test(nodeText) || new RegExp(shortLinkPattern, 'g').test(nodeText);
    } else if (type === 'bot') {
      return new RegExp(botVariablePattern, 'g').test(nodeText);
    } else {
      return new RegExp(smsVariablePattern, 'g').test(nodeText) || new RegExp(shortLinkPattern, 'g').test(nodeText);
    }
  };
  useEffect(() => {
    const getShortUrlInfo = async (shortUrls: Array<string>) => {
      dispatch(getBatchShortLinkInfoAsync({
        shortUrls
      }));
    };
    getShortUrlInfo(shortLinkUrlArray);
  }, [dispatch, shortLinkUrlArray]);
  const getInitValue = (e: string) => {
    return (e || '').split('\n').map((text: any) => {
      if (includeSpecialText(text)) {
        return {
          type: 'paragraph',
          children: convertTextToNodes(text, type)
        };
      } else {
        return {
          type: 'paragraph',
          children: [{
            text
          }]
        };
      }
    });
  };
  const initialValue = useMemo(() => getInitValue(value), [value]);
  const clearTextContent = () => {
    // 清空文本框内容
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, [])
      }
    });
  };
  const computeShorturls = async (textContent: string) => {
    const shortUrls = uniq(textContent.match(new RegExp(shortLinkPattern, 'g'))).sort();
    const prevArr = shortLinkUrlArray.sort();
    if (!isEqual(shortUrls, prevArr)) {
      setShortLinkUrlArray(shortUrls);
    }
  };
  const extendPreviousShortLink = (nodeText: string) => {
    const currentSelection = editor.selection;
    const previousNode: any = Editor.previous(editor, {
      at: currentSelection.anchor.path
    });
    const nodeTextList = nodeText.split(' ');
    const [firstNodeTextItem, ...remainItems] = nodeTextList;
    if (firstNodeTextItem && firstNodeTextItem.match(/[a-z0-9A-Z]{1,}/)) {
      if (previousNode && previousNode[0] && previousNode[0].type === 'shortLink') {
        // 删除当前的nodeText
        Transforms.removeNodes(editor, {
          at: currentSelection.anchor.path
        });
        const character = previousNode[0].character;
        Transforms.removeNodes(editor, {
          at: previousNode[1]
        });
        const insertNodes = [{
          type: 'shortLink',
          character: character + firstNodeTextItem,
          children: [{
            text: ''
          }]
        }];
        if (remainItems && remainItems.length) {
          insertNodes.push({
            text: ' ' + remainItems.join(' ')
          } as any);
        }
        Transforms.insertNodes(editor, insertNodes, {
          at: previousNode[1]
        });
        const currentSelectionAnother = editor.selection;
        const currentPath = currentSelectionAnother.anchor.path;
        const targetPath = [currentPath[0], currentPath[1] + 1];
        Transforms.select(editor, {
          anchor: {
            path: targetPath,
            offset: 0
          },
          focus: {
            path: targetPath,
            offset: 0
          }
        });
        Transforms.collapse(editor, {
          edge: 'anchor'
        });
        ReactEditor.focus(editor);
      }
    }
  };
  const handleChange = () => {
    const currentSelection = editor.selection;
    if (!hasEmoji) {
      let allContent = serialize(editor.children);
      if (EmojiTextReg.test(allContent)) {
        allContent = allContent.replace(EmojiTextReg, '');
        clearTextContent();
        shouldConvertAllLine = true;
        editor.insertText(allContent);
      }
    }
    if (!hasX) {
      let allContent = serialize(editor.children);
      if (/\*/.test(allContent)) {
        allContent = allContent.replace('*', '');
        clearTextContent();
        editor.insertText(allContent);
      }
    }
    if (!currentSelection) {
      return;
    }
    const node: any = Editor.node(editor, currentSelection.anchor.path);
    const nodeText = node[0].text;
    if (shouldConvertAllLine) {
      // 对所有的行进行一次转换
      const resultNodes = [];
      for (let i = 0; i < editor.children.length; i++) {
        const currentNodes = editor.children[i].children;
        const text = serialize(currentNodes, '');
        if (includeSpecialText(text)) {
          resultNodes.push({
            type: 'paragraph',
            children: convertTextToNodes(text, type)
          });
        } else {
          resultNodes.push({
            type: 'paragraph',
            children: [{
              text: text
            }]
          });
        }
      }
      clearTextContent();
      Transforms.insertFragment(editor, resultNodes);
      ReactEditor.blur(editor);
      shouldConvertAllLine = false;
    } else {
      // 主动扩张当前文本的前一个短链
      extendPreviousShortLink(nodeText);

      // 此处进行当前所处节点的特殊转换
      if (includeSpecialText(nodeText)) {
        Transforms.removeNodes(editor, {
          at: currentSelection.anchor.path
        });
        Transforms.insertNodes(editor, convertTextToNodes(nodeText, type), {
          at: currentSelection.anchor.path
        });
      }
    }
    const pureText = serialize(editor.children);
    if (pureText !== value) {
      onChange(pureText);
    }
    computeShorturls(pureText);
  };
  const handlePaste = (e: any) => {
    // 判断黏贴的文本是否包含特殊字符
    const pasteText = e.clipboardData.getData('text');
    if (includeSpecialText(pasteText)) {
      shouldConvertAllLine = true;
    }
  };
  const renderElement = useCallback((props: any) => {
    if (ignoreVariable) {
      return <DefaultElement {...props} />;
    }
    switch (props.element.type) {
      case 'whatsAppVariable':
        return <WhatsappVariableElement {...props} position={position} variables={variables} />;
      case 'botVariable':
        return <BotVariableElement {...props} />;
      case 'smsVariable':
        return <VariableElement {...props} />;
      case 'shortLink':
        return <ShortLinkElement {...props} />;
      default:
        return <DefaultElement {...props} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);
  const handleAddText = (text: string, isReplace: boolean, isSymbol: boolean) => {
    if (isSymbol) {
      if (!editor.selection && !value) {
        Transforms.select(editor, {
          path: [0, 0],
          offset: 0
        });
      }
      const selectionText = window.getSelection();
      editor.insertText(text + (selectionText || '') + text);
      Transforms.move(editor, {
        distance: text.length,
        unit: 'character',
        edge: 'anchor',
        reverse: true
      });
      Transforms.collapse(editor, {
        edge: 'anchor'
      });
      if (editor.selection) {
        Transforms.select(editor, editor.selection.anchor);
      }
      ReactEditor.focus(editor);
    } else if (isReplace) {
      clearTextContent();
      shouldConvertAllLine = true;
      Transforms.insertNodes(editor, {
        text
      });
    } else {
      const textType = computeTextType(text, type);
      if (textType) {
        let index = 0;
        if (textType === ('whatsAppVariable' || 'botVariable') && position) {
          const [language, po] = position.split('-');
          index = addVariable && addVariable[language] && addVariable[language][po] ? addVariable[language][po].length : 0;
        }
        const nodesToInsert = [{
          ...(textType === ('whatsAppVariable' || 'botVariable') && position && {
            index: index
          }),
          type: textType,
          character: text,
          children: [{
            text: ''
          }]
        }];
        // 为了避免用户输入的不便，如果插入的是短链，自动加一个空格
        if (textType === 'shortLink') {
          nodesToInsert.push({
            text: ' '
          } as any);
        }
        Transforms.insertNodes(editor, nodesToInsert);
        Transforms.move(editor);
      } else {
        editor.insertText(text);
      }
    }
  };
  const handleFocus = () => {
    ReactEditor.focus(editor);
  };
  useImperativeHandle(componentRef, () => ({
    handleAddText,
    handleFocus
  }));
  const [focus, setFocus] = useState(false);
  const handleBlur = () => {
    setFocus(false);
    onBlur();
  };
  const generateBorder = (hasError: any, hasWarning: any, isFocus: boolean) => {
    if (hasError) {
      return ['2px solid', 'error.main'];
    } else if (hasWarning) {
      return ['2px solid', 'warning.main'];
    } else if (isFocus) {
      return ['2px solid', 'primary.main'];
    }
    return ['1px solid', 'line.main'];
  };
  const [border, borderColor] = generateBorder(error, shortUrlWarning, focus);
  const [defaultEditorHeight, setDefaultEditorHeight] = useState(defaultHeight);
  return <Box sx={{
    width: isInput ? width : '100%'
  }} className="notranslate">
				<Box sx={{
      position: 'relative',
      height: isInput ? focus || error ? '36px' : '38px' : focus || error ? defaultEditorHeight : defaultEditorHeight + 2,
      border,
      borderColor,
      borderRadius: '4px',
      backgroundColor: isCanEdit ? '#fff' : '#EFEDF5',
      ...(!error && !shortUrlWarning && isCanEdit && {
        '&:hover': {
          borderColor: '#1A1E22'
        }
      })
    }}>
					<Box sx={{
        padding: isInput ? focus || error ? `5px ${footer ? footerWidth || '60px' : '13px'} 5px 13px` : `6px ${footer ? footerWidth || '60px' : '14px'} 6px 14px` : focus || error ? '15px' : '16px',
        width: '100%',
        height: isInput ? '100%' : 'calc(100% - 40px)',
        outline: 'none',
        fontSize: '14px',
        lineHeight: isInput ? '24px' : '30px',
        overflowY: 'auto',
        boxSizing: 'border-box',
        minHeight: isInput ? 0 : defaultHeight - 38,
        maxHeight: defaultEditorHeight - 38,
        '&:empty::before': {
          content: 'attr(placeholder)',
          fontSize: '16px',
          color: '#A29DAE'
        },
        '& [role="textbox"]': {
          wordBreak: isInput ? 'break-all' : '',
          whiteSpace: isInput ? 'nowrap !important' : '',
          overflow: isInput ? 'hidden' : 'auto',
          height: '100% !important'
        },
        '& p': {
          '& [data-slate-placeholder=true]': {
            fontSize: '16px !important',
            color: '#A29DAE',
            opacity: '1 !important',
            textAlign: 'left'
          }
        },
        '& *': {
          '&:focus-visible': {
            outline: 'none !important'
          }
        },
        '&>div': {
          ...(isAr && {
            direction: 'rtl'
          })
        }
      }}>
						<Slate editor={editor} onChange={handleChange} initialValue={initialValue}>
							<Editable onKeyDown={e => {
            if (e.code === 'Enter' && isInput) {
              e.preventDefault();
            }
          }} readOnly={!isCanEdit} renderElement={renderElement} onPaste={handlePaste} onFocus={() => setFocus(true)} onBlur={handleBlur} placeholder={placeholder} />
						</Slate>
					</Box>
					{resizable && <DragButton onChange={moveDistance => {
        setDefaultEditorHeight(myHeight => myHeight + moveDistance >= defaultHeight ? myHeight + moveDistance : defaultHeight);
      }} />}
					{footer && <Box ref={footerRef} sx={{
        ...(isInput ? {
          position: 'absolute',
          right: focus || error ? '7px' : '8px',
          top: focus || error ? '7px' : '8px'
        } : {
          position: 'absolute',
          bottom: focus || error ? '-1px' : 0,
          p: focus || error ? '0 15px' : '0 16px',
          width: '100%',
          boxSizing: 'border-box'
        })
      }}>
							{footer}
						</Box>}
				</Box>
				{error && <Alert severity="error" sx={{
      paddingTop: '5px !important',
      paddingBottom: '5px !important'
    }}>
						{shortUrlError ? shortUrlError : helperText}
					</Alert>}
				{Boolean(!error && shortUrlWarning) && <Alert severity="warning" sx={{
      paddingTop: '5px !important',
      paddingBottom: '5px !important'
    }}>
						{shortUrlWarning}
					</Alert>}
			</Box>;
});
export default SlateEditor;