import { POST, GET, UPLOAD } from 'src/utils/fetch';
import { VerifyGuideSendParams, VerifyGuideCodeParams, VerifyDesignParams, VerifySettingParams, VerifySecurityParams, BlackListParams, AddBlackListParams, DelBlackListParams, VerifyMessageParams } from './type';
import { joinUrlAndSearchParams } from 'src/utils/fetch';

export async function verifyGuideSend(params: VerifyGuideSendParams) {
	return POST('/api/verify/guide', params, false, false);
}

export async function verifyGuideCode(params: VerifyGuideCodeParams) {
	return GET('/api/verify/guide', params, true, true);
}

export async function getVerifyDesign(params: VerifyDesignParams) {
	return GET('/api/verify/design', params, true, false);
}

export async function uploadBrandIcon(query: any, params: any) {
	return UPLOAD(joinUrlAndSearchParams('/api/verify/design/uploadBricdIcon', params), query, false, false);
}

export async function updateVerifyDesign(params: VerifySettingParams) {
	return POST('/api/verify/design', params, false, false);
}

export async function getVerifySecurity() {
	return GET('/api/verify/security/getConfig', undefined, true, false);
}

export async function updateVerifySecurity(params: VerifySecurityParams) {
	return POST('/api/verify/security/updateConfig', params, false, false);
}

export async function getVerifyBlackList(params: BlackListParams) {
	return GET('/api/verify/security/blacklist/get', params, true, false);
}

export async function addVerifyBlackList(params: AddBlackListParams) {
	return POST('/api/verify/security/blacklist/add', params, false, false);
}

export async function delVerifyBlackList(params: DelBlackListParams) {
	return POST('/api/verify/security/blacklist/del', params, false, true, false);
}

export async function getVerifyMessage(params: VerifyMessageParams) {
	return POST('/api/verify/message/search', params, true, false);
}

export async function getVerifyStatistics(params: VerifyMessageParams) {
	return GET('/api/verify/message/statistics', params, true, false);
}
