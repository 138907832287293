import { Alert, Box, TextField, FormHelperText, Typography } from '@mui/material';
import React, { forwardRef, ReactNode, useState } from 'react';
import { outlinedInputErrorStyle } from 'src/app/components/commonStyle';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import EmojiPicker from 'src/app/components/emojiPicker';
import { EmojiTextReg } from 'src/utils/regexp';
import { getByteLength } from 'src/utils/tools';
const iconStyle = {
  fontSize: '20px',
  color: 'primary.dark',
  p: '10px',
  mr: '4px',
  cursor: 'pointer',
  verticalAlign: 'middle'
};
interface IProps {
  isAr?: boolean;
  value: any;
  onChange: (...event: any[]) => void;
  maxLen: string;
  showEmoji?: boolean;
  pRight?: string;
  error?: boolean;
  helperText?: ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
  supportEmoji?: boolean;
  isByte?: boolean;
  rows?: number;
  placeholder?: string;
  errTextStyle?: any;
  ref?: any;
  minRows?: number;
}
export type Ref = any;
const TextCountInput = forwardRef<Ref, IProps>(({
  minRows,
  autoFocus = false,
  onBlur = () => {},
  isAr = false,
  supportEmoji = true,
  showEmoji = false,
  rows = 1,
  value,
  onChange,
  maxLen,
  pRight = '50px',
  error,
  helperText,
  disabled = false,
  placeholder,
  errTextStyle,
  isByte = false
}, ref) => {
  const sliceText = (text: string) => {
    const max = Number(maxLen);
    if (isByte) {
      let str = '';
      for (let i = 0; i < text.length; i++) {
        if (getByteLength(`${str}${text[i]}`) <= max) {
          str += text[i];
        } else {
          break;
        }
      }
      return str;
    }
    return text.slice(0, max);
  };
  const checkLengthBeforeChange = (changeEvent: any) => {
    if (typeof changeEvent === 'string') {
      onChange(sliceText(changeEvent));
    } else {
      onChange({
        ...changeEvent,
        target: {
          value: sliceText(changeEvent.target.value)
        }
      });
    }
  };
  const [cursorPosition, setCursorPosition] = useState<undefined | number>(0);
  const handleBlur = (e: any) => {
    setCursorPosition(e.target.selectionStart || 0);
    onBlur();
  };
  const handleEmojiSelect = (e: any) => {
    const emoji = e.native;
    if (cursorPosition !== undefined) {
      checkLengthBeforeChange(value.slice(0, cursorPosition) + emoji + value.slice(cursorPosition));
      setCursorPosition(undefined);
    } else {
      checkLengthBeforeChange(value + emoji);
    }
  };
  const handleChange = (e: any) => {
    if (supportEmoji) {
      checkLengthBeforeChange(e);
    } else {
      e.target.value = e.target.value.replaceAll(EmojiTextReg, '');
      checkLengthBeforeChange(e);
    }
  };
  const isMultiline = rows > 1 || Boolean(minRows);
  return <Box>
			{!disabled && showEmoji && <Box>
					<EmojiPicker iconStyle={iconStyle} onSelect={handleEmojiSelect} />
				</Box>}
			<Box sx={{
      position: 'relative'
    }}>
				<TextField onBlurCapture={handleBlur} value={value} inputRef={ref} onChange={handleChange} disabled={disabled} autoComplete="off" minRows={minRows} rows={minRows ? undefined : rows} multiline={isMultiline} placeholder={placeholder} autoFocus={autoFocus} sx={{
        width: '100%',
        backgroundColor: 'fill.w',
        '& .MuiOutlinedInput-input': {
          pr: pRight
        },
        textarea: {
          mt: '8px',
          mb: '24px'
        },
        '& ::placeholder': {
          textAlign: 'left'
        },
        ...(error && outlinedInputErrorStyle),
        ...(isAr && {
          direction: 'rtl'
        })
      }} />
				<Typography variant="caption" color="text.secondary" sx={{
        position: 'absolute',
        right: '8px',
        bottom: error ? !isMultiline ? '' : '60px' : '11px',
        top: error ? !isMultiline ? '13px' : '' : ''
      }}>
					{value ? isByte ? getByteLength(value) : value.length : 0}/{maxLen}
				</Typography>
				{error && !isMultiline && <FormHelperText error={error}>
						<ErrorHelperText textStyle={errTextStyle}>{helperText}</ErrorHelperText>
					</FormHelperText>}
				{error && isMultiline && <Alert severity="error" sx={{
        paddingTop: '5px !important',
        paddingBottom: '5px !important'
      }}>
						{helperText}
					</Alert>}
			</Box>
		</Box>;
});
export default TextCountInput;