export const outlinedInputErrorStyle = {
	'& .MuiOutlinedInput-notchedOutline': {
		border: '2px solid #FF4842 !important',
	},
	'&:hover': {
		'& .MuiOutlinedInput-notchedOutline': {
			border: '2px solid #FF4842 !important',
		},
	},
	'& .Mui-focused': {
		'& .MuiOutlinedInput-notchedOutline': {
			border: '2px solid #FF4842 !important',
		},
	},
};
