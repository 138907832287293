import { CustomerType } from 'src/features/user/type';
import loadComponent from '../loadComponent';
const developersRouter = [{
  path: 'apikey',
  element: loadComponent('developers/apikey')
}, {
  path: 'webhook',
  element: loadComponent('developers/webhook')
}, {
  path: 'failedLogs',
  element: loadComponent('developers/failedLogs'),
  unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER],
  roles: ['ADMIN']
}, {
  path: 'webhook/logs',
  element: loadComponent('developers/webhook/logs'),
  roles: ['ADMIN']
}, {
  path: 'whitelist',
  element: loadComponent('developers/whitelist'),
  roles: ['ADMIN']
}];
export default developersRouter;