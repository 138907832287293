import { GET, POST, UPLOAD } from 'src/utils/fetch';
import {
	WaBaByTokenParams,
	WaBaPhoneParams,
	BindPhoneParams,
	UnBindPhoneParams,
	getPhoneInfoParams,
	updatePhoneInfoParams,
	TemplateListParams,
	AddTemplateParams,
	DelTemplateByIdParams,
	DelTemplateParams,
	WhatsappLogsParams,
	CheckWhatsappCopyParams,
	WhatsappOverViewParams,
	CheckUsedLangParams,
	GetTemplateInsightsParams,
	PhoneAliasParams,
} from './type';

export async function createExport(params: any) {
	return POST('/api/whatsapp/message/exports/create', params, true);
}

export async function checkExportProgress(params: any) {
	return GET('/api/whatsapp/message/exports/get', params, true, true);
}

export async function getWaBaByToken(params: WaBaByTokenParams) {
	return GET('/api/whatsapp/waba/getByToken', params, true, false);
}

export async function checkUsedLang(params: CheckUsedLangParams) {
	return GET('/api/whatsapp/templates/getExistedLanguageByName', params, true, true);
}

export async function checkWhatsappCopy(params: CheckWhatsappCopyParams) {
	return POST('/api/whatsapp/templates/copyCheck', params, true, true);
}

export async function getWaBaPhone(params: WaBaPhoneParams) {
	return GET('/api/whatsapp/phone/getAll', params, true, false);
}

export async function getBindedPhone() {
	return GET('/api/whatsapp/phone/getBinded', undefined, true, false);
}

export async function setBindPhone(params: BindPhoneParams) {
	return POST('/api/whatsapp/waba/bind', params, true, false);
}

export async function setUnBindPhone(params: UnBindPhoneParams) {
	return POST('/api/whatsapp/phone/unBind', params, false, false, true);
}

export async function getWabaList() {
	return GET('/api/whatsapp/waba/get', undefined, true, false);
}

export async function getLanguages() {
	return GET('/api/whatsapp/templates/listLanguages', undefined, true, false);
}
export async function getCreator(params: { wabaId: string }) {
	return GET('/api/whatsapp/templates/listTemplateCreatorsByWaba', params, true, false);
}

export async function getUsedLanguages(params: WaBaPhoneParams) {
	return GET('/api/whatsapp/templates/listTplLanguageOfWaba', params, true, false);
}

export async function getUsedCategorys(params: WaBaPhoneParams) {
	return GET('/api/whatsapp/templates/getExistedCategoryByWaba', params, true, false);
}

export async function getPhoneInfo(params: getPhoneInfoParams) {
	return GET('/api/whatsapp/phone/getProfile', params, true, false);
}

export async function uploadPhonePhoto(params: any) {
	return UPLOAD('/api/whatsapp/file/uploadByForm', params, true, false);
}

export async function updatePhoneInfo(params: updatePhoneInfoParams) {
	return POST('/api/whatsapp/phone/updateProfile', params, false, false);
}

export async function getTemplateList(params: TemplateListParams) {
	return GET('/api/whatsapp/templates/list', params, true, false);
}

export async function getFlattenTemplateList(params: TemplateListParams) {
	return POST('/api/whatsapp/templates/search', params, true, false);
}

export async function uploadMedia(params: any) {
	return UPLOAD('/api/whatsapp/templates/uploadMedia', params, false, false);
}

export async function addTemplate(params: AddTemplateParams) {
	return POST('/api/whatsapp/templates/upsert', params, true, true, false, true);
}

export async function delTemplate(params: DelTemplateParams) {
	return POST('/api/whatsapp/templates/deleteAllByName', params, false, false);
}

export async function delTemplateById(params: DelTemplateByIdParams) {
	return POST('/api/whatsapp/templates/delete', params, false, false);
}

export async function getLogs(params: WhatsappLogsParams) {
	return POST('/api/whatsapp/message/search', params, true, false);
}

export async function getAnalyticsParams(params: WhatsappOverViewParams) {
	return GET('/api/whatsapp/analytics/outline', params, true, false);
}

export async function exportCampaign(params: WhatsappLogsParams) {
	return POST('/api/whatsapp/batch/activity/analytics/export/create', params, true);
}

export async function getCampaignExportProgress(params: { id: string }) {
	return GET('/api/whatsapp/batch/activity/analytics/export/get', params, true, true);
}

export async function cancelExportCampaign(params: { id: string }) {
	return GET('/api/whatsapp/batch/activity/analytics/export/cancel', params, true, false);
}

export async function getConversationAnalytics(params: WhatsappOverViewParams) {
	return GET('/api/whatsapp/analytics/conversationAnalytics', params, true, false);
}

export async function getMessageAnalytics(params: WhatsappOverViewParams) {
	return GET('/api/whatsapp/analytics/messageAnalytics', params, true, false);
}

export async function addPhoneNumber(params: any) {
	return POST('/api/whatsapp/phone/add', params, true, false);
}

export async function sendCode(params: any) {
	return POST('/api/whatsapp/phone/requestCode', params, true, false);
}

export async function verifyCode(params: any) {
	return POST('/api/whatsapp/phone/verifyCode', params, true, false);
}

export async function registerPhoneNumber(params: any) {
	return POST('/api/whatsapp/phone/register', params);
}

export async function getInboxDetailByInboxId(params: any) {
	return GET('/api/inbox/inboxes/get', params, true, false);
}

export async function getAddableAgents(params: any) {
	return GET('/api/inbox/inboxes/addableAgents', params, true, false);
}

export async function getChatBotConfigByPhoneId(params: any) {
	return GET('/api/whatsapp/phone/chatbotConfig/get', params, true, true);
}

export async function removeChatBot(params: any) {
	return POST('/api/whatsapp/phone/chatbotConfig/delete', params);
}

export async function updatePhoneNumberChatBotConfig(params: any) {
	return POST('/api/whatsapp/phone/chatbotConfig/update', params);
}

export async function getTemplateInsightsData(params: GetTemplateInsightsParams) {
	return GET('/api/whatsapp/templates/insights/search', params, true);
}

export async function getBatchInsightsData(params: any) {
	return GET('/api/whatsapp/templates/insights', params, true, true);
}

export async function checkReference(params: any) {
	return GET('/api/whatsapp/templates/checkReference', params, true);
}

export async function updatePhoneNumberAlias(params: PhoneAliasParams) {
	return POST('/api/whatsapp/phone/updateYCloudName', params);
}
