import loadComponent from '../loadComponent';
const journeyRouter = [{
  path: 'list',
  element: loadComponent('journey/list')
}, {
  path: 'detail/:id',
  element: loadComponent('journey/detail'),
  children: [{
    path: 'build',
    element: loadComponent('journey/detail/flow')
  }, {
    path: 'analysis',
    element: loadComponent('journey/detail/analytics')
  }]
}];
export default journeyRouter;