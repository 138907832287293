import { defineMessages } from 'react-intl';
import { Data, Pagin } from 'src/utils/fetch';

export enum NodeType {
	TRIGGER = 'TRIGGER',
	SEND_TEMPLATE_MESSAGE = 'SEND_MESSAGE',
	WAIT = 'WAIT',
	ADD_TAG = 'ADD_TAG',
	MESSAGE_STATUS_RULE = 'MESSAGE_STATE_RULE',
}

export type Variable = {
	name: string;
	type: string;
};

export enum NodeErrorType {
	INFINITE_CIRCLE, //
	TRIGGER_WITHOUT_NEXT, //
	NODE_NO_CONFIG, //
	MISSING_VARIABLE,
	MISSING_VALUE,
	PARAMS_ERROR,
	MESSAGE_STATE_RULE_TEMPLATE_ERROR,
}

export const nodeErrorMessageMap = defineMessages({
	[NodeErrorType.INFINITE_CIRCLE]: {
		defaultMessage: 'Infinite Flow Detected',
	},
	[NodeErrorType.TRIGGER_WITHOUT_NEXT]: {
		defaultMessage: 'Missing connection — Trigger must be linked',
	},
	[NodeErrorType.NODE_NO_CONFIG]: {
		defaultMessage: 'Please fill out the form',
	},
	[NodeErrorType.MISSING_VARIABLE]: {
		defaultMessage: 'Abnormal parameter',
	},
	[NodeErrorType.MISSING_VALUE]: {
		defaultMessage: 'Abnormal parameter',
	},
	[NodeErrorType.PARAMS_ERROR]: {
		defaultMessage: 'Abnormal parameter',
	},
	[NodeErrorType.MESSAGE_STATE_RULE_TEMPLATE_ERROR]: {
		defaultMessage: 'Connection error — The message status rules block must be placed after the right send template block.',
	},
});

export type NodeError = {
	nodeId: string;
	type: NodeErrorType;
};

enum NodeGroup {
	ACTION,
	TRIGGER,
	RULES,
}

export const groupConfig = {
	[NodeGroup.TRIGGER]: {
		nodeHeaderColor: 'text.white',
		nodeHeaderBg: 'secondary.main',
		nodeBorderColor: 'secondary.main',
	},
	[NodeGroup.ACTION]: {
		nodeHeaderColor: 'primary.dark',
		nodeHeaderBg: '#DFDAFF',
		nodeBorderColor: 'secondary.main',
	},
	[NodeGroup.RULES]: {
		nodeHeaderColor: 'primary.dark',
		nodeHeaderBg: '#FBD5E5',
		nodeBorderColor: 'secondary.main',
	},
};

// export enum TriggerType {
// 	CONTACT = 'CONTACT',
// 	SHOPIFY = 'SHOPIFY',
// }

export type NodeConfig = {
	label: string;
	group: NodeGroup;
	limit: number;
	guideLink: string;
	cannotConnect: Array<string>;
	checkForm: boolean;
	hasSource: boolean;
	hasTarget: boolean;
	hasMultipleSource: boolean;
	maxSource: number;
	nodeColor: string;
	nodeBg: string;
	nodeBorderColor: string;
	formWidth?: number;
};

export const nodeConfigMap: {
	[key in NodeType]: NodeConfig;
} = {
	[NodeType.TRIGGER]: {
		label: 'Trigger',
		group: NodeGroup.TRIGGER,
		limit: 1,
		guideLink: '',
		cannotConnect: [],
		checkForm: true, // 是否检查表单
		hasSource: true, // 是否有source handle
		hasTarget: false, // 是否有target handle
		hasMultipleSource: false,
		maxSource: 0,
		nodeColor: 'text.white',
		nodeBg: 'secondary.main',
		nodeBorderColor: 'secondary.main',
	},
	[NodeType.SEND_TEMPLATE_MESSAGE]: {
		label: 'Send templates',
		group: NodeGroup.ACTION,
		limit: 1,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
		hasSource: true,
		hasTarget: true,
		hasMultipleSource: false,
		maxSource: 1,
		nodeColor: 'primary.dark',
		nodeBg: '#DFDAFF',
		nodeBorderColor: 'secondary.main',
		formWidth: 829,
	},
	[NodeType.ADD_TAG]: {
		label: 'Add tags',
		group: NodeGroup.ACTION,
		limit: 1,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
		hasSource: true,
		hasTarget: true,
		hasMultipleSource: false,
		maxSource: 1,
		nodeColor: 'primary.dark',
		nodeBg: '#DFDAFF',
		nodeBorderColor: 'secondary.main',
	},
	[NodeType.WAIT]: {
		label: 'Wait',
		group: NodeGroup.RULES,
		limit: 1,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
		hasSource: true,
		hasTarget: true,
		hasMultipleSource: false,
		maxSource: 1,
		nodeColor: 'primary.dark',
		nodeBg: '#FFDE9F',
		nodeBorderColor: 'secondary.main',
	},
	[NodeType.MESSAGE_STATUS_RULE]: {
		label: 'Message status rule',
		group: NodeGroup.RULES,
		limit: 1,
		guideLink: '',
		cannotConnect: [],
		checkForm: true,
		hasSource: true,
		hasTarget: true,
		hasMultipleSource: true,
		maxSource: 1,
		nodeColor: 'primary.dark',
		nodeBg: '#C8E6F9',
		nodeBorderColor: 'secondary.main',
	},
};

export const nodeLabelMap = defineMessages({
	[NodeType.TRIGGER]: {
		defaultMessage: 'Trigger',
		description: 'journey Trigger',
	},
	[NodeType.SEND_TEMPLATE_MESSAGE]: {
		defaultMessage: 'Send templates',
		description: 'journey Send templates',
	},
	[NodeType.ADD_TAG]: {
		defaultMessage: 'Add tags',
		description: 'journey Add tags',
	},
	[NodeType.WAIT]: {
		defaultMessage: 'Wait',
		description: 'journey Wait',
	},
	[NodeType.MESSAGE_STATUS_RULE]: {
		defaultMessage: 'Message status rule',
		description: 'journey Message status rule',
	},
});

export enum JourneyStatusEnum {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
}

export const JourneyStatusLabel = defineMessages({
	[JourneyStatusEnum.ACTIVE]: {
		defaultMessage: 'Active',
	},
	[JourneyStatusEnum.INACTIVE]: {
		defaultMessage: 'Inactive',
	},
});

export interface PageParams {
	pageNo?: number;
	pageSize?: number;
}

export interface GetJourneyParams extends PageParams {
	launched?: boolean;
	name?: string;
}

export interface GetBotsDataItem {
	wabaId: string;
	id: string;
	name: string;
	status: string;
	updateTime: number;
	createTime: number;
	flowData: { nodes: any[] };
	reactflow: { nodes: any[] };
}

export interface GetTriggerCountByIdsParams {
	ids: string;
}

export interface GetBotsData extends Data {
	records: Array<GetBotsDataItem>;
	pagin: Pagin;
}

export interface CreateBotParams {
	chatbotId: string;
	name: string;
}

export interface DeleteBotParams {
	id: string;
}

export interface GetBotConfigParams {
	id: string;
}

export interface UpdateBotConfigParams {
	id: string;
	basicReplyText?: string;
	basicReplyEnabled?: boolean;
}

export interface GetTriggerLogsParams extends PageParams {
	id: string;
	startTime: number;
	endTime: number;
}

export interface GetTriggerCountDataItem {
	pt: string;
	triggerCount: number;
}

export interface GetTriggerCountParams {
	id: string;
	startTime: number | null;
	endTime: number | null;
}
