import { Data } from 'src/utils/fetch';

export interface updateTenantBaseInfoParams {
	company: string;
	taxNumber: string;
	website: string;
	industry: string;
}

export interface UpdateTenantBillInfoParams {
	countryCode: string;
	province: string;
	city: string;
	postalCode: string;
	addressLine1: string;
	addressLine2: string;
}

export interface UpdateUserProfileParams {
	firstName: string;
	lastName: string;
	avatar: string;
	dashboardLanguage: string | undefined;
}

export interface UpdatePwdType {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

export type LoginAccountObject = {
	loginAccount: string;
};

export interface LoginParams extends LoginAccountObject {
	password: string;
}

export enum LoginStatus {
	NOT_FOUND,
	NORMAL,
	INIT,
}

export interface CheckAccountReturnType extends Data {
	data: {
		status: keyof typeof LoginStatus;
	};
}

export interface MobileVcodeSendParams {
	mobile: string;
	type: string;
}

export interface CurrencyCodeParams {
	currencyCode: string;
}

export interface BalanceAlertParams {
	balance: number;
	enable: boolean;
	users: any[];
}

export interface UserFactorsParams {
	userName: any;
}

export interface FactorsSwitchParams {
	multiFactorsSwitch: boolean;
}

export interface FactorsCodeParams {
	code?: string;
	factor: string;
	bizType: string;
	userName: any;
	isHideFail?: boolean;
}

export interface GetApiKeyParams {
	challengeSecret?: string;
	id?: string;
}

export enum CustomerType {
	CHANNEL = 'channel',
	CHANNEL_CUSTOMER = 'channelCustomer',
	TP = 'tp',
}
