type NodeMethod = 'removeChild' | 'insertBefore';

const fixGoogleTranslateError = (): void => {
	if (typeof Node !== 'function' || !Node.prototype) return;

	const wrapNodeMethod = (methodName: NodeMethod, errorMessage: string) => {
		const originalMethod = Node.prototype[methodName] as Function;
		
		Node.prototype[methodName] = function(this: Node, ...args: any[]) {
			const child = args[0];
			const referenceNode = methodName === 'insertBefore' ? args[1] : null;

			if ((methodName === 'removeChild' && child.parentNode !== this) ||
				(methodName === 'insertBefore' && referenceNode && referenceNode.parentNode !== this)) {
				console.error(errorMessage, ...(methodName === 'insertBefore' ? [referenceNode, this] : [child, this]));
				return child;
			}

			return originalMethod.apply(this, args);
		};
	};

	wrapNodeMethod('removeChild', 'Cannot remove a child from a different parent');
	wrapNodeMethod('insertBefore', 'Cannot insert before a reference node from a different parent');
};

export default fixGoogleTranslateError;
