import { FC } from 'react';
import { List } from '@mui/material';
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';
import { useAppSelector } from 'src/app/hooks';
import { getIsExpand } from 'src/features/setting/settingSlice';
interface IProps {
  item: any;
}
const NavGroup: FC<IProps> = ({
  item
}) => {
  const isExpand = useAppSelector(getIsExpand);
  const navCollapse = item.children?.map((menuItem: any) => {
    switch (menuItem.type) {
      case 'collapse':
        return <NavCollapse key={menuItem.id} menu={menuItem} level={menuItem.level} />;
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={menuItem.level} />;
      default:
        return null;
    }
  });
  return <List sx={{
    padding: 0,
    margin: 0,
    zIndex: 0,
    '&:not(:last-child)::after': {
      content: '""',
      height: '1px',
      display: 'block',
      boxSizing: 'border-box',
      background: '#D7D5DF',
      margin: isExpand ? '8px 16px' : '8px',
      opacity: 0.6
    }
  }}>
			{navCollapse}
		</List>;
};
export default NavGroup;