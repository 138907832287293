import { CreateBotParams, DeleteBotParams, GetBotByIdParams, GetBotConfigParams, GetBotsParams, GetTriggerCountByIdsParams, GetTriggerCountParams, GetTriggerLogsParams, UpdateBotConfigParams, UpdateBotParams, GetColumnsParams, GetAnswersParams, ExportAnswersParams } from './type';
import { GET, POST, UPLOAD } from 'src/utils/fetch';
import httpRequest from 'src/utils/request';

export async function testCallApi(params: any) {
	return POST('/api/whatsapp/bots/testCallApi', params, true);
}

export async function getBots(params: GetBotsParams) {
	return GET('/api/whatsapp/bots/list', params, true);
}

export async function getTriggerCountByIds(params: GetTriggerCountByIdsParams) {
	return GET('/api/whatsapp/bots/analytics/batchGetLatest', params, true, true);
}

export async function deleteBot(params: DeleteBotParams) {
	return POST('/api/whatsapp/bots/delete', params);
}

export async function createBot(params: CreateBotParams) {
	return POST('/api/whatsapp/bots/create', params);
}

export async function getBotConfig(params: GetBotConfigParams) {
	return GET('/api/whatsapp/bots/wabaSettings/get', params, true);
}

export async function updateBotConfig(params: UpdateBotConfigParams) {
	return POST('/api/whatsapp/bots/wabaSettings/update', params);
}

export async function createPhoneNumberConfig(params: UpdateBotConfigParams) {
	return POST('/api/whatsapp/bots/phoneNumberBasicReplySettings/create', params);
}

export async function updatePhoneNumberConfig(params: UpdateBotConfigParams) {
	return POST('/api/whatsapp/bots/phoneNumberBasicReplySettings/update', params);
}

export async function deletePhoneNumberConfig(params: UpdateBotConfigParams) {
	return POST('/api/whatsapp/bots/phoneNumberBasicReplySettings/delete', params);
}

export async function getPhoneNumber(params: { wabaId: string }) {
	return GET('/api/whatsapp/phone/getBinded', params, true);
}

export async function getTriggerCount(params: GetTriggerCountParams) {
	return GET('/api/whatsapp/bots/analytics/timeSeries', params, true);
}

export async function getTriggerLogs(params: GetTriggerLogsParams) {
	return GET('/api/whatsapp/bots/analytics/triggerLogs', params, true);
}

export async function getBotById(params: GetBotByIdParams) {
	return GET('/api/whatsapp/bots/get', params, true, false);
}

export async function uploadMediaFile(params: any) {
	return UPLOAD('/api/whatsapp/bots/uploadMessageMedia', params, true, true);
}

export async function updateBot(params: UpdateBotParams) {
	return POST('/api/whatsapp/bots/update', params);
}

export async function getNewAllFilter() {
	return GET('/api/whatsapp/bots/listAllContactAttributes', undefined, true, false);
}

export async function getCoupons() {
	return GET('/api/coupon/activity/available', undefined, true, false);
}

export async function getColumns(params: GetColumnsParams) {
	return httpRequest.get({
		url: '/api/whatsapp/bots/analytics/conversationAnswersFilter',
		params,
		options: {
			returnData: true,
		},
	});
}

export async function getAnswers(params: GetAnswersParams) {
	return GET('/api/whatsapp/bots/analytics/conversationAnswers', params, true, false);
}

export async function exportAnswers(params: ExportAnswersParams) {
	return httpRequest.post({
		url: '/api/whatsapp/bots/analytics/conversationAnswers/export',
		params,
		options: {
			returnData: true,
			showErrorMessage: true,
		},
	});
}
export async function exportAnswersProgress(params: { id: string }, config: any) {
	return httpRequest.get({
		url: '/api/whatsapp/bots/analytics/conversationAnswers/export/get',
		params,
		options: {
			returnData: true,
			showErrorMessage: true,
		},
		axiosConfig: config,
	});
}
