import { defineMessages } from 'react-intl';

// template status
export const TEMPLATE_STATUS = {
	APPROVED: 'APPROVED', // 通过审核
	PENDING: 'PENDING', //  待审核
	REJECTED: 'REJECTED', // 被否决
	PAUSED: 'PAUSED',
	DISABLED: 'DISABLED',
	IN_APPEAL: 'IN_APPEAL',
};

// quality rating
export const qualityRatingMap: any = defineMessages({
	UNKNOWN: {
		defaultMessage: 'Quality pending',
	},
	GREEN: {
		defaultMessage: 'High quality',
	},
	YELLOW: {
		defaultMessage: 'Medium quality',
	},
	RED: {
		defaultMessage: 'Low quality',
	},
});

// template status
export const TEMPLATE_STATUS_LABEL = defineMessages({
	APPROVED: {
		defaultMessage: 'Active',
	}, // 通过审核
	PENDING: { defaultMessage: 'In review' }, //  待审核
	REJECTED: { defaultMessage: 'Rejected' }, // 被否决
	PAUSED: { defaultMessage: 'Paused' },
	DISABLED: { defaultMessage: 'Disabled' },
	IN_APPEAL: { defaultMessage: 'Appealed - In review' },
});

// template status
export const TEMPLATE_STATUS_COLOR: any = {
	'APPROVED-UNKNOWN': 'success',
	'APPROVED-GREEN': 'success',
	'APPROVED-YELLOW': 'warning',
	'APPROVED-RED': 'error',
	PENDING: 'default',
	REJECTED: 'error',
	PAUSED: 'default',
	DISABLED: 'error',
	// APPEALED: 'default',
	IN_APPEAL: 'default',
};

export const CATEGORY = {
	UTILITY: 'UTILITY',
	MARKETING: 'MARKETING',
	AUTHENTICATION: 'AUTHENTICATION',
	TRANSACTIONAL: 'TRANSACTIONAL',
	OTP: 'OTP',
};

export const CATEGORY_MAP = {
	[CATEGORY.UTILITY]: 'Utility',
	[CATEGORY.MARKETING]: 'Marketing',
	[CATEGORY.AUTHENTICATION]: 'Authentication',
	[CATEGORY.TRANSACTIONAL]: 'Transactional',
	[CATEGORY.OTP]: 'One-time passwords',
};

export const CATEGORY_LABEL_MAP = defineMessages({
	[CATEGORY.UTILITY]: {
		defaultMessage: 'Utility',
	},
	[CATEGORY.MARKETING]: {
		defaultMessage: 'Marketing',
	},
	[CATEGORY.AUTHENTICATION]: {
		defaultMessage: 'Authentication',
	},
	[CATEGORY.TRANSACTIONAL]: {
		defaultMessage: 'Transactional',
	},
	[CATEGORY.OTP]: {
		defaultMessage: 'One-time passwords',
	},
});

export const MSGLIMIT: any = defineMessages({
	TIER_50: {
		defaultMessage: '50 customers',
	},
	TIER_250: {
		defaultMessage: '250 customers',
	},
	TIER_1K: {
		defaultMessage: '1000 customers',
	},
	TIER_10K: {
		defaultMessage: '10k customers',
	},
	TIER_100K: {
		defaultMessage: '100k customers',
	},
	TIER_UNLIMITED: {
		defaultMessage: 'Unlimited',
	},
});

export const WhatsappLogsStatus: any = defineMessages({
	sent: { defaultMessage: 'Processing' },
	delivered: { defaultMessage: 'Delivered' },
	read: { defaultMessage: 'Read' },
	failed: { defaultMessage: 'Failed' },
});

export const categoryOptions: any = {
	UNDEFINED: 'Undefined',
	OTHER: 'Other',
	AUTO: 'Automotive',
	BEAUTY: 'Beauty, Spa, and Salon',
	APPAREL: 'Clothing and Apparel',
	EDU: 'Education',
	ENTERTAIN: 'Entertainment',
	EVENT_PLAN: 'Event Planning and Service',
	FINANCE: 'Finance and Banking',
	GROCERY: 'Food and Grocery',
	GOVT: 'Public service',
	HOTEL: 'Hotel and Lodging',
	HEALTH: 'Medical and Health',
	NONPROFIT: 'Non-profit',
	PROF_SERVICES: 'Professional Services',
	RETAIL: 'Shopping and Retail',
	TRAVEL: 'Travel and Transportation',
	RESTAURANT: 'Restaurant',
	NOT_A_BIZ: 'Personal',
};

export const categoryLabelMap: any = defineMessages({
	UNDEFINED: {
		defaultMessage: 'Undefined',
	},
	OTHER: {
		defaultMessage: 'Other',
	},
	AUTO: {
		defaultMessage: 'Automotive',
	},
	BEAUTY: {
		defaultMessage: 'Beauty, Spa, and Salon',
	},
	APPAREL: {
		defaultMessage: 'Clothing and Apparel',
	},
	EDU: {
		defaultMessage: 'Education',
	},
	ENTERTAIN: {
		defaultMessage: 'Entertainment',
	},
	EVENT_PLAN: {
		defaultMessage: 'Event Planning and Service',
	},
	FINANCE: {
		defaultMessage: 'Finance and Banking',
	},
	GROCERY: {
		defaultMessage: 'Food and Grocery',
	},
	GOVT: {
		defaultMessage: 'Public service',
	},
	HOTEL: {
		defaultMessage: 'Hotel and Lodging',
	},
	HEALTH: {
		defaultMessage: 'Medical and Health',
	},
	NONPROFIT: {
		defaultMessage: 'Non-profit',
	},
	PROF_SERVICES: {
		defaultMessage: 'Professional Services',
	},
	RETAIL: {
		defaultMessage: 'Shopping and Retail',
	},
	TRAVEL: {
		defaultMessage: 'Travel and Transportation',
	},
	RESTAURANT: {
		defaultMessage: 'Restaurant',
	},
	NOT_A_BIZ: {
		defaultMessage: 'Personal',
	},
});

export type LogSearchValueParams = {
	sendTime: any;
	deliverTime: any;
	to: string;
	from: string;
	region: any;
	status: Array<string>;
	direction: string;
	templateName?: string;
};
