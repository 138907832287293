import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Box, Button, Tooltip, Typography } from '@mui/material';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { ExportIcon, SuccessIcon, TagIcon, TableSettingIcon } from 'src/app/icons';
import PageBlock from 'src/app/components/pageContainer/components/PageBlock';
import SimpleTable from 'src/app/components/table/SimpleTable';
import TwoFactorAuthDialog from 'src/app/template/setting/twoFactorAuthDialog';
import useTableData from 'src/app/customHooks/useTable';
import useInput from 'src/app/customHooks/useInput';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { selectUser } from 'src/features/user/userSlice';
import { batchAddTagsAsync, downLoadExportAsync, exportContactAsync, getContactListAsync, selectCurSeg, selectTableData, selectTablePagin } from 'src/features/contact/contactList/contactListSlice';
import { showMessage } from 'src/utils/message';
import AddTagDialog from './AddTagDialog';
import AdminVerify from './AdminVerify';
import FilterFieldPopover from './FilterFieldPopover';
import EmptyImage from 'src/app/components/imageContainer/EmptyImage';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
interface IProps {
  columnData: Array<any>;
  sourceColumnData: Array<any>;
  isExpand: boolean;
  isSale: boolean;
  columnsComputing: boolean;
  onEditUser: (e: string, i?: any) => void;
  onUpdateColumn: (keys: string[]) => void;
}
export type Ref = any;
const ContactTable = forwardRef<Ref, IProps>(({
  columnData,
  sourceColumnData,
  isExpand,
  isSale,
  onEditUser,
  onUpdateColumn,
  columnsComputing
}, ref) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const curSeg = useAppSelector(selectCurSeg);
  const tableData = useAppSelector(selectTableData);
  const tablePagin = useAppSelector(selectTablePagin);
  const tableRef = useRef();
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const [selected, setSelected] = useState<Array<any>>([]);
  const [openAddTag, setOpenAddTag] = useState<boolean>(false);
  const [openTFA, setOpenTFA] = useState<boolean>(false);
  const [openAdminVer, setOpenAdminVer] = useState<boolean>(false);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [exportSuccess, setExportSuccess] = useState<boolean>(false);
  const [onExport, setOnExport] = useState<boolean>(false);
  const [showFilterField, setShowFilterField] = useState(false);
  const [filterFieldAnchorEl, setFilterFieldAnchorEl] = useState<SVGSVGElement | null>(null);
  const {
    refresh,
    handleSearch,
    handlePageChange,
    loading,
    setLoading,
    searchFormParams,
    setSearchFormParams,
    resData
  } = useTableData(getContactListAsync);
  const {
    handleInputSearch
  } = useInput(handleSearch);
  const handleSelect = (e: any) => {
    setSelected(e);
  };
  const handleSort = (e: any) => {
    let sortParams: any = {};
    Object.entries(e).forEach((i: any) => {
      sortParams = {
        ...sortParams,
        [i[0]]: i[1] === 'desc'
      };
    });
    handleSearch({
      ...searchFormParams,
      ...sortParams
    });
  };
  const addTagSubmit = async (e: any) => {
    /* Tag ID 改造 */
    const tags = e.map((i: any) => ({
      tag: i.tag,
      color: i.color,
      id: i.id
    }));
    const res: any = await dispatch(batchAddTagsAsync({
      contactIds: selected,
      tags: tags,
      ...(searchFormParams.query && {
        query: searchFormParams.query
      }),
      ...(!searchFormParams.query && searchFormParams)
    })).unwrap();
    if (res.code === CODE.OK) {
      setOpenAddTag(false);
      refresh();
    }
  };
  const downLoadFunc = async (id: string) => {
    setExportSuccess(true);
    let time = 0;
    let contactExportInterval: any = setInterval(async () => {
      try {
        time++;
        const res: any = await dispatch(downLoadExportAsync({
          exportRecordId: id
        })).unwrap();
        if (res.code === CODE.OK) {
          if (res.data.finished) {
            window.location.href = res.data.url;
            clearInterval(contactExportInterval);
            contactExportInterval = null;
            if (time > 5) {
              setExportSuccess(false);
              setOnExport(false);
            } else {
              let timer: any = setInterval(() => {
                time++;
                if (time > 5) {
                  setExportSuccess(false);
                  setOnExport(false);
                  clearInterval(timer);
                  timer = null;
                }
              }, 1000);
            }
          }
        }
      } catch (err) {
        clearInterval(contactExportInterval);
        contactExportInterval = null;
      }
    }, 1000);
  };
  const handleExport = async () => {
    // 导出动作
    const res: any = await dispatch(exportContactAsync({
      searchConditions: searchFormParams,
      contactIds: selected,
      ...(curSeg === 'blocklist' && {
        blocked: true
      })
    }));
    const resDa = res.payload;
    if (resDa.code === CODE.ON_EXPORT) {
      showMessage({
        type: 'error',
        message: resDa.msg
      });
      const id = resDa.data.exportRecordId;
      downLoadFunc(id);
    } else if (resDa.code === CODE.OK) {
      setOpenTFA(false);
      setOpenAdminVer(false);
      downLoadFunc(resDa.data.exportRecordId);
    } else {
      showMessage({
        type: 'error',
        message: resDa.msg
      });
    }
  };
  const handleExportClick = () => {
    if (user?.role === 'ADMIN') {
      setOpenTFA(true);
    } else {
      setOpenAdminVer(true);
    }
  };
  const handleEditInfo = (e: any) => {
    // event.preventDefault()
    // event.stopPropagation()
    onEditUser('info', e);
  };
  const scrollFunc = (event: any) => {
    const id = event.target.id;
    if (id === 'pageContent') {
      if (!event.srcElement.scrollTop) {
        //处理向上使劲滚动的时候scrollTop为undefined
        return undefined;
      }
      // 滚动的高度
      const scrollTop = (event.srcElement ? event.srcElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
      const el: any = tableRef.current;
      const elHeight = el.clientHeight;
      if (scrollTop > 250 && elHeight > window.innerHeight) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }
  };
  const handleToggleShowFilterPopover = () => {
    setShowFilterField(!showFilterField);
  };
  const handleShowFilterPopover = (e: any) => {
    setFilterFieldAnchorEl(e.currentTarget);
    setShowFilterField(true);
  };
  const handleFilterFieldChange = (keys: string[]) => {
    onUpdateColumn(keys);
  };
  useImperativeHandle(ref, () => ({
    refresh,
    handleSearch,
    handleInputSearch,
    setLoading,
    setSearchFormParams
  }));
  useEffect(() => {
    if (resData?.export && !resData.export.finished) {
      setOnExport(true);
      downLoadFunc(resData.export.exportRecordId);
    }
    window.addEventListener('scroll', scrollFunc, true);
    return () => {
      window.removeEventListener('scroll', scrollFunc, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resData]);
  return <PageBlock isLoading={loading || columnsComputing} sx={{
    ...(isScroll && {
      pb: 0
    })
  }}>
			{(exportSuccess || onExport) && <Alert severity="success" icon={<SuccessIcon sx={{
      color: '#fff'
    }} />} onClose={() => setExportSuccess(false)} sx={{
      width: '496px',
      color: 'primary.contrastText',
      backgroundColor: '#11BB82 !important',
      position: 'fixed',
      top: 0,
      left: '50%',
      transform: 'translate(-50%)',
      zIndex: 1000,
      '& .MuiAlert-action': {
        color: '#fff !important',
        '& .MuiButtonBase-root': {
          p: 0
        }
      }
    }}>
					{onExport ? formatMessage({
        defaultMessage: 'contacts export is processing, please wait for it.'
      }) : formatMessage({
        defaultMessage: 'Congratulations, the verification is successful, we will start downloading immediately'
      })}
				</Alert>}
			<Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    }}>
				<Box sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 1
      }}>
					<Typography variant="h2">
						{selected.length > 0 ? `${selected.length} ${curSeg !== 'blocklist' ? 'contact' : 'user'}${selected.length > 1 ? 's' : ''} selected of ${tablePagin && tablePagin.totalCount ? tablePagin.totalCount : 0}` : `${tablePagin && tablePagin.totalCount ? tablePagin.totalCount : 0} ${curSeg !== 'blocklist' ? formatMessage({
            defaultMessage: 'Contacts'
          }) : formatMessage({
            defaultMessage: 'Users'
          })}`}
					</Typography>
					{curSeg !== 'blocklist' && <Button startIcon={<TagIcon />} onClick={() => setOpenAddTag(true)} sx={{
          borderRadius: '6px',
          px: '9px'
        }}>
							<FormattedMessage defaultMessage={'Add tag'} />
						</Button>}
					{isSale && <Button startIcon={<ExportIcon />} onClick={isOtherBrand ? handleExport : handleExportClick} sx={{
          borderRadius: '6px',
          px: '9px'
        }}>
							<FormattedMessage defaultMessage={'Export'} />
						</Button>}
				</Box>
				{curSeg !== 'blocklist' && <Tooltip title={formatMessage({
        defaultMessage: 'Set Displayed attributes'
      })} arrow placement="top">
						<Box sx={{
          backgroundColor: 'action.hover',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          display: 'grid',
          placeItems: 'center',
          ml: 'auto',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: '#DFD5FB'
          },
          ...(showFilterField ? {
            backgroundColor: '#DFD5FB'
          } : {})
        }} onClick={handleShowFilterPopover}>
							<TableSettingIcon sx={{
            fontSize: '24px',
            cursor: 'pointer'
          }} />
						</Box>
					</Tooltip>}
			</Box>
			<Box ref={tableRef} sx={{
      pb: tableData?.length ? '8px' : '107px'
    }}>
				{tableData?.length === 0 ? <EmptyImage sx={{
        width: '220px',
        height: '220px',
        margin: '100px auto 0'
      }} /> : <SimpleTable pagin={tablePagin} onPageChange={handlePageChange} columns={columnData} tableData={tableData} keyWord="contactId" tableStyle={{
        '& tr > td:nth-child(2)': {
          position: 'sticky',
          left: '74px',
          bgcolor: 'background.surface',
          zIndex: 1,
          background: '#fff',
          boxShadow: '-1px 0px 0px 0px #E7E6EF inset'
        },
        '& tr:hover > td:nth-child(2)': {
          background: '#F5F4F9'
        },
        'td, th': {
          py: '10px',
          height: '26px'
        },
        'td > *, th > *': {
          verticalAlign: 'middle'
        },
        '& tr > th:nth-child(2)': {
          position: 'sticky',
          left: '74px',
          bgcolor: 'background.surface',
          zIndex: 6
        },
        '& tr > td:nth-child(1)': {
          position: 'sticky',
          left: 0,
          bgcolor: 'background.surface',
          zIndex: 1,
          background: '#fff'
        },
        '& tr:hover > td:nth-child(1)': {
          background: '#F5F4F9'
        },
        '& tr > th:nth-child(1)': {
          position: 'sticky',
          left: 0,
          bgcolor: 'background.surface',
          zIndex: 6
        }
      }} onSelect={handleSelect} onRowClick={handleEditInfo} onRequestSort={handleSort} isExpand={isExpand} isFixed isChange={curSeg} offset={48} />}
			</Box>
			<AddTagDialog count={selected.length ? selected.length : tablePagin && tablePagin.totalCount ? tablePagin.totalCount : 0} open={openAddTag} onClose={() => setOpenAddTag(false)} onSubmit={addTagSubmit} />
			<TwoFactorAuthDialog open={openTFA} action="export-contacts" onClose={() => setOpenTFA(false)} onOk={handleExport} />
			<AdminVerify open={openAdminVer} onClose={() => setOpenAdminVer(false)} onOk={handleExport} />
			<FilterFieldPopover open={showFilterField} onClose={() => handleToggleShowFilterPopover()} authorEl={filterFieldAnchorEl as Element} sourceColumnData={sourceColumnData} onChange={handleFilterFieldChange} />
		</PageBlock>;
});
export default ContactTable;